/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';

import { Button, Layout, message, PageHeader, Tabs, Tooltip } from 'antd';
import { nanoid } from 'nanoid';
import queryString from 'query-string';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { CloudUploadOutlined } from '@ant-design/icons';

import { apiPost } from '../../../../api';
import apiAnyGet from '../../../../api/AnyGet';
import GTooltip from '../../../components/GTooltip/GTooltip';
import XDataForm, { XDF_TYPE } from '../../../components/xdf-antd/XDataForm';
import { KYB_PRODUCT_TYPE } from '../../../constants';
import KybSiteProductElements from './KybSiteProductElements';
import styles from './styles.scss';
import KybSiteProductFiles from './KybSiteProductFiles';

const defModel = {
  status: 0,
};

export default function KybSiteProductEditor() {
  const history = useHistory();
  const location = useLocation();
  const qs = queryString.parse(location.search);

  const [model, setModel] = useState(defModel);
  const [isModelChanged, setIsModelChanged] = useState(false);

  const { productId } = useParams();

  const fetchSiteNews = async (id) => {
    const data = await apiAnyGet('KybDb:Product')(id, []);
    setModel({
      ...data,
      elements: (data.elements || []).map((x) => ({
        ...x,
        _id: x._id || nanoid(),
      })),
      certificates: (data.certificates || []).map((x) => ({
        ...x,
        _id: x._id || nanoid(),
      })),
      manuals: (data.manuals || []).map((x) => ({
        ...x,
        _id: x._id || nanoid(),
      })),
    });
    setIsModelChanged(false);
  };

  useEffect(() => {
    if (productId !== '--new--') {
      fetchSiteNews(productId);
    }
  }, [productId]);

  const onChangeTab = (key) => {
    history.push(`?tab=${key}`);
  };

  const onChangeModel = (m, is) => {
    setModel({ ...m });
    setIsModelChanged(is);
  };

  const onSaveBlocks = (blocks) => {
    // console.log('onSaveBlocks', blocks);
    setModel({
      ...model,
      blocks: [...blocks],
    });
    setIsModelChanged(true);
  };

  const onSaveNews = async () => {
    const { err, errmsg, data } = await apiPost('/site/product/set', {
      ...model,
    });
    if (err === 0) {
      setIsModelChanged(false);
      if (data._id !== productId) {
        history.replace(`/site/product/${data._id}`);
      }
    } else if (Array.isArray(errmsg)) {
      for (const msg of errmsg) {
        message.error(msg.message);
      }
    } else {
      message.error(errmsg || `Net error ${err}`);
    }
  };

  return (
    <>
      <PageHeader
        className={styles.pageHeader}
        title={<GTooltip tid="kyb_site_product_editor">Редактирование продукта</GTooltip>}
        onBack={() => history.push('/site/products')}
        extra={[
          <Tooltip key="save" title="Сохранить">
            <Button
              type="primary"
              shape="circle"
              icon={<CloudUploadOutlined />}
              onClick={onSaveNews}
              disabled={!isModelChanged}
            />
          </Tooltip>,
        ]}
      />
      <Layout.Content>
        <div className={styles.contextHolder}>
          <div className={styles.contextPage}>
            <Tabs
              activeKey={qs.tab || 'main_page'}
              type="card"
              tabPosition="right"
              onChange={onChangeTab}
            >
              <Tabs.TabPane tab="Главная страница" key="main_page">
                <XDataForm
                  model={model}
                  onChangeModel={onChangeModel}
                  form={[
                    {
                      type: XDF_TYPE.SELECT,
                      label: 'Статус новости',
                      prop: 'status',
                      values: KYB_PRODUCT_TYPE,
                      span: 16,
                    },
                    {
                      type: XDF_TYPE.NUMBER,
                      label: 'Вес продукта для сортировки',
                      prop: 'order',
                      span: 8,
                    },
                    {
                      type: XDF_TYPE.TEXT,
                      label: 'Название продукта на главной странице',
                      prop: 'name',
                      span: 12,
                      autoFocus: true,
                    },
                    {
                      type: XDF_TYPE.TEXT,
                      label: 'Имя url продукта (/product/{productUrl})',
                      prop: 'url',
                      span: 12,
                    },
                    {
                      type: XDF_TYPE.TEXT,
                      label: 'Описание продукта на главной странице',
                      prop: 'homeDesc',
                      span: 24,
                    },
                    {
                      type: XDF_TYPE.TAGS,
                      label: 'Теги для загрузки доп. информации к продукту',
                      prop: 'tags',
                      values: [],
                      span: 24,
                    },
                    {
                      type: XDF_TYPE.PICTURE_UPLOAD,
                      label: 'Укажите картинку продукта для главной страницы',
                      prop: 'homePic',
                      span: 24,
                    },
                  ]}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Главное фото" key="main_pic">
                <XDataForm
                  model={model}
                  onChangeModel={onChangeModel}
                  form={[
                    {
                      type: XDF_TYPE.PICTURE_UPLOAD,
                      label: 'Укажите картинку продукта для главной страницы',
                      prop: 'pic',
                      picSize: 400,
                      span: 24,
                    },
                  ]}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Описание" key="desc">
                <XDataForm
                  model={model}
                  onChangeModel={onChangeModel}
                  form={[
                    {
                      type: XDF_TYPE.TEXTAREA,
                      label: 'Подробное описание продукта',
                      prop: 'desc',
                      rows: 10,
                      autoSize: true,
                      span: 24,
                    },
                  ]}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Доп. фотографии" key="pics">
                <XDataForm
                  model={model}
                  onChangeModel={onChangeModel}
                  form={[
                    {
                      type: XDF_TYPE.GALLERY_UPLOAD,
                      prop: 'pics',
                      simpleArray: true,
                      span: 24,
                    },
                  ]}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Элементы" key="elements">
                <KybSiteProductElements model={model} onChangeModel={onChangeModel} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Инструкции" key="inst">
                <KybSiteProductFiles prop="manuals" model={model} onChangeModel={onChangeModel} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Сертификаты" key="cert">
                <KybSiteProductFiles
                  prop="certificates"
                  model={model}
                  onChangeModel={onChangeModel}
                />
              </Tabs.TabPane>
            </Tabs>
          </div>
        </div>
      </Layout.Content>
    </>
  );
}
