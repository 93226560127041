import React from 'react';

import { Col } from 'antd';
import PropTypes from 'prop-types';

import styles from './styles.scss';

function XDFDivider({ it, size, fontColor }) {
  const { label, labelSup, span } = it;

  return (
    <Col span={span || 24}>
      <div className={styles.formItem}>
        <div className={styles.formItemLabel}>
          <div className={styles.dividerItem} style={{ color: fontColor }}>
            {label} {!!labelSup && <sup>{labelSup}</sup>}
          </div>
        </div>
      </div>
    </Col>
  );
}
XDFDivider.defaultProps = {
  fontColor: '#40a9ff',
};

XDFDivider.propTypes = {
  it: PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    labelSup: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.bool]),
    span: PropTypes.number,
  }).isRequired,
  size: PropTypes.string.isRequired,
  fontColor: PropTypes.string,
};

export default XDFDivider;
