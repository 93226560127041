import React, { useEffect, useState } from 'react';

import { Col, Input } from 'antd';
import cn from 'classnames';
import gv from 'get-value';
import PropTypes from 'prop-types';

import styles from './styles.scss';

function XDFLatLng({ it, size, model, onPropChanged }) {
  const { id, prop, label, placeholder, autoFocus, span, readOnly, showOnly, suffix } = it;

  const [loc, setLoc] = useState('');

  const val = (gv(model, prop) || []).join(', ');
  useEffect(() => {
    if (val !== loc) setLoc(val);
  }, [val]);

  function onChange(ev) {
    const v = ev.target.value;
    setLoc(v);

    const ll = v
      .split(',')
      .map((x) => x.trim())
      .map((x) => parseFloat(x));

    if (Array.isArray(ll) && ll.length === 2 && !isNaN(ll[0]) && !isNaN(ll[1])) {
      onPropChanged(it, ll);
    }
  }

  return (
    <Col span={span || 24}>
      <div className={styles.formItem}>
        <div className={styles.formItemLabel}>
          <label htmlFor={id}>{label}</label>
        </div>
        <div
          className={cn(
            styles.formItemField,
            { [styles.small]: size === 'small' },
            { [styles.showOnly]: !!showOnly },
          )}
        >
          <Input
            id={id}
            size={size}
            placeholder={placeholder || ''}
            autoFocus={!!autoFocus}
            disabled={!!readOnly || !!showOnly}
            value={loc}
            onChange={onChange}
            suffix={suffix}
          />
        </div>
      </div>
    </Col>
  );
}

XDFLatLng.propTypes = {
  it: PropTypes.shape({
    id: PropTypes.string.isRequired,
    prop: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    placeholder: PropTypes.string,
    autoFocus: PropTypes.bool,
    span: PropTypes.number,
    readOnly: PropTypes.bool,
    showOnly: PropTypes.bool,
    suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  }).isRequired,
  size: PropTypes.string.isRequired,
  model: PropTypes.shape({}).isRequired,
  onPropChanged: PropTypes.func.isRequired,
};

export default XDFLatLng;
