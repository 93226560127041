/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useMemo, useState } from 'react';

import { Col, Row } from 'antd';
import cn from 'classnames';
// eslint-disable-next-line import/no-extraneous-dependencies
import dayjs from 'dayjs';
import { nanoid } from 'nanoid';
import objectHash from 'object-hash';
import PropTypes from 'prop-types';

import { DEFAULT_DATEFORMAT, DEFAULT_TIMEFORMAT } from './constants';
import styles from './styles.scss';
import xdfGetValue from './xdf-get-value';
import xdfSetValue from './xdf-set-value';
import XDFButton from './XDFButton';
import XDFCheckbox from './XDFCheckbox';
import XDFColor from './XDFColor';
import XDFDateRange from './XDFDateRangePicker';
import XDFDateTime from './XDFDateTimePicker';
import XDFDivider from './XDFDivider';
import XDFDividerWithCheckbox from './XDFDividerWithCheckbox';
import XDFEmail from './XDFEmail';
import XDFGalleryUpload from './XDFGalleryUpload';
import XDFGeocoder from './XDFGeocoder';
import XDFLatLng from './XDFLatLng';
// eslint-disable-next-line import/no-cycle
import XDFModalTableForm from './XDFModalTableForm';
import XDFNumber from './XDFNumber';
import XDFPhone from './XDFPhone';
import XDFPhoneSmsCode from './XDFPhoneSmsCode';
import XDFPictureUpload from './XDFPictureUpload';
import XDFSearch from './XDFSearch';
import XDFSelect from './XDFSelect';
import XDFShowDateTime from './XDFShowDateTime';
import XDFShowFileSize from './XDFShowFileSize';
import XDFShowText from './XDFShowText';
import XDFSwitch from './XDFSwitch';
import XDFTableSimple from './XDFTableSimple';
import XDFTags from './XDFTags';
import XDFText from './XDFText';
import XDFTextarea from './XDFTextarea';
import XDFTime from './XDFTimePicker';
import XDFUploadBase64 from './XDFUploadBase64';
import XDFUploadBigFile from './XDFUploadBigFile';
import XDFUrl from './XDFUrl';
import XDFWysiwyg2 from './XDFWysiwyg2';
import XDFNumberMinMax from './XDFNumberMinMax';
import XDFVideo from './XDFVideo';
import XDFTimeRange from './XDFTimeRange';
import XDFPassword from './XDFPassword';
import XDFSwitch3 from './XDFSwitch3';
import XDFRadio from './XDFRadio';
import deepClone from './deepClone';

// eslint-disable-next-line import/no-extraneous-dependencies
require('dayjs/locale/ru');
const weekday = require('dayjs/plugin/weekday');
const localeData = require('dayjs/plugin/localeData');
const customParseFormat = require('dayjs/plugin/customParseFormat');

dayjs.locale('ru');
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(customParseFormat);

// Типы полей формы
export const XDF_TYPE = {
  TEXT: 1,
  TEXTAREA: 2,
  NUMBER: 3,
  CHECKBOX: 4,
  SWITCH: 5,
  SELECT: 7,
  MULTISELECT: 8,
  TAGS: 9,
  DATETIME: 10,
  TIME: 11,
  DATERANGE: 12,
  DIVIDER: 13,
  GEOCODER: 14,
  BUTTON: 16,
  PHONE: 17,
  PHONE_SMS_CODE: 18,
  SHOW_DATETIME: 19,
  LAT_LNG: 20,
  EMAIL: 21,
  MODAL_TABLE_FORM: 22,
  SPACE: 23,
  URL: 25,
  WYSIWYG: 26,
  UPLOAD_BASE64: 27,
  SHOW_TEXT: 28,
  COLOR: 29,
  DIVIDER_WITH_CECKBOX: 30,
  TABLE_SIMPLE: 31,
  SEARCH: 32,
  GALLERY_UPLOAD: 34,
  PICTURE_UPLOAD: 35,
  SHOW_FILE_SIZE: 36,
  UPLOAD_BIG_FILE: 37,
  NUMBER_MIN_MAX: 38,
  VIDEO: 39,
  TIMERANGE: 40,
  PASSWORD: 41,
  SWITCH3: 43,
  RADIO: 44,
};

/**
 *
 * @param {*} form.item
 *    type        - XFD_TYPE
 *    prop        - название свойства (возможно использовать dot notation)
 *    label       - название подписи
 *    span        - количество колонок (макс 24)
 *    hide        - =true - скрыть поле
 *    readOnly    - =true - поле только для чтения
 *    autoFocus   - =true - установить фокус на элементе
 *    defValue    - значение по умолчанию, если оно не установлено (устанавливается при инициализации)
 *    required    - обязательное поле
 *    placeholder - для Input подсказака
 *
 * @param onChangeModel вызывется с параметрами
 *    model      - измененная модель
 *    isChanged  - =true - модель измненилась
 *    isError    - в форме ошибка
 *
 */
function XDataForm({
  debug,
  inline,
  title,
  size,
  width,
  table,
  form,
  model,
  plain,
  onChangeModel,
  onPressEnter,
  // eslint-disable-next-line react/prop-types
  children,
}) {
  const [defModel, setDefModel] = useState({});
  const [updateModelDefVal, setUpdateModelDefVal] = useState(false);

  // подсчитаем HASH формы
  const formHash = objectHash({
    form: form
      .filter((x) => !x.hide)
      .map((x) => {
        // eslint-disable-next-line no-param-reassign
        if (!x.id) x.id = nanoid(7);
        return {
          version: x.version,
          placeholder: x.placeholder,
          type: x.type,
          label: x.label,
          span: x.span,
          readOnly: x.readOnly,
          showOnly: x.showOnly,
          autoFocus: x.autoFocus,
          switchBtn: x.switchBtn,
          __valuesHash: x.values && objectHash(x.values),
          __filterValuesHash: x.filterValues && objectHash(x.filterValues),
        };
      }),
  });
  // console.log('formHash', formHash);

  // добаивть уникальный ключ к каждой записи
  const formOut = useMemo(
    () =>
      form
        .filter((x) => !x.hide)
        .map((x, i) => ({
          ...x,
          key: `${x.prop}-${i}`,
        })),
    [formHash],
  );

  useEffect(() => {
    if (updateModelDefVal) {
      onChangeModel(defModel);
      setUpdateModelDefVal(false);
    }
  }, [updateModelDefVal]);

  useEffect(() => {
    // check if def exists
    let ifMC = false;
    const model0 = { ...model };

    for (const it of formOut) {
      if (xdfGetValue(model, it.prop, undefined, plain) === undefined) {
        if (it.defValue !== undefined) {
          // console.log('defValue', it.defValue);
          xdfSetValue(model0, it.prop, it.defValue, plain);
          ifMC = true;
        } else if (it.type === XDF_TYPE.MULTISELECT || it.type === XDF_TYPE.TAGS) {
          if (!Array.isArray(xdfGetValue(model, it.prop, undefined, plain))) {
            xdfSetValue(model0, it.prop, [], plain);
          }
        } else if (it.type === XDF_TYPE.DATETIME) {
          xdfSetValue(model0, it.prop, dayjs().format(it.format || DEFAULT_DATEFORMAT), plain);
        } else if (it.type === XDF_TYPE.DATERANGE) {
          xdfSetValue(model0, it.prop, dayjs().format(it.format || DEFAULT_DATEFORMAT), plain);
          xdfSetValue(model0, it.prop2, dayjs().format(it.format || DEFAULT_DATEFORMAT), plain);
        } else if (it.type === XDF_TYPE.TIME) {
          xdfSetValue(model0, it.prop, dayjs().format(it.format || DEFAULT_TIMEFORMAT), plain);
        }
      }
    }

    if (ifMC) {
      setDefModel(model0);
      setUpdateModelDefVal(true);
    }
  }, [formOut]);

  function checkByRequired(m) {
    for (const fld of formOut) {
      if (fld.require && !xdfGetValue(m, fld.prop, undefined, plain)) return false;
    }
    return true;
  }

  function onPropChanged(it, value, value2) {
    // const m = {
    //   ...model,
    // };
    const m = deepClone(model);

    // console.log('onPropChanged', m, it.prop, value);

    xdfSetValue(m, it.prop, value, plain);

    if (value2 !== undefined) {
      xdfSetValue(m, it.prop2, value2, plain);
    }
    onChangeModel(m, checkByRequired(m));
  }

  function onPropsChanged(modelBlk) {
    const m = {
      ...model,
      ...modelBlk,
    };
    onChangeModel(m, checkByRequired(m));
  }

  function onEnterPress(ev, it) {
    ev.preventDefault();
    // console.log('onEnterPress', it);
    if (onPressEnter) onPressEnter(ev, it);
  }

  function renderElement(it) {
    const iprops = {
      key: it.key,
      size,
      it,
      model,
      onPropChanged,
      onPropsChanged,
      onEnterPress,
      multiple: false,
      plain,
    };

    switch (it.type) {
      case XDF_TYPE.TEXT:
        return <XDFText {...iprops} />;
      case XDF_TYPE.PASSWORD:
        return <XDFPassword {...iprops} />;

      case XDF_TYPE.TEXTAREA:
        return <XDFTextarea {...iprops} />;
      case XDF_TYPE.WYSIWYG:
        return <XDFWysiwyg2 {...iprops} />;
      case XDF_TYPE.SHOW_TEXT:
        return <XDFShowText {...iprops} />;
      case XDF_TYPE.SHOW_FILE_SIZE:
        return <XDFShowFileSize {...iprops} />;

      case XDF_TYPE.TABLE_SIMPLE:
        return <XDFTableSimple {...iprops} />;

      case XDF_TYPE.COLOR:
        return <XDFColor {...iprops} />;

      case XDF_TYPE.PHONE:
        return <XDFPhone {...iprops} />;
      case XDF_TYPE.PHONE_SMS_CODE:
        return <XDFPhoneSmsCode {...iprops} />;
      case XDF_TYPE.EMAIL:
        return <XDFEmail {...iprops} />;

      case XDF_TYPE.NUMBER:
        return <XDFNumber {...iprops} />;
      case XDF_TYPE.NUMBER_MIN_MAX:
        return <XDFNumberMinMax {...iprops} />;

      case XDF_TYPE.URL:
        return <XDFUrl {...iprops} />;

      case XDF_TYPE.GEOCODER:
        return <XDFGeocoder {...iprops} />;
      case XDF_TYPE.LAT_LNG:
        return <XDFLatLng {...iprops} />;

      case XDF_TYPE.CHECKBOX:
        return <XDFCheckbox {...iprops} />;
      case XDF_TYPE.DIVIDER_WITH_CECKBOX:
        return <XDFDividerWithCheckbox {...iprops} />;
      case XDF_TYPE.SWITCH:
        return <XDFSwitch {...iprops} />;
      case XDF_TYPE.SWITCH3:
        return <XDFSwitch3 {...iprops} />;

      case XDF_TYPE.SELECT:
        return <XDFSelect {...iprops} />;
      case XDF_TYPE.RADIO:
        return <XDFRadio {...iprops} />;

      case XDF_TYPE.SEARCH:
        return <XDFSearch {...iprops} />;

      case XDF_TYPE.MULTISELECT:
        iprops.multiple = true;
        return <XDFSelect {...iprops} />;

      case XDF_TYPE.TAGS:
        iprops.multiple = true;
        return <XDFTags {...iprops} />;

      case XDF_TYPE.DATETIME:
        return <XDFDateTime {...iprops} />;
      case XDF_TYPE.DATERANGE:
        return <XDFDateRange {...iprops} />;

      case XDF_TYPE.TIME:
        return <XDFTime {...iprops} />;
      case XDF_TYPE.TIMERANGE:
        return <XDFTimeRange {...iprops} />;

      case XDF_TYPE.SHOW_DATETIME:
        return <XDFShowDateTime {...iprops} />;
      case XDF_TYPE.DIVIDER:
        return <XDFDivider {...iprops} />;

      case XDF_TYPE.BUTTON:
        return <XDFButton {...iprops} />;

      case XDF_TYPE.MODAL_TABLE_FORM:
        return <XDFModalTableForm {...iprops} />;

      case XDF_TYPE.UPLOAD_BASE64:
        return <XDFUploadBase64 {...iprops} />;
      case XDF_TYPE.GALLERY_UPLOAD:
        return <XDFGalleryUpload {...iprops} />;
      case XDF_TYPE.PICTURE_UPLOAD:
        return <XDFPictureUpload {...iprops} />;
      case XDF_TYPE.UPLOAD_BIG_FILE:
        return <XDFUploadBigFile {...iprops} />;

      case XDF_TYPE.VIDEO:
        return <XDFVideo {...iprops} />;

      case XDF_TYPE.SPACE:
        return (
          <Col key={it.key} span={it.span !== undefined ? it.span : 24}>
            {it.value}
          </Col>
        );

      default:
        return null;
    }
  }

  return (
    <div className={cn(styles.xDataForm, { [styles.inlineFormMode]: inline })} style={{ width }}>
      {!!title && <h2>{title}</h2>}
      {!table && (
        <Row gutter={[8, 0]}>
          {formOut.map(renderElement)}
          {children && <Col flex="auto">{children}</Col>}
        </Row>
      )}
      {table && (
        <table>
          <tbody>
            {formOut.map((it) => {
              if (it.type === undefined) {
                return (
                  <tr key={it.key} className={styles.nullDiv}>
                    <td colSpan={it.type === undefined ? 2 : 1}>
                      <div style={{ backgroundColor: it.color }}>{it.label}</div>
                    </td>
                  </tr>
                );
              }

              return (
                <tr key={it.key}>
                  <td>{it.label}</td>
                  <td>
                    <Row gutter={[0, 0]}>
                      {renderElement({
                        ...it,
                        label: undefined,
                        span: 24,
                      })}
                    </Row>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      {!!debug && (
        <Row gutter={[8, 0]}>
          <Col span={24}>
            <div
              style={{
                border: '1px solid #d0d0d0',
                backgroundColor: '#f0f0f0',
                fontFamily: 'courier',
                fontSize: 11,
                padding: 4,
                marginTop: 16,
                whiteSpace: 'pre-wrap',
              }}
            >
              {JSON.stringify(
                Array.isArray(debug)
                  ? Object.keys(model)
                      .filter((k) => debug.includes(k))
                      .reduce((a, k) => {
                        // eslint-disable-next-line no-param-reassign
                        a[k] = model[k];
                        return a;
                      }, {})
                  : model,
                null,
                2,
              )}
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
}

XDataForm.defaultProps = {
  debug: false,
  inline: false,
  title: null,
  size: 'middle',
  width: '100%',
  table: false,
  onPressEnter: null,
  plain: false,
};

XDataForm.propTypes = {
  debug: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.string)]),
  inline: PropTypes.bool,
  title: PropTypes.string,
  size: PropTypes.string,
  width: PropTypes.string,
  table: PropTypes.bool,
  form: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  model: PropTypes.shape({}).isRequired,
  onChangeModel: PropTypes.func.isRequired,
  onPressEnter: PropTypes.func,
  plain: PropTypes.bool,
};

export default XDataForm;
