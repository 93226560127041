import React, { useState } from 'react';

import { Table } from 'antd';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';

import { warningDialog } from '../../../components/Common/WarningDialog';
import XDataFormDialog from '../../../components/xdf-antd/XDataForm/XDataFormDialog';
import styles from './styles.scss';
import { fileForm, productFilesColumns } from './table-defs';

function KybSiteProductFiles({ prop, model, onChangeModel }) {
  const [modelFormDialog, setModelFormDialog] = useState({
    visible: false,
    model: {},
  });

  const onAddElement = (m) => {
    onChangeModel(
      {
        ...model,
        [prop]: [...model[prop], m],
      },
      true,
    );
  };

  const onAddNewFile = () => {
    setModelFormDialog({
      ...modelFormDialog,
      visible: true,
      title: 'Добавление элемента',
      model: {
        _id: nanoid(),
      },
      onSave: onAddElement,
    });
  };

  const onSaveElement = (m) => {
    const idx = model[prop].findIndex((x) => x._id === m._id);
    onChangeModel(
      {
        ...model,
        [prop]: [...model[prop].slice(0, idx), m, ...model[prop].slice(idx + 1)],
      },
      true,
    );
  };

  const onEditFile = (idx) => {
    setModelFormDialog({
      ...modelFormDialog,
      visible: true,
      title: 'Редактированеи элемента',
      model: { ...model[prop][idx] },
      onSave: onSaveElement,
    });
  };

  const onDeleteFile = async (idx) => {
    const res = await warningDialog('Редактирование продукта', 'Удалить элемент?', [
      { label: 'Отмена' },
      { label: 'Удалить' },
    ]);
    if (res === 1) {
      onChangeModel(
        {
          ...model,
          [prop]: [...model[prop].slice(0, idx), ...model[prop].slice(idx + 1)],
        },
        true,
      );
    }
  };

  const onCloseFormDialog = () => {
    setModelFormDialog({ ...modelFormDialog, visible: false });
  };

  const onSaveModelFormDialog = (m) => {
    onCloseFormDialog();
    modelFormDialog.onSave(m);
  };

  return (
    <>
      <Table
        className={styles.elementsTable}
        pagination={false}
        bordered
        size="small"
        dataSource={(model[prop] || []).map((x) => ({
          ...x,
          key: x._id,
        }))}
        columns={productFilesColumns(onAddNewFile, onEditFile, onDeleteFile)}
      />
      <XDataFormDialog
        title={modelFormDialog.title}
        visible={modelFormDialog.visible}
        model={modelFormDialog.model}
        form={fileForm()}
        onClose={onCloseFormDialog}
        onSave={onSaveModelFormDialog}
      />
    </>
  );
}

KybSiteProductFiles.defaultProps = {
  model: {},
};

KybSiteProductFiles.propTypes = {
  prop: PropTypes.string.isRequired,
  model: PropTypes.shape({}),
  onChangeModel: PropTypes.func.isRequired,
};

export default KybSiteProductFiles;
