import React from 'react';

import { Button } from 'antd';

import { EyeOutlined } from '@ant-design/icons';

// Общие колонки, есть на всех видах
// eslint-disable-next-line import/prefer-default-export
export const commonColumns = (onShowIframe) => ({
  name: null, // 'Аналоги', // название
  props: ['key', 'desc'], // список полей для запроса к серверу
  columns: [
    {
      prop: '+name',
      name: 'Название',
      sort: true,
    },
    {
      prop: '+domains',
      name: 'Домены',
      sort: true,
    },
    {
      prop: '+views',
      name: 'Количество',
      sort: true,
    },
    {
      prop: '+lastAccess',
      name: 'Последний',
      sort: true,
      type: 'date',
    },
    {
      prop: '+_id',
      name: '',
      align: 'center',
      actions: true,
      render: (item) => (
        <Button
          type="link"
          size="small"
          icon={<EyeOutlined />}
          onClick={() => onShowIframe(item)}
        />
      ),
    },
  ],
});
