import React from 'react';

import { Layout, PageHeader } from 'antd';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import apiAnyList from '../../../../api/AnyList';
import { subscribeToChannel, unsubscribeToChannel } from '../../../../api/sio';
import { notifyLastForRoleState } from '../../../../state/notify-state';
import GTooltip from '../../../components/GTooltip/GTooltip';
import XDataTable from '../../../components/xdf-antd/XDataTable';
import { USER_ROLE } from '../../../constants';
import styles from './styles.scss';
import { columnsOthers, commonColumns, filterForm } from './table-defs';

export default function MapNewObjectsList() {
  const history = useHistory();
  const notifyLastForRole = useRecoilValue(notifyLastForRoleState(USER_ROLE.MAP_OBJECTS));

  const onRowClick = (item) => {
    history.push(`/maps/object/${item._id}`);
  };

  return (
    <>
      <PageHeader
        className={styles.pageHeader}
        title={<GTooltip tid="map_new_objects">Список новых объектов</GTooltip>}
        extra={
          [
            // <Tooltip key="upload" title="Загрузить файл на сервер">
            //   <Button shape="circle" icon={<CloudUploadOutlined />} disabled />
            // </Tooltip>,
            // <Tooltip key="add" title="Добавить запись">
            //   <Button type="primary" shape="circle" icon={<PlusOutlined />} onClick={addRecord} />
            // </Tooltip>,
            // <Tooltip key="download" title="Скачать файл" onClick={downloadFile}>
            //   <Button shape="circle" icon={<CloudDownloadOutlined />} />
            // </Tooltip>,
          ]
        }
      />
      <Layout.Content>
        <div className={styles.contextHolder}>
          <div className={styles.context}>
            <XDataTable
              apiLoadData={apiAnyList('Sys:MapPoi')}
              commonColumns={commonColumns(notifyLastForRole)}
              extentedColumns={columnsOthers()}
              initSort={['name', 1]}
              initFilter={{ active: [-1] }}
              filterForm={filterForm()}
              onRowClick={onRowClick}
              sioEvents={['LIST:MapPoi', subscribeToChannel, unsubscribeToChannel]}
            />
          </div>
        </div>
      </Layout.Content>
    </>
  );
}
