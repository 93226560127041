import React from 'react';
import { KYB_MAP_PROP_TYPE_ID2NAME } from '../../../constants';

export const categoriesColumns = () => [
  {
    title: 'ID',
    dataIndex: 'id',
    width: 45,
  },
  {
    title: 'Название',
    dataIndex: 'name',
  },
  {
    title: 'Иконка',
    dataIndex: 'icon',
    align: 'right',
    render: (val) => <img src={val} alt="Иконка" />,
  },
  {
    title: 'Кнопка фильтра',
    dataIndex: 'fIcon',
    align: 'right',
    render: (val) => <img width={28} height={28} src={val} alt="Кнопка фильтра" />,
  },
];

export const propsColumns = () => [
  {
    title: 'Поле',
    dataIndex: 'prop',
  },
  {
    title: 'Тип',
    dataIndex: 'type',
    render: (val) => KYB_MAP_PROP_TYPE_ID2NAME[val],
  },
  {
    title: 'Название',
    dataIndex: 'name',
  },
  {
    title: 'Обязательное',
    dataIndex: 'req',
    render: (val) => (val ? '+' : ''),
  },
  {
    title: 'Внешнее',
    dataIndex: 'ext',
    render: (val) => (val ? '+' : ''),
  },
  {
    title: 'Подсказка',
    dataIndex: 'ph',
  },
];

export const dictsColumns = () => [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Поле',
    dataIndex: 'prop',
  },
  {
    title: 'Название',
    dataIndex: 'name',
  },
];

export const iconsColumns = () => [
  {
    title: 'ID',
    dataIndex: 'id',
    width: 45,
  },
  {
    title: 'Название',
    dataIndex: 'name',
  },
  {
    title: 'Иконка',
    dataIndex: 'icon',
    align: 'right',
    render: (val) => <img src={val} alt="Иконка" />,
  },
];
