import { apiPost, ApiError } from '.';

/**
 * Запрос серий продуктов
 */
async function apiMapAnalytics(startDt, endDt) {
  const { err, errmsg, data } = await apiPost('/map/analytics/get', {
    startDt,
    endDt,
  });
  if (err !== 0) throw ApiError(err, errmsg);
  return data;
}

export default apiMapAnalytics;
