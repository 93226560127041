import { apiPost, ApiError } from '.';

/**
 * Запрос серий продуктов
 */
const apiSiteMetaSet = async ({ url, title, desc }) => {
  const { err, errmsg } = await apiPost('/site/meta/set', { url, title, desc });
  if (err !== 0) throw ApiError(err, errmsg);
  return;
};
export default apiSiteMetaSet;
