/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import cn from 'classnames';

import { DeleteOutlined } from '@ant-design/icons';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import DragIcon from '../../../assets/svg/drag.svg';
import styles from './styles.scss';

function SortableBlockItem({ id, value, editId, onClick, onDeleteBlock }) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    margin: '8px 0',
    zIndex: isDragging ? '100' : 'auto',
    opacity: isDragging ? 0.3 : 1,
  };

  const onDelete = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    onDeleteBlock();
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={cn(styles.blkItem, { [styles.inEdit]: editId === id })}
      onClick={onClick}
    >
      <div className={styles.dragArea} {...listeners} {...attributes}>
        <DragIcon />
      </div>
      <div className={styles.blkT}>тип: {value}</div>
      <button type="button" className={styles.remove} title="Удалить" onClick={onDelete}>
        <DeleteOutlined />
      </button>
    </div>
  );
}

export default SortableBlockItem;
