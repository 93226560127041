import React, { useEffect, useState } from 'react';

import { Button, Layout, PageHeader, Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';

import { PlusOutlined } from '@ant-design/icons';

import { apiPostData } from '../../../../api';
import { subscribeToChannel, unsubscribeToChannel } from '../../../../api/sio';
import apiWebinar3TestList from '../../../../api/Webinar3TestList';
import { warningDialog } from '../../../components/Common/WarningDialog';
import GTooltip from '../../../components/GTooltip/GTooltip';
import { XDF_TYPE } from '../../../components/xdf-antd/XDataForm';
import XDataFormDialog from '../../../components/xdf-antd/XDataForm/XDataFormDialog';
import XDataTable from '../../../components/xdf-antd/XDataTable';
import styles from './styles.scss';
import { commonColumns } from './table-defs';

export default function WebinarsTestsList() {
  const history = useHistory();
  const [newTestDialog, setNewTestDialog] = useState({ visible: false, model: {} });
  const [tests, setTests] = useState([]);

  const fetchTests = async () => {
    const { list } = await apiWebinar3TestList()(1, 10000, {}, ['_id', 'name'], 'name', 1);
    setTests(list.map((test) => ({ id: test._id, name: test.name })));
  };

  useEffect(() => {
    fetchTests();
  }, []);

  const addNewTest = async () => {
    const res = await warningDialog('Добавление нового теста', 'Добавить новый тест?', [
      { label: 'Отмена' },
      { label: 'Добавить' },
    ]);

    if (res === 1) {
      setNewTestDialog({
        visible: true,
        model: { name: '', copyTests: 0 },
      });
    }
  };
  const onCreateNewTest = async (m) => {
    const data = await apiPostData('/webinars3/test/create', m);
    history.push(`/webinars/test/${data._id}`);
  };

  const onRowClick = (item) => {
    history.push(`/webinars/test/${item._id}`);
  };

  return (
    <>
      <PageHeader
        className={styles.pageHeader}
        title={<GTooltip tid="webinars_tests">Управление тестами</GTooltip>}
        extra={[
          <Tooltip key="add" title="Добавить новый тест" placement="left">
            <Button type="primary" shape="circle" icon={<PlusOutlined />} onClick={addNewTest} />
          </Tooltip>,
          // <Tooltip key="download" title="Скачать файл" onClick={downloadFile}>
          //   <Button shape="circle" icon={<CloudDownloadOutlined />} />
          // </Tooltip>,
        ]}
      />
      <Layout.Content>
        <div className={styles.contextHolder}>
          <div className={styles.context}>
            <XDataTable
              apiLoadData={apiWebinar3TestList()}
              commonColumns={commonColumns()}
              extentedColumns={null}
              initSort={['_id', 1]}
              initFilter={{}}
              onRowClick={onRowClick}
              sioEvents={['LIST:WEBINARS3_TESTS', subscribeToChannel, unsubscribeToChannel]}
              searchProps={['name']}
            />
          </div>
        </div>
      </Layout.Content>

      <XDataFormDialog
        visible={newTestDialog.visible}
        model={newTestDialog.model}
        title={<GTooltip tid="webinars_tests_create">Создать новый тест</GTooltip>}
        form={[
          {
            type: XDF_TYPE.SELECT,
            label: 'Скопировать вопросы и ответы из теста:',
            prop: 'copyTests',
            span: 24,
            values: [
              {
                id: 0,
                name: 'Нет',
              },
              ...tests,
            ],
          },
          {
            type: XDF_TYPE.TEXT,
            label: 'Название теста:',
            prop: 'name',
            span: 24,
            autoFocus: true,
          },
        ]}
        onClose={() => setNewTestDialog({ ...newTestDialog, visible: false })}
        onSave={onCreateNewTest}
      />
    </>
  );
}
