/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';

import { Button, Col, Layout, Modal, PageHeader, Popover, Row, Table } from 'antd';
import dayjs from 'dayjs';
import Hashids from 'hashids';
import { nanoid } from 'nanoid';
import QRCode from 'react-qr-code';
import { useHistory, useParams } from 'react-router-dom';

import {
  DeleteOutlined,
  LinkOutlined,
  MailOutlined,
  PercentageOutlined,
  QrcodeOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from '@ant-design/icons';

import { apiPostData } from '../../../../api';
import apiAnyList from '../../../../api/AnyList';
import { warningDialog } from '../../../components/Common/WarningDialog';
import GTooltip from '../../../components/GTooltip/GTooltip';
import XDataForm, { XDF_TYPE } from '../../../components/xdf-antd/XDataForm';
import { xdGetTableUrlByParams } from '../../../components/xdf-antd/XDataTable';
import XDImportExcelFile from '../../../components/xdf-antd/XDataTable/XDImportExcelFile';
import { DATETIME_FORMAT } from '../../../constants';
import {
  arrayRemoveItemAtIndex,
  arrayReplaceItemAtIndex,
  getConnectDomain,
} from '../../../utils/utils';
import WebinarTestPreview from '../Tests/WebinarsTestPreview';
import { WEBINAR3_STATUS, WEBINAR3_TYPE, WEBINAR3_VIEW } from '../webinar_constants';
import styles from './styles.scss';
import WebinarsCertEditorDlg from './WebinarsCertEditorDlg';
import WebinarsEventResults0 from './WebinarsEventResults0';

const hashids = new Hashids();

const getStageTemplate = (stages) => {
  let prevEndDt = dayjs().minute(0);
  if (stages.length > 0) {
    prevEndDt = dayjs(stages[stages.length - 1].dtEnd).add(15, 'minutes');
  }
  return {
    id: nanoid(7),
    dtStart: prevEndDt.toISOString(),
    dtEnd: prevEndDt.add(15, 'minutes').toISOString(),
    link: '',
  };
};

const getTestTamplate = () => ({
  id: 0,
  procentOk: 0,
  dtStart: dayjs().minute(0).format(DATETIME_FORMAT),
  dtEnd: dayjs().minute(0).format(DATETIME_FORMAT),
  questions: 0,
  users: 0,
  usersOk: 0,
  usersErr: 0,
});

const webinar3Tests4Select = async () => {
  const data = await apiAnyList('Webinars3Test')(1, 1000, {}, ['_id', 'name'], 'name', 1);
  return data.list.map((x) => ({
    id: x._id,
    name: x.name,
  }));
};

const apiImportUsersToEvent = (eventId) => async (list) => {
  const phones = [];
  for (const item of list) {
    let phoneNumber = item.phone;
    if (phoneNumber[0] !== '+') phoneNumber = `+${phoneNumber}`;
    phones.push(phoneNumber);
  }
  const data = await apiPostData('/webinars3/users/add/to/event/by/phones', {
    eventId,
    phones,
  });

  Modal.info({
    title: 'Пользователи загружены',
    content: (
      <div>
        <h4>Не найдены телефонные номера:</h4>
        {(data.unknownPhones || []).map((x) => (
          <div>{x}</div>
        ))}
      </div>
    ),
    onOk() {},
  });
};

const results1Columns = [
  {
    title: 'Вопрос',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Ответы',
    dataIndex: 'cnt',
    key: 'cnt',
    align: 'right',
  },
];

export default function WebinarEventEditor() {
  const history = useHistory();
  const { view, id } = useParams();
  const eventId = parseInt(id, 10);

  const [editMode, setEditMode] = useState(false);
  const [openImportDlg, setOpenImportDlg] = useState(false);

  const [model, setModel] = useState({});
  const [isModelChanged, setIsModelChanged] = useState(false);

  const [previewTestId, setPreviewTestId] = useState(0);

  const [showResult0, setShowResult0] = useState(false);
  const [showTest, setShowTest] = useState({});
  const [answersCount, setAnswersCount] = useState(null);
  const [results1, setResults1] = useState([]);

  const [eventSelectLink, setEventSelectLink] = useState(null);

  const fetchEvent = async (eid) => {
    const data = await apiPostData('/webinars3/event/get', { id: eid });
    setModel(data);
    setIsModelChanged(false);

    if (data) {
      const counts = data.__result1 || {};
      const list = (data.__test?.questions || [])
        .filter((x) => !!x.type)
        .map((x) => ({
          key: x.id,
          name: x.txt,
          cnt: counts[x.id] || 0,
        }));
      setResults1(list);
    }
  };

  useEffect(() => {
    if (eventId === 0) {
      setModel({
        status: 0,
        view: view === 'seminar' ? 0 : 1,
        type: [],
        name: '',
        coach: '',
        place: '',
        stages: [],
        test: getTestTamplate(),
        certificate: {
          dtSend: dayjs().add(1, 'days').format(DATETIME_FORMAT),
        },
        procentOk: 80,
      });
      setEditMode(true);
    } else {
      fetchEvent(eventId);
    }
  }, [view, eventId]);

  const saveModel = async () => {
    const data = await apiPostData('/webinars3/event/save', {
      id: eventId,
      data: model,
    });
    if (eventId === 0) history.push(`/webinars/event/seminar/${data._id}`);
    else {
      setEditMode(false);
      fetchEvent(eventId);
    }
  };

  const sendEmailAboutStartTest = async () => {
    await apiPostData('/webinars3/event/email/start/test', {
      id: eventId,
    });
  };

  const sendEmailNotify24 = async () => {
    await apiPostData('/webinars3/event/email/notify24', {
      id: eventId,
    });
  };

  const backToList = async () => {
    if (isModelChanged) {
      const res = await warningDialog(
        'Есть несохраненные изменения',
        'Сохранить перед выходом внесённые изменения?',
        [{ label: 'Отмена' }, { label: 'Сохранить' }],
      );

      if (res === 1) {
        await saveModel();
        history.push('/webinars/events');
      }
    } else {
      history.push('/webinars/events');
    }
  };

  const onChangeModel = (m, is) => {
    setModel({ ...m });
    setIsModelChanged(is && m?.stages?.length > 0);
  };

  const onStateChange = (idx, m) => {
    setModel({
      ...model,
      stages: arrayReplaceItemAtIndex(model.stages, idx, {
        ...m,
      }),
    });
    setIsModelChanged(true);
  };

  const addNewStage = () => {
    setModel({
      ...model,
      stages: [...model.stages, getStageTemplate(model.stages)],
    });
    setIsModelChanged(!!model.name);
  };

  const deleteStage = (idx) => {
    setModel({
      ...model,
      stages: arrayRemoveItemAtIndex(model.stages, idx),
    });
  };

  const deleteTest = () => {
    setModel({
      ...model,
      test: getTestTamplate(),
    });
  };

  const onPreviewTest = () => {
    setPreviewTestId(model?.test?.id);
  };

  const onPreviewTestWithAnswers = () => {
    setAnswersCount(model?.test?.answersCount);
    setShowTest(model?.__test);
    setShowResult0(true);
  };

  const gotoUsersListWithEvents = () => {
    const link = xdGetTableUrlByParams('/webinars/users', { _eventsIds: eventId });
    history.push(link);
  };

  const [certDlg, setCertDlg] = useState(false);
  const onCertEditor = () => {
    setCertDlg(true);
  };

  const onSaveCertificate = (certModel) => {
    setModel({
      ...model,
      certificate: {
        ...(model.certificate || {}),
        ...certModel,
      },
    });
    setCertDlg(false);
    setIsModelChanged(true);
  };

  const openUserWebinarLink = () => {
    window.open(`${getConnectDomain()}/?ev=${hashids.encode(model.view, eventId)}`, '_blank');
  };

  return (
    <>
      <PageHeader
        className={styles.pageHeader}
        title={
          model?.view === 0 ? (
            <GTooltip tid="webinars_seminar_editor">Карточка семинара</GTooltip>
          ) : (
            <GTooltip tid="webinars_webinar_editor">Карточка вебинара</GTooltip>
          )
        }
        extra={[
          <Button
            key="link"
            shape="circle"
            icon={<LinkOutlined />}
            onClick={openUserWebinarLink}
          />,
          <Popover
            key="qrcode"
            content={<QRCode value={eventSelectLink || 'KYB'} />}
            title="Ссылка на запись"
            trigger="click"
            open={!!eventSelectLink}
            onOpenChange={() => {
              setEventSelectLink(
                eventSelectLink
                  ? null
                  : `${getConnectDomain()}/?ev=${hashids.encode(model.view, eventId)}`,
              );
            }}
          >
            <Button shape="circle" icon={<QrcodeOutlined />} />
          </Popover>,
          <Button
            key="add_users"
            icon={<UsergroupAddOutlined />}
            onClick={() => setOpenImportDlg(true)}
          >
            Добавить из файла
          </Button>,
          <Button
            key="add_user"
            icon={<UserAddOutlined />}
            onClick={() => history.push('/webinars/users')}
          >
            Добавить участников
          </Button>,
          <Button key="users_list" icon={<UserOutlined />} onClick={gotoUsersListWithEvents}>
            Список участников
          </Button>,
          editMode && (
            <Button key="save" type="primary" disabled={!isModelChanged} onClick={saveModel}>
              Сохранить
            </Button>
          ),
          !editMode && model.status >= 0 && (
            <Button key="edit" onClick={() => setEditMode(true)}>
              Редактировать
            </Button>
          ),
        ].filter(Boolean)}
        onBack={backToList}
      />

      {model?.view >= 0 && (
        <Layout.Content>
          <div className={styles.contextHolder}>
            <div className={styles.context}>
              <h3>Общая информация</h3>
              <XDataForm
                model={model}
                onChangeModel={onChangeModel}
                form={[
                  {
                    type: XDF_TYPE.SELECT,
                    label: 'Статус',
                    prop: 'status',
                    values: WEBINAR3_STATUS,
                    showOnly: !editMode,
                    span: 6,
                  },
                  {
                    type: XDF_TYPE.SELECT,
                    label: 'Вид',
                    prop: 'view',
                    values: WEBINAR3_VIEW,
                    showOnly: true,
                    span: 6,
                  },
                  {
                    type: XDF_TYPE.MULTISELECT,
                    label: 'Тип',
                    prop: 'type',
                    values: WEBINAR3_TYPE,
                    readOnly: !editMode,
                    span: 12,
                  },
                  {
                    type: XDF_TYPE.TEXT,
                    label: 'Название',
                    prop: 'name',
                    span: 20,
                    showOnly: !editMode,
                    autoFocus: true,
                  },
                  {
                    type: XDF_TYPE.TEXT,
                    label: 'Тренер',
                    prop: 'coach',
                    showOnly: !editMode,
                    span: 4,
                  },
                  {
                    type: XDF_TYPE.TEXTAREA,
                    label: 'Место проведения',
                    prop: 'place',
                    showOnly: !editMode,
                    span: 24,
                    hide: model?.view !== 0,
                  },
                ]}
              />
            </div>
          </div>

          <div className={styles.contextHolder}>
            <div className={styles.context}>
              <h3>Этапы проведения</h3>
              <div>
                {(model?.stages || []).map((stage, i) => (
                  <XDataForm
                    key={stage.id}
                    model={stage}
                    onChangeModel={(m) => onStateChange(i, m)}
                    form={[
                      {
                        type: XDF_TYPE.DATETIME,
                        label: 'Дата / время начала',
                        prop: 'dtStart',
                        showTime: true,
                        format: DATETIME_FORMAT,
                        defaultValue: dayjs().minute(0).format(DATETIME_FORMAT),
                        showOnly: !editMode,
                        span: 5,
                      },
                      {
                        type: XDF_TYPE.DATETIME,
                        label: 'Дата / время окончания:',
                        prop: 'dtEnd',
                        showTime: true,
                        format: DATETIME_FORMAT,
                        defaultValue: dayjs().minute(0).add(15, 'minute').format(DATETIME_FORMAT),
                        showOnly: !editMode,
                        span: 5,
                      },
                      model.view === 1 && {
                        type: XDF_TYPE.URL,
                        label: 'Ссылка на вебинар',
                        prop: 'link',
                        showOnly: !editMode,
                        span: 10,
                      },
                      model.view === 0 && {
                        type: XDF_TYPE.SPACE,
                        span: 10,
                      },
                    ].filter(Boolean)}
                  >
                    {editMode && (
                      <Button
                        icon={<DeleteOutlined />}
                        onClick={() => deleteStage(i)}
                        style={{ marginTop: 26, width: '100%' }}
                      >
                        Удалить
                      </Button>
                    )}
                  </XDataForm>
                ))}
              </div>
              <div className={styles.footer}>
                <Button
                  icon={<MailOutlined />}
                  disabled={!(model.stages?.length > 0)}
                  onClick={sendEmailNotify24}
                >
                  Отправить e-mail о начале
                </Button>
                {editMode && <Button onClick={addNewStage}>Добавить этап</Button>}
              </div>
            </div>
          </div>

          {model?.stages?.length > 0 && (
            <div className={styles.contextHolder}>
              <div className={styles.context}>
                <h3>Связанный тест по окончании вебинара</h3>
                <XDataForm
                  model={model}
                  onChangeModel={onChangeModel}
                  form={[
                    {
                      type: XDF_TYPE.SELECT,
                      label: 'Название теста',
                      prop: 'test.id',
                      values: webinar3Tests4Select,
                      showOnly: !editMode,
                      span: model?.test?.id > 0 ? 12 : 24,
                    },
                    {
                      type: XDF_TYPE.NUMBER,
                      label: 'Процент верных ответов для сдачи (%)',
                      prop: 'procentOk',
                      showOnly: !editMode,
                      span: 8,
                      hide: !(model?.test?.id > 0),
                    },
                  ]}
                >
                  {model?.test?.id > 0 && (
                    <Button style={{ marginTop: 26, width: '100%' }} onClick={onPreviewTest}>
                      Посмотреть тест
                    </Button>
                  )}
                </XDataForm>
                {model?.test?.id > 0 && (
                  <XDataForm
                    model={model}
                    onChangeModel={onChangeModel}
                    form={[
                      {
                        type: XDF_TYPE.DATETIME,
                        label: 'Дата / время начала',
                        prop: 'test.dtStart',
                        showTime: true,
                        format: DATETIME_FORMAT,
                        defaultValue: dayjs().minute(0).format(DATETIME_FORMAT),
                        showOnly: !editMode,
                        span: 6,
                      },
                      {
                        type: XDF_TYPE.DATETIME,
                        label: 'Дата / время окончания:',
                        prop: 'test.dtEnd',
                        showTime: true,
                        format: DATETIME_FORMAT,
                        defaultValue: dayjs().minute(0).format(DATETIME_FORMAT),
                        showOnly: !editMode,
                        span: 6,
                      },
                      {
                        type: XDF_TYPE.SPACE,
                        span: 12,
                      },
                      {
                        type: XDF_TYPE.SHOW_TEXT,
                        label: 'Вопросов в тесте',
                        prop: 'test.questions',
                        span: 3,
                      },
                      {
                        type: XDF_TYPE.SHOW_TEXT,
                        label: 'Записывались',
                        prop: 'test.users',
                        span: 3,
                      },
                      {
                        type: XDF_TYPE.SHOW_TEXT,
                        label: 'Сдали тест',
                        prop: 'test.usersOk',
                        span: 3,
                      },
                      {
                        type: XDF_TYPE.SHOW_TEXT,
                        label: 'Не сдали тест',
                        prop: 'test.usersErr',
                        span: 3,
                      },
                      {
                        type: XDF_TYPE.SHOW_TEXT,
                        label: 'Не сдавали',
                        prop: 'test.usersPass',
                        span: 3,
                      },
                    ]}
                  >
                    <Button
                      icon={<PercentageOutlined />}
                      style={{ marginTop: 26 }}
                      disabled={model.status < 2}
                      onClick={onPreviewTestWithAnswers}
                    >
                      Процент выбора ответов
                    </Button>
                  </XDataForm>
                )}

                <div className={styles.footer}>
                  <Button
                    icon={<MailOutlined />}
                    disabled={!(model.test.id > 0)}
                    onClick={sendEmailAboutStartTest}
                  >
                    Отправить e-mail о доступности теста
                  </Button>
                  {editMode && (
                    <Button disabled={!(model?.test?.id > 0)} onClick={deleteTest}>
                      Удалить тест
                    </Button>
                  )}
                </div>
              </div>
            </div>
          )}

          {model?.stages?.length > 0 && (
            <div className={styles.contextHolder}>
              <div className={styles.context}>
                <h3>Сертификат</h3>
                <Row>
                  <Col span={12}>
                    <Button onClick={onCertEditor} disabled={!editMode}>
                      Настроить сертификат
                    </Button>
                    <div style={{ marginTop: 16 }}>
                      {model?.certificate?.preview && (
                        <img src={model?.certificate?.preview} alt="Certificate" />
                      )}
                    </div>
                  </Col>
                  {!!model?.certificate?.fileId && (
                    <Col span={12}>
                      <XDataForm
                        model={model}
                        onChangeModel={onChangeModel}
                        form={[
                          {
                            type: XDF_TYPE.DATETIME,
                            label: 'Рассылка о новом сертификате',
                            prop: 'certificate.dtSend',
                            showTime: true,
                            format: DATETIME_FORMAT,
                            defaultValue: dayjs().minute(0).format(DATETIME_FORMAT),
                            showOnly: !editMode,
                            span: 12,
                          },
                        ]}
                      />
                    </Col>
                  )}
                </Row>
              </div>
            </div>
          )}

          {model?.stages?.length > 0 && (
            <div className={styles.contextHolder} style={{ paddingBottom: 16 }}>
              <div className={styles.context}>
                <h3>Свободные вопросы</h3>
                <Table
                  dataSource={results1}
                  columns={results1Columns}
                  size="small"
                  pagination={false}
                  bordered
                  onRow={(record, rowIndex) => ({
                    onClick: (event) => {
                      history.push(`/webinars/event/${eventId}/r1/${record.key}`);
                    }, // click row
                    onDoubleClick: (event) => {}, // double click row
                    onContextMenu: (event) => {}, // right button click row
                    onMouseEnter: (event) => {}, // mouse enter row
                    onMouseLeave: (event) => {}, // mouse leave row
                  })}
                />
              </div>
            </div>
          )}
        </Layout.Content>
      )}

      <WebinarTestPreview testId={previewTestId} onClose={() => setPreviewTestId(0)} />

      <WebinarsEventResults0
        open={showResult0}
        onClose={() => setShowResult0(false)}
        test={showTest}
        answersCount={answersCount}
        users={model?.test?.users}
      />

      <XDImportExcelFile
        visible={openImportDlg}
        onClose={() => setOpenImportDlg(false)}
        importBtnTitle="Укажите файл для импорта"
        maxStepToLoad={1000}
        uploadApi={apiImportUsersToEvent(eventId)}
        columns={[
          {
            prop: 'phone',
          },
        ]}
      />

      <WebinarsCertEditorDlg
        visible={certDlg}
        onClose={() => setCertDlg(false)}
        onSave={onSaveCertificate}
      />
    </>
  );
}
