import React, { useEffect, useState } from 'react';

import { Button, message, Modal } from 'antd';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';

import { apiPost } from '../../../../api/index';
import LoginDlgLogo from '../../../../assets/svg/login-dlg-logo.svg';
import { meState } from '../../../../state/panel-state';
import { warningDialog } from '../../Common/WarningDialog';
import XDataForm from '../../xdf-antd/XDataForm';
import { XDF_TYPE } from '../../xdf-antd/XDataForm/index';
import styles from './styles.scss';

function UserProfile({ visible, onClose }) {
  const me = useRecoilValue(meState);
  const [model, setModel] = useState({});
  const [isModelChanged, setIsModelChanged] = useState(false);

  useEffect(() => {
    setModel(me);
  }, []);

  function onChangeModel(m, isChanged) {
    setModel({ ...m });
    setIsModelChanged(isChanged);
  }

  async function saveUser() {
    const { err, errmsg, data } = await apiPost('/me/save', model);
    if (err) {
      message.error(errmsg || `Net error: ${err}`);
    } else {
      document.location.reload();
    }
  }

  async function saveUser2() {
    let res = -1;
    if (me.email !== model.email) {
      res = await warningDialog(
        'Изменение E-mail',
        'После нажатия на кнопку «Продолжить» мы вышлем ссылку подтверждения на новый E-mail. До момента подтверждения ваш профиль будет ограничен.',
        [{ label: 'Отмена' }, { label: 'Продолжить' }],
      );
      if (res === 0) return;
    }

    const { err, errmsg, data } = await apiPost('/me/save', model);
    if (err) {
      message.error(errmsg || `Net error: ${err}`);
    } else {
      if (res === 1) {
        document.location.reload();
      } else {
        onClose();
      }
    }
  }

  return (
    <Modal
      className={styles.kybLoginDlg}
      title={null}
      width={610}
      open={visible}
      closable={false}
      footer={null}
    >
      <h1>
        Регистрация в сервисах <LoginDlgLogo />
      </h1>
      {!onClose && (
        <div className={styles.info}>
          Ваш статус: <span>Ожидает авторизации</span>
        </div>
      )}
      <div className={styles.info}>
        Мобильный: <span>{me.phone}</span>
      </div>
      {!onClose && (
        <div className={styles.msg}>
          Укажите ваш E-mail и подтвердите его, а так же заполните другие поля. Далее ваши данные
          будут отправлены на рассмотрение. После одобрения ваших данных вы получите доступ к
          сервисам KYB.
        </div>
      )}
      {!!onClose && (
        <div className={styles.msg}>
          Внимание! В случае смены E-mail его нужно будет подтвердить.
        </div>
      )}
      <XDataForm
        model={model}
        onChangeModel={onChangeModel}
        form={[
          {
            type: XDF_TYPE.EMAIL,
            label: 'Email',
            prop: 'email',
            span: 12,
            require: true,
            autoFocus: !onClose,
          },
          {
            type: XDF_TYPE.TEXT,
            label: 'Веб-сайт',
            prop: 'site',
            span: 12,
          },
          {
            type: XDF_TYPE.TEXT,
            label: 'Фамилия',
            prop: 'lastName',
            require: true,
            span: 8,
          },
          {
            type: XDF_TYPE.TEXT,
            label: 'Имя',
            prop: 'name',
            require: true,
            span: 8,
          },
          {
            type: XDF_TYPE.TEXT,
            label: 'Отчество',
            prop: 'midName',
            require: true,
            span: 8,
          },
          {
            type: XDF_TYPE.TEXT,
            label: 'Компания',
            prop: 'company',
            require: true,
            span: 12,
          },
          {
            type: XDF_TYPE.TEXT,
            label: 'Должность',
            prop: 'status',
            require: true,
            span: 12,
          },
          {
            type: XDF_TYPE.TEXT,
            label: 'Страна',
            prop: 'country',
            require: true,
            span: 12,
          },
          {
            type: XDF_TYPE.TEXT,
            label: 'Город',
            prop: 'city',
            require: true,
            span: 12,
          },
          {
            type: XDF_TYPE.TEXTAREA,
            label: 'Дополнительная информация',
            prop: 'desc',
            rows: 4,
            span: 24,
            autoFocus: !!onClose,
          },
        ]}
      />
      <div className={styles.footer}>
        {!!onClose && (
          <>
            <Button onClick={onClose} style={{ marginRight: 'auto' }}>
              Отмена
            </Button>
            <Button type="primary" disabled={!isModelChanged} onClick={saveUser2}>
              Сохранить
            </Button>
          </>
        )}
        {!onClose && (
          <Button type="primary" disabled={!isModelChanged} onClick={saveUser}>
            Сохранить
          </Button>
        )}
      </div>
    </Modal>
  );
}

UserProfile.defaultProps = {
  onClose: null,
};

UserProfile.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

export default UserProfile;
