import React from 'react';

import { Button, Layout, PageHeader, Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';

import { PlusOutlined } from '@ant-design/icons';

import apiAnyList from '../../../../api/AnyList';
import { subscribeToChannel, unsubscribeToChannel } from '../../../../api/sio';
import GTooltip from '../../../components/GTooltip/GTooltip';
import XDataTable from '../../../components/xdf-antd/XDataTable';
import styles from './styles.scss';
import { commonColumns } from './table-defs';

export default function KybSiteExperts() {
  const history = useHistory();

  const onAddNews = () => {
    history.push(`/site/experts/--new--`);
  };

  const onRowClick = (item) => {
    history.push(`/site/experts/${item._id}`);
  };

  return (
    <>
      <PageHeader
        className={styles.pageHeader}
        title={<GTooltip tid="kyb_site_experts">Экспертный клуб</GTooltip>}
        extra={[
          <Tooltip key="add" title="Добавить">
            <Button type="primary" shape="circle" icon={<PlusOutlined />} onClick={onAddNews} />
          </Tooltip>,
        ]}
      />
      <Layout.Content>
        <div className={styles.contextHolder}>
          <div className={styles.context}>
            <XDataTable
              apiLoadData={apiAnyList('KybDb:Expert')}
              commonColumns={commonColumns()}
              extentedColumns={null}
              onRowClick={onRowClick}
              sioEvents={['LIST:SITE_EXPERTS', subscribeToChannel, unsubscribeToChannel]}
              initSort={['dt', -1]}
              initFilter={{ status: [0, 1] }}
              searchProps={['name']}
            />
          </div>
        </div>
      </Layout.Content>
    </>
  );
}
