import React, { useEffect, useState } from 'react';

import { Col, Modal, Row } from 'antd';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';

import XDataForm, { XDF_TYPE } from '../../../components/xdf-antd/XDataForm';
import XDataUpload from '../../../components/xdf-antd/XDataUpload/XDataUpload';

const MAX_FILE_SIZE = 10 * 1024 * 1024;

function WebinarsCertEditorDlg({ visible, onClose, onSave }) {
  const [open, setOpen] = useState(false);
  const [fileId, setFileId] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [model, setModel] = useState({
    fio: 'Иванов Иван Иванович',
    fioFontSize: 22,
    fioX: 0,
    fioY: 0,
    helpSize: 0,
    addDates: 0,
    dates: `${dayjs().subtract(1, 'day').format('DD.MM.YYYY')} - ${dayjs().format('DD.MM.YYYY')}`,
    dtFontSize: 14,
    dtX: 0,
    dtY: -70,
  });

  useEffect(() => {
    if (visible) {
      setOpen(true);
    } else {
      setOpen(false);
      setPdfUrl(null);
      setFileId(null);
    }
  }, [visible]);

  useEffect(() => {
    if (fileId)
      setPdfUrl(
        `/api/1/webinars3/cert/get/${fileId}?model=${encodeURIComponent(JSON.stringify(model))}`,
      );
    else setPdfUrl(null);
  }, [fileId, model]);

  const onSaveFileInfo = ({ id }) => {
    setFileId(id);
  };

  const onStartUpload = () => {
    console.log('start upload');
  };

  const onSaveCertificate = () => {
    const elements = document.getElementsByClassName('react-pdf__Page__canvas');
    if (elements?.length > 0) {
      const srcCanvas = elements[0];
      const imageData = srcCanvas.toDataURL('image/png');

      const rcanvas = document.getElementById('resize-canvas');
      const ctx = rcanvas.getContext('2d');
      const img = new Image();

      img.onload = () => {
        // set size proportional to image
        rcanvas.height = rcanvas.width * (img.height / img.width);

        // step 1 - resize to 50%
        const oc = document.createElement('canvas');
        const octx = oc.getContext('2d');

        oc.width = img.width * 0.5;
        oc.height = img.height * 0.5;
        octx.drawImage(img, 0, 0, oc.width, oc.height);

        // step 2
        octx.drawImage(oc, 0, 0, oc.width * 0.5, oc.height * 0.5);

        // step 3, resize to final size
        ctx.drawImage(
          oc,
          0,
          0,
          oc.width * 0.5,
          oc.height * 0.5,
          0,
          0,
          rcanvas.width,
          rcanvas.height,
        );

        const smallImgData = rcanvas.toDataURL('image/png');

        // create model certificate
        const certModel = {
          fileId,
          modify: model,
          preview: smallImgData,
        };
        console.log(certModel);

        onSave(certModel);
      };
      img.src = imageData;
    }
  };

  return (
    <Modal
      title="Настройка сертификата"
      open={open}
      width={970}
      onCancel={onClose}
      okText="Сохранить"
      onOk={onSaveCertificate}
    >
      <Row>
        {!pdfUrl && (
          <Col span={24}>
            <XDataUpload
              uploadType="file"
              desc=""
              maxSize={MAX_FILE_SIZE}
              onSave={onSaveFileInfo}
              onStartUpload={onStartUpload}
            />
          </Col>
        )}
        {/* {pdfUrl && <Col span={24}>{pdfUrl}</Col>} */}
        {pdfUrl && (
          <>
            <Col span={16}>
              <Document file={pdfUrl}>
                <Page pageNumber={1} />
              </Document>
            </Col>
            <Col span={8}>
              <XDataForm
                model={model}
                onChangeModel={(m) => setModel(m)}
                form={[
                  {
                    type: XDF_TYPE.TEXT,
                    label: 'ФИО (пример)',
                    prop: 'fio',
                    span: 24,
                  },
                  {
                    type: XDF_TYPE.NUMBER,
                    label: 'Размер шрифта',
                    prop: 'fioFontSize',
                    span: 12,
                  },
                  {
                    type: XDF_TYPE.NUMBER,
                    label: 'X',
                    prop: 'fioX',
                    span: 6,
                  },
                  {
                    type: XDF_TYPE.NUMBER,
                    label: 'Y',
                    prop: 'fioY',
                    span: 6,
                  },
                  {
                    type: XDF_TYPE.DIVIDER_WITH_CECKBOX,
                    label: 'Добавить даты проведения',
                    prop: 'addDates',
                    boolType: false,
                    span: 24,
                  },
                  {
                    type: XDF_TYPE.TEXT,
                    label: 'Пример дат',
                    prop: 'dates',
                    span: 24,
                    hide: model.addDates !== 1,
                  },
                  {
                    type: XDF_TYPE.NUMBER,
                    label: 'Размер шрифта',
                    prop: 'dtFontSize',
                    span: 12,
                    hide: model.addDates !== 1,
                  },
                  {
                    type: XDF_TYPE.NUMBER,
                    label: 'X',
                    prop: 'dtX',
                    span: 6,
                    hide: model.addDates !== 1,
                  },
                  {
                    type: XDF_TYPE.NUMBER,
                    label: 'Y',
                    prop: 'dtY',
                    span: 6,
                    hide: model.addDates !== 1,
                  },
                ]}
              />
            </Col>
          </>
        )}
      </Row>
      <canvas id="resize-canvas" width="130" style={{ display: 'none' }} />
    </Modal>
  );
}

WebinarsCertEditorDlg.defaultProps = {};

WebinarsCertEditorDlg.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default WebinarsCertEditorDlg;
