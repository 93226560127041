import React, { useEffect, useState } from 'react';

import { Button, Divider, message } from 'antd';
import PropTypes from 'prop-types';

import { apiPost } from '../../../../api';
import apiAnyGet from '../../../../api/AnyGet';
import XDataForm, { XDF_TYPE } from '../../../components/xdf-antd/XDataForm';

function KSBannerMainPage({}) {
  const [model, setModel] = useState({});
  const [isModelChanged, setIsModelChanged] = useState(false);

  const fetchBanner = async () => {
    const data = await apiAnyGet('KybDb:Banner')('mp', []);
    setModel({
      ...data,
    });
    setIsModelChanged(false);
  };

  useEffect(() => {
    fetchBanner();
  }, []);

  const onChangeModel = (m, is) => {
    setModel({ ...m });
    setIsModelChanged(is);
  };

  const onSaveItem = async () => {
    const { err, errmsg } = await apiPost('/site/banner/mp/set', {
      ...model,
    });
    if (err === 0) {
      setIsModelChanged(false);
    } else if (Array.isArray(errmsg)) {
      for (const msg of errmsg) {
        message.error(msg.message);
      }
    } else {
      message.error(errmsg || `Net error ${err}`);
    }
  };

  return (
    <div>
      <Button type="primary" disabled={!isModelChanged} onClick={onSaveItem}>
        Сохранить
      </Button>
      <Divider />
      <XDataForm
        model={model}
        onChangeModel={onChangeModel}
        form={[
          {
            type: XDF_TYPE.SWITCH,
            label: 'Включить баннер',
            prop: 'newsOn',
            span: 8,
          },
          {
            type: XDF_TYPE.SWITCH,
            label: 'Подписаться',
            prop: 'subscribeOn',
            span: 8,
          },
          {
            type: XDF_TYPE.SWITCH,
            label: '8 признаков износа',
            prop: 'lastOn',
            span: 8,
          },

          {
            type: XDF_TYPE.TEXT,
            label: 'Заголовок',
            prop: 'newsName',
            span: 24,
            autoFocus: true,
          },
          {
            type: XDF_TYPE.TEXT,
            label: 'Url',
            prop: 'newsUrl',
            span: 24,
          },
          {
            type: XDF_TYPE.PICTURE_UPLOAD,
            label: 'Картинка баннера',
            prop: 'newsPic',
            span: 24,
          },
        ]}
      />
    </div>
  );
}

KSBannerMainPage.defaultProps = {};

KSBannerMainPage.propTypes = {};

export default KSBannerMainPage;
