import React, { useState } from 'react';

import { Button, Layout, message, PageHeader, Tooltip } from 'antd';
import dayjs from 'dayjs';

import { PlusOutlined } from '@ant-design/icons';

import { apiPost } from '../../../../api';
import apiAnyGet from '../../../../api/AnyGet';
import apiAnyList from '../../../../api/AnyList';
import { subscribeToChannel, unsubscribeToChannel } from '../../../../api/sio';
import GTooltip from '../../../components/GTooltip/GTooltip';
import XDataFormDialog from '../../../components/xdf-antd/XDataForm/XDataFormDialog';
import XDataTable from '../../../components/xdf-antd/XDataTable';
import styles from './styles.scss';
import { commonColumns, formModelEditor } from './table-defs';

export default function KybSiteBulletins() {
  const [modelDlg, setModelDlg] = useState({ visisble: false });

  const onAdd = () => {
    setModelDlg({
      ...modelDlg,
      visisble: true,
      model: { status: 0, __date: dayjs().format('DD.MM.YYYY') },
    });
  };

  const onRowClick = async (item) => {
    const data = await apiAnyGet('KybDb:Bulletin')(item._id, []);
    setModelDlg({
      ...modelDlg,
      visisble: true,
      model: {
        ...data,
        __date: dayjs(data.date).format('DD.MM.YYYY'),
      },
    });
  };

  const onCloseDlg = () => {
    setModelDlg({ ...modelDlg, visisble: false });
  };

  const onSave = async (m) => {
    const { err, errmsg } = await apiPost('/site/bulletin/set', {
      ...m,
    });
    if (err === 0) {
      onCloseDlg();
    } else if (Array.isArray(errmsg)) {
      for (const msg of errmsg) {
        message.error(msg.message);
      }
    } else {
      message.error(errmsg || `Net error ${err}`);
    }
  };

  return (
    <>
      <PageHeader
        className={styles.pageHeader}
        title={<GTooltip tid="kyb_site_bulletins">Список бюллетеней</GTooltip>}
        extra={[
          <Tooltip key="add" title="Добавить">
            <Button type="primary" shape="circle" icon={<PlusOutlined />} onClick={onAdd} />
          </Tooltip>,
        ]}
      />
      <Layout.Content>
        <div className={styles.contextHolder}>
          <div className={styles.context}>
            <XDataTable
              apiLoadData={apiAnyList('KybDb:Bulletin')}
              commonColumns={commonColumns()}
              extentedColumns={null}
              onRowClick={onRowClick}
              sioEvents={['LIST:SITE_BULLETINS', subscribeToChannel, unsubscribeToChannel]}
              initFilter={{ status: [0, 1, 2] }}
              initSort={['state', 1]}
              searchProps={['name', 'desc']}
            />
          </div>
        </div>
      </Layout.Content>

      <XDataFormDialog
        title="Редактировать параметры"
        width={700}
        visible={modelDlg.visisble}
        model={modelDlg.model}
        onClose={onCloseDlg}
        onSave={onSave}
        form={formModelEditor()}
      />
    </>
  );
}
