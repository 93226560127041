import { apiPost } from './';
import { message } from 'antd';

/**
 * Запрос серий продуктов
 */
const apiUserStateSet = async (id, state) => {
  const { err, errmsg } = await apiPost('/user/state/set', { id, state });
  if (err) {
    if (Array.isArray(errmsg)) {
      for (const em of errmsg) {
        message.error(em.message, 4);
      }
    } else {
      message.error(errmsg || `Net error: ${err}`);
    }
  }
  return { err };
};
export default apiUserStateSet;
