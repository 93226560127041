import React from 'react';

import { Checkbox, Col } from 'antd';
import cn from 'classnames';
import gv from 'get-value';
import PropTypes from 'prop-types';

import styles from './styles.scss';

function XDFCheckbox({ it, size, model, onPropChanged }) {
  const { id, prop, label, title, boolType, autoFocus, span, readOnly } = it;

  const onChange = (ev) => {
    let val = !!ev.target.checked;
    if (!boolType) val = val ? 1 : 0;
    onPropChanged(it, val);
  };

  const val = gv(model, prop, boolType ? false : 0);
  return (
    <Col span={span || 24}>
      <div className={styles.formItem} data-pname={prop}>
        {!!title && (
          <div className={styles.formItemLabel}>
            <label htmlFor={id}>{title}</label>
          </div>
        )}
        <div
          className={cn(
            styles.formItemField,
            styles.checkbox,
            {
              [styles.small]: size === 'small',
            },
            { [styles.large]: size === 'large' },
          )}
        >
          <Checkbox
            id={id}
            onChange={onChange}
            disabled={!!readOnly}
            autoFocus={!!autoFocus}
            checked={!!val}
          >
            {label}
          </Checkbox>
        </div>
      </div>
    </Col>
  );
}

XDFCheckbox.propTypes = {
  it: PropTypes.shape({
    id: PropTypes.string.isRequired,
    prop: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    boolType: PropTypes.bool,
    autoFocus: PropTypes.bool,
    span: PropTypes.number,
    readOnly: PropTypes.bool,
  }).isRequired,
  size: PropTypes.string.isRequired,
  model: PropTypes.shape({}).isRequired,
  onPropChanged: PropTypes.func.isRequired,
};

export default XDFCheckbox;
