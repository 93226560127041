import React, { useEffect, useState } from 'react';

import { Button, Modal } from 'antd';
import PropTypes from 'prop-types';

import XDataForm from '.';

function XDataFormDialog({ debug, title, visible, width, model, form, onClose, onSave, onDelete }) {
  const [model0, setModel0] = useState({});
  const [isModelChanged, setIsModelChanged] = useState(false);

  useEffect(() => {
    if (visible) {
      setModel0({ ...model });
      setIsModelChanged(false);
    } else {
      setModel0({});
    }
  }, [visible]);

  const onChangeModel = (m, isc) => {
    setModel0({ ...m });
    setIsModelChanged(isc);
  };

  const onSaveModel = () => {
    onSave(model0);
  };

  return (
    <Modal
      destroyOnClose
      open={visible}
      width={width}
      title={title}
      onCancel={onClose}
      footer={[
        onDelete && (
          <Button key="delete" onClick={() => onDelete(model0)} style={{ marginRight: 'auto' }}>
            Удалить
          </Button>
        ),
        <Button key="close" onClick={onClose}>
          Закрыть
        </Button>,
        <Button key="save" type="primary" disabled={!isModelChanged} onClick={onSaveModel}>
          Сохранить
        </Button>,
      ].filter(Boolean)}
    >
      <XDataForm debug={debug} model={model0} onChangeModel={onChangeModel} form={form} />
    </Modal>
  );
}

XDataFormDialog.defaultProps = {
  debug: false,
  title: null,
  visible: false,
  width: 520,
  model: {},
  onDelete: null,
};

XDataFormDialog.propTypes = {
  debug: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  visible: PropTypes.bool,
  model: PropTypes.shape({}),
  width: PropTypes.number,
  form: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
};

export default XDataFormDialog;
