import React, { useEffect, useState } from 'react';

import { Button, Col, Collapse, Layout, PageHeader, Row, Typography } from 'antd';
import { nanoid } from 'nanoid';
import { useHistory, useParams } from 'react-router-dom';
import { arrayMove } from '@dnd-kit/sortable';

import {
  CaretDownOutlined,
  CaretUpOutlined,
  CheckOutlined,
  SettingOutlined,
} from '@ant-design/icons';

import { apiPostData } from '../../../../api';
import apiAnyGet from '../../../../api/AnyGet';
import { warningDialog } from '../../../components/Common/WarningDialog';
import GTooltip from '../../../components/GTooltip/GTooltip';
import XDataForm, { XDF_TYPE } from '../../../components/xdf-antd/XDataForm';
import styles from './styles.scss';
import WebinarsTestAnswerEditor from './WebinarsTestAnswerEditor';
import WebinarTestPreview from './WebinarsTestPreview';
import WebinarsEventsWithTest from '../Dialogs/WebinarsEventsWithTest';

export default function WebinarsTestEditor() {
  const history = useHistory();
  const { id } = useParams();

  const [showQ, setShowQ] = useState([]);
  const [model, setModel] = useState({});
  const [isModelChanged, setIsModelChanged] = useState(false);

  const [previewTestId, setPreviewTestId] = useState(0);
  const [eventsTestId, setEventsTestId] = useState(0);

  const [ansEditor, setAnsEditor] = useState({ visible: false, model: {} });

  const fetchData = async (itemId) => {
    const data = await apiAnyGet('Webinars3Test')(itemId, []);
    setModel(data);
    setIsModelChanged(false);
  };

  useEffect(() => {
    fetchData(parseInt(id, 10));
  }, [id]);

  const onChangeModel = (m, is) => {
    setModel({ ...m });
    setIsModelChanged(is);
  };

  const saveModel = async () => {
    await apiPostData('/webinars3/test/save', {
      id: model._id,
      data: model,
    });
    setIsModelChanged(false);
  };

  const onSave = async () => {
    const res = await warningDialog('Сохранить изменения', 'Сохранить внесенные изменения?', [
      { label: 'Отмена' },
      { label: 'Сохранить' },
    ]);

    if (res === 1) {
      saveModel();
    }
  };

  const onAddNewQ = () => {
    const qId = nanoid(5);
    setModel({
      ...model,
      questions: [
        ...model.questions,
        {
          id: qId,
          txt: `Вопрос ${model.questions.length + 1}`,
          desc: '',
          type: 0,
          answers: [
            {
              id: nanoid(5),
              txt: '',
              type: 0,
            },
          ],
        },
      ],
    });
    setShowQ([qId]);
    setIsModelChanged(true);
  };

  const onShowQ = (k) => {
    setShowQ(k);
  };

  const onCloseAnswerEditor = () => {
    setAnsEditor({ visible: false, model: {} });
  };

  const onSaveAnswer = (m) => {
    const idx = model.questions.findIndex((x) => x.id === m.id);
    if (idx >= 0) {
      setModel({
        ...model,
        questions: [...model.questions.slice(0, idx), m, ...model.questions.slice(idx + 1)],
      });
    }
    setIsModelChanged(true);
    onCloseAnswerEditor();
  };

  const onDeleteAnswer = (m) => {
    const idx = model.questions.findIndex((x) => x.id === m.id);
    if (idx >= 0) {
      setModel({
        ...model,
        questions: [...model.questions.slice(0, idx), ...model.questions.slice(idx + 1)],
      });
    }
    setIsModelChanged(true);
    onCloseAnswerEditor();
  };

  const toUp = (ev, ansId) => {
    ev.stopPropagation();

    const idx = model.questions.findIndex((x) => x.id === ansId);
    if (idx > 0) {
      const questions = arrayMove(model.questions, idx, idx - 1);
      setModel({
        ...model,
        questions: [...questions],
      });
      setIsModelChanged(true);
    }
  };

  const toDown = (ev, ansId) => {
    ev.stopPropagation();

    const idx = model.questions.findIndex((x) => x.id === ansId);
    if (idx < model.questions.length - 1) {
      const questions = arrayMove(model.questions, idx, idx + 1);
      setModel({
        ...model,
        questions: [...questions],
      });
      setIsModelChanged(true);
    }
  };

  const backToList = async () => {
    if (isModelChanged) {
      const res = await warningDialog(
        'Есть несохраненные изменения',
        'Сохранить перед выходом внесённые изменения?',
        [{ label: 'Отмена' }, { label: 'Сохранить' }],
      );

      if (res === 1) {
        await saveModel();
        history.push('/webinars/tests');
      }
    } else {
      history.push('/webinars/tests');
    }
  };

  return (
    <>
      <PageHeader
        className={styles.pageHeader}
        title={<GTooltip tid="webinars_test_editor">Карточка теста</GTooltip>}
        extra={[
          <Button key="used" onClick={() => setEventsTestId(parseInt(id, 10))}>
            Используется в мепроприятиях
          </Button>,
          <Button key="preview" onClick={() => setPreviewTestId(parseInt(id, 10))}>
            Предпросмотр
          </Button>,
          <Button key="save" type="primary" disabled={!isModelChanged} onClick={onSave}>
            Сохранить
          </Button>,
        ]}
        onBack={backToList}
      />
      {model?._id > 0 && (
        <Layout.Content>
          <div className={styles.contextHolder}>
            <div className={styles.context}>
              <XDataForm
                model={model}
                onChangeModel={onChangeModel}
                form={[
                  {
                    type: XDF_TYPE.TEXT,
                    label: 'Название',
                    prop: 'name',
                    span: 24,
                    require: true,
                    autoFocus: true,
                  },
                  {
                    type: XDF_TYPE.TEXTAREA,
                    label: 'Описание',
                    prop: 'desc',
                    placeholder: 'Укажите дополнительную информацию',
                    span: 24,
                  },
                ]}
              />
            </div>
          </div>
          <div className={styles.contextHolder}>
            <div className={styles.context}>
              <Typography.Title level={5}>Вопросы, пояснения и ответы</Typography.Title>
              {model?.questions?.length > 0 && (
                <Collapse activeKey={showQ} onChange={onShowQ}>
                  {model.questions.map((item, idx) => (
                    <Collapse.Panel
                      key={item.id}
                      header={`Вопрос ${idx + 1}`}
                      extra={[
                        item.type === 1 && (
                          <span key="type" className={styles.atype1}>
                            не оценивается
                          </span>
                        ),
                        <Button
                          key="up"
                          type="text"
                          size="small"
                          icon={<CaretUpOutlined />}
                          onClick={(ev) => toUp(ev, item.id)}
                        />,
                        <Button
                          key="down"
                          type="text"
                          size="small"
                          icon={<CaretDownOutlined />}
                          onClick={(ev) => toDown(ev, item.id)}
                        />,
                        <Button
                          key="edit"
                          type="text"
                          size="small"
                          icon={<SettingOutlined />}
                          style={{ marginLeft: 48 }}
                          onClick={(ev) => {
                            ev.stopPropagation();
                            setAnsEditor({ visible: true, model: { ...item } });
                          }}
                        />,
                      ].filter(Boolean)}
                    >
                      <div className={styles.qBlock}>
                        <Row>
                          <Col span={24}>
                            <div className={styles.vtitle}>Вопрос</div>
                            <div className={styles.val}>{item.txt}</div>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24}>
                            <div className={styles.vtitle}>Пояснение</div>
                            <div className={styles.val}>{item.desc}</div>
                          </Col>
                        </Row>
                        {item.type === 0 && (
                          <table>
                            <thead>
                              <tr>
                                <th>id</th>
                                <th>Правильный ответ</th>
                                <th>Ответ</th>
                              </tr>
                            </thead>
                            <tbody>
                              {item.answers.map((ans, i) => (
                                <tr key={ans.id}>
                                  <td>{i + 1}</td>
                                  <td>
                                    {ans.type === 1 ? (
                                      <CheckOutlined style={{ color: 'red' }} />
                                    ) : (
                                      ''
                                    )}
                                  </td>
                                  <td>{ans.txt}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        )}
                      </div>
                    </Collapse.Panel>
                  ))}
                </Collapse>
              )}
              <Row justify="end">
                <Col>
                  <Button onClick={onAddNewQ} style={{ marginTop: 16 }}>
                    Добавить вопрос
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </Layout.Content>
      )}

      <WebinarsTestAnswerEditor
        visible={ansEditor.visible}
        model={ansEditor.model}
        onClose={onCloseAnswerEditor}
        onSave={onSaveAnswer}
        onDelete={onDeleteAnswer}
      />

      <WebinarsEventsWithTest testId={eventsTestId} onClose={() => setEventsTestId(0)} />
      <WebinarTestPreview testId={previewTestId} onClose={() => setPreviewTestId(0)} />
    </>
  );
}
