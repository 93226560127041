import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Col, Select } from 'antd';
import cn from 'classnames';
import gv from 'get-value';
import PropTypes from 'prop-types';

import styles from './styles.scss';

function XDFSearch({ it, size, model, onPropChanged, multiple }) {
  const {
    id,
    prop,
    label,
    placeholder,
    autoFocus,
    span,
    readOnly,
    values,
    filterValues,
    loadingDataMsg,
  } = it;

  const mountedRef = useRef(false);
  const isMounted = useCallback(() => mountedRef.current, []);

  const [loading, setLoading] = useState(false);
  const [values0, setValues0] = useState([]);

  // mount / unmount
  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  });

  const onChange = (value) => {
    onPropChanged(it, value);
  };

  const onSearch = async (str) => {
    setLoading(true);
    const list = await values(str, model);
    if (isMounted) setValues0(list);
    setLoading(false);
  };

  const val = gv(model, prop, '');
  return (
    <Col span={span || 24}>
      <div className={styles.formItem}>
        {!!label && (
          <div className={styles.formItemLabel}>
            <label htmlFor={id}>{label}</label>
          </div>
        )}
        <div
          className={cn(
            styles.formItemField,
            { [styles.large]: size === 'large' },
            { [styles.small]: size === 'small' },
            { [styles.selectNotShow]: loading },
          )}
        >
          <Select
            showSearch
            autoClearSearchValue
            defaultActiveFirstOption
            id={id}
            size={size}
            placeholder={placeholder}
            autoFocus={!!autoFocus}
            disabled={!!readOnly}
            value={val}
            onChange={onChange}
            style={{ width: '100%' }}
            loading={loading}
            optionFilterProp="children"
            onSearch={onSearch}
          >
            {(values0 || []).map((v) => (
              <Select.Option key={v.id} value={v.id}>
                {v.name}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
    </Col>
  );
}

XDFSearch.propTypes = {
  it: PropTypes.shape({
    id: PropTypes.string.isRequired,
    prop: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    placeholder: PropTypes.string,
    autoFocus: PropTypes.bool,
    values: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
    span: PropTypes.number,
    readOnly: PropTypes.bool,
  }).isRequired,
  size: PropTypes.string.isRequired,
  model: PropTypes.shape({}).isRequired,
  onPropChanged: PropTypes.func.isRequired,
};

export default XDFSearch;
