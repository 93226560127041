import React, { useState } from 'react';

import { Layout, Modal, PageHeader } from 'antd';

import apiAnyList from '../../../../api/AnyList';
import apiGTolltipSet from '../../../../api/GTolltipSet';
import { subscribeToChannel, unsubscribeToChannel } from '../../../../api/sio';
import GTooltip from '../../../components/GTooltip/GTooltip';
import XDataForm, { XDF_TYPE } from '../../../components/xdf-antd/XDataForm';
import XDataTable from '../../../components/xdf-antd/XDataTable';
import styles from './styles.scss';
import { commonColumns } from './table-defs';

export default function CommonGTooltipList() {
  const [model, setModel] = useState({ visible: false });

  function onRowClick(item) {
    setModel({
      visible: true,
      ...item,
    });
  }

  function onChangeModel(m) {
    setModel({
      ...model,
      ...m,
    });
  }

  function closeDialog() {
    setModel({
      visible: false,
    });
  }

  async function saveTooltip() {
    await apiGTolltipSet(model._id, model.txt);
    closeDialog();
  }

  return (
    <>
      <PageHeader
        className={styles.pageHeader}
        title={<GTooltip tid="gtooltip_list">Список подсказок</GTooltip>}
      />
      <Layout.Content>
        <div className={styles.contextHolder}>
          <div className={styles.context}>
            <XDataTable
              apiLoadData={apiAnyList('GlobalTooltip')}
              commonColumns={commonColumns()}
              extentedColumns={null}
              onRowClick={onRowClick}
              sioEvents={['LIST:GTOOLTIP', subscribeToChannel, unsubscribeToChannel]}
              initSort={['_id', 1]}
            />
          </div>
        </div>
      </Layout.Content>
      <Modal
        title="Редактировать подсказку"
        open={model.visible}
        onCancel={closeDialog}
        okText="Сохранить"
        onOk={saveTooltip}
      >
        <XDataForm
          model={model}
          onChangeModel={onChangeModel}
          form={[
            {
              type: XDF_TYPE.TEXTAREA,
              label: 'Текст подсказки',
              prop: 'txt',
              rows: 8,
              span: 24,
              autoFocus: true,
            },
          ]}
        />
      </Modal>
    </>
  );
}
