import React from 'react';

import { Button, Layout, PageHeader, Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';

import { PlusOutlined } from '@ant-design/icons';

import apiAnyList from '../../../../api/AnyList';
import { subscribeToChannel, unsubscribeToChannel } from '../../../../api/sio';
import GTooltip from '../../../components/GTooltip/GTooltip';
import XDataTable from '../../../components/xdf-antd/XDataTable';
import styles from './styles.scss';
import { commonColumns } from './table-defs';

export default function KybSiteProduct() {
  const history = useHistory();

  const onAddNews = () => {
    history.push(`/site/news/--new--`);
  };

  const onRowClick = (item) => {
    history.push(`/site/product/${item._id}`);
  };

  return (
    <>
      <PageHeader
        className={styles.pageHeader}
        title={<GTooltip tid="kyb_site_products">Список продуктов</GTooltip>}
        // extra={[
        //   <Tooltip key="add" title="Добавить новость">
        //     <Button type="primary" shape="circle" icon={<PlusOutlined />} onClick={onAddNews} />
        //   </Tooltip>,
        // ]}
      />
      <Layout.Content>
        <div className={styles.contextHolder}>
          <div className={styles.context}>
            <XDataTable
              apiLoadData={apiAnyList('KybDb:Product')}
              commonColumns={commonColumns()}
              extentedColumns={null}
              onRowClick={onRowClick}
              sioEvents={['LIST:SITE_PRODUCTS', subscribeToChannel, unsubscribeToChannel]}
              initSort={['_id', 1]}
              searchProps={['name', 'desc']}
            />
          </div>
        </div>
      </Layout.Content>
    </>
  );
}
