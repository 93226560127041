/**
 * Округление числа до нужного знака после запятой
 * @param x
 * @param n
 * @returns {*}
 */
// eslint-disable-next-line import/prefer-default-export
export const xdRoundPlus = (x, n = 2) => {
  // x - число, n - количество знаков
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(x) || isNaN(n)) return undefined;
  // eslint-disable-next-line no-restricted-properties
  const m = Math.pow(10, n);
  return Math.round(x * m) / m;
};

export const xdGetColProp = (prop) => {
  if (prop && prop.charAt(0) === '+') return prop.substring(1);
  return prop;
};
