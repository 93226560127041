import React from 'react';

import { Button, Modal } from 'antd';

import { ExclamationCircleOutlined } from '@ant-design/icons';

const { confirm } = Modal;

/**
 * Открыть диалог для уточнения действия, возвращает код из btns
 * @param {*} title
 * @param {*} content
 * @param {*} btns  (максимум 2)
 *  - label - название на кнопке
 * @returns
 */
// eslint-disable-next-line import/prefer-default-export
export async function warningDialog(title, content, btns) {
  return new Promise((resolve, reject) => {
    confirm({
      title,
      content,
      icon: <ExclamationCircleOutlined />,
      closable: true,
      okText: btns.length > 1 && btns[1].label,
      cancelText: btns.length > 0 && btns[0].label,
      afterClose: () => {
        resolve(-1);
      },
      onOk() {
        resolve(1);
      },
      onCancel() {
        resolve(0);
      },
    });
  });
}
