import { apiPost, ApiError } from '.';

/**
 * Запрос серий продуктов
 */
const apiEmailTemplateSave = async (id, state, name, code) => {
  return apiPost('/email/template/save', {
    id,
    data: {
      state,
      name,
      code,
    },
  });
};
export default apiEmailTemplateSave;
