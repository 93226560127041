import React, { useEffect, useState } from 'react';

import { Button, Checkbox, Tag } from 'antd';
import dayjs from 'dayjs';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import Hashids from 'hashids';
import { apiPostData } from '../../../api';
import KybLoginDialog from '../../components/Dialogs/KybLoginDialog/KybLoginDialog';
import XDataForm, { XDF_TYPE } from '../../components/xdf-antd/XDataForm';
import {
  WEBINAR3_TYPE,
  WEBINAR3_TYPES_ID2NAME,
  WEBINAR3_VIEW,
} from '../../pages/Webinars/webinar_constants';
import Webinars3UserForm from '../Common/Webinars3UserForm';
import styles from './styles.scss';
import Webinars3ConnectHeader from './Webinars3ConnectHeader';
import Webinars3StopDialogs from './Webinars3StopDialogs';

export default function Webinars3ConnectHome() {
  const location = useLocation();
  const qs = queryString.parse(location.search);

  const [loginDialogVisible, setLoginDialogVisible] = useState(false);
  const [showUserForm, setShowUserForm] = useState(false);

  const [model, setModel] = useState({ view: 1 });
  const [sendBtnDisable, setSendBtnDisable] = useState(false);
  const [eventsList, setEventsList] = useState([]);
  const [selectedEventIds, setSelectedEventIds] = useState([]);
  const [showResultDialog, setShowResultDialog] = useState(0);
  const [preselected, setPreselected] = useState(0);

  const fetchList = async () => {
    const { list } = await apiPostData('/webinars3/event/public/list');
    setEventsList(list);
  };

  const addUserToEvents = async () => {
    if (selectedEventIds.length > 0) {
      const { result } = await apiPostData('/webinars3/user/add', { eventIds: selectedEventIds });
      setShowResultDialog(result);
    } else {
      document.location.replace('/');
    }
  };

  useEffect(() => {
    // set #app
    document.getElementById('app').style['background-color'] = '#ffffff';
    fetchList();
  }, []);

  useEffect(() => {
    if (qs?.ev) {
      const hashids = new Hashids();
      const eventIds = hashids.decode(qs.ev);
      if (eventIds.length === 2) {
        setModel({
          ...model,
          view: eventIds[0],
        });
        setSelectedEventIds([...selectedEventIds, eventIds[1]]);
        setPreselected(eventIds[1]);
      }
    }
  }, [qs?.ev]);

  const onChangeModel = (m) => {
    setModel(m);
  };

  const onShowLoginDialog = () => {
    setSendBtnDisable(true);
    setLoginDialogVisible(true);
  };

  const onAuthSuccess = (data) => {
    if (data?.webinarUser?.state !== 1) {
      // show user form
      setShowUserForm(true);
    } else {
      setLoginDialogVisible(false);
      //
      addUserToEvents();
    }
  };

  const onSuccessUserForm = async () => {
    addUserToEvents();
  };

  const onCloseUserForm = () => {
    document.location.reload();
  };

  const onSelectEvent = (e, eventId) => {
    if (e.target.checked) {
      setSelectedEventIds([...selectedEventIds, eventId]);
    } else {
      setSelectedEventIds([...selectedEventIds.filter((id) => !eventId)]);
    }
  };
  // console.log(selectedEventIds);

  return (
    <div className={styles.main}>
      <Webinars3ConnectHeader />
      <div className={styles.container}>
        <h1>РЕГИСТРАЦИЯ НА МЕРОПРИЯТИЯ KYB</h1>
        <div className={styles.subh1}>
          ООО «КУБ Евразия» – представительство KYB в России и странах Таможенного Союза
        </div>

        <div className={styles.form}>
          {preselected === 0 && (
            <XDataForm
              model={model}
              onChangeModel={onChangeModel}
              form={[
                {
                  type: XDF_TYPE.RADIO,
                  label: 'Вид мероприятия',
                  prop: 'view',
                  values: WEBINAR3_VIEW,
                  defValue: 1,
                  span: 24,
                },
                {
                  type: XDF_TYPE.MULTISELECT,
                  label: 'Тип мероприятия',
                  prop: 'type',
                  values: WEBINAR3_TYPE,
                  span: 24,
                },
              ]}
            />
          )}

          <div style={{ marginTop: 24 }}>
            {preselected === 0 && <div>Выбор интересующего мероприятия</div>}
            <div className={styles.eventsList}>
              {(eventsList || [])
                .filter((ev) => {
                  const types = model.type || [];
                  if (types.length === 0 && ev.view === model.view) return true;
                  if (
                    ev.view === model.view &&
                    types.filter((v) => (ev.type || []).includes(v)).length > 0
                  )
                    return true;
                  return false;
                })
                .filter((ev) => {
                  if (preselected === 0) return true;
                  return ev._id === preselected;
                })
                .map((event) => (
                  <div key={event._id} className={styles.eventBlk}>
                    <div className={styles.type}>
                      {event.type.map((t) => (
                        <Tag key={t} color="error">
                          {WEBINAR3_TYPES_ID2NAME[t]}
                        </Tag>
                      ))}
                    </div>
                    <div className={styles.title}>{event.name}</div>
                    {event.coach && <div className={styles.coach}>Тренер: {event.coach}</div>}
                    <div className={styles.dates}>
                      {dayjs(event.firtsStage.dtStart).format('DD MMMM YYYY, HH:mm')} ..
                      {dayjs(event.lastStage.dtEnd).format('DD MMMM YYYY, HH:mm')}
                    </div>
                    <div className={styles.sel}>
                      <Checkbox
                        onChange={(e) => onSelectEvent(e, event._id)}
                        checked={selectedEventIds.includes(event._id)}
                      >
                        Интересует
                      </Checkbox>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className={styles.footer}>
            <Button onClick={onShowLoginDialog} disabled={sendBtnDisable}>
              {selectedEventIds.length > 0 ? 'Отправить' : 'Войти в личный кабинет'}
            </Button>
            <p>
              Нажимая на кнопку, вы даете согласие на обработку персональных данных и соглашаетесь c
              её политикой
            </p>
          </div>
        </div>
      </div>

      <KybLoginDialog visible={loginDialogVisible} onAuthSuccess={onAuthSuccess} />
      <Webinars3UserForm
        visible={showUserForm}
        onSuccess={onSuccessUserForm}
        onClose={onCloseUserForm}
      />
      <Webinars3StopDialogs result={showResultDialog} />
    </div>
  );
}
