import React, { useEffect, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { meState } from '../../../state/panel-state';
import Webinars3ConnectHome from '../Home-Public/Webinars3ConnectHome';
import Webinars3MainForm from './Webinars3MainFrame';
import Webinars3UserForm from './Webinars3UserForm';

export default function Webinars3Connect() {
  const me = useRecoilValue(meState);

  const [showUserForm, setShowUserForm] = useState(false);

  useEffect(() => {
    if (me?._id > 0 && me?.webinarUser?.state !== 1) {
      setShowUserForm(true);
    }
  }, [me?._id]);

  const onSuccessUserForm = () => {
    document.location.reload();
  };

  const onCloseUserForm = () => {
    document.location.reload();
  };

  // eslint-disable-next-line no-underscore-dangle
  if (me.__error === 100) {
    return <Webinars3ConnectHome />;
  }

  return (
    <>
      {me?.webinarUser?.state === 1 && <Webinars3MainForm />}

      <Webinars3UserForm
        visible={showUserForm}
        onSuccess={onSuccessUserForm}
        onClose={onCloseUserForm}
      />
    </>
  );
}
