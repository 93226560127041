import React, { useEffect, useState } from 'react';

import { Button, message, Modal } from 'antd';
import PropTypes from 'prop-types';

import apiAnyGet from '../../../../api/AnyGet';
import { apiPost } from '../../../../api/index';
import { warningDialog } from '../../../components/Common/WarningDialog';
import GTooltip from '../../../components/GTooltip/GTooltip';
import XDataForm from '../../../components/xdf-antd/XDataForm';
import { mainCatalogFormProps } from './table-defs';

function MainCatalogEditDlg({ visible, model, onClose }) {
  const [model0, setModel0] = useState({});
  const [isModelChanged, setIsModelChanged] = useState(false);

  async function loadModel(id) {
    const data = await apiAnyGet('KybCatalog:Main')(id, []);
    setModel0(data);
    setIsModelChanged(false);
  }

  useEffect(() => {
    if (visible && model._id) {
      loadModel(model._id);
    }
  }, [visible]);

  function onChangeModel(m, v) {
    setModel0({ ...m });
    setIsModelChanged(v);
  }

  async function onSave() {
    const res = await warningDialog('Редактирование записи', 'Сохранить внесённые изменения?', [
      { label: 'Отмена' },
      { label: 'Сохранить' },
    ]);

    if (res === 1) {
      // save record
      const { err, errmsg } = await apiPost('/catalog/main/set', {
        id: model._id,
        data: model0,
      });
      if (err > 0) {
        if (Array.isArray(errmsg)) {
          for (const msg of errmsg) {
            message.error(msg.message);
          }
        } else {
          message.error(errmsg || `Net error ${err}`);
        }
      } else onClose();
    }
  }

  async function onDelete() {
    const res = await warningDialog(
      'Удаление записи',
      'Удалить запись, без возможности восстановления?',
      [{ label: 'Отмена' }, { label: 'Удалить' }],
    );

    if (res === 1) {
      // delete record
      const { err, errmsg } = await apiPost('/catalog/main/del', {
        id: model._id,
      });
      if (err > 0) {
        if (Array.isArray(errmsg)) {
          for (const msg of errmsg) {
            message.error(msg.message);
          }
        } else {
          message.error(errmsg || `Net error ${err}`);
        }
      } else onClose();
    }
  }

  return (
    <Modal
      title={<GTooltip tid="catalogs_main_dlg">Редактирование записи каталога</GTooltip>}
      open={visible}
      width={800}
      closable={false}
      footer={[
        <Button key="del" style={{ marginRight: 'auto' }} onClick={onDelete}>
          Удалить запись
        </Button>,
        <Button key="close" onClick={onClose}>
          Закрыть
        </Button>,
        <Button key="save" type="primary" onClick={onSave} disabled={!isModelChanged}>
          Сохранить
        </Button>,
      ]}
    >
      <XDataForm model={model0} onChangeModel={onChangeModel} form={mainCatalogFormProps(model0)} />
    </Modal>
  );
}

MainCatalogEditDlg.propTypes = {
  visible: PropTypes.bool.isRequired,
  model: PropTypes.shape({}).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default MainCatalogEditDlg;
