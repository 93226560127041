import React, { useEffect, useState } from 'react';

import { Modal } from 'antd';
import PropTypes from 'prop-types';
import XDataForm, { XDF_TYPE } from '../../../components/xdf-antd/XDataForm';
import { KYB_MAP_PROP_TYPES } from '../../../constants';

function MapSetsPropsDlg({ visible, model, onSave, onCancel }) {
  const [model0, setModel0] = useState({});
  const [isModelChanged, setIsModelChanged] = useState(false);

  useEffect(() => {
    if (visible) {
      setModel0(model);
    }
  }, [visible]);

  const onSaveModel = () => {
    onSave(model0);
  };

  const onChangeModel = (m, v) => {
    setModel0({ ...m });
    setIsModelChanged(v);
  };

  return (
    <Modal
      title="Редактирование поля объекта"
      open={visible}
      onOk={onSaveModel}
      okButtonProps={{
        disabled: !isModelChanged,
      }}
      onCancel={onCancel}
    >
      <XDataForm
        model={model0}
        onChangeModel={onChangeModel}
        form={[
          {
            type: XDF_TYPE.SELECT,
            label: 'Тип поля',
            prop: 'type',
            values: KYB_MAP_PROP_TYPES,
            span: 24,
          },
          {
            type: XDF_TYPE.TEXT,
            label: 'Поле',
            prop: 'prop',
            span: 24,
          },
          {
            type: XDF_TYPE.TEXT,
            label: 'Название',
            prop: 'name',
            span: 24,
          },
          {
            type: XDF_TYPE.TEXT,
            label: 'Подсказка',
            prop: 'ph',
            span: 24,
          },
          {
            type: XDF_TYPE.SWITCH,
            label: 'Обязательное',
            prop: 'req',
            boolType: false,
            span: 12,
          },
          {
            type: XDF_TYPE.SWITCH,
            label: 'Внешнее',
            prop: 'ext',
            boolType: false,
            span: 12,
          },
        ]}
      />
    </Modal>
  );
}

MapSetsPropsDlg.defaultProps = {};

MapSetsPropsDlg.propTypes = {
  visible: PropTypes.bool.isRequired,
  model: PropTypes.shape({}).isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default MapSetsPropsDlg;
