import React, { useEffect, useRef, useState } from 'react';

import { Col, Empty, Input, Tooltip } from 'antd';
import cn from 'classnames';
import gv from 'get-value';
import PropTypes from 'prop-types';

import styles from './styles.scss';
import { xdRoundPlus } from '../XDataTable/xdf-utils';

const parseYoutubeId = (url) => {
  // eslint-disable-next-line prefer-regex-literals
  const regexp = new RegExp(
    '(?:youtube(?:-nocookie)?.com/(?:[^/]+/.+/|(?:v|e(?:mbed)?)/|.*[?&]v=)|youtu.be/)([^"&?/s]{11})',
    'i',
  );
  const matches = url.match(regexp);
  return matches ? matches[1] : false;
};

function XDFVideo({ it, size, model, onPropChanged }) {
  const { id, prop, label, placeholder, autoFocus, span, readOnly, showOnly, require } = it;

  const pic = useRef(null);
  const [videoModel, setVideoModel] = useState({ videoId: '' });
  const val = (gv(model, prop) || '').split('|');
  const [videoType, videoId, aspectRatio] = val;

  useEffect(() => {
    if (videoType && videoId) {
      setVideoModel({
        videoType,
        videoId,
      });
    } else {
      setVideoModel({
        videoId: '',
      });
    }
  }, [videoType, videoId, aspectRatio]);

  const onChange = (ev) => {
    const v = ev.target.value;
    const vid = parseYoutubeId(v);

    setVideoModel({
      ...videoModel,
      videoId: vid || v,
    });
  };

  const onLoadImage = () => {
    const w = pic.current?.width || 400;
    const h = pic.current?.height || 300;
    const wh = xdRoundPlus(w / h, 3);

    const videoCode = `youtube|${videoModel.videoId}|${wh}`;
    if (videoCode !== val) onPropChanged(it, videoCode);
  };

  return (
    <Col span={span || 24}>
      <div className={styles.formItem}>
        <div className={styles.formItemLabel}>
          <label htmlFor={id}>
            {label}
            {require && <sup>*</sup>}
          </label>
        </div>
        <div
          className={cn(
            styles.formItemField,
            { [styles.small]: size === 'small' },
            { [styles.showOnly]: !!showOnly },
          )}
        >
          <Input
            id={id}
            size={size}
            placeholder={placeholder || ''}
            autoFocus={!!autoFocus}
            disabled={!!readOnly || !!showOnly}
            value={videoModel.videoId}
            onChange={onChange}
          />

          {!videoModel.videoId && (
            <div className={styles.videoBlk}>
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Нет видео" />
            </div>
          )}

          {videoModel.videoId && (
            <div className={styles.videoBlk}>
              <img
                ref={pic}
                onLoad={onLoadImage}
                src={`//img.youtube.com/vi/${videoModel.videoId}/hqdefault.jpg`}
                alt="Video preview"
              />
            </div>
          )}
        </div>
      </div>
    </Col>
  );
}

XDFVideo.propTypes = {
  it: PropTypes.shape({
    id: PropTypes.string.isRequired,
    prop: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    placeholder: PropTypes.string,
    autoFocus: PropTypes.bool,
    span: PropTypes.number,
    readOnly: PropTypes.bool,
    showOnly: PropTypes.bool,
    require: PropTypes.bool,
  }).isRequired,
  size: PropTypes.string.isRequired,
  model: PropTypes.shape({}).isRequired,
  onPropChanged: PropTypes.func.isRequired,
};

export default XDFVideo;
