import React from 'react';

import { Button, Modal } from 'antd';
import PropTypes from 'prop-types';

import { subscribeToChannel, unsubscribeToChannel } from '../../../../api/sio';
import apiWebinar3EventList from '../../../../api/Webinar3EventList';
import GTooltip from '../../../components/GTooltip/GTooltip';
import XDataTable from '../../../components/xdf-antd/XDataTable';
import { eventsWithTestTable } from './events-list';

function WebinarsAddUsersToEventDlg({ visible, onClose, onSelect }) {
  return (
    <Modal
      open={visible}
      title={
        <GTooltip tid="webinars_add_users_to_event">
          Укажите вебинар, куда добавить пользователей
        </GTooltip>
      }
      onCancel={onClose}
      footer={<Button onClick={onClose}>Закрыть</Button>}
      width="80%"
    >
      {visible && (
        <XDataTable
          apiLoadData={apiWebinar3EventList()}
          commonColumns={eventsWithTestTable()}
          extentedColumns={null}
          initSort={['firtsStage.dtStart', 1]}
          initFilter={{}}
          onRowClick={(ev) => onSelect(ev._id)}
          sioEvents={['LIST:WEBINARS3_EVENTS', subscribeToChannel, unsubscribeToChannel]}
          searchProps={['name']}
          urlParamName="__ev__"
        />
      )}
    </Modal>
  );
}

WebinarsAddUsersToEventDlg.defaultProps = {};

WebinarsAddUsersToEventDlg.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default WebinarsAddUsersToEventDlg;
