import React, { useEffect, useState, useRef } from 'react';

import { Button, Col, Layout, Modal, PageHeader, Row } from 'antd';
import { useRecoilValue } from 'recoil';

import { CloudUploadOutlined } from '@ant-design/icons';

import apiAnyList from '../../../../api/AnyList';
import { subscribeToChannel, unsubscribeToChannel } from '../../../../api/sio';
import { meState } from '../../../../state/panel-state';
import GTooltip from '../../../components/GTooltip/GTooltip';
import XDataForm, { XDF_TYPE } from '../../../components/xdf-antd/XDataForm';
import XDataTable from '../../../components/xdf-antd/XDataTable';
import XDataUpload from '../../../components/xdf-antd/XDataUpload/XDataUpload';
import styles from './styles.scss';
import { commonColumns } from './table-defs';

const MAX_FILE_SIZE = 256 * 1024 * 1024;

const defModel = {
  type: 'picture',
  picOptimize: true,
  picWebp: true,
  desc: '',
};

export default function CommonFiles() {
  const tableEmitter = useRef(null);

  const me = useRecoilValue(meState);

  const [uploadDlgVisible, setUploadDlgVisible] = useState(false);
  const [startUploading, setStartUploading] = useState(false);

  const [model, setModel] = useState(defModel);

  useEffect(() => {
    if (!uploadDlgVisible) {
      setModel(defModel);
    }
  }, [uploadDlgVisible]);

  const onUploadFile = () => {
    setUploadDlgVisible(true);
  };

  const closeUploadDialog = () => {
    setUploadDlgVisible(false);
    setStartUploading(false);
  };

  const onSaveFileInfo = () => {
    closeUploadDialog();
    setTimeout(() => {
      tableEmitter.current.emit('RELOAD-DATA');
    }, 1000);
  };

  const onStartUpload = () => {
    setStartUploading(true);
  };

  const onSaveEventEmmitter = (ee) => {
    tableEmitter.current = ee;
  };

  return (
    <>
      <PageHeader
        className={styles.pageHeader}
        title={<GTooltip tid="file_list">Список файлов (картинок)</GTooltip>}
        extra={[
          <Button key="upload" type="primary" icon={<CloudUploadOutlined />} onClick={onUploadFile}>
            Добавить файл
          </Button>,
        ]}
      />
      <Layout.Content>
        <div className={styles.contextHolder}>
          <div className={styles.context}>
            <XDataTable
              apiLoadData={apiAnyList('File')}
              commonColumns={commonColumns()}
              extentedColumns={null}
              sioEvents={['LIST:FILE', subscribeToChannel, unsubscribeToChannel]}
              initSort={['cdt', -1]}
              onSaveEventEmitter={onSaveEventEmmitter}
            />
          </div>
        </div>
      </Layout.Content>
      <Modal
        title="Загрузить файл"
        open={uploadDlgVisible}
        closable={false}
        footer={[
          <Button key="back" onClick={closeUploadDialog} disabled={startUploading}>
            Закрыть
          </Button>,
        ]}
        width={610}
      >
        <Row gutter={[16, 0]}>
          <Col span={16}>
            <XDataForm
              model={model}
              onChangeModel={(m) => setModel({ ...m })}
              form={[
                {
                  type: XDF_TYPE.SELECT,
                  label: 'Тип загружаемого файла',
                  prop: 'type',
                  defValue: 'picture',
                  values: [
                    {
                      id: 'picture',
                      name: 'Картинка (resize: 1920 max)',
                    },
                    {
                      id: 'avatar',
                      name: 'Аватар (resize: 480х480)',
                    },
                    {
                      id: 'file',
                      name: 'Просто файл (без изменений)',
                    },
                  ],
                  span: 24,
                  readOnly: startUploading,
                },
                {
                  type: XDF_TYPE.CHECKBOX,
                  label: 'Оптимизировать',
                  prop: 'picOptimize',
                  boolType: true,
                  hide: !['picture', 'avatar'].includes(model.type),
                  span: 12,
                  readOnly: startUploading,
                },
                {
                  type: XDF_TYPE.CHECKBOX,
                  label: 'Создавать WEBP',
                  prop: 'picWebp',
                  boolType: true,
                  hide: !['picture', 'avatar'].includes(model.type),
                  span: 12,
                  readOnly: startUploading,
                },
                {
                  type: XDF_TYPE.TEXTAREA,
                  label: 'Описание (обязательное)',
                  prop: 'desc',
                  span: 24,
                  rows: 3,
                  autoFocus: true,
                  readOnly: startUploading,
                },
              ]}
            />
          </Col>
          <Col span={8} style={{ textAlign: 'right' }}>
            <XDataUpload
              uploadType={model.type}
              desc={model.desc}
              maxSize={MAX_FILE_SIZE}
              optimizeImage={model.picOptimize}
              webpImage={model.picWebp}
              onSave={onSaveFileInfo}
              onStartUpload={onStartUpload}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
}
