import React, { useEffect, useState } from 'react';

import { Button, Collapse, Layout, PageHeader, Table, Tooltip } from 'antd';
import { useRecoilValue } from 'recoil';

import { CloudUploadOutlined } from '@ant-design/icons';

import { mapSettingsState } from '../../../../state/map-state';
import GTooltip from '../../../components/GTooltip/GTooltip';
import XDataForm, { XDF_TYPE } from '../../../components/xdf-antd/XDataForm';
import styles from './styles.scss';
import { categoriesColumns, dictsColumns, propsColumns, iconsColumns } from './table-defs';
import MapSetsCategoriesDlg from './MapSetsCategoriesDlg';
import MapSetsPropsDlg from './MapSetsPropsDlg';
import MapSetsDictsDlg from './MapSetsDictsDlg';
import MapSetsIconsDlg from './MapSetsIconsDlg';

const { Panel } = Collapse;

export default function MapSettings() {
  const mapSettings = useRecoilValue(mapSettingsState);

  const [catDlg, setCatDlg] = useState({ visible: false, model: {}, idx: -1 });
  const [propDlg, setPropDlg] = useState({ visible: false, model: {}, idx: -1 });
  const [dictDlg, setDictDlg] = useState({ visible: false, model: {}, idx: -1 });
  const [iconDlg, setIconDlg] = useState({ visible: false, model: {}, idx: -1 });

  const [model, setModel] = useState({});
  const [isModelChanged, setIsModelChanged] = useState(false);

  useEffect(() => {
    setModel(mapSettings);
  }, []);

  const onChangeModel = (m, v) => {
    setModel({ ...m });
    setIsModelChanged(v);
  };

  // categories
  const onSaveCat = (cat) => {
    setCatDlg({ ...catDlg, visible: false });
    setIsModelChanged(true);
    setModel({
      ...mapSettings,
      categories: [
        ...mapSettings.categories.slice(0, catDlg.idx),
        cat,
        ...mapSettings.categories.slice(catDlg.idx + 1),
      ],
    });
  };

  // props
  const onSaveProp = (p) => {
    setPropDlg({ ...propDlg, visible: false });
    setIsModelChanged(true);
    setModel({
      ...mapSettings,
      props: [
        ...mapSettings.props.slice(0, propDlg.idx),
        p,
        ...mapSettings.props.slice(propDlg.idx + 1),
      ],
    });
  };

  // dicts
  const onSaveDict = (d) => {
    setDictDlg({ ...dictDlg, visible: false });
    setIsModelChanged(true);
    setModel({
      ...mapSettings,
      dict: [
        ...mapSettings.dict.slice(0, dictDlg.idx),
        d,
        ...mapSettings.dict.slice(dictDlg.idx + 1),
      ],
    });
  };

  // icons
  const onSaveIcon = (d) => {
    setIconDlg({ ...iconDlg, visible: false });
    setIsModelChanged(true);
    setModel({
      ...mapSettings,
      icons: [
        ...mapSettings.icons.slice(0, iconDlg.idx),
        d,
        ...mapSettings.icons.slice(iconDlg.idx + 1),
      ],
    });
  };

  return (
    <>
      <PageHeader
        className={styles.pageHeader}
        title={<GTooltip tid="map_settings">Настройки карты</GTooltip>}
        extra={[
          <Tooltip key="upload" title="Сохранить настройки">
            <Button
              type="primary"
              shape="circle"
              icon={<CloudUploadOutlined />}
              disabled={!isModelChanged}
            />
          </Tooltip>,
        ]}
      >
        <XDataForm
          model={model}
          onChangeModel={onChangeModel}
          form={[
            {
              type: XDF_TYPE.TEXT,
              label: 'Google Api Key',
              prop: 'googleKeyApi',
              span: 12,
              require: true,
            },
            {
              type: XDF_TYPE.TEXT,
              label: 'Yandex Api Key',
              prop: 'yandexKeyApi',
              require: true,
              span: 12,
            },
          ]}
        />
      </PageHeader>
      <Layout.Content>
        <div className={styles.contextHolder}>
          <div className={styles.context}>
            <Collapse defaultActiveKey={[]}>
              <Panel header="Настройки категорий" key="categories">
                <Table
                  columns={categoriesColumns()}
                  dataSource={(model.categories || []).map((x) => ({ ...x, key: x.id }))}
                  pagination={false}
                  size="small"
                  onRow={(record, rowIndex) => ({
                    onClick: () => setCatDlg({ visible: true, model: record, idx: rowIndex }),
                  })}
                />
              </Panel>
              <Panel header="Настройки полей" key="props">
                <Table
                  columns={propsColumns()}
                  dataSource={(model.props || []).map((x) => ({ ...x, key: x.prop }))}
                  pagination={false}
                  size="small"
                  onRow={(record, rowIndex) => ({
                    onClick: () => setPropDlg({ visible: true, model: record, idx: rowIndex }),
                  })}
                />
              </Panel>
              <Panel header="Настройки словарей" key="dicts">
                <Table
                  columns={dictsColumns()}
                  dataSource={(model.dict || []).map((x) => ({ ...x, key: x.id }))}
                  pagination={false}
                  size="small"
                  onRow={(record, rowIndex) => ({
                    onClick: () => setDictDlg({ visible: true, model: record, idx: rowIndex }),
                  })}
                />
              </Panel>
              <Panel header="Настройки иконок" key="icons">
                <Table
                  columns={iconsColumns()}
                  dataSource={(model.icons || []).map((x) => ({ ...x, key: x.id }))}
                  pagination={false}
                  size="small"
                  onRow={(record, rowIndex) => ({
                    onClick: () => setIconDlg({ visible: true, model: record, idx: rowIndex }),
                  })}
                />
              </Panel>
            </Collapse>
          </div>
        </div>
      </Layout.Content>

      <MapSetsCategoriesDlg
        visible={catDlg.visible}
        model={catDlg.model}
        onSave={onSaveCat}
        onCancel={() => setCatDlg({ ...catDlg, visible: false })}
      />
      <MapSetsPropsDlg
        visible={propDlg.visible}
        model={propDlg.model}
        onSave={onSaveProp}
        onCancel={() => setPropDlg({ ...propDlg, visible: false })}
      />
      <MapSetsDictsDlg
        visible={dictDlg.visible}
        model={dictDlg.model}
        onSave={onSaveDict}
        onCancel={() => setDictDlg({ ...dictDlg, visible: false })}
      />
      <MapSetsIconsDlg
        visible={iconDlg.visible}
        model={iconDlg.model}
        onSave={onSaveIcon}
        onCancel={() => setIconDlg({ ...iconDlg, visible: false })}
      />
    </>
  );
}
