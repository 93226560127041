import React from 'react';

import { Button, Col, Row } from 'antd';
import cn from 'classnames';
import gv from 'get-value';
import PropTypes from 'prop-types';

import styles from './styles.scss';

function XDFTableSimple({ it, size, model, onPropChanged }) {
  const { id, prop, span, columns } = it;

  const rows = gv(model, prop, []);
  const onDelete = (idx) => {
    onPropChanged(it, [...rows.slice(0, idx), ...rows.slice(idx + 1)]);
  };

  return (
    <Col span={span || 24}>
      <div className={styles.formItem}>
        <div
          className={cn(
            styles.formItemField,
            { [styles.small]: size === 'small' },
            styles.simpleTable,
          )}
        >
          {(rows || []).map((row, i) => (
            <Row key={i} gutter={[8, 0]}>
              {(columns || []).map((col) => {
                if (col.action === 'delete') {
                  return (
                    <Col key={col.action} span={col.span || 24}>
                      <Button type="link" size={size} onClick={() => onDelete(i)}>
                        Удалить
                      </Button>
                    </Col>
                  );
                }
                return (
                  <Col key={col.prop} span={col.span || 24}>
                    <div className={styles.simpleTableCell}>{row[col.prop]}</div>
                  </Col>
                );
              })}
            </Row>
          ))}
        </div>
      </div>
    </Col>
  );
}

XDFTableSimple.propTypes = {
  it: PropTypes.shape({
    id: PropTypes.string.isRequired,
    prop: PropTypes.string.isRequired,
    span: PropTypes.number,
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        action: PropTypes.string,
        type: PropTypes.string,
        prop: PropTypes.string,
      }),
    ),
  }).isRequired,
  size: PropTypes.string.isRequired,
  model: PropTypes.shape({}).isRequired,
  onPropChanged: PropTypes.func.isRequired,
};

export default XDFTableSimple;
