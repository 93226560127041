import React, { useEffect, useState } from 'react';

import { Modal } from 'antd';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';

import { mapCategoriesState, mapDictState, mapIconsState } from '../../../../state/map-state';
import XDataForm, { XDF_TYPE } from '../../../components/xdf-antd/XDataForm';
import { KYB_MAP_OBJECT_GROUP } from '../../../constants';

function MapObjectsMassChange({ visible, onSave, onCancel }) {
  const categoriesList = useRecoilValue(mapCategoriesState);
  const iconsList = useRecoilValue(mapIconsState);
  const dictList = useRecoilValue(mapDictState);

  const [model0, setModel0] = useState({});
  const [isModelChanged, setIsModelChanged] = useState(false);

  useEffect(() => {
    if (visible) {
      setModel0({});
    }
  }, [visible]);

  const onSaveModel = () => {
    onSave(model0);
  };

  const onChangeModel = (m, v) => {
    setModel0({ ...m });
    setIsModelChanged(v);
  };

  return (
    <Modal
      title="Групповые изменения"
      open={visible}
      onOk={onSaveModel}
      okButtonProps={{
        disabled: !isModelChanged,
      }}
      onCancel={onCancel}
    >
      <XDataForm
        model={model0}
        onChangeModel={onChangeModel}
        form={[
          {
            type: XDF_TYPE.SELECT,
            label: 'Категория',
            prop: 'cid',
            values: categoriesList,
            span: 16,
          },
          {
            type: XDF_TYPE.CHECKBOX,
            boolType: false,
            title: 'Активно',
            prop: 'active',
            span: 8,
          },
          {
            type: XDF_TYPE.MULTISELECT,
            label: 'Виды работ',
            prop: 'ts',
            values: dictList.filter((x) => x.prop === 'ts'),
            span: 24,
          },
          {
            type: XDF_TYPE.MULTISELECT,
            label: 'Виды услуг',
            prop: 'tos',
            values: dictList.filter((x) => x.prop === 'tos'),
            span: 24,
          },
          {
            type: XDF_TYPE.SELECT,
            label: 'Группа',
            prop: 'gr',
            span: 24,
            values: Object.keys(KYB_MAP_OBJECT_GROUP).map((k) => ({
              id: parseInt(k, 10),
              name: KYB_MAP_OBJECT_GROUP[k],
            })),
          },
          {
            type: XDF_TYPE.SELECT,
            label: 'Уникальная иконка объекта',
            prop: '_iid',
            span: 24,
            values: [{ id: '', name: 'Не указана' }].concat(
              iconsList?.map((el) => ({
                id: el.id,
                name: `${el.name} [${el.id}]`,
              })),
            ),
          },
          {
            type: XDF_TYPE.DIVIDER,
            label: 'Проверка возможности изменения объектов',
          },
          {
            type: XDF_TYPE.NUMBER,
            label: 'Введите число',
            prop: '__checkNumber',
            require: true,
          },
        ]}
      />
    </Modal>
  );
}

MapObjectsMassChange.defaultProps = {};

MapObjectsMassChange.propTypes = {
  visible: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default MapObjectsMassChange;
