import React, { useState } from 'react';

import { Input, Modal } from 'antd';
import PropTypes from 'prop-types';

import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';

import SortableItem from './SortableItem';
import styles from './styles.scss';
import UploadItemWithCrop from './UploadItemWithCrop';

function XDataGallery({
  basePicUrl,
  picWidth,
  onChange,
  items,
  maxCount,
  disableDescription,
  disabled,
  roundImage,
  picDescDisable,
  aspects,
}) {
  const [activeItem, setActiveItem] = useState({});
  const [editDescDlg, setEditDescDlg] = useState({ visible: false, desc: '' });

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragStart = (event) => {
    // setActiveId(event.active.id);
    setActiveItem(event.active);
  };

  const handleDragEnd = (event) => {
    // setActiveId(null);
    setActiveItem({});
    const { active, over } = event;

    if (active.id !== over.id) {
      // setItems((values) => {
      //   const oldIndex = values.findIndex((x) => x.id === active.id);
      //   const newIndex = values.findIndex((x) => x.id === over.id);
      //   return arrayMove(values, oldIndex, newIndex);
      // });

      const oldIndex = items.findIndex((x) => x.id === active.id);
      const newIndex = items.findIndex((x) => x.id === over.id);
      onChange(arrayMove(items, oldIndex, newIndex));
    }
  };

  const onRemovePic = (picId) => {
    const idx = items.findIndex((x) => x.id === picId);
    onChange([...items.slice(0, idx), ...items.slice(idx + 1)]);
  };

  const onEditDesc = (picId) => {
    const it = items.find((x) => x.id === picId);
    if (it) {
      setEditDescDlg({ ...editDescDlg, visible: true, picId, desc: it.desc, desc0: it.desc });
    }
  };

  const closeEditDescDlg = () => {
    setEditDescDlg({ ...editDescDlg, visible: false });
  };

  const onChangeDesc = () => {
    const idx = items.findIndex((x) => x.id === editDescDlg.picId);
    if (idx >= 0) {
      onChange([
        ...items.slice(0, idx),
        {
          ...items[idx],
          desc: editDescDlg.desc,
        },
        ...items.slice(idx + 1),
      ]);
    }
    closeEditDescDlg();
  };

  const onAddPicture = (pic) => {
    onChange([...items, pic]);
  };

  return (
    <>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
      >
        <div className={styles.picHolder} style={{ '--upload-item-size': `${picWidth}px` }}>
          {items.length < maxCount && (
            <UploadItemWithCrop
              onAddFile={onAddPicture}
              picWidth={picWidth}
              disableDescription={disableDescription}
              aspects={aspects}
              disabled={disabled}
            />
          )}

          <SortableContext items={items} strategy={rectSortingStrategy}>
            {items.map((pic) => (
              <SortableItem
                key={pic.id}
                id={pic.id}
                handle
                value={pic.filename}
                picWidth={picWidth}
                basePicUrl={basePicUrl}
                onRemovePic={onRemovePic}
                onEditDesc={picDescDisable ? null : onEditDesc}
                picDesc={pic.desc}
                roundImage={roundImage}
                disabled={disabled}
              />
            ))}
            <DragOverlay>
              {activeItem?.id ? (
                <div
                  style={{
                    // width: 'var(--upload-item-size)',
                    height: 'var(--upload-item-size)',
                    // backgroundImage: `url("${basePicUrl}${activeItem?.id}")`,
                    // backgroundSize: 'cover',
                    // backgroundPosition: 'center',
                    backgroundColor: 'grey',
                  }}
                >
                  <img
                    style={{ height: '100%' }}
                    src={`${basePicUrl}h/${picWidth}/${activeItem?.id}`}
                    alt="move"
                  />
                </div>
              ) : null}
            </DragOverlay>
          </SortableContext>
        </div>
      </DndContext>
      <Modal
        title="Редактирование описание"
        open={editDescDlg.visible}
        onCancel={closeEditDescDlg}
        onOk={onChangeDesc}
        okText="Сохранить"
        okButtonProps={{
          disabled: editDescDlg.desc === editDescDlg.desc0,
        }}
      >
        <Input.TextArea
          showCount
          autoSize
          autoFocus
          value={editDescDlg.desc}
          onChange={(ev) => setEditDescDlg({ ...editDescDlg, desc: ev.target.value })}
        />
      </Modal>
    </>
  );
}

XDataGallery.defaultProps = {
  picWidth: 130,
  items: [],
  maxCount: 99,
  disableDescription: false,
  disabled: false,
  roundImage: false,
  picDescDisable: false,
  aspects: [
    {
      label: '1x1',
      wph: 1,
    },
  ],
};

XDataGallery.propTypes = {
  basePicUrl: PropTypes.string.isRequired,
  picWidth: PropTypes.number,
  maxCount: PropTypes.number,
  roundImage: PropTypes.bool,
  disableDescription: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      filename: PropTypes.string,
      desc: PropTypes.string,
    }),
  ),
  picDescDisable: PropTypes.bool,
  aspects: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      wph: PropTypes.number,
    }),
  ),
};

export default XDataGallery;
