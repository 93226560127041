import React from 'react';

import { Tag } from 'antd';

import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';

export const WEBINAR3_VIEW = [
  {
    id: 0,
    name: 'Семинар (личная встреча)',
    tag: <Tag color="gold">Семинар (личная встреча)</Tag>,
  },
  {
    id: 1,
    name: 'Вебинар (через интернет)',
    tag: <Tag color="geekblue">Вебинар (через интернет)</Tag>,
  },
];

export const WEBINAR3_VIEW_ID2TAG = WEBINAR3_VIEW.reduce((a, x) => ({ ...a, [x.id]: x.tag }), {});

export const WEBINAR3_STATUS = [
  {
    id: 0,
    name: 'В плане',
    tag: (
      <Tag icon={<ClockCircleOutlined />} color="default">
        В плане
      </Tag>
    ),
  },
  {
    id: 1,
    name: 'Активен',
    tag: (
      <Tag icon={<VideoCameraOutlined />} color="success">
        Активен
      </Tag>
    ),
  },
  {
    id: 2,
    name: 'Завершен',
    tag: (
      <Tag icon={<CheckCircleOutlined />} color="processing">
        Завершен
      </Tag>
    ),
  },
  {
    id: -1,
    name: 'Отменен',
    tag: (
      <Tag icon={<CloseCircleOutlined />} color="error">
        Отменен
      </Tag>
    ),
  },
];

export const WEBINAR3_STATUS_ID2TAG = WEBINAR3_STATUS.reduce(
  (a, x) => ({ ...a, [x.id]: x.tag }),
  {},
);

export const WEBINAR3_TYPE = [
  {
    id: 0,
    name: 'Технический',
  },
  {
    id: 1,
    name: 'Маркетинговый',
  },
  {
    id: 2,
    name: 'Продажи',
  },
];

export const WEBINAR3_TYPES_ID2NAME = WEBINAR3_TYPE.reduce(
  (a, x) => ({ ...a, [x.id]: x.name }),
  {},
);
