import React, { useEffect, useState } from 'react';

import { Button, Modal } from 'antd';
import PropTypes from 'prop-types';

import apiAnyGet from '../../../../api/AnyGet';
import styles from './styles.scss';

function WebinarTestPreview({ testId, onClose, answersCount }) {
  const [visible, setVisible] = useState(false);
  const [model, setModel] = useState({});

  const fetchData = async (itemId) => {
    const data = await apiAnyGet('Webinars3Test')(itemId, []);
    setModel(data);
    // Logger.log(data);
  };

  useEffect(() => {
    if (testId > 0) {
      setVisible(true);
      fetchData(testId);
    } else {
      setVisible(false);
      setModel({});
    }
  }, [testId]);

  return (
    <Modal
      open={visible}
      footer={[
        <Button key="close" onClick={onClose}>
          Закрыть
        </Button>,
      ]}
      width="80%"
      title={model?.name}
      closable={false}
    >
      <div className={styles.previewDesc}>{model?.desc}</div>
      {model?.questions?.map((q, i) => (
        <div key={q.id} className={styles.previewQ}>
          <div className={styles.pqNumb}>Вопрос {i + 1}</div>
          <div className={styles.pqName}>{q.txt}</div>
          <div className={styles.pqDesc}>{q.desc}</div>
          <div className={styles.pqAns}>
            {q.answers?.map((a) => (
              <div key={a.id}>
                <input id={a.id} type="checkbox" />
                <label htmlFor={a.id}>{a.txt}</label>
              </div>
            ))}
          </div>
        </div>
      ))}
    </Modal>
  );
}

WebinarTestPreview.defaultProps = {
  testId: 0,
  answersCount: null,
};

WebinarTestPreview.propTypes = {
  testId: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  answersCount: PropTypes.shape({}),
};

export default WebinarTestPreview;
