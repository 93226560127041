/* eslint-disable no-param-reassign */
const cleanProps = ['__g4b', '__lat', '__lng', '_t'];

// eslint-disable-next-line import/prefer-default-export
export function fixMapObject(object) {
  for (const prop of cleanProps) {
    // eslint-disable-next-line no-param-reassign
    delete object[prop];
  }

  // fix geo location
  if (object.ll?.type === 'Point' && Array.isArray(object.ll?.coordinates)) {
    object.lat = object.ll?.coordinates[1];
    object.lng = object.ll?.coordinates[0];
  }

  return object;
}
