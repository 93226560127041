import React from 'react';

import { Spin } from 'antd';
import PropTypes from 'prop-types';

import KybLogoIcon from '../../../assets/svg/kyb-logo.svg';
import styles from './styles.scss';

function SiteLoading({ example }) {
  return (
    <div className={styles.main}>
      <KybLogoIcon />
      <Spin size="large" className={styles.spin} />
    </div>
  );
}

SiteLoading.defaultProps = {
  example: null,
};

SiteLoading.propTypes = {
  example: PropTypes.string,
};

export default SiteLoading;
