/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';

import { Comment, Layout, List, PageHeader } from 'antd';
import { useHistory, useParams } from 'react-router-dom';

import { apiPostData } from '../../../../api';
import GTooltip from '../../../components/GTooltip/GTooltip';
import styles from './styles.scss';
import dayjs from 'dayjs';

export default function WebinarEventResults1() {
  const history = useHistory();
  const { view, id, taid } = useParams();

  const eventId = parseInt(id, 10);

  const [model, setModel] = useState({});

  const fetchEvent = async (eid, aid) => {
    const data = await apiPostData('/webinars3/event/get/results1', {
      id: eid,
      aid,
    });

    setModel(data);
  };

  useEffect(() => {
    fetchEvent(eventId, taid);
  }, [view, eventId, taid]);

  const backToList = async () => {
    history.goBack();
  };

  return (
    <>
      <PageHeader
        className={styles.pageHeader}
        title={
          model?.view === 0 ? (
            <GTooltip tid="webinars_seminar_results1">
              Карточка семинара - ответы на свободные вопросы
            </GTooltip>
          ) : (
            <GTooltip tid="webinars_webinar_results1">
              Карточка вебинара - ответы на свободные вопросы
            </GTooltip>
          )
        }
        onBack={backToList}
      />

      <Layout.Content>
        <div className={styles.contextHolder}>
          <div className={styles.context}>
            <h3>Вопрос</h3>
            <div className={styles.r1Q}>{model.testQuestion}</div>
          </div>
        </div>

        <div className={styles.contextHolder}>
          <div className={styles.context}>
            <List
              itemLayout="vertical"
              dataSource={model.__answers || []}
              renderItem={(item) => (
                <List.Item key={item.uid} actions={null}>
                  <List.Item.Meta
                    title={
                      <a href={`/webinars/user/${item.uid}`}>
                        {item.u.lastname} {item.u.firstname} | {item.u.company}, {item.u.status}
                      </a>
                    }
                    description={
                      <span>
                        {item.u.region}, {item.u.city}, {item.u.email},{' '}
                        {dayjs(item.dt).format('DD.MM.YYYY HH:mm')}
                      </span>
                    }
                  />
                  {item.ans}
                </List.Item>
              )}
            />
          </div>
        </div>
      </Layout.Content>
    </>
  );
}
