import React from 'react';

import { BellOutlined } from '@ant-design/icons';

import NotifyPopup from '../../../components/Common/NotifyPopup';

// Общие колонки, есть на всех видах
// eslint-disable-next-line import/prefer-default-export
export const commonColumns = (notifyLastForRole) => ({
  name: null, // 'Кроссы', // название
  props: [], // список полей для запроса к серверу
  columns: [
    {
      prop: '_id',
      name: '',
      width: 35,
      align: 'center',
      excelHide: true,
      render: (item, val) => {
        const noti = notifyLastForRole[val];
        if (noti) return <NotifyPopup noti={noti} />;
        return <BellOutlined style={{ color: '#aaa' }} />;
      },
    },
    {
      prop: '+maker',
      name: 'Марка',
      excelColName: 'Производитель',
      excelColPos: 2,
      sort: true,
    },
    {
      prop: '+oe_pn',
      name: 'ОЕМ №',
      excelColName: 'OEM #',
      excelColPos: 1,
      sort: true,
    },
    {
      prop: '+kyb_pn',
      name: 'KYB №',
      excelColName: 'KYB #',
      excelColPos: 3,
      sort: true,
    },
  ],
});
