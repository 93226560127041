/* eslint-disable import/no-unresolved */
import * as React from 'react';
import DatePicker from './DayJsDatePicker';

const TimePicker = React.forwardRef((props, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <DatePicker {...props} picker="time" mode={undefined} ref={ref} />
));

TimePicker.displayName = 'TimePicker';

export default TimePicker;
