import React, { useEffect, useState } from 'react';

import { Button, Checkbox, Col, Input, Modal, Row, Select } from 'antd';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';

import { DeleteOutlined } from '@ant-design/icons';

import GTooltip from '../../../components/GTooltip/GTooltip';
import styles from './styles.scss';
import { warningDialog } from '../../../components/Common/WarningDialog';

function AnswersBlock({ model, onChange, onDelete }) {
  const onChangeText = (ev) => {
    onChange({
      ...model,
      txt: ev.target.value,
    });
  };

  const onChangeType = (val) => {
    onChange({
      ...model,
      type: val,
    });
  };

  return (
    <Row className={styles.aeBlock}>
      <Col span={24}>
        <Input
          value={model.txt}
          onChange={onChangeText}
          addonBefore={
            <Select
              defaultValue={0}
              className="select-before"
              onChange={onChangeType}
              value={model.type}
              style={{ width: 150 }}
            >
              <Select.Option value={0}>не правильный</Select.Option>
              <Select.Option value={1}>
                <span style={{ color: 'var(--kyb-color)' }}>правильный</span>
              </Select.Option>
            </Select>
          }
          suffix={<DeleteOutlined onClick={() => onDelete(model)} className={styles.aeDeletBtn} />}
        />
      </Col>
    </Row>
  );
}

AnswersBlock.propTypes = {
  model: PropTypes.shape({
    txt: PropTypes.string,
    type: PropTypes.number,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

function WebinarsTestAnswerEditor({ visible, model, onClose, onSave, onDelete }) {
  const [model0, setModel0] = useState({});

  useEffect(() => {
    if (visible) {
      setModel0({
        answers: [],
        ...model,
      });
    }
  }, [visible]);

  /* Q */
  const onChangeQType = (el) => {
    const type = el.target.checked ? 1 : 0;
    setModel0({
      ...model0,
      answers: type === 1 ? [] : model0.answers,
      answer: type === 0 ? '' : model0.answer,
      type,
    });
  };

  const onChangeTxt = (el) => {
    setModel0({
      ...model0,
      txt: el.target.value,
    });
  };

  const onChangeDesc = (el) => {
    setModel0({
      ...model0,
      desc: el.target.value,
    });
  };

  const onChangeAns1 = (el) => {
    setModel0({
      ...model0,
      answer: el.target.value,
    });
  };

  /* Answers */
  const onChangeAns = (m) => {
    const idx = model0.answers.findIndex((x) => x.id === m.id);
    if (idx >= 0) {
      setModel0({
        ...model0,
        answers: [...model0.answers.slice(0, idx), m, ...model0.answers.slice(idx + 1)],
      });
    }
  };

  const onDeleteAns = (m) => {
    const idx = model0.answers.findIndex((x) => x.id === m.id);
    if (idx >= 0) {
      setModel0({
        ...model0,
        answers: [...model0.answers.slice(0, idx), ...model0.answers.slice(idx + 1)],
      });
    }
  };

  const addNewAnswer = () => {
    setModel0({
      ...model0,
      answers: [
        ...model0.answers,
        {
          id: nanoid(5),
          txt: '',
          type: 0,
        },
      ],
    });
  };

  //
  const deleteTestAns = async () => {
    const res = await warningDialog(
      'Удаление вопроса',
      'Удаленный вопрос нельзя восстановить. Удалить вопрос с описанием и ответами?',
      [{ label: 'Отмена' }, { label: 'Удалить' }],
    );

    if (res === 1) {
      onDelete(model0);
    }
  };

  const saveTestAns = async () => {
    let isEmpty = false;

    if (model0.type === 0) {
      model0.answers.forEach((it) => {
        if (!it.txt) isEmpty = true;
      });
    }

    if (!isEmpty) onSave(model0);
    else {
      const res = await warningDialog(
        'Пустые поля ответов',
        'В вариантах ответов есть не заполненные поля. Удалить и сохранить?',
        [{ label: 'Отмена' }, { label: 'Сохранить' }],
      );
      if (res === 1) {
        onSave({
          ...model0,
          answers: model0.answers.filter((it) => it.txt),
        });
      }
    }
  };

  return (
    <Modal
      open={visible}
      title={<GTooltip tid="webinars_tests_ans_editor">Редактирование вопроса</GTooltip>}
      width={630}
      footer={[
        <Button key="del" style={{ marginRight: 'auto' }} onClick={deleteTestAns}>
          Удалить вопрос
        </Button>,
        <Button key="close" onClick={onClose}>
          Отмена
        </Button>,
        <Button key="save" type="primary" onClick={saveTestAns}>
          Сохранить
        </Button>,
      ]}
    >
      <Row className={styles.aeTextHl}>
        <Col>Вопрос и пояснение к нему, при необходимости</Col>
      </Row>
      <Row justify="space-between" className={styles.aeInputLabel}>
        <Col>Вопрос:</Col>
        <Col>
          <Checkbox onChange={onChangeQType} checked={!!model0.type}>
            не оценивается
          </Checkbox>
        </Col>
      </Row>
      <Row style={{ marginBottom: 16 }}>
        <Col span={24}>
          <Input.TextArea value={model0.txt} onChange={onChangeTxt} />
        </Col>
      </Row>
      <Row className={styles.aeInputLabel}>
        <Col>Пояснение:</Col>
      </Row>
      <Row style={{ marginBottom: 16 }}>
        <Col span={24}>
          <Input.TextArea value={model0.desc} onChange={onChangeDesc} />
        </Col>
      </Row>
      <Row className={styles.aeTextHl}>
        <Col>Ответы, может быть несколько правильных</Col>
      </Row>

      {model0.type === 0 && (
        <>
          {(model0.answers || []).map((ans) => (
            <AnswersBlock key={ans.id} model={ans} onChange={onChangeAns} onDelete={onDeleteAns} />
          ))}
          <Row>
            <Col>
              <Button onClick={addNewAnswer} style={{ marginTop: 8 }} size="small">
                Добавить ответ
              </Button>
            </Col>
          </Row>
        </>
      )}

      {model0.type === 1 && (
        <Row style={{ marginBottom: 16 }}>
          <Col span={24}>
            <Input.TextArea value={model0.answer} onChange={onChangeAns1} />
          </Col>
        </Row>
      )}
    </Modal>
  );
}

WebinarsTestAnswerEditor.defaultProps = {};

WebinarsTestAnswerEditor.propTypes = {
  visible: PropTypes.bool.isRequired,
  model: PropTypes.shape({
    answers: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default WebinarsTestAnswerEditor;
