import { apiPost, ApiError } from '.';

// кеширование ответов
const _cache = {};

/**
 * Запрос серий продуктов
 */
function apiAnyPropList(collection, prop) {
  return async (filter = {}) => {
    const key = `${collection}|${prop}|${JSON.stringify(filter)}`;
    if (_cache[key]) return _cache[key];

    const { err, errmsg, data } = await apiPost('/any/prop/list', {
      role: 0,
      collection,
      prop,
      filter,
    });
    if (err !== 0) throw ApiError(err, errmsg);
    const list = data.list.sort().map((x) => ({
      id: x,
      name: x,
    }));
    _cache[key] = list;
    return list;
  };
}

export default apiAnyPropList;
