// eslint-disable-next-line import/prefer-default-export
export const USER_ROLE = {
  // 100
  GTOOLTIP: 101,
  EMAIL_TEMPLATES: 102,
  FILES_CONTROL: 103,
  // 200
  USERS_NEW: 201,
  USERS_APPROVED: 202,
  USERS_BLOCKED: 203,
  // 300
  CATALOG_MAIN: 301,
  CATALOG_CROSS: 302,
  CATALOG_ANALOG: 303,
  CATALOG_CHARACTER: 304,
  CATALOG_IFRAME: 305,
  // 400
  MAP_OBJECTS: 401,
  MAP_NEW_OBJECTS: 402,
  MAP_COMMENTS: 403,
  MAP_SETTINGS: 404,
  MAP_ANALYTICS: 405,
};

export const OBJECT_STATES = [
  {
    id: -1,
    name: 'Новый объект',
  },
  {
    id: 0,
    name: 'Не активно',
  },
  {
    id: 1,
    name: 'Активно',
  },
];

export const KYB_MAP_OBJECT_GROUP = {
  0: '',
  1: 'KYB в мире',
  2: 'KYB в России',
};

export const KYB_MAP_OBJECT_COMMENT_STATE = [
  {
    id: -1,
    name: 'Удален',
  },
  {
    id: 0,
    name: 'На рассмотрении',
  },
  {
    id: 1,
    name: 'Утвержден',
  },
];

export const KYB_SITE_CATALOG_STATUS = [
  {
    id: -1,
    name: 'Удален',
  },
  {
    id: 0,
    name: 'Не опубликовано',
  },
  {
    id: 1,
    name: 'Опубликовано',
  },
  {
    id: 2,
    name: 'Архив',
  },
];

export const KYB_FAQ_SECTION = [
  {
    id: 0,
    name: 'Общие вопросы',
  },
  {
    id: 1,
    name: 'Подбор',
  },
  {
    id: 2,
    name: 'Установка и гарантии',
  },
  {
    id: 3,
    name: 'Покупка, сотрудничество',
  },
  {
    id: 4,
    name: 'Подделки',
  },
];

export const KYB_MAP_PROP_TYPES = [
  { id: 9, name: 'Поле Да/Нет (On/Off)' }, // 0/1
  { id: 0, name: 'Текст / строка', sel: true }, // string
  { id: 8, name: 'Текстовое поле в несколько строк' }, // string (textarea)
  { id: 20, name: 'WebText редактор' },
  { id: 10, name: 'Адрес, геокодированием' }, // string + geoButton + reversGeocoder
  { id: 22, name: 'Страна, геокодированием' }, // string + reversGeocoder
  { id: 23, name: 'Регион, геокодированием' }, // string + reversGeocoder
  { id: 24, name: 'Город, геокодированием' }, // string + reversGeocoder
  { id: 1, name: 'Число' }, // int/float
  { id: 2, name: 'Email' }, // email
  { id: 3, name: 'Url' }, // url
  { id: 13, name: 'Список картинок' }, // [imgId, imgId, imgId, ..]
  { id: 4, name: 'Выбор одного из списка' }, // id of dict
  { id: 5, name: 'Выбор нескольких из списка' }, // [id1, id2, ..] of dict
  { id: 7, name: 'Массив произвольных строк (список телефонов)' }, // [string, string, string, ..]
  { id: 18, name: 'Внешнее видео (Youtube, Vimeo)' }, // [string, string, string, ..]
  { id: 11, name: 'Дата' }, // int (милисек)
  { id: 14, name: 'Дата создания (автом. поле)' }, // int (милисек)
  { id: 15, name: 'Дата редактирования (автом. поле)' }, // int (милисек)
  { id: 16, name: 'Только для чтения (RO)' }, // поле только для чтения, для вывода полей из базы, которое нельзя редактировать
  { id: 17, name: 'Группа объекта' },
  { id: 19, name: 'Уникальная иконка объекта' },
  { id: 21, name: 'Список ссылок' },
  { id: 25, name: 'Рейтинг пользователей (только просмотр)' },
  { id: 26, name: 'Рейтинг объекта 1-5' },
  { id: 27, name: 'Список ближайших объектов (RO)' },
  { id: -1, name: 'Категория объекта' },
];

export const KYB_MAP_PROP_TYPE_ID2NAME = KYB_MAP_PROP_TYPES.reduce((a, x) => {
  // eslint-disable-next-line no-param-reassign
  a[x.id] = x.name;
  return a;
}, {});

export const KYB_SITE_ITEM_STATUS = [
  {
    id: 0,
    name: 'Не опубликовано',
  },
  {
    id: 1,
    name: 'Опубликовано',
  },
];

export const KYB_PRODUCT_TYPE = [
  {
    id: 0,
    name: 'Продукт скрыт из показа',
  },
  {
    id: 1,
    name: 'Стандартные серии',
  },
  {
    id: 2,
    name: 'Спорт и тюнинг',
  },
  {
    id: 3,
    name: 'Доп. оборудование',
  },
  {
    id: 4,
    name: 'Детали рулевого управления и подвески',
  },
];

export const KYB_LEFT_BANNER_TYPE = [
  {
    id: 1,
    name: 'Одна стрелка по кругу (тип 1)',
  },
  {
    id: 2,
    name: 'Две стрелки по кругу (тип 2)',
  },
  {
    id: 3,
    name: 'Три стрелки по кругу (тип 3)',
  },
  {
    id: 4,
    name: 'Подписаться на новости (тип 4)',
  },
  {
    id: 5,
    name: 'Произвольная картинка с подписью (тип 5)',
  },
];

export const DATETIME_FORMAT = 'DD.MM.YYYY HH.mm';
