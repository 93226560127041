import React, { useEffect, useState } from 'react';

import { Button, Modal } from 'antd';
import PropTypes from 'prop-types';

import styles from './styles.scss';

/**
 * @param {*} result
 *   =1 - OK
 *   =2 - Email
 *   =3 - UserBlocked
 * @returns
 */
function Webinars3StopDialogs({ result }) {
  const [okDialog, setOkDialog] = useState(false);
  const [emailDialog, setEmailDialog] = useState(false);

  useEffect(() => {
    if (result > 0) {
      // eslint-disable-next-line default-case
      switch (result) {
        case 1:
          setOkDialog(true);
          break;
        case 2:
          setEmailDialog(true);
          break;
      }
    }
  }, [result]);

  const onOk = () => {
    document.location.replace('/');
  };

  return (
    <>
      <Modal
        open={okDialog}
        title="Заявка на участие одобрена"
        footer={<Button onClick={onOk}>Ok</Button>}
        closable={false}
      >
        <div className={styles.dlgContent}>
          Ваша заявка на участие принята. Отменить запись или изменить ваши контакты вы можете в
          личном кабинете. Ссылка на вебинар, будет отправлена на ваш e-mail.
        </div>
      </Modal>

      <Modal
        open={emailDialog}
        title="Подтвердите E-mail для участия в вебинаре"
        footer={<Button onClick={onOk}>Ok</Button>}
        closable={false}
      >
        <div className={styles.dlgContent}>
          Для завершения регистрации на вебинар, необходимо подтвердить ваш Е-mail. Мы выслали на
          указанный вами Е-mail письмо с подтверждением. Пожалуйста, проверьте вашу электронную
          почту.
          <br />
          Изменить указанный E-mail можно в личном кабинете
        </div>
      </Modal>
    </>
  );
}

Webinars3StopDialogs.defaultProps = {};

Webinars3StopDialogs.propTypes = {
  result: PropTypes.number.isRequired,
};

export default Webinars3StopDialogs;
