import { selector } from 'recoil';

import apiAnyGet from '../api/AnyGet';

export const webinar1SettingsState = selector({
  key: '__webinar1_settings__',
  get: async () => {
    const response = await await apiAnyGet('KybDb:Webinar2User')('WEBINAR2_SETTINGS', []);
    return response;
  },
});
