import { XDF_TYPE } from '../../components/xdf-antd/XDataForm';

// eslint-disable-next-line import/prefer-default-export
export const USER_XD_FORM = (disableEnterPhone, hidePhoneNumber = false) =>
  [
    {
      type: XDF_TYPE.TEXT,
      label: 'Фамилия',
      prop: 'lastname',
      placeholder: 'Введите вашу фамилию',
      require: true,
      autoFocus: true,
      span: 24,
    },
    {
      type: XDF_TYPE.TEXT,
      label: 'Имя',
      prop: 'firstname',
      placeholder: 'Введите ваше имя',
      require: true,
      span: 24,
    },
    {
      type: XDF_TYPE.TEXT,
      label: 'Компания',
      prop: 'company',
      placeholder: 'Укажите компанию',
      require: true,
      span: 24,
    },
    {
      type: XDF_TYPE.TEXT,
      label: 'Должность',
      prop: 'status',
      placeholder: 'Укажите должность',
      require: true,
      span: 24,
    },
    !hidePhoneNumber && {
      type: XDF_TYPE.PHONE,
      label: 'Номер вашего телефона',
      prop: 'phone',
      placeholder: '(999) 999-99-99',
      require: true,
      span: 24,
      readOnly: disableEnterPhone,
    },
    {
      type: XDF_TYPE.EMAIL,
      label: 'E-mail',
      prop: 'email',
      placeholder: 'boxname@mail.com',
      require: true,
      span: 24,
    },
    {
      type: XDF_TYPE.SELECT,
      label: 'Ваш регион',
      prop: 'region',
      placeholder: 'Выберите ваш регион',
      values: [
        {
          id: 'Россия',
          name: 'Россия',
        },
        {
          id: 'Казахстан',
          name: 'Казахстан',
        },
        {
          id: 'Беларусь',
          name: 'Беларусь',
        },
        {
          id: 'Армения',
          name: 'Армения',
        },
        {
          id: 'Киргизия',
          name: 'Киргизия',
        },
      ],
      require: true,
      span: 24,
    },
    {
      type: XDF_TYPE.TEXT,
      label: 'Город',
      prop: 'city',
      placeholder: 'Укажите ваш город',
      require: true,
      span: 24,
    },
  ].filter(Boolean);
