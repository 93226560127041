/* eslint-disable no-underscore-dangle */
import React, { useEffect, useRef, useState } from 'react';

import { Modal, Progress } from 'antd';
import PropTypes from 'prop-types';

import { useXDFScript } from '../XDataForm/xdf-hooks';
import { xdGetColProp, xdRoundPlus } from './xdf-utils';

const LOAD_DATA_STEP = 20;

export const EXCELJS_URL = 'https://cdnjs.cloudflare.com/ajax/libs/exceljs/4.3.0/exceljs.min.js';
export const FILESAVER_URL = 'https://cdn.jsdelivr.net/npm/file-saver@2.0.5/dist/FileSaver.min.js';

/**
 * Autofit columns by width
 * https://github.com/exceljs/exceljs/issues/83
 *
 * @param worksheet {ExcelJS.Worksheet}
 * @param minimalWidth
 */
const autoWidth = (worksheet, minimalWidth = 10) => {
  worksheet.columns.forEach((column) => {
    let maxColumnLength = 0;
    column.eachCell({ includeEmpty: true }, (cell) => {
      maxColumnLength = Math.max(
        maxColumnLength,
        minimalWidth,
        cell.value ? cell.value.toString().length : 0,
      );
    });
    // eslint-disable-next-line no-param-reassign
    column.width = maxColumnLength + 2;
  });
};

function XDTExportToExcel({ startExport, onCloseExport, excelExport }) {
  const [progress, setProgress] = useState(0);
  const [data, setData] = useState(null);

  const [loadJs, setLoadJs] = useState(false);
  const [doneExcelJS] = useXDFScript(loadJs && EXCELJS_URL);
  const [doneJS] = useXDFScript(doneExcelJS && FILESAVER_URL);

  const workbook = useRef(null);
  const sheet = useRef(null);

  const onSaveXlsx = async () => {
    if (excelExport.autoWidth) {
      autoWidth(sheet.current);
    }

    workbook.current.xlsx.writeBuffer().then((buffer) => {
      // eslint-disable-next-line no-undef
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        `${excelExport.filename}.xlsx`,
      );

      // close
      setTimeout(() => {
        onCloseExport();
      }, 1000);
    });
  };

  const loadData = async (page) => {
    if (!startExport) return;

    const result = await excelExport.apiLoadData(
      page,
      excelExport.exportStep || LOAD_DATA_STEP,
      excelExport.filter,
      excelExport.props,
      excelExport.sortProp,
      excelExport.sortOrder,
      excelExport.searchProps,
    );

    setData(result);
  };

  const onStartExport = async () => {
    // eslint-disable-next-line no-undef
    workbook.current = new ExcelJS.Workbook();
    workbook.current.created = new Date();
    workbook.current.modified = new Date();

    // add sheet
    sheet.current = workbook.current.addWorksheet(excelExport.tabname);

    // columns
    const sheetColumns = [
      ...excelExport.commonColumns.columns,
      ...(excelExport.extColumnsSelected?.columns || []),
    ]
      .filter((col) => !col.excelHide)
      .map((col, i) => {
        const prop = xdGetColProp(col.prop);
        const style = {};
        if (col.align) {
          style.alignment = { horizontal: col.align };
        }
        return {
          key: `k_${prop}_${i}`,
          header: col.excelColName || (typeof col.name === 'string' ? col.name : ''),
          width: 13,
          style,
          __sort: col.excelColPos || i,
        };
      })
      .sort((a, b) => a.__sort - b.__sort);
    sheet.current.columns = sheetColumns;

    // start load data
    loadData(1);
  };

  useEffect(() => {
    if (workbook.current && data) {
      // add data
      for (const item of data.list) {
        const row = {};
        [...excelExport.commonColumns.columns, ...(excelExport.extColumnsSelected?.columns || [])]
          .filter((c) => !c.excelHide)
          .forEach((col, i) => {
            const prop = xdGetColProp(col.prop);
            row[`k_${prop}_${i}`] = excelExport.getRowValue(col, prop, item, true);
          });
        // debugger;
        sheet.current.addRow(row);
      }

      // load next?
      if (data.list?.length > 0) {
        loadData(data.page + 1);
        setProgress(progress + (data.list?.length || 0));
      } else {
        onSaveXlsx();
      }
    }
  }, [data]);

  useEffect(() => {
    if (startExport) {
      setProgress(0);
      if (!window.ExcelJS) setLoadJs(true);
      else onStartExport();
    }
    return () => {
      if (startExport) {
        // console.log('startExport - CLOSE');
        workbook.current = null;
      }
    };
  }, [startExport]);

  useEffect(() => {
    if (doneJS) {
      onStartExport();
    }
  }, [doneJS]);

  return (
    <Modal
      title="Экспорт данных в xlsx файл..."
      destroyOnClose
      closable={false}
      keyboard={false}
      maskClosable={false}
      open={startExport}
      onCancel={onCloseExport}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ disabled: progress === data?.total }}
      width={340}
      bodyStyle={{ textAlign: 'center' }}
    >
      <Progress type="circle" percent={!data ? 0 : xdRoundPlus((100 * progress) / data.total, 0)} />
    </Modal>
  );
}

XDTExportToExcel.defaultProps = {
  startExport: false,
};

XDTExportToExcel.propTypes = {
  startExport: PropTypes.bool,
  onCloseExport: PropTypes.func.isRequired,
  excelExport: PropTypes.shape({
    filename: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    tabname: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    exportStep: PropTypes.number,
    autoWidth: PropTypes.bool,
    // internal
    getRowValue: PropTypes.func.isRequired,
    apiLoadData: PropTypes.func.isRequired, // функция для загрузки данных
    filter: PropTypes.shape({}),
    props: PropTypes.arrayOf(PropTypes.string),
    searchProps: PropTypes.arrayOf(PropTypes.string),
    sortProp: PropTypes.string,
    sortOrder: PropTypes.number,
    commonColumns: PropTypes.shape({
      columns: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    extColumnsSelected: PropTypes.shape({
      columns: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
};

export default XDTExportToExcel;
