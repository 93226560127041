/* eslint-disable no-param-reassign */
import { atom, selector, selectorFamily } from 'recoil';

import { notificationsInitState } from './panel-state';

/**
 * Хранение списка оповещений
 */
export const notifyListState = atom({
  key: 'notifyListState',
  default: notificationsInitState,
});

/**
 * Запрос объекта role => notify count
 */
export const notifyCountByRoleState = selector({
  key: 'notifyCountByRoleState',
  get: ({ get }) => {
    const notiList = get(notifyListState);
    return notiList.reduce((r, x) => {
      if (!r[x.role]) r[x.role] = 1;
      else r[x.role] += 1;
      return r;
    }, {});
  },
});

/**
 * Запрос соотв естьли в групповой роли оповещения
 */
export const notifyByRoleGroupState = selector({
  key: 'notifyByRoleGroupState',
  get: ({ get }) => {
    const notiList = get(notifyListState);
    return notiList.reduce((r, x) => {
      const groupRole = x.role - (x.role % 100);
      if (!r[groupRole]) r[groupRole] = true;
      return r;
    }, {});
  },
});

/**
 * Запрос последних сообщений для ID для роли
 */
export const notifyLastForRoleState = selectorFamily({
  key: 'notifyLastForRoleState',
  get:
    (role) =>
    ({ get }) => {
      const notiList = get(notifyListState);
      return notiList
        .filter((it) => it.role === role)
        .reduce((r, x) => {
          if (!r[x.id]) r[x.id] = x;
          return r;
        }, {});
    },
});
