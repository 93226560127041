import { XDF_TYPE } from '../../../components/xdf-antd/XDataForm';
import { KYB_SITE_CATALOG_STATUS } from '../../../constants';

// eslint-disable-next-line import/prefer-default-export
export const commonColumns = () => ({
  name: null,
  props: [], // список полей для запроса к серверу
  rowStyle: (item) => {
    if (item.status <= 0) return { color: 'rgba(255,0,0,0.5)' };
    return {};
  },
  columns: [
    {
      prop: '+status',
      name: 'Статус',
      sort: true,
      nowrap: true,
      prop2value: [KYB_SITE_CATALOG_STATUS, 'id', 'name'],
    },
    {
      prop: '+state',
      name: 'Сортировка',
      sort: true,
    },
    {
      prop: '+name',
      name: 'Название',
      sort: true,
      nowrap: true,
    },
    {
      prop: '+desc',
      name: 'Краткое описание',
      sort: true,
    },
  ],
});

export const formModelEditor = () => [
  {
    type: XDF_TYPE.SELECT,
    label: 'Статус',
    prop: 'status',
    values: KYB_SITE_CATALOG_STATUS,
    span: 18,
  },
  {
    type: XDF_TYPE.NUMBER,
    label: 'Вес для сортировки',
    prop: 'state',
    span: 6,
  },
  {
    type: XDF_TYPE.TEXT,
    label: 'Укажите название каталога',
    prop: 'name',
    span: 18,
  },
  {
    type: XDF_TYPE.DATETIME,
    label: 'Дата каталога',
    prop: '__date',
    span: 6,
  },
  {
    type: XDF_TYPE.TEXTAREA,
    label: 'Описание каталога',
    prop: 'desc',
    rows: 4,
    span: 16,
  },
  {
    type: XDF_TYPE.PICTURE_UPLOAD,
    label: 'Картинка обложки',
    prop: 'pic',
    span: 8,
  },
  {
    type: XDF_TYPE.SHOW_TEXT,
    label: 'Название файла на диске',
    prop: 'rname',
    span: 16,
  },
  {
    type: XDF_TYPE.SHOW_FILE_SIZE,
    label: 'Размер файла',
    prop: 'size',
    span: 8,
  },
  {
    type: XDF_TYPE.UPLOAD_BIG_FILE,
    title: 'Загрузить файл',
    prop: 'file',
    fileProps: ['rname', 'size'],
    maxFileSize: 64 * 1024 * 1024,
    span: 24,
  },
  {
    type: XDF_TYPE.TAGS,
    label: 'Теги',
    prop: 'tags',
    values: [],
    span: 24,
  },
];
