import React, { useState } from 'react';

import { Button, Layout, Modal, PageHeader, Tooltip } from 'antd';

import { PlusOutlined } from '@ant-design/icons';

import apiAnyList from '../../../../api/AnyList';
import { subscribeToChannel, unsubscribeToChannel } from '../../../../api/sio';
import apiSiteMetaSet from '../../../../api/SiteMetaSet';
import GTooltip from '../../../components/GTooltip/GTooltip';
import XDataForm, { XDF_TYPE } from '../../../components/xdf-antd/XDataForm';
import XDataTable from '../../../components/xdf-antd/XDataTable';
import styles from './styles.scss';
import { commonColumns } from './table-defs';

export default function KybSiteMeta() {
  const [model, setModel] = useState({ visible: false });
  const [isModelChanged, setIsModelChanged] = useState(false);

  const onRowClick = (item) => {
    setModel({
      visible: true,
      ...item,
    });
    setIsModelChanged(false);
  };

  const addRecord = () => {
    setModel({
      visible: true,
    });
    setIsModelChanged(false);
  };

  const onChangeModel = (m, is) => {
    setModel({
      ...model,
      ...m,
    });
    setIsModelChanged(is);
  };

  const closeDialog = () => {
    setModel({
      visible: false,
    });
  };

  const saveMeta = async () => {
    await apiSiteMetaSet(model);
    closeDialog();
  };

  return (
    <>
      <PageHeader
        className={styles.pageHeader}
        title={<GTooltip tid="kyb_site_meta">Управление META страниц сайта</GTooltip>}
        extra={[
          <Tooltip key="add" title="Добавить запись">
            <Button type="primary" shape="circle" icon={<PlusOutlined />} onClick={addRecord} />
          </Tooltip>,
        ]}
      />
      <Layout.Content>
        <div className={styles.contextHolder}>
          <div className={styles.context}>
            <XDataTable
              apiLoadData={apiAnyList('Sys:KybSiteMeta')}
              commonColumns={commonColumns()}
              extentedColumns={null}
              onRowClick={onRowClick}
              sioEvents={['LIST:SITE_META', subscribeToChannel, unsubscribeToChannel]}
              initSort={['_id', 1]}
              searchProps={['url', 'title', 'desc']}
            />
          </div>
        </div>
      </Layout.Content>

      <Modal
        title="Редактировать подсказку"
        open={model.visible}
        onCancel={closeDialog}
        okText="Сохранить"
        onOk={saveMeta}
        width={700}
        okButtonProps={{ disabled: !isModelChanged }}
      >
        <XDataForm
          model={model}
          onChangeModel={onChangeModel}
          form={[
            {
              type: XDF_TYPE.TEXT,
              label: 'URL страницы',
              prop: 'url',
              span: 24,
              require: true,
              autoFocus: true,
            },
            {
              type: XDF_TYPE.TEXT,
              label: 'Title (meta)',
              prop: 'title',
              span: 24,
              require: true,
            },
            {
              type: XDF_TYPE.TEXTAREA,
              label: 'Description (meta)',
              prop: 'desc',
              rows: 8,
              span: 24,
              require: true,
            },
          ]}
        />
      </Modal>
    </>
  );
}
