import React, { useState } from 'react';

import { Button, Layout, PageHeader, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { useRecoilValue } from 'recoil';

import { CloudUploadOutlined, PlusOutlined } from '@ant-design/icons';

import { apiPost } from '../../../../api';
import apiAnyList from '../../../../api/AnyList';
import { subscribeToChannel, unsubscribeToChannel } from '../../../../api/sio';
import { notifyLastForRoleState } from '../../../../state/notify-state';
import GTooltip from '../../../components/GTooltip/GTooltip';
import XDataTable from '../../../components/xdf-antd/XDataTable';
import XDImportExcelFile from '../../../components/xdf-antd/XDataTable/XDImportExcelFile';
import { USER_ROLE } from '../../../constants';
import CrossEditDlg from './CrossEditDlg';
import styles from './styles.scss';
import { commonColumns } from './table-defs';

export const uploadDataToServer =
  (collection, eventName, cleanBeforeImport = true) =>
  async (data) => {
    const { err } = await apiPost('/import/data', {
      collection,
      eventName,
      cleanBeforeImport,
      data,
    });
    return err;
  };

export default function CrossList() {
  const notifyLastForRole = useRecoilValue(notifyLastForRoleState(USER_ROLE.CATALOG_CROSS));

  const [editDlg, setEditDlg] = useState({ visible: false, model: {} });
  const [importDlg, setImportDlg] = useState(false);

  const onRowClick = (item) => {
    setEditDlg({ visible: true, model: item });
  };

  const addRecord = () => {
    setEditDlg({ visible: true, model: {} });
  };

  const onCloseDlg = () => {
    setEditDlg({ ...editDlg, visible: false });
  };

  // const downloadFile = () => {
  //   window.open('/api/1/catalog/cross/export', '_blank');
  // };

  return (
    <>
      <PageHeader
        className={styles.pageHeader}
        title={<GTooltip tid="catalogs_cross">Кроссы</GTooltip>}
        extra={[
          <Tooltip key="upload" title="Загрузить файл на сервер">
            <Button
              shape="circle"
              icon={<CloudUploadOutlined />}
              onClick={() => setImportDlg(true)}
            />
          </Tooltip>,
          <Tooltip key="add" title="Добавить запись">
            <Button type="primary" shape="circle" icon={<PlusOutlined />} onClick={addRecord} />
          </Tooltip>,
          // <Tooltip key="download" title="Скачать файл" onClick={downloadFile}>
          //   <Button shape="circle" icon={<CloudDownloadOutlined />} />
          // </Tooltip>,
        ]}
      />
      <Layout.Content>
        <div className={styles.contextHolder}>
          <div className={styles.context}>
            <XDataTable
              apiLoadData={apiAnyList('KybCatalog:KybCross')}
              commonColumns={commonColumns(notifyLastForRole)}
              extentedColumns={null}
              initSort={['maker', 1]}
              initFilter={{}}
              onRowClick={onRowClick}
              sioEvents={['LIST:CROSS', subscribeToChannel, unsubscribeToChannel]}
              searchProps={['oe_pn', 'kyb_pn', 'maker']}
              excelExport={{
                filename: `CrossExport_${dayjs().format('YYYY-MM-DD')}`,
                tabname: 'Кросс',
                exportStep: 2000,
                autoWidth: true,
              }}
            />
          </div>
        </div>
      </Layout.Content>

      <CrossEditDlg visible={editDlg.visible} model={editDlg.model} onClose={onCloseDlg} />
      <XDImportExcelFile
        visible={importDlg}
        onClose={() => setImportDlg(false)}
        importBtnTitle="Укажите файл для импорта Кроссов"
        maxStepToLoad={1000}
        uploadApi={uploadDataToServer('KybCatalog:KybCross', 'LIST:CROSS')}
        columns={[
          {
            prop: 'oe_pn',
          },
          {
            prop: 'kyb_pn',
          },
          {
            prop: 'maker',
          },
        ]}
      />
    </>
  );
}
