import React from 'react';

import { Layout, PageHeader } from 'antd';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import apiAnyList from '../../../../api/AnyList';
import { subscribeToChannel, unsubscribeToChannel } from '../../../../api/sio';
import { notifyLastForRoleState } from '../../../../state/notify-state';
import GTooltip from '../../../components/GTooltip/GTooltip';
import XDataTable from '../../../components/xdf-antd/XDataTable';
import { USER_ROLE } from '../../../constants';
import styles from './styles.scss';
import { commonColumns } from './table-defs';

export default function Users() {
  const history = useHistory();
  const notifyLastForRole = useRecoilValue(notifyLastForRoleState(USER_ROLE.USERS_APPROVED));

  const onRowClick = (item) => {
    history.push(`/user/edit/${item._id}`);
  };

  return (
    <>
      <PageHeader
        className={styles.pageHeader}
        title={<GTooltip tid="users_approved">Разрешенный доступ</GTooltip>}
      />
      <Layout.Content>
        <div className={styles.contextHolder}>
          <div className={styles.context}>
            <XDataTable
              apiLoadData={apiAnyList('User')}
              commonColumns={commonColumns(notifyLastForRole)}
              extentedColumns={null}
              initSort={['ldt', -1]}
              initFilter={{ state: 1 }}
              onRowClick={onRowClick}
              sioEvents={['LIST:USER', subscribeToChannel, unsubscribeToChannel]}
              searchProps={['name', 'lastName', 'midName', 'phone', 'email', 'company']}
            />
          </div>
        </div>
      </Layout.Content>
    </>
  );
}
