import React from 'react';

import { Button, Tag, Typography } from 'antd';
import cn from 'classnames';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { isBrowser } from 'react-device-detect';
import { useRecoilValue } from 'recoil';

import { CheckCircleOutlined, EyeOutlined } from '@ant-design/icons';

import { meState } from '../../../../state/panel-state';
import {
  WEBINAR3_TYPES_ID2NAME,
  WEBINAR3_VIEW_ID2TAG,
} from '../../../pages/Webinars/webinar_constants';
import styles from './styles.scss';

function W3FinishedEventCard({ event, test }) {
  const me = useRecoilValue(meState);

  const onShowCertificate = () => {
    window.open(`/api/1/certificate/view/${event._id}/${me._id}`);
  };

  return (
    <div className={styles.context}>
      <div className={cn(styles.header, { [styles.full]: isBrowser })}>
        <div className={styles.title}>{event.name}</div>
        {event.test?.id > 0 && (
          <div className={styles.status}>
            {test?.done === 1 && (
              <Tag icon={<CheckCircleOutlined />} color={test?.success && 'success'}>
                {test?.success ? 'Тест пройден' : 'Тест не сдан'}
              </Tag>
            )}
            {test?.done !== 1 && <Tag icon={<CheckCircleOutlined />}>Тест не сдавался</Tag>}
          </div>
        )}
      </div>
      <div className={styles.body}>
        {event.coach && <div>Тренер: {event.coach}</div>}
        {event.stages?.length > 0 && (
          <div>
            {event.stages.map((stage, idx) => (
              <div className={styles.stage} key={stage.id}>
                <div>
                  <b>Этап - {idx + 1}</b> {dayjs(stage.dtStart).format('DD.MM.YYYY с HH:mm')} по{' '}
                  {dayjs(stage.dtEnd).format('HH:mm')}
                </div>
                {stage.link && (
                  <a href={stage.link} target="_blank" rel="noreferrer">
                    <Typography.Paragraph copyable={{ text: stage.link }}>
                      {dayjs(stage.dtEnd).isBefore(dayjs())
                        ? 'Ссылка на запись вебинара'
                        : 'Ссылка на трансляцию вебинара'}
                    </Typography.Paragraph>
                  </a>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
      <div className={cn(styles.footer, { [styles.full]: isBrowser })}>
        <div className={styles.view}>{WEBINAR3_VIEW_ID2TAG[event.view]}</div>
        <div className={styles.type}>
          {event.type.map((t) => (
            <Tag key={t} color="error">
              {WEBINAR3_TYPES_ID2NAME[t]}
            </Tag>
          ))}
        </div>

        {event?.certificate?.fileId && (test?.success || !(event?.test?.id > 0)) ? (
          <Button type="link" icon={<EyeOutlined />} onClick={onShowCertificate}>
            Сертификат
          </Button>
        ) : (
          <i />
        )}
      </div>
    </div>
  );
}

W3FinishedEventCard.defaultProps = {
  test: null,
};

W3FinishedEventCard.propTypes = {
  event: PropTypes.shape({
    name: PropTypes.string.isRequired,
    status: PropTypes.number,
    view: PropTypes.number,
    type: PropTypes.arrayOf(PropTypes.number),
    coach: PropTypes.string,
    test: PropTypes.shape({
      dtEnd: PropTypes.string,
      id: PropTypes.number,
    }),
    stages: PropTypes.arrayOf(
      PropTypes.shape({
        dtStart: PropTypes.string,
        dtEnd: PropTypes.string,
        link: PropTypes.string,
      }),
    ),
    certificate: PropTypes.shape({
      fileId: PropTypes.string,
    }),
  }).isRequired,
  test: PropTypes.shape({
    success: PropTypes.bool,
    done: PropTypes.number,
  }),
};

export default W3FinishedEventCard;
