/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';

import { Layout } from 'antd';

import { apiPostData } from '../../../../api';
import GTooltip from '../../../components/GTooltip/GTooltip';
import styles from './styles.scss';
import W3EventCard from './W3EventCard';

export default function WebinarEventsActiveList() {
  const [eventsList, setEventsList] = useState([]);

  const fetchList = async () => {
    const { list } = await apiPostData('/webinars3/user/event/list');
    setEventsList(list);
  };

  useEffect(() => {
    fetchList();
  }, []);

  return (
    <>
      <div className={styles.pageHeader}>
        <GTooltip tid="webinars_preson_events_active">Мои записи на вебинары, семинары</GTooltip>
      </div>

      <Layout.Content>
        <div className={styles.contextHolder}>
          {eventsList.map((it) => (
            <W3EventCard key={it.__event._id} event={it.__event} />
          ))}
        </div>
      </Layout.Content>
    </>
  );
}
