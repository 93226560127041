import React from 'react';

import { Button, Tag, Typography } from 'antd';
import cn from 'classnames';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { isBrowser } from 'react-device-detect';
import { useHistory } from 'react-router-dom';

import { warningDialog } from '../../../components/Common/WarningDialog';
import {
  WEBINAR3_STATUS_ID2TAG,
  WEBINAR3_TYPES_ID2NAME,
  WEBINAR3_VIEW_ID2TAG,
} from '../../../pages/Webinars/webinar_constants';
import styles from './styles.scss';
import { apiPostData } from '../../../../api';

function W3AvailableEventCard({ event }) {
  const history = useHistory();

  const addUserToEvent = async () => {
    const res = await warningDialog(
      'Записаться на вебинар',
      'Записаться на прохождение вебинара?',
      [{ label: 'Отмена' }, { label: 'Записаться' }],
    );

    if (res === 1) {
      await apiPostData('/webinars3/user/add', { eventIds: [event._id] });
      history.push('/events/active');
    }
  };

  return (
    <div className={styles.context}>
      <div className={cn(styles.header, { [styles.full]: isBrowser })}>
        <div className={styles.title}>{event.name}</div>
        {!isBrowser && <div className={styles.status}>{WEBINAR3_STATUS_ID2TAG[event.status]}</div>}

        {event.test?.id > 0 && event.test && (
          <div className={styles.testDtEnd}>
            Сдача теста доступна до {dayjs(event.test.dtEnd).format('HH:mm, DD.MM.YYYY')}
          </div>
        )}
        {isBrowser && <div className={styles.status}>{WEBINAR3_STATUS_ID2TAG[event.status]}</div>}
      </div>
      <div className={styles.body}>
        {event.coach && <div>Тренер: {event.coach}</div>}
        {event.stages?.length > 0 && (
          <div>
            {event.stages.map((stage, idx) => (
              <div className={styles.stage} key={stage.id}>
                <div>
                  <b>Этап - {idx + 1}</b> {dayjs(stage.dtStart).format('DD.MM.YYYY с HH:mm')} по{' '}
                  {dayjs(stage.dtEnd).format('HH:mm')}
                </div>
                {stage.link && (
                  <a href={stage.link} target="_blank" rel="noreferrer">
                    <Typography.Paragraph copyable={{ text: stage.link }}>
                      {dayjs(stage.dtEnd).isBefore(dayjs())
                        ? 'Ссылка на запись вебинара'
                        : 'Ссылка на трансляцию вебинара'}
                    </Typography.Paragraph>
                  </a>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
      <div className={cn(styles.footer, { [styles.full]: isBrowser })}>
        <div className={styles.view}>{WEBINAR3_VIEW_ID2TAG[event.view]}</div>
        <div className={styles.type}>
          {event.type.map((t) => (
            <Tag key={t} color="error">
              {WEBINAR3_TYPES_ID2NAME[t]}
            </Tag>
          ))}
        </div>

        <Button type="primary" onClick={addUserToEvent}>
          Записаться
        </Button>
      </div>
    </div>
  );
}

W3AvailableEventCard.defaultProps = {};

W3AvailableEventCard.propTypes = {
  event: PropTypes.shape({
    name: PropTypes.string.isRequired,
    status: PropTypes.number,
    view: PropTypes.number,
    type: PropTypes.arrayOf(PropTypes.number),
    coach: PropTypes.string,
    test: PropTypes.shape({
      dtEnd: PropTypes.string,
      id: PropTypes.number,
    }),
    stages: PropTypes.arrayOf(
      PropTypes.shape({
        dtStart: PropTypes.string,
        dtEnd: PropTypes.string,
        link: PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default W3AvailableEventCard;
