import 'antd/es/date-picker/style/index';

import generatePicker from 'antd/es/date-picker/generatePicker';

/* eslint-disable import/no-extraneous-dependencies */
// import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import dayjsGenerateConfig from './dayjs-ru-generator';

const DatePicker = generatePicker(dayjsGenerateConfig);

export default DatePicker;
