import React from 'react';

import { Col } from 'antd';
import cn from 'classnames';
// eslint-disable-next-line import/no-extraneous-dependencies
import dayjs from 'dayjs';
import gv from 'get-value';
import PropTypes from 'prop-types';

import { DEFAULT_TIMEFORMAT } from './constants';
import TimePicker from './DayJsTimePicker';
import styles from './styles.scss';

function XDFTime({ it, size, model, onPropChanged }) {
  const { id, prop, label, placeholder, autoFocus, span, readOnly, format, noW100 } = it;

  function onChange(date, dateString) {
    onPropChanged(it, dateString);
  }

  const val = gv(model, prop, dayjs());
  // console.log('XDFTime', val);
  return (
    <Col span={span || 24}>
      <div className={styles.formItem}>
        <div className={styles.formItemLabel}>
          <label htmlFor={id}>{label}</label>
        </div>
        <div className={cn(styles.formItemField, { [styles.small]: size === 'small' })}>
          <TimePicker
            id={id}
            size={size}
            placeholder={placeholder || ''}
            autoFocus={!!autoFocus}
            disabled={!!readOnly}
            value={dayjs(val, format || DEFAULT_TIMEFORMAT)}
            onChange={onChange}
            style={{ width: noW100 ? 'auto' : '100%' }}
            format={format || DEFAULT_TIMEFORMAT}
          />
        </div>
      </div>
    </Col>
  );
}

XDFTime.propTypes = {
  it: PropTypes.shape({
    id: PropTypes.string.isRequired,
    prop: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    placeholder: PropTypes.string,
    autoFocus: PropTypes.bool,
    span: PropTypes.number,
    readOnly: PropTypes.bool,
    format: PropTypes.string,
    noW100: PropTypes.bool,
  }).isRequired,
  size: PropTypes.string.isRequired,
  model: PropTypes.shape({}).isRequired,
  onPropChanged: PropTypes.func.isRequired,
};

export default XDFTime;
