/* eslint-disable import/prefer-default-export */
import React from 'react';
import { BellOutlined, CheckOutlined } from '@ant-design/icons';
import NotifyPopup from '../../components/Common/NotifyPopup';

// Общие колонки, есть на всех видах
export const commonColumns = (notifyLastForRole, webinarSettings) => ({
  name: null,
  props: ['emailApproveDt', 'udt'], // список полей для запроса к серверу
  columns: [
    {
      prop: '_id',
      name: '',
      width: 35,
      align: 'center',
      excelHide: true,
      render: (item, val) => {
        const noti = notifyLastForRole[val];
        if (noti) return <NotifyPopup noti={noti} />;
        return <BellOutlined style={{ color: '#aaa' }} />;
      },
    },
    {
      prop: '+cdt',
      name: 'Дата',
      sort: true,
      nowrap: true,
      type: 'date',
      format: 'DD.MM.YYYY HH:mm',
    },
    {
      prop: '+surname',
      name: 'Фамилия',
      sort: true,
      nowrap: true,
    },
    {
      prop: '+name',
      name: 'Имя',
      sort: true,
      nowrap: true,
    },
    {
      prop: '+date',
      name: 'Дата вебинара',
      sort: true,
      nowrap: true,
      prop2value: [webinarSettings.wdates, 'id', 'name'],
    },
    {
      prop: '+region',
      name: 'Регион',
      sort: true,
      nowrap: true,
      prop2value: [webinarSettings.wregions, 'id', 'name'],
    },
    {
      prop: '+date2',
      name: 'Дата вебинара',
      sort: true,
      nowrap: true,
    },
    {
      prop: '+region2',
      name: 'Регион',
      sort: true,
      nowrap: true,
    },
    {
      prop: '+region2',
      name: 'Регион',
      sort: true,
      nowrap: true,
    },
    {
      prop: '+city',
      name: 'Город',
      sort: true,
      nowrap: true,
    },
    {
      prop: '+company',
      name: 'Компания',
      sort: true,
      nowrap: true,
    },
    {
      prop: '+position',
      name: 'Должность',
      sort: true,
      nowrap: true,
    },
    {
      prop: '+email',
      name: 'E-mail',
      sort: true,
      nowrap: true,
    },
    {
      prop: '+emailApproveDt',
      name: 'E-mail подтвержден',
      nowrap: true,
      align: 'center',
      excelRender: (data) => (data.emailApproveDt ? '+' : ''),
      render: (data) => {
        if (data.emailApproveDt) return <CheckOutlined style={{ color: 'green' }} />;
        return ' ';
      },
    },
  ],
});
