import React, { useState } from 'react';

import { Table } from 'antd';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';

import { warningDialog } from '../../../components/Common/WarningDialog';
import XDataFormDialog from '../../../components/xdf-antd/XDataForm/XDataFormDialog';
import styles from './styles.scss';
import { elementForm, productElementsColumns } from './table-defs';

function KybSiteProductElements({ model, onChangeModel }) {
  const [modelFormDialog, setModelFormDialog] = useState({
    visible: false,
    model: {},
  });

  const onAddElement = (m) => {
    onChangeModel(
      {
        ...model,
        elements: [...model.elements, m],
      },
      true,
    );
  };

  const onAddNewElement = () => {
    setModelFormDialog({
      ...modelFormDialog,
      visible: true,
      title: 'Добавление элемента',
      model: {
        _id: nanoid(),
      },
      onSave: onAddElement,
    });
  };

  const onSaveElement = (m) => {
    const idx = model.elements.findIndex((x) => x._id === m._id);
    onChangeModel(
      {
        ...model,
        elements: [...model.elements.slice(0, idx), m, ...model.elements.slice(idx + 1)],
      },
      true,
    );
  };

  const onEditElement = (idx) => {
    setModelFormDialog({
      ...modelFormDialog,
      visible: true,
      title: 'Редактированеи элемента',
      model: { ...model.elements[idx] },
      onSave: onSaveElement,
    });
  };

  const onDeleteElement = async (idx) => {
    const res = await warningDialog('Редактирование продукта', 'Удалить элемент?', [
      { label: 'Отмена' },
      { label: 'Удалить' },
    ]);
    if (res === 1) {
      onChangeModel(
        {
          ...model,
          elements: [...model.elements.slice(0, idx), ...model.elements.slice(idx + 1)],
        },
        true,
      );
    }
  };

  const onCloseFormDialog = () => {
    setModelFormDialog({ ...modelFormDialog, visible: false });
  };

  const onSaveModelFormDialog = (m) => {
    onCloseFormDialog();
    modelFormDialog.onSave(m);
  };

  return (
    <>
      <Table
        className={styles.elementsTable}
        pagination={false}
        bordered
        size="small"
        dataSource={(model.elements || []).map((x) => ({
          ...x,
          key: x._id,
        }))}
        columns={productElementsColumns(
          onAddNewElement,
          onEditElement,
          onDeleteElement,
          (model.elements || []).length >= 3,
        )}
      />
      <XDataFormDialog
        title={modelFormDialog.title}
        visible={modelFormDialog.visible}
        model={modelFormDialog.model}
        form={elementForm()}
        onClose={onCloseFormDialog}
        onSave={onSaveModelFormDialog}
      />
    </>
  );
}

KybSiteProductElements.defaultProps = {
  model: {},
};

KybSiteProductElements.propTypes = {
  model: PropTypes.shape({
    elements: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  onChangeModel: PropTypes.func.isRequired,
};

export default KybSiteProductElements;
