import React from 'react';
import { BellOutlined } from '@ant-design/icons';
import NotifyPopup from '../../../components/Common/NotifyPopup';

// Общие колонки, есть на всех видах
// eslint-disable-next-line import/prefer-default-export
export function commonColumns(notifyLastForRole) {
  return {
    name: 'Пользователи', // название
    props: [], // список полей для запроса к серверу
    columns: [
      {
        prop: '_id',
        name: '',
        width: 35,
        align: 'center',
        render: (item, val) => {
          const noti = notifyLastForRole[val];
          if (noti) return <NotifyPopup noti={noti} />;
          return <BellOutlined style={{ color: '#aaa' }} />;
        },
      },
      {
        prop: '+_id',
        name: 'ID',
        sort: true,
        width: 61,
      },
      {
        prop: '+firstName',
        name: 'Фамилия',
        sort: true,
      },
      {
        prop: '+name',
        name: 'Имя',
        sort: true,
      },
      {
        prop: '+phone',
        name: 'Телефон',
        sort: true,
      },
      {
        prop: '+phoneLastCode',
        name: 'Код для входа',
      },
      {
        prop: '+phoneStatus',
        name: 'Проверка',
      },
      {
        prop: '+cdt',
        name: 'Регистрация',
        sort: true,
        type: 'date',
        format: 'DD.MM.YYYY / HH:mm',
      },
      {
        prop: '+email',
        name: 'E-mail',
        sort: true,
      },
      {
        prop: '+emailStatus',
        name: 'Проверка Email',
      },
    ],
  };
}
