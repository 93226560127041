import React from 'react';

import { Col, Input } from 'antd';
import cn from 'classnames';
import gv from 'get-value';
import PropTypes from 'prop-types';

import styles from './styles.scss';

function XDFTextarea({ it, size, model, onPropChanged }) {
  const {
    id,
    prop,
    label,
    placeholder,
    autoFocus,
    span,
    readOnly,
    showOnly,
    rows,
    autoSize,
    showCount,
  } = it;

  const onChange = (ev) => {
    onPropChanged(it, ev.target.value);
  };

  const val = gv(model, prop, '');
  return (
    <Col span={span || 24}>
      <div className={styles.formItem}>
        <div className={styles.formItemLabel}>
          <label htmlFor={id}>{label}</label>
        </div>
        <div
          className={cn(
            styles.formItemField,
            { [styles.small]: size === 'small' },
            { [styles.showOnly]: !!showOnly },
          )}
        >
          <Input.TextArea
            id={id}
            size={size}
            placeholder={placeholder || ''}
            autoFocus={!!autoFocus}
            disabled={!!readOnly || !!showOnly}
            value={val}
            onChange={onChange}
            autoSize={{ minRows: rows || 2, maxRows: autoSize ? 32 : rows || 2 }}
            showCount={!!showCount}
          />
        </div>
      </div>
    </Col>
  );
}

XDFTextarea.propTypes = {
  it: PropTypes.shape({
    id: PropTypes.string.isRequired,
    prop: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    placeholder: PropTypes.string,
    autoFocus: PropTypes.bool,
    span: PropTypes.number,
    readOnly: PropTypes.bool,
    showOnly: PropTypes.bool,
    rows: PropTypes.number,
    autoSize: PropTypes.bool,
    showCount: PropTypes.bool,
  }).isRequired,
  size: PropTypes.string.isRequired,
  model: PropTypes.shape({}).isRequired,
  onPropChanged: PropTypes.func.isRequired,
};

export default XDFTextarea;
