import React, { useEffect, useState } from 'react';

import { useHistory, useLocation, useParams } from 'react-router-dom';

import styles from './styles.scss';

export default function Null() {
  // router
  // const history = useHistory();
  // const location = useLocation();
  // const { proudctId } = useParams();

  return <div className={styles.main}>Null</div>;
}
