import React, { useState } from 'react';

import { Layout, Modal, PageHeader } from 'antd';
import { useRecoilValue } from 'recoil';

import apiAnyList from '../../../api/AnyList';
import { subscribeToChannel, unsubscribeToChannel } from '../../../api/sio';
import apiSiteMetaSet from '../../../api/SiteMetaSet';
import { notifyLastForRoleState } from '../../../state/notify-state';
import { webinar1SettingsState } from '../../../state/webinar1-state';
import GTooltip from '../../components/GTooltip/GTooltip';
import XDataForm, { XDF_TYPE } from '../../components/xdf-antd/XDataForm';
import XDataTable from '../../components/xdf-antd/XDataTable';
import styles from './styles.scss';
import { commonColumns } from './table-defs';

export default function WebinarsV1List() {
  const webinarSettings = useRecoilValue(webinar1SettingsState);
  const notifyLastForRole = useRecoilValue(notifyLastForRoleState(701));

  const [model, setModel] = useState({ visible: false });

  const onRowClick = (item) => {
    setModel({
      visible: true,
      ...item,
    });
  };

  const onChangeModel = (m) => {
    setModel({
      ...model,
      ...m,
    });
  };

  const closeDialog = () => {
    setModel({
      visible: false,
    });
  };

  const saveTooltip = async () => {
    await apiSiteMetaSet(model);
    closeDialog();
  };

  return (
    <>
      <PageHeader
        className={styles.pageHeader}
        title={<GTooltip tid="webinar1_list">Участники вебинаров</GTooltip>}
      />
      <Layout.Content>
        <div className={styles.contextHolder}>
          <div className={styles.context}>
            <XDataTable
              apiLoadData={apiAnyList('KybDb:Webinar2User')}
              commonColumns={commonColumns(notifyLastForRole, webinarSettings)}
              extentedColumns={null}
              onRowClick={onRowClick}
              sioEvents={['LIST:WEBINAR_V1', subscribeToChannel, unsubscribeToChannel]}
              initFilter={{ state: { $gte: 0 } }}
              initSort={['cdt', -1]}
              searchProps={['surname', 'name']}
              excelExport={{
                filename: 'Webinar',
                tabname: 'Список',
                exportStep: 100,
                autoWidth: true,
              }}
            />
          </div>
        </div>
      </Layout.Content>

      <Modal
        title="Редактировать участника"
        open={model.visible}
        onCancel={closeDialog}
        okText="Сохранить"
        onOk={saveTooltip}
        width={700}
      >
        <XDataForm
          model={model}
          onChangeModel={onChangeModel}
          form={[
            {
              type: XDF_TYPE.TEXT,
              label: 'Фамилия',
              prop: 'surname',
              span: 12,
              required: true,
              autoFocus: true,
            },
            {
              type: XDF_TYPE.TEXT,
              label: 'Имя',
              prop: 'name',
              span: 12,
              required: true,
            },
            {
              type: XDF_TYPE.SELECT,
              label: 'Регион',
              prop: 'region',
              span: 12,
              values: webinarSettings.wregions,
              required: true,
            },
            {
              type: XDF_TYPE.TEXT,
              label: 'Город',
              prop: 'city',
              span: 12,
              required: true,
            },
            {
              type: XDF_TYPE.TEXT,
              label: 'Компания',
              prop: 'company',
              span: 12,
              required: true,
            },
            {
              type: XDF_TYPE.TEXT,
              label: 'Должность',
              prop: 'position',
              span: 12,
              required: true,
            },
            {
              type: XDF_TYPE.TEXT,
              label: 'E-mail',
              prop: 'email',
              span: 12,
              required: true,
            },
            {
              type: XDF_TYPE.SELECT,
              label: 'Дата участия в вебинаре',
              prop: 'date',
              values: webinarSettings.wdates,
              span: 12,
              required: true,
            },
            {
              type: XDF_TYPE.SHOW_DATETIME,
              label: 'Дата создания',
              prop: 'cdt',
              span: 8,
            },
            {
              type: XDF_TYPE.SHOW_DATETIME,
              label: 'Дата подтверждения Е-mail',
              prop: 'emailApproveDt',
              span: 8,
            },
            {
              type: XDF_TYPE.SHOW_DATETIME,
              label: 'Дата редактирования',
              prop: 'udt',
              span: 8,
            },
          ]}
        />
      </Modal>
    </>
  );
}
