/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';

import { Button, Layout, message, PageHeader, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { useParams, useHistory } from 'react-router-dom';

import { PlusOutlined, SaveOutlined } from '@ant-design/icons';

import apiAnyPropList from '../../../../api/AnyPropList';
import GTooltip from '../../../components/GTooltip/GTooltip';
import XDataForm, { XDF_TYPE } from '../../../components/xdf-antd/XDataForm';
import styles from './styles.scss';
import { DATE_FORMAT, NPIA_ACCESS, NPIA_STATE, PART_TYPES } from './table-defs';
import { apiPost } from '../../../../api';

import XDataGallery from '../../../components/xdf-antd/XDataGallery/XDataGallery';

const defModel = {
  status: 0,
  acc: 0,
  dt: dayjs().format(DATE_FORMAT),
  title: '',
  preview: '',
  html: '',
  parts: [],
  cars: [],
  __carProdDates1: dayjs('1901-01-01').format(DATE_FORMAT),
  __carProdDates2: dayjs().format(DATE_FORMAT),
};

export default function NewProductsInAssortmentEditor() {
  const history = useHistory();
  const { objectId } = useParams();

  const [isModelChanged, setIsModelChanged] = useState(false);
  const [model, setModel] = useState(defModel);

  const fetchData = async (id) => {
    setIsModelChanged(false);
    const { err, data } = await apiPost('/npia/get', { id });
    setModel({
      ...defModel,
      ...data,
    });
  };

  useEffect(() => {
    if (objectId !== '--new--') {
      fetchData(objectId);
    }
  }, [objectId]);

  useEffect(() => {
    setModel({
      ...model,
      __vendorCode: '',
    });
  }, [model?.__productType]);

  const onChangeModel = (m, isMChanged) => {
    setIsModelChanged(isMChanged);
    setModel({ ...m });
  };

  const fetchPartNummbers = async (id, { __productType }) => {
    if (id.length < 2) return [];
    const list = await apiAnyPropList(
      'KybCatalog:Main',
      'partNumber',
    )({ part: __productType, partNumber: { $regex: `.*${id}.*`, $options: 'i' } });
    return list;
  };

  const fetchModels = async (id, { __carVendor }) => {
    const list = await apiAnyPropList(
      'KybCatalog:Main',
      'model',
    )({ firm: __carVendor, model: { $regex: `.*${id}.*`, $options: 'i' } });
    return list;
  };

  const addPart = (m) => {
    setModel({
      ...m,
      __vendorCode: '',
      parts: [
        ...m.parts,
        {
          type: m.__productType,
          part: m.__vendorCode,
        },
      ],
    });
  };

  const addCar = (m) => {
    setModel({
      ...m,
      __carModel: '',
      cars: [
        ...m.cars,
        {
          firm: m.__carVendor,
          model: m.__carModel,
          from: dayjs(m.__carProdDates1, DATE_FORMAT).format('MM.YYYY'),
          to:
            dayjs(m.__carProdDates2, DATE_FORMAT).year() === 9999
              ? ''
              : dayjs(m.__carProdDates2, DATE_FORMAT).format('MM.YYYY'),
        },
      ],
    });
  };

  const onSave = async () => {
    const { err, errmsg } = await apiPost('/npia/set', { id: objectId, data: model });
    if (err === 0) history.push('/news/newproducts');
    else if (Array.isArray(errmsg)) {
      for (const em of errmsg) {
        message.error(em.message, 4);
      }
    } else {
      message.error(errmsg || `Net error: ${err}`);
    }
  };

  const onPicListChanged = (items) => {
    setModel({
      ...model,
      pics: items.map((x) => x.id),
      __pics: items,
    });
    setIsModelChanged(true);
  };

  return (
    <>
      <PageHeader
        className={styles.pageHeader}
        title={
          <GTooltip tid="news_products_in_assortment_create">
            Создание новости «Новинки ассортимента»
          </GTooltip>
        }
        extra={[
          <Tooltip key="save" title="Сохранить изменения">
            <Button
              type="primary"
              icon={<SaveOutlined />}
              disabled={!isModelChanged}
              onClick={onSave}
            >
              Сохранить
            </Button>
          </Tooltip>,
        ]}
      />
      <Layout.Content>
        <div className={styles.contextHolder}>
          <div className={styles.context}>
            <XDataForm
              title="Новостной контент"
              model={model}
              onChangeModel={onChangeModel}
              form={[
                {
                  type: XDF_TYPE.SELECT,
                  label: 'Статус:',
                  prop: 'status',
                  values: NPIA_STATE,
                  span: 6,
                },
                {
                  type: XDF_TYPE.SELECT,
                  label: 'Права доступа к новости:',
                  prop: 'acc',
                  values: NPIA_ACCESS,
                  readOnly: true,
                  span: 6,
                },
                {
                  type: XDF_TYPE.DATETIME,
                  label: 'Дата новости:',
                  prop: 'dt',
                  require: true,
                  span: 6,
                },
                {
                  type: XDF_TYPE.TEXT,
                  label: 'Заголовок:',
                  prop: 'title',
                  placeholder: 'Введите заголовок',
                  require: true,
                  span: 18,
                },
                {
                  type: XDF_TYPE.TEXTAREA,
                  label: 'Анонс:',
                  prop: 'preview',
                  placeholder: 'Введите заголовок',
                  require: true,
                  rows: 4,
                  span: 18,
                },
                {
                  type: XDF_TYPE.UPLOAD_BASE64,
                  prop: 'pic',
                  align: 'left',
                  height: '98px',
                  resize: {
                    width: 162,
                    height: 162,
                  },
                  span: 6,
                },
                {
                  type: XDF_TYPE.WYSIWYG,
                  label: 'Основной текст:',
                  prop: 'html',
                  placeholder: 'Введите основной текст новости',
                  require: true,
                  rows: 4,
                  span: 18,
                },
              ]}
            />
          </div>
        </div>

        <div className={styles.contextHolder}>
          <div className={styles.context}>
            <XDataGallery
              picWidth={110}
              basePicUrl="/gi/"
              onChange={onPicListChanged}
              items={model.__pics}
            />
          </div>
        </div>
        <div className={styles.contextHolder}>
          <div className={styles.context}>
            <XDataForm
              title="Артикулы новости"
              model={model}
              onChangeModel={onChangeModel}
              form={[
                {
                  type: XDF_TYPE.SELECT,
                  label: 'Тип продукции:',
                  prop: '__productType',
                  values: PART_TYPES,
                  defValue: 'Амортизатор',
                  require: true,
                  span: 6,
                },
                {
                  type: XDF_TYPE.SEARCH,
                  label: 'Артикул:',
                  prop: '__vendorCode',
                  values: fetchPartNummbers,
                  readOnly: !model.__productType,
                  span: 6,
                },
                {
                  type: XDF_TYPE.BUTTON,
                  title: 'Добавить',
                  btnIcon: <PlusOutlined />,
                  width: 'auto',
                  onClick: addPart,
                  readOnly: !model.__vendorCode,
                  span: 6,
                },
                {
                  type: XDF_TYPE.TABLE_SIMPLE,
                  prop: 'parts',
                  columns: [
                    {
                      prop: 'type',
                      span: 8,
                    },
                    {
                      prop: 'part',
                      span: 8,
                    },
                    {
                      action: 'delete',
                      span: 8,
                    },
                  ],
                  span: 18,
                },
              ]}
            />
          </div>
        </div>

        <div className={styles.contextHolder}>
          <div className={styles.context}>
            <XDataForm
              title="Таблица применимости"
              model={model}
              onChangeModel={onChangeModel}
              form={[
                {
                  type: XDF_TYPE.SELECT,
                  label: 'Марка автомобиля:',
                  prop: '__carVendor',
                  values: apiAnyPropList('KybCatalog:Main', 'firm'),
                  require: true,
                  span: 6,
                },
                {
                  type: XDF_TYPE.SEARCH,
                  label: 'Модель автомобиля:',
                  prop: '__carModel',
                  values: fetchModels,
                  span: 6,
                },
                {
                  type: XDF_TYPE.DATERANGE,
                  label: 'Период выпуска:',
                  prop: '__carProdDates1',
                  prop2: '__carProdDates2',
                  ranges: {
                    '1900 .. н.в.': [dayjs('1900-01-01'), dayjs('9999-12-31')],
                    '1980 .. н.в.': [dayjs('1980-01-01'), dayjs('9999-12-31')],
                  },
                  span: 6,
                },
                {
                  type: XDF_TYPE.BUTTON,
                  title: 'Добавить',
                  btnIcon: <PlusOutlined />,
                  width: 'auto',
                  onClick: addCar,
                  readOnly: !model.__carVendor || !model.__carModel,
                  span: 6,
                },
                {
                  type: XDF_TYPE.TABLE_SIMPLE,
                  title: null,
                  prop: 'cars',
                  columns: [
                    {
                      prop: 'firm',
                      span: 6,
                    },
                    {
                      prop: 'model',
                      span: 6,
                    },
                    {
                      prop: 'from',
                      span: 3,
                    },
                    {
                      prop: 'to',
                      span: 3,
                    },
                    {
                      action: 'delete',
                      span: 6,
                    },
                  ],
                  span: 24,
                },
              ]}
            />
          </div>
        </div>
      </Layout.Content>
    </>
  );
}
