import React from 'react';

import { Col, Switch } from 'antd';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './styles.scss';
import xdfGetValue from './xdf-get-value';

function XDFSwitch({ it, size, model, onPropChanged }) {
  const { id, prop, label, boolType, autoFocus, span, readOnly, plain } = it;

  const onChange = (_val) => {
    let val = !!_val;
    if (!boolType) val = val ? 1 : 0;
    onPropChanged(it, val);
  };

  const val = xdfGetValue(model, prop, boolType ? false : 0, plain);
  return (
    <Col span={span || 24}>
      <div className={styles.formItem} data-pname={prop}>
        <div className={styles.formItemLabel}>
          <label htmlFor={id}>{label}</label>
        </div>
        <div
          className={cn(
            styles.formItemField,
            styles.checkbox,
            {
              [styles.small]: size === 'small',
            },
            { [styles.large]: size === 'large' },
          )}
        >
          <Switch
            id={id}
            onChange={onChange}
            disabled={!!readOnly}
            autoFocus={!!autoFocus}
            checked={!!val}
          />
        </div>
      </div>
    </Col>
  );
}

XDFSwitch.propTypes = {
  it: PropTypes.shape({
    id: PropTypes.string.isRequired,
    prop: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    boolType: PropTypes.bool,
    autoFocus: PropTypes.bool,
    span: PropTypes.number,
    readOnly: PropTypes.bool,
    plain: PropTypes.bool,
  }).isRequired,
  size: PropTypes.string.isRequired,
  model: PropTypes.shape({}).isRequired,
  onPropChanged: PropTypes.func.isRequired,
};

export default XDFSwitch;
