import React from 'react';

import { Col, Radio } from 'antd';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './styles.scss';
import xdfGetValue from './xdf-get-value';

function XDFSwitch3({ it, size, model, onPropChanged }) {
  const { id, prop, label, span, readOnly, plain } = it;
  const val = xdfGetValue(model, prop, -1);

  const onChange = (ev) => {
    // const nv = ev.target.value;
    // onPropChanged(it, nv);
  };

  const onClick0 = () => {
    onPropChanged(it, val === 0 ? -1 : 0);
  };

  const onClick1 = () => {
    onPropChanged(it, val === 1 ? -1 : 1);
  };

  return (
    <Col span={span || 24}>
      <div className={styles.formItem} data-pname={prop}>
        <div className={styles.formItemLabel}>
          <label htmlFor={id}>{label}</label>
        </div>
        <div
          className={cn(
            styles.formItemField,
            styles.checkbox,
            {
              [styles.small]: size === 'small',
            },
            { [styles.large]: size === 'large' },
          )}
          style={{ marginTop: 4 }}
        >
          <Radio.Group onChange={onChange} value={val} size="small" buttonStyle="solid">
            {/* <Radio.Button value={-1}>&nbsp;?&nbsp;</Radio.Button> */}
            <Radio.Button value={1} onClick={onClick1}>
              Да
            </Radio.Button>
            <Radio.Button value={0} onClick={onClick0}>
              Нет
            </Radio.Button>
          </Radio.Group>
        </div>
      </div>
    </Col>
  );
}

XDFSwitch3.propTypes = {
  it: PropTypes.shape({
    id: PropTypes.string.isRequired,
    prop: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    boolType: PropTypes.bool,
    autoFocus: PropTypes.bool,
    span: PropTypes.number,
    readOnly: PropTypes.bool,
    plain: PropTypes.bool,
  }).isRequired,
  size: PropTypes.string.isRequired,
  model: PropTypes.shape({}).isRequired,
  onPropChanged: PropTypes.func.isRequired,
};

export default XDFSwitch3;
