/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';

import { Button, Dropdown, Menu, notification } from 'antd';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';

import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';

import DragIcon from '../../../assets/svg/drag.svg';
import UpDownIcon from '../../../assets/svg/up-down.svg';
import copyTextToClipboard from '../../utils/copy2clipboard';
import XDataForm from '../xdf-antd/XDataForm';
import { getBlockFormByType } from './blocks-forms';
import KybBlockPicsDlg from './KybBlockPicsDlg';
import KybBlockYoutubeDlg from './KybBlockYoutubeDlg';
import SortableBlockItem from './SortableBlockItem';
import styles from './styles.scss';

function KybPageBlocksEditor({ blocks, onSaveBlocks, pics }) {
  const [activeItem, setActiveItem] = useState({});
  const [blkIdx, setBlkIdx] = useState(-1);

  const [showPicSelectDlg, setShowPicSelectDlg] = useState(false);
  const [showYoutubeSelectDlg, setShowYoutubeSelectDlg] = useState(false);

  const startEditBlock = (bi) => {
    setBlkIdx(bi);
  };

  const onDeleteBlock = (idx) => {
    setBlkIdx(-1);
    if (idx >= 0) {
      onSaveBlocks([...blocks.slice(0, idx), ...blocks.slice(idx + 1)]);
    }
  };

  const onAddBlock = ({ key }) => {
    onSaveBlocks([
      ...blocks,
      {
        type: parseInt(key, 10),
        id: nanoid(),
      },
    ]);
  };

  const onChangeModel = (m, is) => {
    // eslint-disable-next-line no-param-reassign
    blocks[blkIdx] = {
      ...m,
    };
    onSaveBlocks(blocks);
  };

  const onInsertPic = () => {
    setShowPicSelectDlg(true);
  };

  const onSelectPic = async (pic) => {
    setShowPicSelectDlg(false);
    const ok = await copyTextToClipboard(`!${pic.pic}||%%%%!`);
    if (ok) {
      notification.success({
        message: 'Код вставки картинки скопирован в буфер обмена',
        description: 'Выберите место в тексте и вставьте из буфера обмена (Ctrl+V)',
        duration: 9,
      });
    }
  };

  const onSelectVideo = async (video) => {
    setShowYoutubeSelectDlg(false);
    const ok = await copyTextToClipboard(
      `!${video.videoId}.youtube|${video.wh}|%%${video.name}%%!`,
    );
    if (ok) {
      notification.success({
        message: 'Код вставки видео скопирован в буфер обмена',
        description: 'Выберите место в тексте и вставьте из буфера обмена (Ctrl+V)',
        duration: 9,
      });
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragStart = (event) => {
    // setActiveId(event.active.id);
    // console.log('handleDragStart', event.active);
    setBlkIdx(-1);
    setActiveItem(event.active);
  };

  const handleDragEnd = (event) => {
    // setActiveId(null);
    setActiveItem({});
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = blocks.findIndex((x) => x.id === active.id);
      const newIndex = blocks.findIndex((x) => x.id === over.id);
      onSaveBlocks(arrayMove(blocks, oldIndex, newIndex));
    }
  };

  const items = [
    {
      label: 'Заголовок + одна колонка (тип: 1)',
      key: 1,
    },
    {
      label: 'Две колонки (тип: 2)',
      key: 2,
    },
    {
      label: 'Цитирование (тип: 3)',
      key: 3,
    },
    {
      label: 'Заголовк + две колонки (скрывающийся блок) (тип: 4)',
      key: 4,
    },
    {
      label: 'Заголовк + одна колонка (скрывающийся блок) (тип: 5)',
      key: 5,
    },
    {
      label: 'Три картинки (тип: 7)',
      key: 7,
    },
  ];

  const menu = <Menu onClick={onAddBlock} items={items} />;

  // const menu = (
  //   <Menu onClick={onAddBlock}>
  //     <Menu.Item key="1">Заголовок + одна колонка (тип: 1)</Menu.Item>
  //     <Menu.Item key="2">Две колонки (тип: 2)</Menu.Item>
  //     <Menu.Item key="3">Цитирование (тип: 3)</Menu.Item>
  //     <Menu.Item key="4">Заголовк + две колонки (скрывающийся блок) (тип: 4)</Menu.Item>
  //     <Menu.Item key="5">Заголовк + одна колонка (скрывающийся блок) (тип: 5)</Menu.Item>
  //     <Menu.Item key="7">Три картинки (тип: 7)</Menu.Item>
  //   </Menu>
  // );

  return (
    <div className={styles.main}>
      <div className={styles.left}>
        <div>
          <Dropdown overlay={menu} trigger={['click']}>
            <Button style={{ width: '100%' }}>Добавить блок</Button>
          </Dropdown>
        </div>

        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
        >
          <div className={styles.blocksList}>
            <SortableContext items={blocks} strategy={rectSortingStrategy}>
              {blocks.map((blk, i) => (
                <SortableBlockItem
                  key={blk.id}
                  id={blk.id}
                  handle
                  value={blk.type}
                  // editId={model?.id}
                  editId={blkIdx >= 0 ? blocks[blkIdx]?.id : null}
                  onClick={() => startEditBlock(i)}
                  onDeleteBlock={() => onDeleteBlock(i)}
                />
              ))}
              <DragOverlay>
                {activeItem?.id ? (
                  <div className={styles.blkItem}>
                    <div className={styles.dragArea}>
                      <DragIcon />
                    </div>
                    <div className={styles.blkT}>
                      <UpDownIcon />
                    </div>
                  </div>
                ) : null}
              </DragOverlay>
            </SortableContext>
          </div>
        </DndContext>
      </div>
      <div className={styles.right}>
        {blkIdx >= 0 && (
          <div>
            <div className={styles.editorCtrls}>
              <Button onClick={() => setShowYoutubeSelectDlg(true)}>Вставить видео</Button>
              {pics?.length > 0 && (
                <Button style={{ marginLeft: 24 }} onClick={onInsertPic}>
                  Вставить картинку
                </Button>
              )}
            </div>
            <div className={styles.blockEditor}>
              <XDataForm
                model={blocks[blkIdx]}
                onChangeModel={onChangeModel}
                form={getBlockFormByType(blocks[blkIdx].type)}
              />
            </div>
          </div>
        )}
      </div>

      {pics?.length > 0 && (
        <KybBlockPicsDlg
          visible={showPicSelectDlg}
          pics={pics}
          onSelect={onSelectPic}
          onClose={() => setShowPicSelectDlg(false)}
        />
      )}

      <KybBlockYoutubeDlg
        visible={showYoutubeSelectDlg}
        onSelect={onSelectVideo}
        onClose={() => setShowYoutubeSelectDlg(false)}
      />
    </div>
  );
}

KybPageBlocksEditor.defaultProps = {
  blocks: [],
  pics: [],
};

KybPageBlocksEditor.propTypes = {
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.number,
    }),
  ),
  onSaveBlocks: PropTypes.func.isRequired,
  pics: PropTypes.arrayOf(
    PropTypes.shape({
      pic: PropTypes.string,
      desc: PropTypes.string,
    }),
  ),
};

export default KybPageBlocksEditor;
