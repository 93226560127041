import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Col, Radio, Select } from 'antd';
import cn from 'classnames';
import gv from 'get-value';
import PropTypes from 'prop-types';

import styles from './styles.scss';

function XDFRadio({ it, size, model, onPropChanged, multiple }) {
  const {
    id,
    prop,
    label,
    placeholder,
    autoFocus,
    span,
    readOnly,
    showOnly,
    values,
    filterValues,
    loadingDataMsg,
  } = it;

  const mountedRef = useRef(false);
  const isMounted = useCallback(() => mountedRef.current, []);

  const [loading, setLoading] = useState(false);
  const [values0, setValues0] = useState([]);

  // mount / unmount
  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  });

  // load values, if promise
  useEffect(() => {
    if (values && Array.isArray(values)) {
      setValues0(values);
    } else {
      // eslint-disable-next-line react/prop-types
      values().then((list) => {
        // console.log(list);
        if (isMounted()) {
          setValues0(list);
          setLoading(false);
        }
      });
      setLoading(true);
    }
  }, [values]);

  const onChange = (e) => {
    const { value } = e.target;
    onPropChanged(it, value);
  };

  let val = gv(model, prop, multiple ? [] : undefined);
  if (loading) {
    val = multiple ? [] : undefined;
  }

  // check val
  if (!Array.isArray(val)) {
    const item = values0.find((x) => x.id === val);
    if (!item) {
      val = '';
    }
  }

  return (
    <Col span={span || 24}>
      <div className={styles.formItem}>
        {!!label && (
          <div className={styles.formItemLabel}>
            <label htmlFor={id}>{label}</label>
          </div>
        )}
        <div
          className={cn(
            styles.formItemField,
            { [styles.large]: size === 'large' },
            { [styles.small]: size === 'small' },
            { [styles.selectNotShow]: loading },
          )}
        >
          <Radio.Group
            id={id}
            size={size}
            onChange={onChange}
            value={val}
            disabled={!!readOnly || loading}
          >
            {(values0 || [])
              .filter((v) => {
                if (!filterValues) return true;
                return filterValues.includes(v.id);
              })
              .map((v) => (
                <Radio key={v.id} value={v.id}>
                  {v.name}
                </Radio>
              ))}
          </Radio.Group>
        </div>
      </div>
    </Col>
  );
}

XDFRadio.propTypes = {
  it: PropTypes.shape({
    id: PropTypes.string.isRequired,
    prop: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    placeholder: PropTypes.string,
    autoFocus: PropTypes.bool,
    span: PropTypes.number,
    readOnly: PropTypes.bool,
    showOnly: PropTypes.bool,
    values: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
    filterValues: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    loadingDataMsg: PropTypes.string,
  }).isRequired,
  size: PropTypes.string.isRequired,
  model: PropTypes.shape({}).isRequired,
  onPropChanged: PropTypes.func.isRequired,
  multiple: PropTypes.bool.isRequired,
};

export default XDFRadio;
