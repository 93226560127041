import { XDF_TYPE } from '../../../components/xdf-antd/XDataForm';
import { KYB_FAQ_SECTION, KYB_SITE_CATALOG_STATUS } from '../../../constants';

// eslint-disable-next-line import/prefer-default-export
export const commonColumns = () => ({
  name: null,
  props: [], // список полей для запроса к серверу
  rowStyle: (item) => {
    if (item.status <= 0) return { color: 'rgba(255,0,0,0.5)' };
    return {};
  },
  columns: [
    {
      prop: '+status',
      name: 'Статус',
      sort: true,
      nowrap: true,
      prop2value: [KYB_SITE_CATALOG_STATUS, 'id', 'name'],
    },
    {
      prop: '+state',
      name: 'Секция',
      sort: true,
      prop2value: [KYB_FAQ_SECTION, 'id', 'name'],
    },
    {
      prop: '+name',
      name: 'Название',
      sort: true,
    },
    {
      prop: '+tags',
      name: 'Теги',
      sort: true,
    },
  ],
});

export const formModelEditor = () => [
  {
    type: XDF_TYPE.SELECT,
    label: 'Статус',
    prop: 'status',
    values: KYB_SITE_CATALOG_STATUS.filter((x) => [0, 1].includes(x.id)),
    span: 12,
  },
  {
    type: XDF_TYPE.SELECT,
    label: 'Раздел',
    prop: 'state',
    values: KYB_FAQ_SECTION,
    span: 12,
  },
  {
    type: XDF_TYPE.TEXTAREA,
    label: 'Вопрос',
    prop: 'name',
    rows: 4,
    span: 24,
  },
  {
    type: XDF_TYPE.TEXTAREA,
    label: 'Ответ',
    prop: 'desc',
    rows: 7,
    span: 24,
  },
  {
    type: XDF_TYPE.TAGS,
    label: 'Теги',
    prop: 'tags',
    values: [],
    span: 24,
  },
];
