import React from 'react';

import { Button, Col } from 'antd';
import cn from 'classnames';
import gv from 'get-value';
import PropTypes from 'prop-types';

import styles from './styles.scss';

function XDFButton({ it, size, model }) {
  const { id, label, hideLabel, title, btnIcon, span, readOnly, onClick, width } = it;

  return (
    <Col span={span || 24}>
      <div className={styles.formItem}>
        {!hideLabel && (
          <div className={styles.formItemLabel}>
            <label htmlFor={id}>{label || <span>&nbsp;</span>}</label>
          </div>
        )}
        <div className={cn(styles.formItemField, { [styles.small]: size === 'small' })}>
          <Button
            id={id}
            size={size}
            disabled={!!readOnly}
            icon={btnIcon}
            style={{ width: width || '100%' }}
            onClick={() => onClick(model)}
          >
            {title}
          </Button>
        </div>
      </div>
    </Col>
  );
}

XDFButton.propTypes = {
  it: PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    hideLabel: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    btnIcon: PropTypes.element,
    span: PropTypes.number,
    readOnly: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    width: PropTypes.string,
  }).isRequired,
  model: PropTypes.shape({}).isRequired,
  size: PropTypes.string.isRequired,
};

export default XDFButton;
