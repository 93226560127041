import React, { useRef, useState } from 'react';

import { Layout, message, PageHeader } from 'antd';
import { useHistory } from 'react-router-dom';

import { FolderAddOutlined } from '@ant-design/icons';

import { subscribeToChannel, unsubscribeToChannel } from '../../../../api/sio';
import apiW3UserList from '../../../../api/W3UserList';
import GTooltip from '../../../components/GTooltip/GTooltip';
import XDataTable from '../../../components/xdf-antd/XDataTable';
import styles from './styles.scss';
import { commonColumns, filterFormWebinarsUser } from './table-defs';
import WebinarsAddUsersToEventDlg from '../Dialogs/WebinarsAddUsersToEventDlg';
import { apiPostData } from '../../../../api';

export default function WebinarsUsersList() {
  const history = useHistory();
  const [showAddUsersToEventDlg, setShowAddUsersToEventDlg] = useState(false);

  const tableEmitter = useRef(null);
  const [filter, setFilter] = useState({});

  const onSaveEventEmmitter = (ee) => {
    tableEmitter.current = ee;
    tableEmitter.current?.addListener('FILTER-CHANGED', (f) => {
      setFilter(f);
    });
  };

  const onRowClick = (item) => {
    history.push(`/webinars/user/${item._id}`);
  };

  const onAddUsersToEvent = async (eventId) => {
    setShowAddUsersToEventDlg(false);
    const { list } = await apiW3UserList('User')(1, 100000, filter, ['_id'], '_id', 1);
    const userIds = list.map((x) => x._id);

    await apiPostData('/webinars3/users/add/to/event', {
      eventId,
      userIds,
    });

    message.success('Пользователи добавлены в вебинар');
  };

  return (
    <>
      <PageHeader
        className={styles.pageHeader}
        title={<GTooltip tid="webinars_users">Управление участниками</GTooltip>}
      />
      <Layout.Content>
        <div className={styles.contextHolder}>
          <div className={styles.context}>
            <XDataTable
              apiLoadData={apiW3UserList('User')}
              commonColumns={commonColumns()}
              extentedColumns={null}
              onSaveEventEmitter={onSaveEventEmmitter}
              initSort={['webinarUser.lastname', 1]}
              initFilter={{}}
              filterForm={filterFormWebinarsUser()}
              onRowClick={onRowClick}
              sioEvents={['LIST:USER', subscribeToChannel, unsubscribeToChannel]}
              searchProps={[
                'webinarUser.phone',
                'webinarUser.lastname',
                'webinarUser.firstname',
                'webinarUser.phone',
              ]}
              leftActions={[
                Object.keys(filter).length > 0 && {
                  icon: <FolderAddOutlined />,
                  tooltip: 'Добавить участников в вебинар/семинар',
                  onClick: () => setShowAddUsersToEventDlg(true),
                },
              ].filter(Boolean)}
              enableItemsSelect={{
                prop: '_id',
                type: 'number',
              }}
            />
          </div>
        </div>
      </Layout.Content>

      <WebinarsAddUsersToEventDlg
        visible={showAddUsersToEventDlg}
        onClose={() => setShowAddUsersToEventDlg(false)}
        onSelect={onAddUsersToEvent}
      />
    </>
  );
}
