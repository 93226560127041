import React, { useEffect, useRef, useState } from 'react';

import { Button, Modal } from 'antd';
import PropTypes from 'prop-types';

import XDataForm, { XDF_TYPE } from '../xdf-antd/XDataForm';
import styles from './styles.scss';
import { roundPlus } from '../../utils/utils';

const defModel = {
  round: 0,
  videoId: '',
  name: '',
};

function KybBlockYoutubeDlg({ visible, onSelect, onClose }) {
  const [model, setModel] = useState(defModel);

  const pic = useRef(null);

  useEffect(() => {
    if (!visible) {
      setModel(defModel);
    }
  }, [visible]);

  const onChangeModel = (m, is) => {
    setModel({
      ...m,
    });
  };

  const onSelectVideo = () => {
    const w = pic.current?.width || 400;
    const h = pic.current?.height || 300;
    onSelect({
      ...model,
      wh: roundPlus(w / h, 3),
    });
  };

  return (
    <Modal
      title="Вставка видео"
      open={visible}
      width={540}
      onCancel={onClose}
      footer={[
        <Button key="close" onClick={onClose}>
          Закрыть
        </Button>,
        <Button key="select" type="primary" disabled={!model.videoId} onClick={onSelectVideo}>
          Выбрать
        </Button>,
      ]}
    >
      <XDataForm
        model={model}
        onChangeModel={onChangeModel}
        form={[
          {
            type: XDF_TYPE.TEXT,
            label: 'Youtube ID видео',
            prop: 'videoId',
            span: 12,
            autoFocus: true,
          },
          {
            type: XDF_TYPE.SELECT,
            label: 'Форма',
            prop: 'round',
            values: [
              {
                id: 0,
                name: 'Прямоугольная',
              },
              {
                id: 1,
                name: 'Круглая',
              },
            ],
            span: 12,
          },
          {
            type: XDF_TYPE.TEXT,
            label: 'Описание видео',
            prop: 'name',
            span: 24,
          },
        ]}
      />
      {!!model.videoId && (
        <div className={styles.videoPreview}>
          <img
            ref={pic}
            src={`//img.youtube.com/vi/${model.videoId}/hqdefault.jpg`}
            alt="Video preview"
          />
        </div>
      )}
    </Modal>
  );
}

KybBlockYoutubeDlg.defaultProps = {};

KybBlockYoutubeDlg.propTypes = {
  visible: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default KybBlockYoutubeDlg;
