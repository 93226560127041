// Общие колонки, есть на всех видах
export function commonColumns() {
  return {
    name: 'Подсказки', // название
    props: [], // список полей для запроса к серверу
    columns: [
      {
        prop: '+_id',
        name: 'ID',
        sort: true,
        width: 160,
      },
      {
        prop: '+txt',
        name: 'Текст',
        sort: true,
      },
    ],
  };
}
