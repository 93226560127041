import { XDF_TYPE } from '../../../components/xdf-antd/XDataForm';
import { KYB_FAQ_SECTION, KYB_SITE_CATALOG_STATUS } from '../../../constants';

// eslint-disable-next-line import/prefer-default-export
export const commonColumns = () => ({
  name: null,
  props: [], // список полей для запроса к серверу
  rowStyle: (item) => {
    if (item.status <= 0) return { color: 'rgba(255,0,0,0.5)' };
    return {};
  },
  columns: [
    {
      prop: '+status',
      name: 'Статус',
      sort: true,
      nowrap: true,
      prop2value: [KYB_SITE_CATALOG_STATUS, 'id', 'name'],
    },
    {
      prop: '+state',
      name: 'Сортировка',
      sort: true,
    },
    {
      prop: '+name',
      name: 'Название',
      sort: true,
    },
    {
      prop: '+tags',
      name: 'Теги',
      sort: true,
    },
  ],
});

export const formModelEditor = () => [
  {
    type: XDF_TYPE.SELECT,
    label: 'Статус',
    prop: 'status',
    values: KYB_SITE_CATALOG_STATUS.filter((x) => [-1, 0, 1].includes(x.id)),
    span: 16,
  },
  {
    type: XDF_TYPE.NUMBER,
    label: 'Вес для сортировки',
    prop: 'state',
    span: 8,
  },
  {
    type: XDF_TYPE.VIDEO,
    label: 'Youtube ID видео или ссылку на Youtube видео',
    prop: 'file',
    span: 24,
  },
  {
    type: XDF_TYPE.TEXT,
    label: 'Описание видео',
    prop: 'name',
    span: 24,
  },
  {
    type: XDF_TYPE.TAGS,
    label: 'Теги',
    prop: 'tags',
    values: [],
    span: 24,
  },
];
