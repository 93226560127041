/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';

import { Button, Checkbox, Input, Layout } from 'antd';
import cn from 'classnames';
import dayjs from 'dayjs';
import { isBrowser } from 'react-device-detect';
import { useHistory, useParams } from 'react-router-dom';

import { ArrowLeftOutlined } from '@ant-design/icons';

import { apiPostData } from '../../../../api';
import { warningDialog } from '../../../components/Common/WarningDialog';
import GTooltip from '../../../components/GTooltip/GTooltip';
import styles from './styles.scss';

export default function W3TestRun() {
  const history = useHistory();
  const { eventId } = useParams();

  const [model, setModel] = useState({});
  const [result, setResult] = useState({});

  const fetchList = async () => {
    const { test } = await apiPostData('/webinars3/user/test/run', {
      eventId: parseInt(eventId, 10),
    });
    setModel(test);
  };

  useEffect(() => {
    fetchList();
  }, []);

  const onAnsTheQuestion = ({ target }, questionId, ansId) => {
    const { checked } = target;
    setResult({
      ...result,
      [`${questionId}.${ansId}`]: checked,
    });
  };

  const onFreeAns = ({ target }, questionId) => {
    const { value } = target;
    setResult({
      ...result,
      [`${questionId}`]: value,
    });
  };

  const sendTest = async () => {
    const res = await warningDialog(
      'Отправить на проверку',
      'Отменить это действие будет нельзя. Завершить сдачу теста и отправить его на проверку?',
      [{ label: 'Отмена' }, { label: 'Отправить' }],
    );

    if (res === 1) {
      // create & save
      const extRes = {
        eventId: parseInt(eventId, 10),
        questions: [],
      };

      for (const q of model.questions) {
        if (q.type === 0) {
          const answers = [];
          for (const ans of q.answers) {
            answers.push({
              id: ans.id,
              v: result[`${q.id}.${ans.id}`] ? 1 : 0,
            });
          }

          extRes.questions.push({
            id: q.id,
            type: q.type,
            answers,
          });
        } else if (q.type === 1) {
          extRes.questions.push({
            id: q.id,
            type: q.type,
            answer: result[`${q.id}`],
          });
        }
      }

      await apiPostData('/webinars3/user/test/done', {
        eventId: parseInt(eventId, 10),
        data: extRes,
      });

      history.replace('/events/finished');
    }
  };

  return (
    <>
      <div className={styles.pageHeader}>
        <span>
          <Button type="text" icon={<ArrowLeftOutlined />} onClick={() => history.goBack()} />
          &nbsp;
          <GTooltip tid="webinars_person_test_run">Прохождение теста</GTooltip>
        </span>
        {isBrowser && (
          <Button type="primary" onClick={sendTest}>
            Отправить на проверку
          </Button>
        )}
      </div>

      <Layout.Content>
        <div className={styles.contextHolder}>
          <div className={styles.context}>
            <div className={cn(styles.header, { [styles.full]: isBrowser })}>
              <h1>{model.name}</h1>
              <div>сдать можно до {dayjs(model.dtEnd).format('HH:mm, DD MMMM YYYY')}</div>
            </div>
            <div className={styles.body}>
              <div className={styles.desc}>{model.desc}</div>
              {(model.questions || []).map((question, i) => (
                <div key={question.id} className={styles.qBlk}>
                  <h2>ВОПРОС {i + 1}</h2>
                  <div className={styles.txt}>{question.txt}</div>
                  <div className={styles.desc}>{question.desc}</div>
                  {question.type === 0 && (
                    <div>
                      {(question.answers || []).map((ans) => (
                        <div key={ans.id} className={styles.ans}>
                          <Checkbox onChange={(e) => onAnsTheQuestion(e, question.id, ans.id)}>
                            {ans.txt}
                          </Checkbox>
                        </div>
                      ))}
                    </div>
                  )}
                  {question.type === 1 && (
                    <div style={{ padding: 4 }}>
                      <Input.TextArea
                        autoSize={{ minRows: 2, maxRows: 8 }}
                        onChange={(e) => onFreeAns(e, question.id)}
                        value={result[question.id]}
                        maxLength={1000}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
            {!isBrowser && (
              <div className={styles.footer}>
                <Button type="primary" onClick={sendTest}>
                  Отправить на проверку
                </Button>
              </div>
            )}
          </div>
        </div>
      </Layout.Content>
    </>
  );
}
