import { apiPost, ApiError } from '.';

/**
 * Запрос серий продуктов
 */
const apiEmailTemplateGet = async (id, props = ['name', 'state', 'code']) => {
  const { err, errmsg, data } = await apiPost('/any/get', {
    collection: 'EmailTemplate',
    role: 0,
    id,
    props,
  });
  if (err !== 0) throw ApiError(err, errmsg);
  return data;
};
export default apiEmailTemplateGet;
