import { apiPost, ApiError } from '.';

/**
 * Запрос серий продуктов
 */
const apiNotifyHideById = async (notifyId) => {
  const { err, errmsg } = await apiPost('/notify/hide/byid', { notifyId });
  if (err !== 0) throw ApiError(err, errmsg);
  return;
};
export default apiNotifyHideById;
