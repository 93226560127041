/* eslint-disable import/prefer-default-export */
import React from 'react';
import { BellOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import NotifyPopup from '../../../components/Common/NotifyPopup';

// Общие колонки, есть на всех видах
export const commonColumns = (notifyLastForRole, webinarSettings) => ({
  name: null,
  props: [], // список полей для запроса к серверу
  columns: [
    {
      prop: '_id',
      name: '',
      width: 35,
      align: 'center',
      excelHide: true,
      render: (item, val) => {
        const noti = notifyLastForRole[val];
        if (noti) return <NotifyPopup noti={noti} />;
        return <BellOutlined style={{ color: '#aaa' }} />;
      },
    },
    {
      prop: '+_id',
      name: 'ID',
      sort: true,
      nowrap: true,
    },
    {
      prop: '+status',
      name: 'Статус',
      sort: true,
      render: (item, val) => (
        <div style={{ marginLeft: 8 }}>
          {val === 0 ? <EyeInvisibleOutlined /> : <EyeOutlined />}
        </div>
      ),
    },
    {
      prop: '+_dt',
      name: 'Дата новости',
      sort: true,
      nowrap: true,
      type: 'date',
      format: 'DD.MM.YYYY',
    },
    {
      prop: '+title',
      name: 'Заголовок',
      sort: true,
      nowrap: true,
    },
    {
      prop: '+parts',
      name: 'Артикулы новости',
      nowrap: true,
      render: (item, val) => (val || []).map((x) => x.part).join(', '),
    },
  ],
});

// Состояния новостей
export const NPIA_STATE = [
  {
    id: -1,
    name: 'Удалена',
  },
  {
    id: 0,
    name: 'Не опубликовано',
  },
  {
    id: 1,
    name: 'Опубликовано',
  },
];

// доступ
export const NPIA_ACCESS = [
  {
    id: 0,
    name: 'Доступно всем',
  },
];

export const DATE_FORMAT = 'DD.MM.YYYY';

// part types
export const PART_TYPES = [
  'Амортизатор',
  'Пружина',
  'Пыльник',
  'Верх. опора',
  'Подшипник',
  'Стойка стабилизатора',
  'Шаровая опора',
  'Наконечник рулевой тяги',
  'Рулевая тяга',
]
  .sort()
  .map((x) => ({
    id: x,
    name: x,
  }));
