import React from 'react';

import { BellOutlined, EditOutlined } from '@ant-design/icons';

import apiAnyPropList from '../../../../api/AnyPropList';
import NotifyPopup from '../../../components/Common/NotifyPopup';
import { XDF_TYPE } from '../../../components/xdf-antd/XDataForm/index';
import { PART_TYPES } from '../../News/NewProducts/table-defs';

// Общие колонки, есть на всех видах
export function commonColumns(notifyLastForRole) {
  return {
    name: null, // 'Кроссы', // название
    props: [], // список полей для запроса к серверу
    columns: [
      {
        prop: '_id',
        name: '',
        width: 35,
        align: 'center',
        excelHide: true,
        render: (item, val) => {
          const noti = notifyLastForRole[val];
          if (noti) return <NotifyPopup noti={noti} />;
          return <BellOutlined style={{ color: '#aaa' }} />;
        },
      },
      {
        prop: '_id',
        name: '',
        width: 35,
        align: 'center',
        excelHide: true,
        render: (item, val) => <EditOutlined />,
      },
      {
        prop: '+startMonth',
        name: 'Начало (м)',
        excelColName: 'Месяц начала выпуска',
        sort: true,
        inline: true,
      },
      {
        prop: '+startYear',
        name: 'Начало (г)',
        excelColName: 'Год начала выпуска',
        sort: true,
        inline: true,
      },
      {
        prop: '+endMonth',
        name: 'Конец (м)',
        excelColName: 'Месяц окончания выпуска',
        sort: true,
        inline: true,
      },
      {
        prop: '+endYear',
        name: 'Конец (г)',
        excelColName: 'Год окончания выпуска',
        sort: true,
        inline: true,
      },
      {
        prop: '+endYear2',
        name: 'Конец (г) условно',
        excelColName: 'Год окончания выпуска (условно)',
        sort: true,
        inline: true,
      },
      {
        prop: '+firm',
        name: 'Марка',
        sort: true,
        nowrap: true,
        inline: true,
      },
      {
        prop: '+model',
        name: 'Модель',
        sort: true,
        nowrap: true,
        inline: true,
      },
      {
        prop: '+bodyType',
        name: 'Тип кузова',
        sort: true,
        nowrap: true,
        inline: true,
        excelRender: (it, val) => (val ? val.map((x) => `[${x}]`).join(' ') : ''),
      },
      {
        prop: '+catalog',
        name: 'Каталог',
        sort: true,
        inline: true,
      },
      {
        prop: '+fr',
        name: 'Перед/зад',
        sort: true,
        inline: true,
      },
      {
        prop: '+lr',
        name: 'Левый/правый',
        sort: true,
        inline: true,
      },
      {
        prop: '+partNumber',
        name: 'Артикул KYB',
        sort: true,
        inline: true,
      },
      {
        prop: '+info',
        name: '*',
        align: 'center',
        sort: true,
        render: (item, val) => (val ? '*' : ''),
        inline: true,
      },
      {
        prop: '+part',
        name: 'Деталь',
        sort: true,
        inline: true,
      },
      {
        prop: '+series',
        name: 'Серия KYB',
        sort: true,
        inline: true,
      },
      {
        prop: '+type',
        name: 'Тип',
        sort: true,
        nowrap: true,
        inline: true,
      },
      {
        prop: '+codes',
        name: 'Код модели, двигателя, кузова',
        sort: true,
        inline: true,
      },
      {
        prop: '+version',
        name: 'Версия',
        sort: true,
        nowrap: true,
        inline: true,
      },
    ],
  };
}

export function mainCatalogFormProps(model0) {
  return [
    {
      type: XDF_TYPE.NUMBER,
      label: 'Месяц начала',
      prop: 'startMonth',
      span: 6,
      autoFocus: true,
    },
    {
      type: XDF_TYPE.NUMBER,
      label: 'Год начала',
      prop: 'startYear',
      span: 4,
    },
    {
      type: XDF_TYPE.NUMBER,
      label: 'Месяц конца',
      prop: 'endMonth',
      span: 4,
    },
    {
      type: XDF_TYPE.TEXT,
      label: `Год конца ${model0 ? model0.endYear0 : ''}`,
      prop: 'endYear',
      span: 4,
    },
    {
      type: XDF_TYPE.TEXT,
      label: 'Год конца (условно)',
      prop: 'endYear2',
      span: 6,
    },
    {
      type: XDF_TYPE.SELECT,
      label: 'Марка',
      prop: 'firm',
      values: apiAnyPropList('KybCatalog:Main', 'firm'),
      span: 8,
    },
    {
      type: XDF_TYPE.TEXT,
      label: 'Модель',
      prop: 'model',
      span: 16,
    },
    {
      type: XDF_TYPE.MULTISELECT,
      label: 'Тип кузова',
      prop: 'bodyType',
      values: apiAnyPropList('KybCatalog:Main', 'bodyType'),
      span: 24,
    },
    {
      type: XDF_TYPE.SELECT,
      label: 'Каталог',
      prop: 'catalog',
      values: apiAnyPropList('KybCatalog:Main', 'catalog'),
      span: 4,
    },
    {
      type: XDF_TYPE.SELECT,
      label: 'Перед/зад',
      prop: 'fr',
      values: apiAnyPropList('KybCatalog:Main', 'fr'),
      span: 4,
    },
    {
      type: XDF_TYPE.SELECT,
      label: 'Левый/правый',
      prop: 'lr',
      values: apiAnyPropList('KybCatalog:Main', 'lr'),
      span: 4,
    },
    {
      type: XDF_TYPE.TEXT,
      label: 'Артикул KYB',
      prop: 'partNumber',
      span: 8,
    },
    {
      type: XDF_TYPE.SWITCH,
      label: '*',
      prop: 'info',
      boolType: true,
      span: 4,
    },
    {
      type: XDF_TYPE.SELECT,
      label: 'Деталь',
      prop: 'part',
      // values: apiAnyPropList('KybCatalog:Main', 'part'),
      values: PART_TYPES,
      span: 8,
    },
    {
      type: XDF_TYPE.SELECT,
      label: 'Серия KYB',
      prop: 'series',
      values: apiAnyPropList('KybCatalog:Main', 'series'),
      span: 8,
    },
    {
      type: XDF_TYPE.SELECT,
      label: 'Тип',
      prop: 'type',
      values: apiAnyPropList('KybCatalog:Main', 'type'),
      span: 8,
    },
    {
      type: XDF_TYPE.TEXT,
      label: 'Код модели, двигателя, кузова',
      prop: 'codes',
      span: 24,
    },
    {
      type: XDF_TYPE.TEXT,
      label: 'Версия',
      prop: 'version',
      span: 24,
    },
  ];
}
