// Общие колонки, есть на всех видах
// eslint-disable-next-line import/prefer-default-export
export const commonColumns = () => ({
  name: null, // 'Кроссы', // название
  props: [], // список полей для запроса к серверу
  columns: [
    // {
    //   prop: '_id',
    //   name: '',
    //   width: 35,
    //   align: 'center',
    //   excelHide: true,
    //   render: (item, val) => {
    //     const noti = notifyLastForRole[val];
    //     if (noti) return <NotifyPopup noti={noti} />;
    //     return <BellOutlined style={{ color: '#aaa' }} />;
    //   },
    // },
    {
      prop: '+_id',
      name: '#',
      sort: true,
    },
    {
      prop: '+name',
      name: 'Название теста',
      sort: true,
      width: '100%',
    },
    {
      prop: 'eventsCount',
      name: 'Используется в мепроприятиях',
      sort: true,
    },
    {
      prop: 'questionsCount',
      name: 'Вопросов',
      sort: true,
    },
  ],
});
