// eslint-disable-next-line import/prefer-default-export
export const EDITOR_HELP_MACROS = [
  {
    id: 'EMAIL_TRACE',
    txt: 'Отслеживание открытия Email, в письмо вставляется ссылка на картинку 1x1 пиксел, с уникальным URL',
  },
  {
    id: 'TMPL.название_шаблона',
    txt: 'Вставка другого шаблона в данный, обычно используется для стандартизации HEADER/FOOTER',
  },
  {
    id: 'SYS.kyb_system_domain',
    txt: 'Домен сайта KYB System',
  },
  {
    id: 'SYS.approve_email_link',
    txt: 'Ссылка для подтврждения Email пользователя',
  },
  {
    id: 'USER.name',
    txt: 'Имя пользователя',
  },
  {
    id: 'USER.lastName',
    txt: 'Фамилия пользователя',
  },
  {
    id: 'USER.midName',
    txt: 'Отчество пользователя',
  },
  {
    id: 'USER.fullName',
    txt: 'ФИО пользователя',
  },
  {
    id: 'USER.phone',
    txt: 'Телефон пользователя',
  },
  {
    id: 'USER.email',
    txt: 'Email пользователя',
  },
];
