import React, { useEffect, useState } from 'react';

import { Button, Layout, PageHeader } from 'antd';
import dayjs from 'dayjs';
import { useRecoilValue } from 'recoil';

import { apiPostData } from '../../../api';
import { meState } from '../../../state/panel-state';
import XDataForm from '../../components/xdf-antd/XDataForm';
import { USER_XD_FORM } from '../Common/user-form';
import styles from './styles.scss';

export default function Webinars3UserProfile() {
  const me = useRecoilValue(meState);

  const [model, setModel] = useState({});
  const [isModelChanged, setIsModelChanged] = useState(false);

  useEffect(() => {
    setModel({ ...me.webinarUser });
  }, [me?.webinarUser?.phone]);

  const onChangeModel = (m, ism) => {
    setModel(m);
    setIsModelChanged(ism);
  };

  const onSaveForm = async () => {
    await apiPostData('/webinars3/user/save', {
      ...model,
      timezone: dayjs().format('Z'),
    });
    setIsModelChanged(false);
  };

  return (
    <>
      <PageHeader
        className={styles.pageHeader}
        title="Профиль"
        extra={
          <Button key="save" type="primary" disabled={!isModelChanged} onClick={onSaveForm}>
            Сохранить
          </Button>
        }
      />
      <Layout.Content>
        <div className={styles.contextHolder}>
          <div className={styles.context} style={{ maxWidth: 450 }}>
            <XDataForm
              model={model}
              onChangeModel={onChangeModel}
              form={USER_XD_FORM(true, true)}
            />
          </div>
        </div>
      </Layout.Content>
    </>
  );
}
