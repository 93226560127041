import React from 'react';

import cn from 'classnames';

import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import styles from './styles.scss';

function SortableItem({
  id,
  value,
  picWidth,
  basePicUrl,
  onRemovePic,
  onEditDesc,
  roundImage,
  picDesc,
  disabled,
}) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    width: roundImage ? picWidth + 2 : 'auto',
    height: picWidth + 2,
    margin: '8px',
    zIndex: isDragging ? '100' : 'auto',
    opacity: isDragging ? 0.3 : 1,
  };

  return (
    <div
      ref={setNodeRef}
      className={cn(styles.photo, { [styles.noEdit]: !onEditDesc })}
      style={style}
    >
      <div className={cn({ [styles.round]: roundImage })}>
        <img src={`${basePicUrl}h/${picWidth}/${value}`} {...listeners} {...attributes} alt="pic" />

        {!disabled && (
          <button
            type="button"
            className={styles.remove}
            title="Удалить"
            onClick={() => onRemovePic(id)}
          >
            <DeleteOutlined />
          </button>
        )}

        {!!onEditDesc && (
          <button
            type="button"
            className={styles.edit}
            title="Редактировать описание"
            onClick={() => onEditDesc(id)}
          >
            <EditOutlined />
          </button>
        )}
      </div>
      {!!picDesc && <div className={styles.picDesc}>{picDesc}</div>}
    </div>
  );
}

export default SortableItem;
