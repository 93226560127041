import React, { useState } from 'react';

import { Button, Col, Modal } from 'antd';
import cn from 'classnames';
import gv from 'get-value';
import PropTypes from 'prop-types';

import XDataForm from '.';
import styles from './styles.scss';

const filterIcon = (
  <svg
    stroke="currentColor"
    fill="none"
    strokeWidth="2"
    viewBox="0 0 24 24"
    strokeLinecap="round"
    strokeLinejoin="round"
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
  </svg>
);

function XDFModalTableForm({ it, size, model, onPropChanged }) {
  const { id, label, prop, title, btnIcon, span, readOnly, values } = it;

  const [showDialog, setShowDialog] = useState(false);
  const [filter, setFilter] = useState({});

  function onShowFilterDialog(ev) {
    const f = gv(model, prop, {});
    setFilter(f);
    setShowDialog(true);
  }

  function onSaveFilter(ev) {
    setShowDialog(false);
    onPropChanged(it, filter);
  }

  function onCloseDialog(ev) {
    setShowDialog(false);
  }

  function onFilterChanged(f) {
    setFilter(f);
  }

  return (
    <>
      <Col span={span || 24}>
        <div className={styles.formItem}>
          {!!label && (
            <div className={styles.formItemLabel}>
              <label htmlFor={id}>{label}</label>
            </div>
          )}
          <div className={cn(styles.formItemField, { [styles.small]: size === 'small' })}>
            <Button
              id={id}
              size={size}
              disabled={!!readOnly}
              icon={btnIcon || filterIcon}
              onClick={onShowFilterDialog}
            />
          </div>
        </div>
      </Col>

      {showDialog && (
        <Modal
          title={title || 'Фильтр'}
          open
          onOk={onSaveFilter}
          onCancel={onCloseDialog}
          width={700}
          footer={[
            <Button key="cancel" onClick={onCloseDialog}>
              Отменить
            </Button>,
            <Button key="ok" type="primary" onClick={onSaveFilter}>
              Сохранить
            </Button>,
          ]}
        >
          <XDataForm
            table
            size="small"
            model={filter}
            onChangeModel={onFilterChanged}
            form={values}
          />
        </Modal>
      )}
    </>
  );
}

XDFModalTableForm.propTypes = {
  it: PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    prop: PropTypes.string.isRequired,
    title: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    btnIcon: PropTypes.element,
    span: PropTypes.number,
    readOnly: PropTypes.bool,
  }).isRequired,
  size: PropTypes.string.isRequired,
  model: PropTypes.shape({}).isRequired,
  onPropChanged: PropTypes.func.isRequired,
};

export default XDFModalTableForm;
