// Общие колонки, есть на всех видах

// eslint-disable-next-line import/prefer-default-export
export function commonColumns() {
  return {
    name: null,
    props: ['pic', 'url', 'bannerOn'], // список полей для запроса к серверу
    columns: [
      {
        prop: '+_id',
        name: 'Тип',
        sort: true,
        nowrap: true,
      },
      {
        prop: '+name',
        name: 'Название',
        sort: true,
      },
      {
        prop: '+title',
        name: 'Заголовок',
        sort: true,
      },
      {
        prop: '+desc',
        name: 'Краткое описание',
        sort: true,
      },
    ],
  };
}
