import React from 'react';

import { Col } from 'antd';
import cn from 'classnames';
import gv from 'get-value';
import PropTypes from 'prop-types';

import XDataGallery from '../XDataGallery/XDataGallery';
import styles from './styles.scss';

function XDFGalleryUpload({ it, size, model, onPropChanged }) {
  const {
    id,
    prop,
    label,
    span,
    readOnly,
    picsProps,
    picSize,
    maxCount,
    disableDescription,
    roundImage,
    simpleArray,
    aspects,
  } = it;

  const [picId, picName, picDesc] = picsProps || [];

  const val = gv(model, prop) || [];
  const picsList = (val || []).map((x) => {
    if (simpleArray)
      return {
        id: x,
        filename: x,
        desc: x,
      };
    return {
      id: x[picId || 'id'] || x[picName || 'name'],
      filename: x[picName || 'name'],
      desc: x[picDesc || 'desc'],
    };
  });

  const onPicListChanged = (plist) => {
    const pics = plist.map((x) => {
      if (simpleArray) return x.filename;

      // convert
      const v = {};
      if (picId !== '$$$') v[picId || 'id'] = x.id;
      if (picName !== '$$$') v[picName || 'name'] = x.filename;
      if (picDesc !== '$$$') v[picDesc || 'desc'] = x.desc;
      return v;
    });
    // console.log('onPicListChanged', pics);
    onPropChanged(it, pics);
  };

  return (
    <Col span={span || 24}>
      <div className={styles.formItem}>
        <div className={styles.formItemLabel}>
          <label htmlFor={id}>
            {label}
            {require && <sup>*</sup>}
          </label>
        </div>
        <div className={cn(styles.formItemField)}>
          <XDataGallery
            picWidth={picSize || 162}
            basePicUrl="/gi/"
            onChange={onPicListChanged}
            items={picsList}
            maxCount={maxCount}
            disableDescription={disableDescription || false}
            roundImage={roundImage || false}
            aspects={aspects}
          />
        </div>
      </div>
    </Col>
  );
}

XDFGalleryUpload.propTypes = {
  it: PropTypes.shape({
    id: PropTypes.string.isRequired,
    prop: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    span: PropTypes.number,
    readOnly: PropTypes.bool,
    picsProps: PropTypes.arrayOf(PropTypes.string.isRequired),
    picSize: PropTypes.number,
    maxCount: PropTypes.number,
    disableDescription: PropTypes.bool,
    roundImage: PropTypes.bool,
    simpleArray: PropTypes.bool,
    aspects: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        wph: PropTypes.number,
      }),
    ),
  }).isRequired,
  model: PropTypes.shape({}).isRequired,
  onPropChanged: PropTypes.func.isRequired,
};

export default XDFGalleryUpload;
