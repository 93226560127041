import React from 'react';

import PropTypes from 'prop-types';

import KybLoginDialog from './KybLoginDialog/KybLoginDialog';

function LoginDialog({ visible }) {
  return <KybLoginDialog visible={visible} />;
}

LoginDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
};

export default LoginDialog;
