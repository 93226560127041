import React from 'react';

import { Button, Layout, PageHeader } from 'antd';
import { useHistory } from 'react-router-dom';

import apiAnyList from '../../../../api/AnyList';
import { subscribeToChannel, unsubscribeToChannel } from '../../../../api/sio';
import GTooltip from '../../../components/GTooltip/GTooltip';
import XDataTable from '../../../components/xdf-antd/XDataTable';
import styles from './styles.scss';
import { commonColumns } from './table-defs';

export default function CommonEmailList() {
  // router
  const history = useHistory();
  // const location = useLocation();
  // const { proudctId } = useParams();

  // const v = apiAnyList('User');
  // debugger

  const createNewTemplate = () => {
    history.push('/common/email/template/_');
  };

  const onRowClick = (item) => {
    history.push(`/common/email/template/${item._id}`);
  };

  return (
    <>
      <PageHeader
        className={styles.pageHeader}
        title={<GTooltip tid="email_tmpl_list">Список шаблонов Email</GTooltip>}
        extra={[
          <Button key="new" onClick={createNewTemplate}>
            Создать новый шаблон
          </Button>,
        ]}
      />
      <Layout.Content>
        <div className={styles.contextHolder}>
          <div className={styles.context}>
            <XDataTable
              apiLoadData={apiAnyList('EmailTemplate')}
              commonColumns={commonColumns()}
              extentedColumns={null}
              onRowClick={onRowClick}
              sioEvents={['LIST:EMAIL_TMPL', subscribeToChannel, unsubscribeToChannel]}
              initSort={['_id', 1]}
            />
          </div>
        </div>
      </Layout.Content>
    </>
  );
}
