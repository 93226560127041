import React from 'react';

import { BellOutlined } from '@ant-design/icons';

import NotifyPopup from '../../../components/Common/NotifyPopup';

// Общие колонки, есть на всех видах
// eslint-disable-next-line import/prefer-default-export
export const commonColumns = (notifyLastForRole) => ({
  name: null, // 'Аналоги', // название
  props: [], // список полей для запроса к серверу
  columns: [
    {
      prop: '_id',
      name: '',
      width: 35,
      align: 'center',
      excelHide: true,
      render: (item, val) => {
        const noti = notifyLastForRole[val];
        if (noti) return <NotifyPopup noti={noti} />;
        return <BellOutlined style={{ color: '#aaa' }} />;
      },
    },
    {
      prop: '+productId',
      name: 'Артикул',
      sort: true,
    },
    {
      prop: '+productSeries',
      name: 'Серия аналога',
      sort: true,
    },
    {
      prop: '+productAnalog',
      name: 'Аналог',
      sort: true,
    },
  ],
});
