import React, { useEffect, useState } from 'react';

import { Modal } from 'antd';
import PropTypes from 'prop-types';

import XDataForm, { XDF_TYPE } from '../../../components/xdf-antd/XDataForm';
import { KYB_SITE_ITEM_STATUS } from '../../../constants';

const defModel = {
  year: 2021,
  name: '',
  desc: '',
  pic: '',
};

function KybHistoryEditorDlg({ model, onClose, onSave }) {
  const [model0, setModel0] = useState(defModel);
  const [isModelChanged, setIsModelChanged] = useState(false);

  useEffect(() => {
    if (model) {
      setModel0({ ...defModel, ...model });
    } else {
      setModel0(defModel);
      setIsModelChanged(false);
    }
  }, [model]);

  const onChangeModel = (m, isc) => {
    setModel0({ ...m });
    setIsModelChanged(isc);
  };

  return (
    <Modal
      title="Редактированеи записи"
      open={!!model}
      onCancel={onClose}
      okText="Сохранить"
      okButtonProps={{ disabled: !isModelChanged }}
      onOk={() => onSave(model0)}
    >
      {!!model?._id && (
        <XDataForm
          // debug
          model={model0}
          onChangeModel={onChangeModel}
          form={[
            {
              type: XDF_TYPE.NUMBER,
              label: 'Год',
              prop: 'year',
              span: 12,
            },
            {
              type: XDF_TYPE.SELECT,
              label: 'Статус новости',
              prop: 'status',
              values: KYB_SITE_ITEM_STATUS,
              span: 12,
            },
            {
              type: XDF_TYPE.TEXT,
              label: 'Название (текст под стрелкой справа)',
              prop: 'name',
              span: 24,
              rows: 3,
              autoFocus: true,
            },
            {
              type: XDF_TYPE.TEXTAREA,
              label: 'Описание',
              prop: 'desc',
              span: 24,
              rows: 5,
            },
            {
              type: XDF_TYPE.PICTURE_UPLOAD,
              label: 'Картинка',
              prop: 'pic',
              roundImage: true,
              span: 24,
            },
          ]}
        />
      )}
    </Modal>
  );
}

KybHistoryEditorDlg.defaultProps = {
  model: null,
};

KybHistoryEditorDlg.propTypes = {
  model: PropTypes.shape({}),
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default KybHistoryEditorDlg;
