/* eslint-disable no-undef */
import React, { useState, useEffect, useRef } from 'react';

import { Button, Col, message, Modal, Progress, Row, Upload } from 'antd';
import PropTypes from 'prop-types';
import { UploadOutlined } from '@ant-design/icons';

import styles from './styles.scss';
import { useXDFScript } from '../XDataForm/xdf-hooks';
import { xdRoundPlus } from './xdf-utils';
import { EXCELJS_URL } from './XDTExportToExcel';
import { apiPost } from '../../../../api';

const LOAD_STEP = 100;

const cellValue = (cell) => {
  if (cell.value === null) {
    return '';
  }
  if (cell.value instanceof Object && cell.value.result !== undefined) {
    return cell.value.result;
  }
  if (cell.type === ExcelJS.ValueType.Number) {
    return xdRoundPlus(cell.value);
  }
  return cell.value;
};

function XDImportExcelFile({
  visible,
  onClose,
  uploadApi,
  initObject,
  columns,
  importBtnTitle,
  maxStepToLoad,
}) {
  const [doneExcelJS] = useXDFScript(visible && EXCELJS_URL);

  const wb = useRef(null);
  const ws = useRef(null);
  const uploadData = useRef(null);

  const [total, setTotal] = useState(0);
  const [currentPos, setCurrentPos] = useState(0);
  const [loading, setLoading] = useState(false);
  const [readyForUpload, setReadyForUpload] = useState(false);
  const [uploading, setUploading] = useState(false);

  const uploadDataToServer = async () => {
    setUploading(true);
    const err = await uploadApi(uploadData.current.slice(1));
    if (err) {
      message.error(`Upload error: ${err}`);
    } else {
      setTimeout(() => {
        onClose();
      }, 200);
    }
  };

  // init -> open dlg
  useEffect(() => {
    if (visible) {
      wb.current = null;
      ws.current = null;
      uploadData.current = [];
      setTotal(0);
      setCurrentPos(0);
      setLoading(false);
      setReadyForUpload(false);
      setUploading(false);
    }
  }, [visible]);

  // start read
  useEffect(() => {
    if (visible && ws.current) {
      if (currentPos === total) {
        // end
        setReadyForUpload(true);
      } else {
        setTimeout(() => {
          let pos = 0;
          while (currentPos + pos < total) {
            const row = ws.current.getRow(currentPos + pos + 1);
            const it = { ...initObject };
            // eslint-disable-next-line no-loop-func
            columns.forEach((col, idx) => {
              if (currentPos + pos === 0) {
                it[col.prop] = col.prop ? cellValue(row.getCell(idx + 1)) : '';
              } else if (col.prop) {
                if (col.splitBy) {
                  it[col.prop] = cellValue(row.getCell(idx + 1))
                    .split(col.splitBy)
                    .map((x) => x.trim());
                } else if (col.type === 'float') {
                  it[col.prop] = parseFloat(cellValue(row.getCell(idx + 1)));
                  if (col.minMax) {
                    if (it[col.prop] < col.minMax[0] || it[col.prop] > col.minMax[1]) {
                      alert(`Занчение ${col.prop} выходит за min/max: ${it[col.prop]}`);
                      onClose();
                    }
                  }
                } else {
                  it[col.prop] = cellValue(row.getCell(idx + 1));
                }
              }
            });
            uploadData.current.push(it);

            pos += 1;
            if (pos > maxStepToLoad) break;
          }
          setCurrentPos(currentPos + pos);
        }, 10);
      }
    }
  }, [visible, ws.current, currentPos, total]);

  const decodeXlsxFile = (buffer) => {
    setLoading(true);
    wb.current = new ExcelJS.Workbook();
    wb.current.xlsx.load(buffer).then(() => {
      wb.current.eachSheet((_ws) => {
        if (ws.current === null) {
          ws.current = _ws;
          uploadData.current = [];
          setTotal(ws.current.rowCount);
          setCurrentPos(0);
          setUploading(false);
        }
      });
    });
  };

  // const someFunction = useCallback(() => {
  //   console.log(title);
  // }, [title]);

  const beforeUpload = (file) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      decodeXlsxFile(reader.result);
    });
    reader.readAsArrayBuffer(file);
    return false;
  };

  return (
    <Modal
      open={visible}
      title="Импорт данных из Excel файла"
      onCancel={onClose}
      destroyOnClose
      cancelButtonProps={{
        disabled: uploading,
      }}
      okButtonProps={{
        disabled: !readyForUpload || uploading,
      }}
      okText="Импорт данные"
      onOk={uploadDataToServer}
      closable={false}
      maskClosable={false}
      width={700}
    >
      <Upload
        className={styles.uploadFormBtn}
        accept=".xlsx"
        maxCount="1"
        beforeUpload={beforeUpload}
        defaultFileList={[]}
        disabled={!doneExcelJS || loading}
        showUploadList={{
          showRemoveIcon: false,
        }}
      >
        <Button icon={<UploadOutlined />} style={{ width: '100%' }} disabled={loading}>
          {importBtnTitle}
        </Button>
      </Upload>
      {!readyForUpload && total > 0 && (
        <div className={styles.progressImport}>
          <Progress percent={xdRoundPlus((100 * currentPos) / total, 0)} size="small" />
        </div>
      )}
      {readyForUpload && (
        <div className={styles.progressInfo}>
          <div className={styles.msg}>Загружено {total} строк</div>
          <div className={styles.table}>
            <table>
              <thead>
                <tr>
                  <th>Поле в БД</th>
                  <th>Столбец в Xlsx</th>
                  <th>Строка 1</th>
                  <th>Строка 2</th>
                  <th>Строка 3</th>
                </tr>
              </thead>
              <tbody>
                {columns.map((col) => {
                  const rows = [];
                  rows.push(<td key="z">{col.prop}</td>);
                  for (let i = 0; i < 4; i += 1) {
                    rows.push(<td key={i}>{uploadData.current[i][col.prop]}</td>);
                  }
                  return <tr key={col.prop}>{rows}</tr>;
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </Modal>
  );
}

XDImportExcelFile.defaultProps = {
  importBtnTitle: 'Укажите файл для импорта',
  maxStepToLoad: LOAD_STEP,
  initObject: {},
};

XDImportExcelFile.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  uploadApi: PropTypes.func.isRequired,
  importBtnTitle: PropTypes.string,
  maxStepToLoad: PropTypes.number,
  initObject: PropTypes.shape({}),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      prop: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

export default XDImportExcelFile;
