import React from 'react';

import { Popover } from 'antd';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import { BellFilled } from '@ant-design/icons';

import styles from './styles.scss';

function NotifyPopup({ noti }) {
  return (
    <Popover
      placement="right"
      content={
        <div className={styles.notifyItem}>
          <div className={styles.ndt}>{dayjs(noti.cdt).format('DD.MM / HH:mm:ss')}</div>
          <div className={styles.ntxt}>{noti.txt}</div>
        </div>
      }
    >
      <BellFilled style={{ color: '#cc0033' }} />
    </Popover>
  );
}

NotifyPopup.propTypes = {
  noti: PropTypes.shape({
    cdt: PropTypes.string.isRequired,
    txt: PropTypes.string.isRequired,
  }).isRequired,
};

export default NotifyPopup;
