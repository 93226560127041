/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';

import { Layout } from 'antd';

import { apiPostData } from '../../../../api';
import GTooltip from '../../../components/GTooltip/GTooltip';
import styles from './styles.scss';
import W3FinishedEventCard from './W3FinishedEventCard';

export default function WebinarEventsFinishedList() {
  const [eventsList, setEventsList] = useState([]);

  const fetchList = async () => {
    const { list } = await apiPostData('/webinars3/user/event/list', { state: 1 });
    setEventsList(list);
  };

  useEffect(() => {
    fetchList();
  }, []);

  return (
    <>
      <div className={styles.pageHeader}>
        <GTooltip tid="webinars_preson_events_finished">
          Завершённые вебинары, семинары. Полученные сертификаты.
        </GTooltip>
      </div>

      <Layout.Content>
        <div className={styles.contextHolder}>
          {eventsList.map((it) => (
            <W3FinishedEventCard key={it.__event._id} event={it.__event} test={it.test} />
          ))}
        </div>
      </Layout.Content>
    </>
  );
}
