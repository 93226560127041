import React, { useState } from 'react';

import { Button, Col, Collapse, Modal, Row } from 'antd';
import PropTypes from 'prop-types';

import { CheckOutlined } from '@ant-design/icons';

import { roundPlus } from '../../../utils/utils';
import styles from './styles.scss';

function WebinarsEventResults0({ open, onClose, test, answersCount, users, resultsMatrix }) {
  const [showQ, setShowQ] = useState([]);

  const onShowQ = (k) => {
    setShowQ(k);
  };

  return (
    <Modal
      open={open}
      title="Просмотр выбранных ответов на вопросы"
      onCancel={onClose}
      width="80%"
      footer={[
        <Button key="close" onClick={onClose}>
          Закрыть
        </Button>,
      ]}
    >
      {test?.questions?.length > 0 && (
        <Collapse activeKey={showQ} onChange={onShowQ}>
          {test.questions
            .filter((item) => item.type === 0)
            .map((item, idx) => (
              <Collapse.Panel
                key={item.id}
                header={`Вопрос ${idx + 1}`}
                extra={[
                  item.type === 1 && (
                    <span key="type" className={styles.atype1}>
                      не оценивается
                    </span>
                  ),
                ].filter(Boolean)}
              >
                <div className={styles.qBlock}>
                  <Row>
                    <Col span={24}>
                      <div className={styles.vtitle}>Вопрос</div>
                      <div className={styles.val}>{item.txt}</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <div className={styles.vtitle}>Пояснение</div>
                      <div className={styles.val}>{item.desc}</div>
                    </Col>
                  </Row>
                  {item.type === 0 && (
                    <table>
                      <thead>
                        <tr>
                          <th>id</th>
                          <th>Правильный ответ</th>
                          <th>Ответ</th>
                          {answersCount && (
                            <th style={{ width: 70, textAlign: 'center' }}>% ответов</th>
                          )}
                          {resultsMatrix && (
                            <th style={{ width: 70, textAlign: 'center' }}>Выбран ответ</th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {item.answers.map((ans, i) => (
                          <tr key={ans.id}>
                            <td>{i + 1}</td>
                            <td>
                              {ans.type === 1 ? <CheckOutlined style={{ color: 'red' }} /> : ''}
                            </td>
                            <td>{ans.txt}</td>
                            {answersCount && (
                              <td style={{ width: 70, textAlign: 'center' }}>
                                {users === 0
                                  ? ''
                                  : roundPlus(
                                      (100 * answersCount[`${item.id}|${ans.id}`]) / users,
                                      0,
                                    ) === 0
                                  ? ''
                                  : roundPlus(
                                      (100 * answersCount[`${item.id}|${ans.id}`]) / users,
                                      0,
                                    )}
                              </td>
                            )}
                            {resultsMatrix && (
                              <td style={{ width: 70, textAlign: 'center' }}>
                                {resultsMatrix[`${item.id}|${ans.id}`] === 1 ? (
                                  <CheckOutlined />
                                ) : (
                                  ''
                                )}
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </Collapse.Panel>
            ))}
        </Collapse>
      )}
    </Modal>
  );
}

WebinarsEventResults0.defaultProps = {
  answersCount: null,
  users: 0,
  resultsMatrix: null,
};

WebinarsEventResults0.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  test: PropTypes.shape({
    questions: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  answersCount: PropTypes.shape({}),
  users: PropTypes.number,
  resultsMatrix: PropTypes.shape({}),
};

export default WebinarsEventResults0;
