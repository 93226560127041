import React, { useRef, useState } from 'react';

import { Button, Layout, PageHeader } from 'antd';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';

import { EnvironmentOutlined, UsergroupAddOutlined, VideoCameraOutlined } from '@ant-design/icons';

import { subscribeToChannel, unsubscribeToChannel } from '../../../../api/sio';
import apiWebinar3EventList from '../../../../api/Webinar3EventList';
import GTooltip from '../../../components/GTooltip/GTooltip';
import XDataTable, { xdGetTableUrlByParams } from '../../../components/xdf-antd/XDataTable';
import styles from './styles.scss';
import { commonColumns, filterFormWebinarsEvents } from './table-defs';

const DATES_FORMAT = 'DD.MM.YYYY';

export default function WebinarsEventsList() {
  const history = useHistory();

  const tableEmitter = useRef(null);
  const [filter, setFilter] = useState({});

  const onSaveEventEmmitter = (ee) => {
    tableEmitter.current = ee;
    tableEmitter.current?.addListener('FILTER-CHANGED', (f) => {
      setFilter(f);
    });
  };

  const onRowClick = (item) => {
    history.push(`/webinars/event/edit/${item._id}`);
  };

  const addSeminar = () => {
    history.push('/webinars/event/seminar/0');
  };
  const addWebiinar = () => {
    history.push('/webinars/event/webinar/0');
  };

  const selectUsersByEvents = async () => {
    const { list } = await apiWebinar3EventList()(1, 10000, filter, ['_id'], '_id', 1);
    const link = xdGetTableUrlByParams('/webinars/users', {
      _eventsIds: list.map((x) => x._id),
    });
    history.push(link);
  };

  return (
    <>
      <PageHeader
        className={styles.pageHeader}
        title={<GTooltip tid="webinars_events">Вебинары и семинары</GTooltip>}
        extra={[
          <Button key="create_semi" icon={<EnvironmentOutlined />} onClick={addSeminar}>
            Создать семинар
          </Button>,
          <Button key="create_webinar" icon={<VideoCameraOutlined />} onClick={addWebiinar}>
            Создать вебинар
          </Button>,
        ]}
      />
      <Layout.Content>
        <div className={styles.contextHolder}>
          <div className={styles.context}>
            <XDataTable
              apiLoadData={apiWebinar3EventList()}
              commonColumns={commonColumns()}
              extentedColumns={null}
              initSort={['firtsStage.dtStart', -1]}
              initFilter={
                {
                  // '$$S#firtsStage.dtStart': dayjs().subtract(1, 'year').format(DATES_FORMAT),
                  // '$$F#firtsStage.dtStart': dayjs().add(1, 'year').format(DATES_FORMAT),
                }
              }
              filterForm={filterFormWebinarsEvents()}
              onRowClick={onRowClick}
              sioEvents={['LIST:WEBINARS3_EVENTS', subscribeToChannel, unsubscribeToChannel]}
              searchProps={['name']}
              enableItemsSelect={{
                prop: '_id',
                type: 'number',
              }}
              leftActions={[
                {
                  icon: <UsergroupAddOutlined />,
                  onClick: selectUsersByEvents,
                  tooltip: 'Выбрать пользователей, учавствующих в данных вебинарах',
                },
              ]}
              onSaveEventEmitter={onSaveEventEmmitter}
              // datesFilter={[
              //   '$$S#firtsStage.dtStart', // параметр в фильтре "С"
              //   '$$F#firtsStage.dtStart', // параметр в фильтре "ДО"
              //   DATES_FORMAT, // формат дат (для передачи)
              // ]}
              // datesPresets={[
              //   {
              //     label: 'Текущий месяц',
              //     dt1: dayjs().date(1).format(DATES_FORMAT),
              //     dt2: dayjs().add(1, 'month').date(1).subtract(1, 'day').format(DATES_FORMAT),
              //   },
              //   {
              //     label: 'Прошлый месяц',
              //     dt1: dayjs().subtract(1, 'month').date(1).format(DATES_FORMAT),
              //     dt2: dayjs().date(1).subtract(1, 'day').format(DATES_FORMAT),
              //   },
              // ]}
              urlParamName="_e_"
              // debug={{ filter: true }}
            />
          </div>
        </div>
      </Layout.Content>
    </>
  );
}
