import React, { useState } from 'react';

import { Button, Layout, PageHeader, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { useRecoilValue } from 'recoil';

import { CloudUploadOutlined, PlusOutlined } from '@ant-design/icons';

import apiAnyList from '../../../../api/AnyList';
import { subscribeToChannel, unsubscribeToChannel } from '../../../../api/sio';
import { notifyLastForRoleState } from '../../../../state/notify-state';
import GTooltip from '../../../components/GTooltip/GTooltip';
import XDataTable from '../../../components/xdf-antd/XDataTable';
import XDImportExcelFile from '../../../components/xdf-antd/XDataTable/XDImportExcelFile';
import { USER_ROLE } from '../../../constants';
import { uploadDataToServer } from '../Cross/CrossList';
import SpecEditDlg from './SpecEditDlg';
import styles from './styles.scss';
import { commonColumns } from './table-defs';

export default function SpecList() {
  const notifyLastForRole = useRecoilValue(notifyLastForRoleState(USER_ROLE.CATALOG_CHARACTER));

  const [editDlg, setEditDlg] = useState({ visible: false, model: {} });
  const [importDlg, setImportDlg] = useState(false);

  const onRowClick = (item) => {
    setEditDlg({ visible: true, model: item });
  };

  const addRecord = () => {
    setEditDlg({ visible: true, model: {} });
  };

  const onCloseDlg = () => {
    setEditDlg({ ...editDlg, visible: false });
  };

  // const downloadFile = () => {
  //   window.open('/api/1/catalog/spec/export', '_blank');
  // };

  return (
    <>
      <PageHeader
        className={styles.pageHeader}
        title={<GTooltip tid="catalogs_spec">Управление характеристиками на сайте</GTooltip>}
        extra={[
          <Tooltip key="upload" title="Загрузить файл на сервер">
            <Button
              shape="circle"
              icon={<CloudUploadOutlined />}
              onClick={() => setImportDlg(true)}
            />
          </Tooltip>,
          <Tooltip key="add" title="Добавить запись">
            <Button type="primary" shape="circle" icon={<PlusOutlined />} onClick={addRecord} />
          </Tooltip>,
          // <Tooltip key="download" title="Скачать файл" onClick={downloadFile}>
          //   <Button shape="circle" icon={<CloudDownloadOutlined />} />
          // </Tooltip>,
        ]}
      />
      <Layout.Content>
        <div className={styles.contextHolder}>
          <div className={styles.context}>
            <XDataTable
              apiLoadData={apiAnyList('KybCatalog:MainSpec')}
              commonColumns={commonColumns(notifyLastForRole)}
              extentedColumns={null}
              initSort={['partNumber', 1]}
              initFilter={{}}
              onRowClick={onRowClick}
              sioEvents={['LIST:SPEC', subscribeToChannel, unsubscribeToChannel]}
              searchProps={['partNumber', 'series', 'part']}
              excelExport={{
                filename: `SpecExport_${dayjs().format('YYYY-MM-DD')}`,
                tabname: 'Характеристики',
                exportStep: 100,
                autoWidth: true,
              }}
            />
          </div>
        </div>
      </Layout.Content>

      <SpecEditDlg visible={editDlg.visible} model={editDlg.model} onClose={onCloseDlg} />
      <XDImportExcelFile
        visible={importDlg}
        onClose={() => setImportDlg(false)}
        importBtnTitle="Укажите файл для импорта характеристик"
        maxStepToLoad={1000}
        uploadApi={uploadDataToServer('KybCatalog:MainSpec', 'LIST:SPEC')}
        columns={[
          {
            prop: 'partNumber',
          },
          {
            prop: 'part',
          },
          {
            prop: 'series',
          },
          {
            prop: 'type',
          },
          {
            prop: 'spec',
          },
          {
            prop: 'fr',
          },
          {
            prop: 'rl',
          },
          {
            prop: 'changes',
          },
          {
            prop: 'amoProtect',
          },
          {
            prop: 'fixType',
          },
        ]}
      />
    </>
  );
}
