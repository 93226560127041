import React, { useEffect, useState } from 'react';

import { Col, Input } from 'antd';
import cn from 'classnames';
import gv from 'get-value';
import PropTypes from 'prop-types';

import styles from './styles.scss';

const PRECISE_PATTERN =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function XDFEmail({ it, size, model, onPropChanged }) {
  const { id, prop, label, placeholder, autoFocus, span, readOnly, showOnly, suffix, require } = it;
  const [email0, setEmail0] = useState('');

  const val = gv(model, prop, '');

  useEffect(() => {
    setEmail0(val);
  }, [val]);

  function onChange(ev) {
    const valz = ev.target.value;
    setEmail0(valz);
    if (valz) {
      const m = valz.match(PRECISE_PATTERN);
      if (m && m[0] === valz) {
        onPropChanged(it, valz);
      } else {
        onPropChanged(it, undefined);
      }
    }
  }

  return (
    <Col span={span || 24}>
      <div className={styles.formItem}>
        <div className={styles.formItemLabel}>
          <label htmlFor={id}>
            {label}
            {require && <sup>*</sup>}
          </label>
        </div>
        <div
          className={cn(
            styles.formItemField,
            { [styles.small]: size === 'small' },
            { [styles.showOnly]: !!showOnly },
          )}
        >
          <Input
            id={id}
            size={size}
            placeholder={placeholder || ''}
            autoFocus={!!autoFocus}
            disabled={!!readOnly || !!showOnly}
            value={email0}
            onChange={onChange}
            suffix={suffix}
          />
        </div>
      </div>
    </Col>
  );
}

XDFEmail.propTypes = {
  it: PropTypes.shape({
    id: PropTypes.string.isRequired,
    prop: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    placeholder: PropTypes.string,
    autoFocus: PropTypes.bool,
    span: PropTypes.number,
    readOnly: PropTypes.bool,
    showOnly: PropTypes.bool,
    suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    require: PropTypes.bool,
  }).isRequired,
  size: PropTypes.string.isRequired,
  model: PropTypes.shape({}).isRequired,
  onPropChanged: PropTypes.func.isRequired,
};

export default XDFEmail;
