/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';

import { Button, Layout, message, PageHeader, Tabs, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { nanoid } from 'nanoid';
import { useHistory, useParams } from 'react-router-dom';

import { CloudUploadOutlined } from '@ant-design/icons';

import { apiPost } from '../../../../api';
import apiAnyGet from '../../../../api/AnyGet';
import GTooltip from '../../../components/GTooltip/GTooltip';
import KybPageBlocksEditor from '../../../components/KybPageBlocksEditor/KybPageBlocksEditor';
import XDataForm, { XDF_TYPE } from '../../../components/xdf-antd/XDataForm';
import { KYB_SITE_ITEM_STATUS } from '../../../constants';
import styles from './styles.scss';

const defModel = {
  _id: '___Null___',
  status: 0,
  publicDt: dayjs().format('DD.MM.YYYY'),
  pic: '',
  pics: [],
};

export default function KybSiteNewsEditor() {
  const history = useHistory();

  const [model, setModel] = useState(defModel);
  const [isModelChanged, setIsModelChanged] = useState(false);

  const { newsId } = useParams();

  const fetchSiteNews = async (id) => {
    const data = await apiAnyGet('KybDb:News')(id, []);
    setModel({
      ...data,
      publicDt: data.publicDt || dayjs(data.dt).format('DD.MM.YYYY'),
      blocks: (data?.blocks || []).map((b) => ({
        ...b,
        id: b.id || nanoid(),
      })),
      __dt: dayjs(data.dt).format('DD.MM.YYYY'),
    });
    setIsModelChanged(false);
  };

  useEffect(() => {
    if (newsId !== '--new--') {
      fetchSiteNews(newsId);
    }
  }, [newsId]);

  const onChangeTab = (key) => {
    // console.log(key);
  };

  const onChangeModel = (m, is) => {
    setModel({ ...m });
    setIsModelChanged(is);
  };

  const onSaveBlocks = (blocks) => {
    // console.log('onSaveBlocks', blocks);
    setModel({
      ...model,
      blocks: [...blocks],
    });
    setIsModelChanged(true);
  };

  const onSaveNews = async () => {
    const { err, errmsg, data } = await apiPost('/site/news/set', {
      ...model,
    });
    if (err === 0) {
      setIsModelChanged(false);
      if (data._id !== newsId) {
        history.replace(`/site/news/${data._id}`);
      }
    } else if (Array.isArray(errmsg)) {
      for (const msg of errmsg) {
        message.error(msg.message);
      }
    } else {
      message.error(errmsg || `Net error ${err}`);
    }
  };

  return (
    <>
      <PageHeader
        className={styles.pageHeader}
        title={<GTooltip tid="kyb_site_news_editor">Редактирование новости на сайте</GTooltip>}
        onBack={() => history.goBack()}
        extra={[
          <Tooltip key="save" title="Сохранить">
            <Button
              type="primary"
              shape="circle"
              icon={<CloudUploadOutlined />}
              onClick={onSaveNews}
              disabled={!isModelChanged}
            />
          </Tooltip>,
        ]}
      />
      <Layout.Content>
        <div className={styles.contextHolder}>
          <div className={styles.contextPage}>
            <Tabs defaultActiveKey="preview" type="card" tabPosition="right" onChange={onChangeTab}>
              <Tabs.TabPane tab="Анонс" key="preview">
                <XDataForm
                  debug
                  model={model}
                  onChangeModel={onChangeModel}
                  form={[
                    {
                      type: XDF_TYPE.SELECT,
                      label: 'Статус новости',
                      prop: 'status',
                      values: KYB_SITE_ITEM_STATUS,
                      span: 12,
                    },
                    {
                      type: XDF_TYPE.DATETIME,
                      label: 'Дата новости',
                      prop: 'publicDt',
                      span: 12,
                    },
                    {
                      type: XDF_TYPE.TEXT,
                      label: 'Заголовок',
                      prop: 'title',
                      span: 24,
                      autoFocus: true,
                    },
                    {
                      type: XDF_TYPE.TEXTAREA,
                      label: 'Текст анонса',
                      prop: 'preview',
                      span: 24,
                      rows: 5,
                    },
                    {
                      type: XDF_TYPE.PICTURE_UPLOAD,
                      label: 'Картинка анонса',
                      prop: 'pic',
                      roundImage: true,
                      span: 24,
                    },
                  ]}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Фотографии" key="pics">
                <XDataForm
                  model={model}
                  onChangeModel={onChangeModel}
                  form={[
                    {
                      type: XDF_TYPE.GALLERY_UPLOAD,
                      prop: 'pics',
                      picsProps: ['pic', 'pic', 'desc'],
                      span: 24,
                    },
                  ]}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Основной блок" key="blocks">
                <KybPageBlocksEditor
                  blocks={model?.blocks}
                  onSaveBlocks={onSaveBlocks}
                  pics={model?.pics}
                />
              </Tabs.TabPane>
            </Tabs>
          </div>
        </div>
      </Layout.Content>
    </>
  );
}
