import { apiPost, ApiError } from '.';

/**
 * Запрос серий продуктов
 */
function apiAnyGet(collection, role = 0) {
  return async (id, props) => {
    const { err, errmsg, data } = await apiPost('/any/get', {
      collection,
      role,
      id,
      props,
    });
    if (err !== 0) throw ApiError(err, errmsg);
    return data;
  };
}

export default apiAnyGet;
