import { apiPost, ApiError } from '.';

/**
 * Запрос серий продуктов
 */
const apiEmailTemplateSend = async (templateId) => {
  return apiPost('/email/template/send', {
    templateId,
  });
};
export default apiEmailTemplateSend;
