import React, { useEffect, useState } from 'react';

import { Button, Modal } from 'antd';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import apiAnyList from '../../../../api/AnyList';
import { subscribeToChannel, unsubscribeToChannel } from '../../../../api/sio';
import GTooltip from '../../../components/GTooltip/GTooltip';
import XDataTable from '../../../components/xdf-antd/XDataTable';
import { eventsWithTestTable } from './events-list';
import apiWebinar3EventList from '../../../../api/Webinar3EventList';

function WebinarsEventsWithTest({ testId, onClose }) {
  const history = useHistory();

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (testId > 0) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [testId]);

  const onRowClick = (item) => {
    history.push(`/webinars/event/edit/${item._id}`);
  };

  return (
    <Modal
      open={visible}
      title={
        <GTooltip tid="webinars_events_with_this_test">
          Вебинары и семинары использующие тест
        </GTooltip>
      }
      onCancel={onClose}
      footer={<Button onClick={onClose}>Закрыть</Button>}
      width="80%"
    >
      <XDataTable
        apiLoadData={apiWebinar3EventList()}
        commonColumns={eventsWithTestTable()}
        extentedColumns={null}
        initSort={['firtsStage.dtStart', 1]}
        initFilter={{
          'test.id': testId,
        }}
        onRowClick={onRowClick}
        sioEvents={['LIST:WEBINARS3_EVENTS', subscribeToChannel, unsubscribeToChannel]}
        searchProps={['name']}
      />
    </Modal>
  );
}

WebinarsEventsWithTest.defaultProps = {};

WebinarsEventsWithTest.propTypes = {
  testId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default WebinarsEventsWithTest;
