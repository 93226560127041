import React from 'react';

import { Col, Input } from 'antd';
import cn from 'classnames';
import gv from 'get-value';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import styles from './styles.scss';

function XDFShowDateTime({ it, size, model }) {
  const { id, prop, label, format, span } = it;

  const val = gv(model, prop);
  return (
    <Col span={span || 24}>
      <div className={styles.formItem}>
        <div className={styles.formItemLabel}>
          <label htmlFor={id}>{label}</label>
        </div>
        <div className={cn(styles.formItemField, { [styles.small]: size === 'small' })}>
          <div id={id} className={styles.showDateTime}>
            {val === undefined ? '' : dayjs(val).format(format || 'YYYY-MM-DD HH:mm:ss')}
          </div>
        </div>
      </div>
    </Col>
  );
}

XDFShowDateTime.propTypes = {
  it: PropTypes.shape({
    id: PropTypes.string.isRequired,
    prop: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    span: PropTypes.number,
    format: PropTypes.string,
  }).isRequired,
  size: PropTypes.string.isRequired,
  model: PropTypes.shape({}).isRequired,
};

export default XDFShowDateTime;
