import React, { useEffect, useState } from 'react';

import { Button, message, Modal, Skeleton } from 'antd';
import PropTypes from 'prop-types';

import apiAnyGet from '../../../../api/AnyGet';
import apiUserStateSet from '../../../../api/UserStateSet';
import { warningDialog } from '../../../components/Common/WarningDialog';
import GTooltip from '../../../components/GTooltip/GTooltip';
import XDataForm, { XDF_TYPE } from '../../../components/xdf-antd/XDataForm';
import styles from './styles.scss';

function UserUnblockDlg({ userId, onClose }) {
  const [model, setModel] = useState(null);

  useEffect(async () => {
    if (userId > 0) {
      const user = await apiAnyGet('User')(userId, [
        '_id',
        'name',
        'lastName',
        'midName',
        'email',
        'phone',
        'phoneOk',
        'phoneLastCodeDt',
        'company',
        'status',
        'country',
        'city',
        'desc',
      ]);

      if (user.phoneOk === 1) user.__phoneState = 'Телефон подтвержден';
      else if (user.phoneLastCodeDt) user.__phoneState = 'Код выслан';

      setModel(user);
    } else {
      setModel(null);
    }
  }, [userId]);

  function onChangeModel(m, isChanged) {
    setModel({ ...m });
  }

  async function unblockUser() {
    const { err } = await apiUserStateSet(userId, 0);
    if (!err) message.info('Пользователь разблокирован.');
    onClose();
  }

  async function onUnblockUser() {
    const res = await warningDialog('Смена статуса пользователя', 'Разблокировать пользователя?', [
      { label: 'Отмена' },
      { label: 'Разблокировать' },
    ]);
    if (res === 1) {
      unblockUser();
    }
  }

  return (
    <Modal
      className={styles.kybLoginDlg}
      title={<GTooltip tid="authorization_dlg">Управление пользовательским доступом</GTooltip>}
      width={880}
      open={userId > 0}
      closable
      onCancel={onClose}
      footer={[
        <Button key="unblock" disabled={!model} onClick={onUnblockUser}>
          Разблокировать
        </Button>,
      ]}
    >
      {!model && <Skeleton active />}

      {!!model && (
        <XDataForm
          model={model}
          onChangeModel={onChangeModel}
          form={[
            {
              type: XDF_TYPE.TEXT,
              label: 'ID пользователя',
              prop: '_id',
              span: 8,
              showOnly: true,
            },
            {
              type: XDF_TYPE.TEXT,
              label: 'Телефон',
              prop: 'phone',
              span: 8,
              showOnly: true,
            },
            {
              type: XDF_TYPE.TEXT,
              label: 'Состояние',
              prop: '__phoneState',
              span: 8,
              showOnly: true,
            },
            {
              type: XDF_TYPE.TEXT,
              label: 'Фамилия',
              prop: 'lastName',
              span: 8,
              showOnly: true,
            },
            {
              type: XDF_TYPE.TEXT,
              label: 'Имя',
              prop: 'name',
              span: 8,
              showOnly: true,
            },
            {
              type: XDF_TYPE.TEXT,
              label: 'Отчество',
              prop: 'midName',
              span: 8,
              showOnly: true,
            },
            {
              type: XDF_TYPE.TEXT,
              label: 'E-mail',
              prop: 'email',
              span: 8,
              showOnly: true,
            },
            {
              type: XDF_TYPE.TEXT,
              label: 'Компания',
              prop: 'company',
              span: 8,
              showOnly: true,
            },
            {
              type: XDF_TYPE.TEXT,
              label: 'Должность',
              prop: 'status',
              span: 8,
              showOnly: true,
            },
            {
              type: XDF_TYPE.TEXT,
              label: 'Страна',
              prop: 'country',
              span: 8,
              showOnly: true,
            },
            {
              type: XDF_TYPE.TEXT,
              label: 'Город',
              prop: 'city',
              span: 8,
              showOnly: true,
            },
            {
              type: XDF_TYPE.TEXTAREA,
              label: 'Дополнительная информация',
              prop: 'desc',
              rows: 4,
              span: 16,
              showOnly: true,
            },
          ]}
        />
      )}
    </Modal>
  );
}

UserUnblockDlg.defaultProps = {};

UserUnblockDlg.propTypes = {
  userId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default UserUnblockDlg;
