import { apiPost, ApiError } from '.';

/**
 * Запрос серий продуктов
 */
const apiGTolltipSet = async (tid, txt) => {
  const { err, errmsg, data } = await apiPost('/tooltip/set', { tid, txt });
  if (err !== 0) throw ApiError(err, errmsg);
  return;
};
export default apiGTolltipSet;
