import React, { useEffect } from 'react';

import { Spin } from 'antd';

import { apiPostData } from '../../../api';

export default function Webinars3UserLogout() {
  useEffect(async () => {
    await apiPostData('/auth/logout');
    document.location.reload();
  }, []);
  return (
    <div style={{ textAlign: 'center', paddingTop: 32 }}>
      <Spin />
    </div>
  );
}
