import React, { useEffect, useState } from 'react';

import { Button, Modal, Tree } from 'antd';
import PropTypes from 'prop-types';

import { KYB_STAFF_ACCESS_TREE_RULES } from '../../../../../routes';
import GTooltip from '../../../../components/GTooltip/GTooltip';
import styles from '../styles.scss';

function KybStaffAccessDialog({ visible, acc, onSave, onClose }) {
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    if (visible) {
      setRoles(acc);
    }
  }, [visible]);

  const onCheck = (checkedKeys, info) => {
    // console.log('onCheck', checkedKeys, info);
    setRoles(checkedKeys);
  };

  return (
    <Modal
      open={visible}
      title={<GTooltip tid="kyb_staff_acc">Настройки доступа Модератор – КУБ ЕВРАЗИЯ</GTooltip>}
      width={520}
      closable
      onCancel={onClose}
      footer={[
        <Button key="save" type="primary" onClick={() => onSave(roles)}>
          Применить
        </Button>,
        <Button key="approve" onClick={onClose}>
          Отменить
        </Button>,
      ]}
    >
      <div className={styles.treeAccess}>
        <Tree
          defaultExpandAll
          checkable
          selectable={false}
          onCheck={onCheck}
          treeData={KYB_STAFF_ACCESS_TREE_RULES}
          checkedKeys={roles}
        />
      </div>
    </Modal>
  );
}

KybStaffAccessDialog.defaultProps = {};

KybStaffAccessDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  acc: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default KybStaffAccessDialog;
