import React, { useEffect, useState } from 'react';

import { message } from 'antd';
import PropTypes from 'prop-types';

import { apiPost } from '../../../../api/index';
import KybLoginPhoneDialog from './KybLoginPhoneDialog';
import KybLoginSmsCodeDialog from './KybLoginSmsCodeDialog';

function KybLoginDialog({ visible, onAuthSuccess }) {
  const [phoneDlgVisible, setPhoneDlgVisible] = useState(false);
  const [codeDlgVisible, setCodeDlgVisible] = useState(false);
  const [codeType, setCodeType] = useState('');

  const [errCode, setErrCode] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    if (visible) {
      setCodeDlgVisible(false);
      setPhoneDlgVisible(true);
    }
  }, [visible]);

  const onSendPhone = async (phone) => {
    const { err, errmsg, data } = await apiPost('/auth/phone/send', { phone });
    if (err) {
      message.error(errmsg || `Net error: ${err}`);
    } else {
      setCodeType(data.codeType);
      setPhoneDlgVisible(false);
      setCodeDlgVisible(true);
      setPhoneNumber(phone);
    }
  };

  const onCodeCheck = async (code) => {
    const { err, errmsg, data } = await apiPost('/auth/code/check', { phone: phoneNumber, code });
    if (err) {
      if (err === 105) {
        setErrCode(true);
      } else {
        message.error(errmsg || `Net error: ${err}`);
      }
    } else if (onAuthSuccess) {
      setCodeDlgVisible(false);
      onAuthSuccess(data);
    } else document.location.reload();
  };

  return (
    <>
      <KybLoginPhoneDialog visible={phoneDlgVisible} onSendPhone={onSendPhone} />
      <KybLoginSmsCodeDialog
        visible={codeDlgVisible}
        onCodeCheck={onCodeCheck}
        errCode={errCode}
        codeType={codeType}
      />
    </>
  );
}

KybLoginDialog.defaultProps = {
  onAuthSuccess: null,
};

KybLoginDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  onAuthSuccess: PropTypes.func,
};

export default KybLoginDialog;
