/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';

import { Button, Layout, message, PageHeader, Tabs, Tooltip } from 'antd';
import { nanoid } from 'nanoid';
import queryString from 'query-string';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { CloudUploadOutlined, DeleteOutlined } from '@ant-design/icons';

import { apiPost } from '../../../../api';
import apiAnyGet from '../../../../api/AnyGet';
import { warningDialog } from '../../../components/Common/WarningDialog';
import GTooltip from '../../../components/GTooltip/GTooltip';
import KybPageBlocksEditor from '../../../components/KybPageBlocksEditor/KybPageBlocksEditor';
import XDataForm, { XDF_TYPE } from '../../../components/xdf-antd/XDataForm';
import { KYB_SITE_ITEM_STATUS } from '../../../constants';
import styles from './styles.scss';

const defModel = {
  _id: '___Null___',
  status: 0,
  pic: '',
  pics: [],
};

export default function KybSiteInstallationsEditor() {
  const history = useHistory();
  const location = useLocation();
  const qs = queryString.parse(location.search);

  const [model, setModel] = useState(defModel);
  const [isModelChanged, setIsModelChanged] = useState(false);

  const { zid } = useParams();

  const fetchSiteNews = async (id) => {
    const data = await apiAnyGet('KybDb:Installation')(id, []);
    setModel({
      ...data,
      blocks: (data?.blocks || []).map((b) => ({
        ...b,
        id: b.id || nanoid(),
      })),
    });
    setIsModelChanged(false);
  };

  useEffect(() => {
    if (zid !== '--new--') {
      fetchSiteNews(zid);
    }
  }, [zid]);

  const onChangeTab = (key) => {
    history.push(`?tab=${key}`);
  };

  const onChangeModel = (m, is) => {
    setModel({ ...m });
    setIsModelChanged(is);
  };

  const onSaveBlocks = (blocks) => {
    setModel({
      ...model,
      blocks: [...blocks],
    });
    setIsModelChanged(true);
  };

  const onSaveItem = async () => {
    const { err, errmsg, data } = await apiPost('/site/installation/set', {
      ...model,
    });
    if (err === 0) {
      setIsModelChanged(false);
      if (data._id !== zid) {
        history.replace(`/site/installations/${data._id}`);
      }
    } else if (Array.isArray(errmsg)) {
      for (const msg of errmsg) {
        message.error(msg.message);
      }
    } else {
      message.error(errmsg || `Net error ${err}`);
    }
  };

  const onDeleteItem = async () => {
    const res = await warningDialog('Рекомендация к установке', 'Удалить запись?', [
      { label: 'Отмена' },
      { label: 'Удалить' },
    ]);

    if (res === 1) {
      const { err, errmsg } = await apiPost('/site/installation/set', {
        ...model,
        status: -1,
      });
      if (err === 0) history.push(`/site/installations`);
      else if (Array.isArray(errmsg)) {
        for (const msg of errmsg) {
          message.error(msg.message);
        }
      } else {
        message.error(errmsg || `Net error ${err}`);
      }
    }
  };

  return (
    <>
      <PageHeader
        className={styles.pageHeader}
        title={<GTooltip tid="kyb_site_installations_editor">Рекомендация к установке</GTooltip>}
        onBack={() => history.goBack()}
        extra={[
          <Tooltip key="del" title="Удалить">
            <Button shape="circle" icon={<DeleteOutlined />} onClick={onDeleteItem} />
          </Tooltip>,
          <Tooltip key="save" title="Сохранить">
            <Button
              type="primary"
              shape="circle"
              icon={<CloudUploadOutlined />}
              onClick={onSaveItem}
              disabled={!isModelChanged}
            />
          </Tooltip>,
        ]}
      />
      <Layout.Content>
        <div className={styles.contextHolder}>
          <div className={styles.contextPage}>
            <Tabs
              activeKey={qs.tab || 'common'}
              type="card"
              tabPosition="right"
              onChange={onChangeTab}
            >
              <Tabs.TabPane tab="Параметры страницы" key="common">
                <XDataForm
                  model={model}
                  onChangeModel={onChangeModel}
                  form={[
                    {
                      type: XDF_TYPE.SELECT,
                      label: 'Статус',
                      prop: 'status',
                      values: KYB_SITE_ITEM_STATUS,
                      span: 6,
                    },
                    {
                      type: XDF_TYPE.TEXT,
                      label: 'Название',
                      prop: 'name',
                      span: 18,
                      autoFocus: true,
                    },
                    {
                      type: XDF_TYPE.PICTURE_UPLOAD,
                      label: 'Картинка анонса',
                      prop: 'pic',
                      roundImage: true,
                      span: 24,
                    },
                  ]}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Фотографии" key="pics">
                <XDataForm
                  model={model}
                  onChangeModel={onChangeModel}
                  form={[
                    {
                      type: XDF_TYPE.GALLERY_UPLOAD,
                      prop: 'pics',
                      picsProps: ['pic', 'pic', 'desc'],
                      span: 24,
                    },
                  ]}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Редактирование страницы" key="blocks">
                <KybPageBlocksEditor
                  blocks={model?.blocks}
                  onSaveBlocks={onSaveBlocks}
                  pics={model?.pics}
                />
              </Tabs.TabPane>
            </Tabs>
          </div>
        </div>
      </Layout.Content>
    </>
  );
}
