import { selector } from 'recoil';
import apiMapSettings from '../api/MapSettings';

export const mapSettingsState = selector({
  key: '__map_settings__',
  get: async ({ get }) => {
    const response = await apiMapSettings();
    return response;
  },
});

export const mapGoogleKeyApi = selector({
  key: '__map_googleKeyApi__',
  get: ({ get }) => {
    const { googleKeyApi } = get(mapSettingsState);
    return googleKeyApi;
  },
});

export const mapCategoriesState = selector({
  key: '__map_categories__',
  get: ({ get }) => {
    const { categories } = get(mapSettingsState);
    return categories;
  },
});

export const mapIconsState = selector({
  key: '__map_icons__',
  get: ({ get }) => {
    const { icons } = get(mapSettingsState);
    return icons;
  },
});

export const mapDictState = selector({
  key: '__map_dict__',
  get: ({ get }) => {
    const { dict } = get(mapSettingsState);
    return dict;
  },
});

export const mapPropsState = selector({
  key: '__map_props__',
  get: ({ get }) => {
    const { props } = get(mapSettingsState);
    return props;
  },
});

export const mapId2PropsState = selector({
  key: '__map_id2props__',
  get: ({ get }) => {
    const { props } = get(mapSettingsState);
    return props.reduce((a, x) => {
      // eslint-disable-next-line no-param-reassign
      a[x.prop] = x;
      return a;
    }, {});
  },
});
