import React, { useEffect, useState } from 'react';

import { Button, Layout, message, PageHeader, Tabs, Tooltip, Tree } from 'antd';
import cn from 'classnames';
import { nanoid } from 'nanoid';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';

import { CloudUploadOutlined, DownOutlined } from '@ant-design/icons';

import apiAnyGet from '../../../../api/AnyGet';
import GTooltip from '../../../components/GTooltip/GTooltip';
import styles from './styles.scss';
import KybPageBlocksEditor from '../../../components/KybPageBlocksEditor/KybPageBlocksEditor';
import XDataForm, { XDF_TYPE } from '../../../components/xdf-antd/XDataForm';
import { apiPost } from '../../../../api';

const pagesTree = [
  {
    title: 'О KYB',
    key: '-about',
    children: [
      {
        title: 'Заводы KYB',
        key: 'factories_',
      },
      {
        title: 'KYB в России',
        key: 'russia_',
      },
      {
        title: 'KYB в мире',
        key: 'world_',
      },
    ],
  },
  {
    title: 'Поддержка',
    key: '-support',
    children: [
      {
        title: 'Гарантия KYB',
        key: 'support_guarantee_',
      },
    ],
  },
  {
    title: 'Партнерам',
    key: '-partners',
    children: [
      {
        title: 'Проект KYB-сервис',
        key: 'partners_service_',
      },
      {
        title: 'Авторизация СТО',
        key: 'partners_sto_',
      },
      {
        title: 'Сертификация магазинов',
        key: 'partners_shop_',
      },
    ],
  },
  {
    title: 'Условия',
    key: 'conditions_',
  },
  {
    title: 'Конфиденциальность',
    key: 'confidentiality_',
  },
  {
    title: 'Помощь по карте',
    key: 'maphelp1',
  },
];

const getPageTitleByKey = (key) => {
  for (const p of pagesTree) {
    if (p.key === key) return p.title;
    if (p.children) {
      for (const v of p.children) {
        if (v.key === key) return v.title;
      }
    }
  }
  return '';
};

export default function KybSitePages() {
  const history = useHistory();
  const location = useLocation();
  const { page } = queryString.parse(location.search);

  const [model, setModel] = useState({});
  const [isModelChanged, setIsModelChanged] = useState(false);

  const fetchPage = async () => {
    const data = await apiAnyGet('KybDb:Page')(page, []);
    setModel({
      ...data,
      blocks: (data?.blocks || []).map((b) => ({
        ...b,
        id: b.id || nanoid(),
      })),
    });
    setIsModelChanged(false);
  };

  useEffect(() => {
    if (page) fetchPage(page);
  }, [page]);

  const onSelect = (selectedKeys, { node }) => {
    if (node.key.charAt(0) !== '-') {
      history.push(`?page=${node.key}`);
    } else {
      history.push('?');
    }
  };

  const onSaveBlocks = (blocks) => {
    setModel({
      ...model,
      blocks: [...blocks],
    });
    setIsModelChanged(true);
  };

  const onChangeModel = (m, is) => {
    setModel({ ...m });
    setIsModelChanged(is);
  };

  const onSaveItem = async () => {
    const { err, errmsg } = await apiPost('/site/page/set', {
      ...model,
    });
    if (err === 0) {
      setIsModelChanged(false);
    } else if (Array.isArray(errmsg)) {
      for (const msg of errmsg) {
        message.error(msg.message);
      }
    } else {
      message.error(errmsg || `Net error ${err}`);
    }
  };

  return (
    <>
      <PageHeader
        className={styles.pageHeader}
        title={
          <GTooltip tid="kyb_site_edit_page">
            Редактирование страницы
            {page ? (
              <span>
                : <span style={{ color: 'var(--kyb-color)' }}>{getPageTitleByKey(page)}</span>
              </span>
            ) : (
              ''
            )}
          </GTooltip>
        }
        extra={[
          <Tooltip key="save" title="Сохранить">
            <Button
              type="primary"
              shape="circle"
              icon={<CloudUploadOutlined />}
              onClick={onSaveItem}
              disabled={!isModelChanged}
            />
          </Tooltip>,
        ]}
      />
      <Layout.Content>
        <div className={styles.contextHolder}>
          <div className={cn(styles.context, styles.tree)}>
            <Tree
              defaultExpandAll
              showLine
              switcherIcon={<DownOutlined />}
              selectedKeys={[page]}
              onSelect={onSelect}
              treeData={pagesTree}
            />
          </div>
          {page && page === model?._id && (
            <div className={cn(styles.context, styles.editor)}>
              <Tabs type="card" tabPosition="top">
                <Tabs.TabPane tab="Редактирование страницы" key="blocks">
                  <KybPageBlocksEditor
                    blocks={model?.blocks}
                    onSaveBlocks={onSaveBlocks}
                    pics={model?.pics}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Параметры страницы" key="meta">
                  <XDataForm
                    model={model}
                    onChangeModel={onChangeModel}
                    form={[
                      {
                        type: XDF_TYPE.TEXT,
                        label: 'Title страницы',
                        prop: 'metaTitle',
                        span: 24,
                      },
                      {
                        type: XDF_TYPE.TEXT,
                        label: 'Description страницы',
                        prop: 'metaDesc',
                        span: 24,
                      },
                      {
                        type: XDF_TYPE.TEXT,
                        label: 'Теги страницы (через запятую)',
                        prop: 'metaTags',
                        span: 24,
                      },
                    ]}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Фотографии" key="pics">
                  <XDataForm
                    model={model}
                    onChangeModel={onChangeModel}
                    form={[
                      {
                        type: XDF_TYPE.GALLERY_UPLOAD,
                        prop: 'pics',
                        picsProps: ['pic', 'pic', 'desc'],
                        span: 24,
                      },
                    ]}
                  />
                </Tabs.TabPane>
              </Tabs>
            </div>
          )}
        </div>
      </Layout.Content>
    </>
  );
}
