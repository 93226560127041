import React, { useEffect, useState } from 'react';

import { Modal } from 'antd';
import PropTypes from 'prop-types';

import XDataForm, { XDF_TYPE } from '../../../components/xdf-antd/XDataForm';

const defModel = {
  name: '',
  bannerOn: 0,
};

function KybSeriesEditorDlg({ model, onClose, onSave }) {
  const [model0, setModel0] = useState(defModel);
  const [isModelChanged, setIsModelChanged] = useState(false);

  useEffect(() => {
    if (model) {
      setModel0({ ...defModel, ...model });
    } else {
      setModel0(defModel);
      setIsModelChanged(false);
    }
  }, [model]);

  const onChangeModel = (m, isc) => {
    setModel0({ ...m });
    setIsModelChanged(isc);
  };

  return (
    <Modal
      title="Редактированеи записи"
      open={!!model}
      onCancel={onClose}
      okText="Сохранить"
      okButtonProps={{ disabled: !isModelChanged }}
      onOk={() => onSave(model0)}
    >
      {!!model?._id && (
        <XDataForm
          // debug
          model={model0}
          onChangeModel={onChangeModel}
          form={[
            {
              type: XDF_TYPE.TEXT,
              label: 'Название',
              prop: 'name',
              span: 24,
              autoFocus: true,
            },
            // {
            //   type: XDF_TYPE.SWITCH,
            //   label: 'Включить баннер',
            //   prop: 'bannerOn',
            //   span: 24,
            // },
            {
              type: XDF_TYPE.TEXT,
              label: 'Заголовок',
              prop: 'title',
              span: 24,
              // hide: model0.bannerOn !== 1,
            },
            {
              type: XDF_TYPE.TEXTAREA,
              label: 'Описание',
              prop: 'desc',
              span: 24,
              rows: 5,
              // hide: model0.bannerOn !== 1,
            },
            {
              type: XDF_TYPE.TEXT,
              label: 'Ссылка при клике на баннер',
              prop: 'url',
              span: 24,
              // hide: model0.bannerOn !== 1,
            },
            {
              type: XDF_TYPE.PICTURE_UPLOAD,
              label: 'Укажите картинку для главной страницы',
              prop: 'pic',
              span: 24,
              // hide: model0.bannerOn !== 1,
            },
          ]}
        />
      )}
    </Modal>
  );
}

KybSeriesEditorDlg.defaultProps = {
  model: null,
};

KybSeriesEditorDlg.propTypes = {
  model: PropTypes.shape({}),
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default KybSeriesEditorDlg;
