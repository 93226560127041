import React, { useEffect, useState } from 'react';

import { Button, Layout, message, PageHeader, Tooltip } from 'antd';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { CloudUploadOutlined } from '@ant-design/icons';

import apiAnyGet from '../../../../api/AnyGet';
import {
  mapCategoriesState,
  mapDictState,
  mapGoogleKeyApi,
  mapIconsState,
  mapId2PropsState,
} from '../../../../state/map-state';
import GTooltip from '../../../components/GTooltip/GTooltip';
import KybMap from '../../../components/Map/KybMap';
import XDataForm, { XDF_TYPE } from '../../../components/xdf-antd/XDataForm';
import { KYB_MAP_OBJECT_GROUP } from '../../../constants';
import styles from './styles.scss';
import { fixMapObject } from './utils';
import apiMapObjectSet from '../../../../api/MapObjectSet';

export default function MapObjectEditor() {
  const googleKeyApi = useRecoilValue(mapGoogleKeyApi);
  const categoriesList = useRecoilValue(mapCategoriesState);
  const iconsList = useRecoilValue(mapIconsState);
  const dictList = useRecoilValue(mapDictState);
  const mapId2Props = useRecoilValue(mapId2PropsState);

  const { objectId } = useParams();

  const [model, setModel] = useState({});
  const [isModelChanged, setIsModelChanged] = useState(false);

  async function loadModel(id) {
    setIsModelChanged(false);
    if (id === '000000000000000000000000') {
      setModel({
        _id: '000000000000000000000000',
        cid: 1,
        active: 0,
        lat: 55.753843,
        lng: 37.620179,
      });
      return;
    }

    const data = await apiAnyGet('Sys:MapPoi')(id, []);
    const m = fixMapObject(data);
    if (m.active === -1) m.active = 0;
    setModel(m);
  }

  useEffect(() => {
    if (objectId) {
      loadModel(objectId);
    }
  }, [objectId]);

  const generateEditForm = () => {
    const formProps = [
      {
        type: XDF_TYPE.SELECT,
        label: 'Категория',
        prop: 'cid',
        values: categoriesList,
        span: 12,
      },
    ];

    let catProps = [];
    const catSets = categoriesList?.find((x) => x.id === model.cid);
    if (catSets && catSets.formProps) {
      catProps = catSets.formProps;
    }

    const icons = iconsList?.map((el) => ({
      id: el.id,
      name: `${el.name} [${el.id}]`,
    }));

    catProps.forEach((p) => {
      const ps = mapId2Props[p];
      switch (ps.type) {
        // eslint-disable-next-line default-case-last
        default:
          formProps.push({
            type: XDF_TYPE.TEXT,
            label: ps.name,
            prop: ps.prop,
            span: 24,
            required: !!ps.req,
          });
          break;

        case 3:
          formProps.push({
            type: XDF_TYPE.URL,
            label: ps.name,
            prop: ps.prop,
            span: 24,
            required: !!ps.req,
          });
          break;

        case 13:
          // formProps.push({
          //   type: 'picture-ext2',
          //   label: ps.name,
          //   prop: ps.prop,
          //   span: 24,
          //   multi: true, // pics -> array
          //   maxPics: 20,
          //   apiUrl: '/api/2/kbm/image/get/',
          //   showCount: true,
          // });
          break;

        case 10:
          formProps.push({
            type: XDF_TYPE.TEXT,
            label: ps.name,
            prop: ps.prop,
            span: 24,
            required: !!ps.req,
          });
          formProps.push({
            type: XDF_TYPE.TEXT,
            label: 'Адрес - автогеокодер (не будет сохранено)',
            prop: '_addr',
            span: 24,
            showOnly: true,
            required: !!ps.req,
          });
          break;

        case 5:
          formProps.push({
            type: XDF_TYPE.MULTISELECT,
            label: ps.name,
            prop: ps.prop,
            values: dictList.filter((x) => x.prop === ps.prop),
            span: 24,
          });
          break;

        case 9:
          formProps.push({
            type: XDF_TYPE.CHECKBOX,
            boolType: false,
            title: ps.name,
            prop: ps.prop,
            span: 12,
          });
          break;

        case 7:
          formProps.push({
            type: XDF_TYPE.TAGS,
            label: ps.name,
            prop: ps.prop,
            span: 24,
            values: [],
          });
          break;

        case 14:
        case 15:
          formProps.push({
            type: XDF_TYPE.SHOW_DATETIME,
            label: ps.name,
            prop: ps.prop,
            span: 24,
          });
          break;

        case 25:
          break;

        case 16:
          formProps.push({
            type: XDF_TYPE.TEXT,
            label: ps.name,
            prop: ps.prop,
            span: 24,
            readOnly: true,
          });
          break;

        case 22:
        case 23:
        case 24:
          formProps.push({
            type: XDF_TYPE.TEXT,
            label: ps.name,
            prop: ps.prop,
            span: 8,
            showOnly: true,
            required: !!ps.req,
          });
          break;

        case 20:
          formProps.push({
            type: XDF_TYPE.WYSIWYG,
            label: ps.name,
            prop: ps.prop,
            span: 24,
            required: !!ps.req,
          });
          break;

        case 19:
          formProps.push({
            type: XDF_TYPE.SELECT,
            label: ps.name,
            prop: ps.prop,
            span: 24,
            values: [{ id: '', name: 'Не указана' }].concat(icons),
          });
          break;

        case 17:
          formProps.push({
            type: XDF_TYPE.SELECT,
            label: ps.name,
            prop: ps.prop,
            span: 24,
            values: Object.keys(KYB_MAP_OBJECT_GROUP).map((k) => ({
              id: parseInt(k, 10),
              name: KYB_MAP_OBJECT_GROUP[k],
            })),
          });
          break;
      }
    });

    return formProps;
  };

  const onChangeModel = (m, v) => {
    setModel({ ...m });
    setIsModelChanged(v);
  };

  const onObjectSet = async () => {
    // console.log('SAVE!', JSON.stringify(model, null, 2));
    const { err, errmsg } = await apiMapObjectSet(model);
    if (err !== 0) {
      if (Array.isArray(errmsg)) {
        for (const msg of errmsg) {
          message.error(msg.message);
        }
      } else {
        message.error(errmsg || `Net error ${err}`);
      }
    } else {
      setIsModelChanged(false);
    }
  };

  return (
    <>
      <PageHeader
        className={styles.pageHeader}
        title={<GTooltip tid="map_object_editor">Редактирование объекта</GTooltip>}
        extra={[
          <Tooltip key="save" title="Сохранить объект">
            <Button
              type="primary"
              shape="circle"
              icon={<CloudUploadOutlined />}
              disabled={!isModelChanged}
              onClick={onObjectSet}
            />
          </Tooltip>,

          // <Tooltip key="add" title="Добавить запись">
          //   <Button type="primary" shape="circle" icon={<PlusOutlined />} onClick={addRecord} />
          // </Tooltip>,
          // <Tooltip key="download" title="Скачать файл" onClick={downloadFile}>
          //   <Button shape="circle" icon={<CloudDownloadOutlined />} />
          // </Tooltip>,
        ]}
      />
      <Layout.Content>
        <div className={styles.contextHolder}>
          <div className={styles.context}>
            {model._id && (
              <>
                <div className={styles.form}>
                  <div className={styles.formHolder}>
                    <XDataForm
                      model={model}
                      onChangeModel={onChangeModel}
                      form={generateEditForm()}
                    />
                  </div>
                </div>
                <div className={styles.map}>
                  <div className={styles.mapHolder}>
                    <KybMap
                      googleKey={googleKeyApi}
                      categories={categoriesList}
                      onHide={() => {}}
                      lat={model.lat}
                      lng={model.lng}
                      zoom={17}
                      editObject={model}
                      onMovedEditObject={(ll) => {
                        setModel({
                          ...model,
                          ...ll,
                        });
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Layout.Content>
    </>
  );
}
