import React, { useEffect, useState } from 'react';

import { Button, Modal } from 'antd';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';

import { mapCategoriesState, mapDictState, mapIconsState } from '../../../../state/map-state';
import XDataForm, { XDF_TYPE } from '../../../components/xdf-antd/XDataForm';
import { KYB_MAP_OBJECT_GROUP } from '../../../constants';

function MapObjectUploadDlg({ visible }) {
  const categoriesList = useRecoilValue(mapCategoriesState);

  const [loadExcelDone, setLoadExcelDone] = useState(false);
  const [model, setModel] = useState({});

  const onCloseDlg = () => {};

  return (
    <Modal
      title="Загрузка объектов"
      open={visible}
      onOk={onCloseDlg}
      okButtonProps={{
        disabled: !loadExcelDone,
      }}
      onCancel={onCloseDlg}
    >
      {!loadExcelDone && (
        <div>
          <Button>Загрузить Excel файл</Button>
        </div>
      )}

      {loadExcelDone && (
        <XDataForm
          model={model}
          onChangeModel={(m) => setModel({ ...m })}
          form={[
            {
              type: XDF_TYPE.SELECT,
              label: 'Категория',
              prop: 'cid',
              values: categoriesList,
              span: 16,
            },
            {
              type: XDF_TYPE.CHECKBOX,
              boolType: false,
              title: 'Активно',
              prop: 'active',
              span: 8,
            },
          ]}
        />
      )}
    </Modal>
  );
}

MapObjectUploadDlg.defaultProps = {};

MapObjectUploadDlg.propTypes = {
  visible: PropTypes.bool.isRequired,
};

export default MapObjectUploadDlg;
