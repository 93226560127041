/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';

import { Col, Tooltip } from 'antd';
import cn from 'classnames';
import gv from 'get-value';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';

import styles from './styles.scss';

function XDFColor({ it, size, model, onPropChanged }) {
  const { id, prop, label, colorType, span, showOnly, require, tooltip } = it;

  const [showPicker, setShowPicker] = useState(false);

  useEffect(() => {
    setShowPicker(false);
  }, []);

  const onColorChange = (color) => {
    onPropChanged(it, color[colorType || 'hex']);
  };

  const onShowPicker = () => {
    setShowPicker(true);
  };

  const onClosePicker = () => {
    setShowPicker(false);
  };

  const val = gv(model, prop, '#000000');
  return (
    <Col span={span || 24}>
      <div className={styles.formItem}>
        <div className={styles.formItemLabel}>
          <label htmlFor={id}>
            {label}
            {require && <sup>*</sup>}
          </label>
        </div>
        <Tooltip title={tooltip}>
          <div
            className={cn(
              styles.formItemField,
              { [styles.small]: size === 'small' },
              { [styles.showOnly]: !!showOnly },
            )}
          >
            <div className={styles.colorBlock} onClick={onShowPicker}>
              <div className={styles.colorEl} style={{ backgroundColor: val }} />
            </div>

            {showPicker ? (
              <div className={styles.colorPopover}>
                <div className={styles.colorClover} onClick={onClosePicker} />
                <SketchPicker color={val} onChange={onColorChange} />
              </div>
            ) : null}
          </div>
        </Tooltip>
      </div>
    </Col>
  );
}

XDFColor.propTypes = {
  it: PropTypes.shape({
    id: PropTypes.string.isRequired,
    prop: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    span: PropTypes.number,
    readOnly: PropTypes.bool,
    showOnly: PropTypes.bool,
    suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    require: PropTypes.bool,
    onEnter: PropTypes.func,
    tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    colorType: PropTypes.string,
  }).isRequired,
  size: PropTypes.string.isRequired,
  model: PropTypes.shape({}).isRequired,
  onPropChanged: PropTypes.func.isRequired,
};

export default XDFColor;
