import React, { useEffect, useState, useRef } from 'react';

import { Button, Collapse, Layout, message, PageHeader, Popconfirm, Typography } from 'antd';
import { nanoid } from 'nanoid';
import { useHistory, useParams } from 'react-router-dom';

import {
  CloudUploadOutlined,
  DeleteOutlined,
  EyeOutlined,
  QuestionCircleOutlined,
  SendOutlined,
} from '@ant-design/icons';

import apiEmailTemplateGet from '../../../../api/EmailTemplateGet';
import apiEmailTemplateRemove from '../../../../api/EmailTemplateRemove';
import apiEmailTemplateSave from '../../../../api/EmailTemplateSave';
import apiEmailTemplateSend from '../../../../api/EmailTemplateSend';
import GTooltip from '../../../components/GTooltip/GTooltip';
import XDataForm, { XDF_TYPE } from '../../../components/xdf-antd/XDataForm';
import { EDITOR_HELP_MACROS } from './editor-help';
import EmailTemplatePreviewDlg from './EmailTemplatePreviewDlg';
import styles from './styles.scss';
import { useAceXml } from './emails-editor-hooks';

// import 'ace-builds/src-noconflict/mode-html';
// import 'ace-builds/src-noconflict/theme-monokai';
// import 'ace-builds/webpack-resolver';
// import Beautify from 'ace-builds/src-noconflict/ext-beautify';

export default function CommonEmailEditor() {
  // router
  const history = useHistory();
  // const location = useLocation();
  const { templateId } = useParams();

  const [model, setModel] = useState({});
  const [modelChanged, setModelChanged] = useState(false);
  const [code, setCode] = useState('');
  const [progress, setProgress] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  const refEditor = useRef(null);
  const [aceInitDone, aceCode] = useAceXml(refEditor, code);

  const loadTemplate = async (id) => {
    setProgress(true);
    const template = await apiEmailTemplateGet(id);

    setModel({
      id: template._id,
      state: template.state,
      name: template.name,
    });
    setCode(template.code);
    setModelChanged(false);

    setProgress(false);
  };

  const saveTemplate = async () => {
    setProgress(true);

    const { err, errmsg, data } = await apiEmailTemplateSave(
      model.id,
      model.state,
      model.name,
      aceCode,
    );
    if (err) message.error(errmsg || `Net error: ${err}`);
    else {
      if (templateId === '_') history.replace(`/common/email/template/${data._id}`);
      setModelChanged(false);
    }

    setProgress(false);
  };

  useEffect(() => {
    if (templateId === '_') {
      // new
      setModel({
        id: nanoid(7).toLowerCase(),
        state: 1,
      });
      setCode('');
    } else {
      // load
      loadTemplate(templateId);
    }
  }, [templateId]);

  useEffect(() => {
    // console.log('aceCode', aceCode);
    // setCode(aceCode);
    setModelChanged(true);
  }, [aceCode]);

  // const onChangeCode = (c) => {
  //   setCode(c);
  //   setModelChanged(true);
  // };

  const onChangeModel = (m) => {
    setModel({ ...m });
    setModelChanged(true);
  };

  const sendToMe = () => {
    if (templateId !== '_') apiEmailTemplateSend(templateId);
  };

  const onCancelBlock = () => {};

  const onDeleteTemplate = async () => {
    if (templateId !== '_') {
      await apiEmailTemplateRemove(templateId);
    }
  };

  return (
    <>
      <PageHeader
        className={styles.pageHeader}
        title={<GTooltip tid="email_tmpl_editor">Редактор E-mail</GTooltip>}
        extra={[
          <Popconfirm
            key="4"
            placement="leftBottom"
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            title="Вы уверены, что хотите удалить данный шаблон?"
            onConfirm={onDeleteTemplate}
            onCancel={onCancelBlock}
            okText="Удалить"
            cancelText="Нет"
            disabled={progress || templateId === '_'}
          >
            <Button danger icon={<DeleteOutlined />} disabled={progress || templateId === '_'}>
              Удалить
            </Button>
          </Popconfirm>,

          <Button
            key="3"
            icon={<SendOutlined />}
            disabled={progress || templateId === '_'}
            onClick={sendToMe}
          >
            Прислать мне
          </Button>,
          <Button
            key="2"
            icon={<EyeOutlined />}
            disabled={progress || templateId === '_'}
            onClick={() => setShowPreview(true)}
          >
            Предпросмотр
          </Button>,
          <Button
            key="1"
            icon={<CloudUploadOutlined />}
            type="primary"
            onClick={saveTemplate}
            disabled={progress || !modelChanged}
          >
            Сохранить
          </Button>,
        ]}
      />
      <Layout.Content>
        <div className={styles.contextHolder}>
          <div className={styles.context}>
            <div className={styles.emailDetails}>
              <XDataForm
                model={model}
                onChangeModel={onChangeModel}
                form={[
                  // {
                  //   type: XDF_TYPE.CHECKBOX,
                  //   label: 'Шаблон доступен',
                  //   prop: 'state',
                  //   span: 24,
                  // },
                  {
                    type: XDF_TYPE.TEXT,
                    label: 'ID шаблона (должно быть уникальным!, [a..z-_0..9])',
                    prop: 'id',
                    span: 24,
                  },
                  {
                    type: XDF_TYPE.TEXT,
                    label: 'Название шаблона',
                    prop: 'name',
                    span: 24,
                  },
                ]}
              />
              <article>
                <h3>Возможно использовать переменные</h3>
                <p>
                  Все перемнные вставляются как - {'{{EXAMPLE}}'} <br />
                  (в двойных круглых скобках)
                </p>
                <Collapse accordion>
                  {EDITOR_HELP_MACROS.map((x) => (
                    <Collapse.Panel
                      header={
                        <Typography.Paragraph
                          code
                          copyable={{
                            text: `{{${x.id}}}`,
                          }}
                        >
                          {x.id}
                        </Typography.Paragraph>
                      }
                      key={x.id}
                    >
                      {x.txt}
                    </Collapse.Panel>
                  ))}
                </Collapse>
              </article>
            </div>
            <div className={styles.emailCode}>
              <div className={styles.codeWrapper}>
                <div className={styles.codeHelp}>
                  <a href="https://documentation.mjml.io/" target="_blank" rel="noreferrer">
                    Документация по MJML
                  </a>
                  <span style={{ float: 'right' }}>Ctrl+Shift+B - для форматирования кода</span>
                </div>
                <div className={styles.codeHolder}>
                  <div id="xml-editor" ref={refEditor} style={{ width: '100%', height: '100%' }} />
                  {/* <AceEditor
                    mode="html"
                    theme="monokai"
                    onChange={onChangeCode}
                    name="EMAIL_CODE_EDITOR"
                    editorProps={{ $blockScrolling: true }}
                    width="100%"
                    height="100%"
                    value={code}
                    commands={Beautify.commands}
                    setOptions={{
                      // enableBasicAutocompletion: true,
                      // enableLiveAutocompletion: true,
                      // enableSnippets: false,
                      showLineNumbers: true,
                      tabSize: 2,
                      useWorker: false,
                    }}
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout.Content>

      <EmailTemplatePreviewDlg
        templateId={model.id || ''}
        visible={showPreview}
        onClose={() => setShowPreview(false)}
      />
    </>
  );
}
