import React, { useEffect, useMemo, useState } from 'react';

import { matchPath, Route } from 'react-router-dom';

import routes from '../../../routes';
import Null from '../../pages/Null/Null';

// eslint-disable-next-line import/prefer-default-export
export function useMainMenu(me, currentPath) {
  const [selectedItem, setSelectedItem] = useState('100-101');
  const [openMainItem, setOpenMainItem] = useState('');

  const routesList = useMemo(() => {
    const rrList = [];
    for (const r of routes) {
      if (Array.isArray(r.subs)) {
        r.subs.forEach((rr) => {
          rrList.push(
            <Route
              key={rr.path}
              path={rr.path}
              exact={rr.exact}
              component={rr.component || Null}
            />,
          );
        });
      }
    }
    return rrList;
  }, [me]);

  useEffect(() => {
    for (const r of routes) {
      if (Array.isArray(r.subs)) {
        r.subs.forEach((rr) => {
          const match = matchPath(window.location.pathname, {
            path: rr.path,
            exact: !!rr.exact,
            strict: false,
          });
          if (match) {
            setSelectedItem([`${r.role}-${rr.role}`]);
            setOpenMainItem([`${r.role}`]);
          }
        });
      }
    }
  }, [currentPath]);

  return [routesList, selectedItem, openMainItem];
}
