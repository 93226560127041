import React, { useEffect } from 'react';

import { Col } from 'antd';
import cn from 'classnames';
// eslint-disable-next-line import/no-extraneous-dependencies
import dayjs from 'dayjs';
import gv from 'get-value';
import PropTypes from 'prop-types';

import { DEFAULT_DATEFORMAT } from './constants';
import DatePicker from './DayJsDatePicker';
import styles from './styles.scss';

function XDFDateTime({ it, size, model, onPropChanged }) {
  const {
    id,
    prop,
    label,
    placeholder,
    autoFocus,
    span,
    readOnly,
    showOnly,
    showTime,
    format,
    noW100,
    disabledDate,
    iso8601,
  } = it;

  const onChange = (date, dateString) => {
    if (iso8601) onPropChanged(it, (date || dayjs()).toISOString());
    else onPropChanged(it, dateString);
  };

  const val = gv(model, prop, dayjs().format(format || DEFAULT_DATEFORMAT));
  let dateVal = dayjs(val, !iso8601 ? format || DEFAULT_DATEFORMAT : undefined);
  const dateWithFormatValid = dateVal.isValid();
  if (!dateWithFormatValid) {
    dateVal = dayjs(val);
  }

  // useEffect(() => {
  //   if (!dateWithFormatValid) {
  //     onChange(dateVal, dateVal.format(format || DEFAULT_DATEFORMAT));
  //   }
  // }, [dateWithFormatValid]);

  // console.log('val', val, '->', format || DEFAULT_DATEFORMAT, '->', dateVal);
  return (
    <Col span={span || 24}>
      <div className={styles.formItem}>
        <div className={styles.formItemLabel}>
          <label htmlFor={id}>{label}</label>
        </div>
        <div
          className={cn(
            styles.formItemField,
            { [styles.small]: size === 'small' },
            { [styles.showOnly]: !!showOnly },
          )}
        >
          <DatePicker
            id={id}
            size={size}
            placeholder={placeholder || ''}
            autoFocus={!!autoFocus}
            disabled={!!(readOnly || showOnly)}
            value={dateVal}
            onChange={onChange}
            style={{ width: noW100 ? 'auto' : '100%' }}
            format={format || DEFAULT_DATEFORMAT}
            showTime={showTime}
            allowClear={false}
            disabledDate={disabledDate}
          />
        </div>
      </div>
    </Col>
  );
}

XDFDateTime.propTypes = {
  it: PropTypes.shape({
    id: PropTypes.string.isRequired,
    prop: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    placeholder: PropTypes.string,
    autoFocus: PropTypes.bool,
    span: PropTypes.number,
    readOnly: PropTypes.bool,
    showOnly: PropTypes.bool,
    showTime: PropTypes.bool,
    format: PropTypes.string,
    noW100: PropTypes.bool,
    disabledDate: PropTypes.func,
    iso8601: PropTypes.bool,
  }).isRequired,
  size: PropTypes.string.isRequired,
  model: PropTypes.shape({}).isRequired,
  onPropChanged: PropTypes.func.isRequired,
};

export default XDFDateTime;
