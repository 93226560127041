import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Col, Select } from 'antd';
import cn from 'classnames';
import gv from 'get-value';
import PropTypes from 'prop-types';

import styles from './styles.scss';

function XDFTags({ it, size, model, onPropChanged }) {
  const { id, prop, label, placeholder, autoFocus, span, readOnly, values } = it;

  const mountedRef = useRef(false);
  const isMounted = useCallback(() => mountedRef.current, []);

  const [loading, setLoading] = useState(false);
  const [values0, setValues0] = useState([]);

  // mount / unmount
  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  });

  // load values, if promise
  useEffect(() => {
    if (values && Array.isArray(values)) {
      setValues0(values);
    } else {
      // eslint-disable-next-line react/prop-types
      values().then((list) => {
        if (isMounted()) {
          setValues0(list);
          setLoading(false);
        }
      });
      setLoading(true);
    }
  }, [values]);

  const onChange = (value) => {
    onPropChanged(it, value);
  };

  const val = gv(model, prop, []);
  return (
    <Col span={span || 24}>
      <div className={styles.formItem}>
        <div className={styles.formItemLabel}>
          <label htmlFor={id}>{label}</label>
        </div>
        <div
          className={cn(
            styles.formItemField,
            { [styles.small]: size === 'small' },
            { [styles.large]: size === 'large' },
          )}
        >
          <Select
            id={id}
            size={size}
            placeholder={placeholder || ''}
            autoFocus={!!autoFocus}
            disabled={!!readOnly}
            value={val}
            onChange={onChange}
            style={{ width: '100%' }}
            showSearch
            loading={loading}
            mode="tags"
          >
            {(values0 || []).map((v) => (
              <Select.Option key={v.id} value={v.id}>
                {v.name}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
    </Col>
  );
}

XDFTags.propTypes = {
  it: PropTypes.shape({
    id: PropTypes.string.isRequired,
    prop: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    placeholder: PropTypes.string,
    autoFocus: PropTypes.bool,
    span: PropTypes.number,
    readOnly: PropTypes.bool,
    values: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  }).isRequired,
  size: PropTypes.string.isRequired,
  model: PropTypes.shape({}).isRequired,
  onPropChanged: PropTypes.func.isRequired,
};

export default XDFTags;
