import React, { useEffect, useRef, useState } from 'react';

import { useXDFCss, useXDFScript } from './xdf-hooks';

const JODIT_JS_URL = '//cdnjs.cloudflare.com/ajax/libs/jodit/3.6.1/jodit.min.js';
const JODIT_CSS_URL = '//cdnjs.cloudflare.com/ajax/libs/jodit/3.6.1/jodit.min.css';

const config = {
  showCharsCounter: false,
  showWordsCounter: false,
  showXPathInStatusbar: false,
  toolbarButtonSize: 'small',
  buttons:
    'bold,strikethrough,underline,italic,|,ul,ol,|,outdent,indent,|,font,fontsize,brush,|,link,|,align,hr,|,source',
  buttonsMD:
    'bold,strikethrough,underline,italic,|,ul,ol,|,outdent,indent,|,font,fontsize,brush,|,link,|,align,hr,|,source',
  buttonsSM:
    'bold,strikethrough,underline,italic,|,ul,ol,|,outdent,indent,|,font,fontsize,brush,|,link,|,align,hr,|,source',
  buttonsXS:
    'bold,strikethrough,underline,italic,|,ul,ol,|,outdent,indent,|,font,fontsize,brush,|,link,|,align,hr,|,source',
  uploader: {
    insertImageAsBase64URI: true,
  },
};
// 'bold,strikethrough,underline,italic,|,ul,ol,|,outdent,indent,|,font,fontsize,brush,|,image,link,|,align,hr,|,source',

// eslint-disable-next-line import/prefer-default-export
export const useJOdit = (ref, initContent) => {
  const editor = useRef(null);

  const [doneJS] = useXDFScript(JODIT_JS_URL);
  const [libDone] = useXDFCss(doneJS && JODIT_CSS_URL);

  const [html, setHtml] = useState('');
  const [initDone, setInitDone] = useState(false);

  useEffect(() => {
    if (libDone && !editor.current) {
      // eslint-disable-next-line no-undef
      editor.current = Jodit.make(ref.current, config);
      editor.current.setEditorValue(initContent || '');
      editor.current.events.on('change', (v) => {
        // console.log('Jodit', v);
        setHtml(v);
      });
      setInitDone(true);
      // console.log('!!!!!!!!!!!!!!!!!!!!!!!!', !!editor.current);
    }
    return () => {
      // console.log('------------------------', !!editor.current);
      if (editor.current) {
        editor.current.destruct();
        editor.current = null;
      }
    };
  }, [libDone, ref.current]);

  useEffect(() => {
    // console.log('initContent', initContent);
    if (initContent && editor.current) editor.current.setEditorValue(initContent);
  }, [initContent]);

  return [initDone, html];
};
