import { apiPost, ApiError } from '.';

/**
 * Запрос серий продуктов
 */
function apiW3UserList(collection, role = 0) {
  return async (page, pageSize, filter, props, sortProp, sortOrder, searchProps) => {
    const { err, errmsg, data } = await apiPost('/webinar3/user/list', {
      collection,
      role,
      page,
      pageSize,
      filter,
      props,
      sortProp,
      sortOrder,
      schProps: searchProps,
    });
    if (err !== 0) throw ApiError(err, errmsg);
    return data;
  };
}

export default apiW3UserList;
