import React from 'react';
import { Button, Tooltip } from 'antd';
import { AppstoreAddOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { XDF_TYPE } from '../../../components/xdf-antd/XDataForm';
import { prettySize } from 'pretty-size';

export const commonColumns = () => ({
  name: null,
  props: [], // список полей для запроса к серверу
  rowStyle: (item) => {
    if (item.status === 0) return { color: 'rgba(255,0,0,0.5)' };
    return {};
  },
  columns: [
    {
      prop: '+status',
      name: 'Тип',
      sort: true,
      nowrap: true,
    },
    {
      prop: '+order',
      name: 'Сортировка',
      sort: true,
      nowrap: true,
    },
    {
      prop: '+name',
      name: 'Название',
      sort: true,
      nowrap: true,
    },
    {
      prop: '+homeDesc',
      name: 'Краткое описание',
      sort: true,
      nowrap: true,
    },
  ],
});

export const productElementsColumns = (
  onAddNewElement,
  onEditElement,
  onDeleteElement,
  isAddDisabled,
) => [
  {
    title: 'Заголовок',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Подробности',
    dataIndex: 'desc',
    key: 'desc',
  },
  {
    title: (
      <Tooltip title="Добавить новый элемент (не более 3)" placement="right">
        <Button
          type="primary"
          shape="circle"
          icon={<AppstoreAddOutlined />}
          disabled={isAddDisabled}
          onClick={onAddNewElement}
        />
      </Tooltip>
    ),
    key: 'actions',
    align: 'center',
    render: (text, record, index) => (
      <>
        <Tooltip title="Редактировать элемент" placement="left">
          <Button
            size="small"
            type="primary"
            shape="circle"
            icon={<EditOutlined />}
            style={{ marginRight: 8 }}
            onClick={() => onEditElement(index)}
          />
        </Tooltip>
        <Tooltip title="Удалить элемент" placement="right">
          <Button
            size="small"
            shape="circle"
            icon={<DeleteOutlined />}
            onClick={() => onDeleteElement(index)}
          />
        </Tooltip>
      </>
    ),
  },
];

export const elementForm = () => [
  {
    type: XDF_TYPE.PICTURE_UPLOAD,
    label: 'Картинка элемента',
    prop: 'pic',
    span: 24,
  },
  {
    type: XDF_TYPE.TEXT,
    label: 'Заголовок',
    prop: 'title',
    span: 24,
    autoFocus: true,
  },
  {
    type: XDF_TYPE.TEXT,
    label: 'Описание',
    prop: 'desc',
    span: 24,
  },
];

export const productFilesColumns = (onAddNewFile, onEditFile, onDeleteFile) => [
  {
    title: 'Заголовок',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Месяц',
    dataIndex: 'month',
    key: 'month',
  },
  {
    title: 'Год',
    dataIndex: 'year',
    key: 'year',
  },
  {
    title: 'Размер',
    dataIndex: 'size',
    key: 'size',
    render: (text) => prettySize(text, true),
  },
  {
    title: (
      <Tooltip title="Добавить новый файл" placement="right">
        <Button
          type="primary"
          shape="circle"
          icon={<AppstoreAddOutlined />}
          onClick={onAddNewFile}
        />
      </Tooltip>
    ),
    key: 'actions',
    align: 'center',
    render: (text, record, index) => (
      <>
        <Tooltip title="Редактировать файл" placement="left">
          <Button
            size="small"
            type="primary"
            shape="circle"
            icon={<EditOutlined />}
            style={{ marginRight: 8 }}
            onClick={() => onEditFile(index)}
          />
        </Tooltip>
        <Tooltip title="Удалить файл" placement="right">
          <Button
            size="small"
            shape="circle"
            icon={<DeleteOutlined />}
            onClick={() => onDeleteFile(index)}
          />
        </Tooltip>
      </>
    ),
  },
];

export const fileForm = () => [
  {
    type: XDF_TYPE.TEXT,
    label: 'Укажите заголовок файла',
    prop: 'title',
    span: 24,
    autoFocus: true,
  },
  {
    type: XDF_TYPE.TEXT,
    label: 'Укажите название месяца',
    prop: 'month',
    span: 16,
  },
  {
    type: XDF_TYPE.NUMBER,
    label: 'Год',
    prop: 'year',
    span: 8,
  },
  {
    type: XDF_TYPE.SHOW_TEXT,
    label: 'Название файла на диске',
    prop: 'rname',
    span: 16,
  },
  {
    type: XDF_TYPE.SHOW_FILE_SIZE,
    label: 'Размер файла',
    prop: 'size',
    span: 8,
  },
  {
    type: XDF_TYPE.UPLOAD_BIG_FILE,
    title: 'Загрузить файл',
    prop: 'name',
    fileProps: ['rname', 'size'],
    maxFileSize: 64 * 1024 * 1024,
    span: 24,
  },
];
