import React from 'react';

import { Checkbox, Col } from 'antd';
import PropTypes from 'prop-types';
import gv from 'get-value';

import styles from './styles.scss';

function XDFDividerWithCheckbox({ it, model, onPropChanged, fontColor }) {
  const { label, labelSup, span, id, prop, boolType, autoFocus, readOnly } = it;

  const onChange = (ev) => {
    let val = !!ev.target.checked;
    if (!boolType) val = val ? 1 : 0;
    onPropChanged(it, val);
  };

  const val = gv(model, prop, boolType ? false : 0);
  return (
    <Col span={span || 24}>
      <div className={styles.formItem}>
        <div className={styles.formItemLabel}>
          <div className={styles.dividerItem} style={{ color: fontColor }}>
            {label} {!!labelSup && <sup>{labelSup}</sup>}
            <div className={styles.chbh}>
              <Checkbox
                id={id}
                onChange={onChange}
                disabled={!!readOnly}
                autoFocus={!!autoFocus}
                checked={!!val}
              />
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
}
XDFDividerWithCheckbox.defaultProps = {
  fontColor: '#40a9ff',
};

XDFDividerWithCheckbox.propTypes = {
  it: PropTypes.shape({
    id: PropTypes.string.isRequired,
    prop: PropTypes.string.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    boolType: PropTypes.bool,
    autoFocus: PropTypes.bool,
    readOnly: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    labelSup: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.bool]),
    span: PropTypes.number,
  }).isRequired,
  model: PropTypes.shape({}).isRequired,
  onPropChanged: PropTypes.func.isRequired,
  fontColor: PropTypes.string,
};

export default XDFDividerWithCheckbox;
