import React from 'react';

import { Col, Input, Tooltip } from 'antd';
import cn from 'classnames';
import gv from 'get-value';
import PropTypes from 'prop-types';

import styles from './styles.scss';

function XDFText({ it, size, model, onPropChanged, onEnterPress }) {
  const {
    id,
    prop,
    label,
    placeholder,
    autoFocus,
    span,
    readOnly,
    showOnly,
    suffix,
    require,
    tooltip,
    showCount,
    labelWrap,
  } = it;

  const onChange = (ev) => {
    onPropChanged(it, ev.target.value);
  };

  const onKeyDown = (ev) => {
    if (ev.keyCode === 13) {
      if (onEnterPress) onEnterPress(ev, it);
    }
  };

  const val = gv(model, prop, '');
  return (
    <Col span={span || 24}>
      <div className={styles.formItem} data-pname={prop}>
        <div className={cn(styles.formItemLabel, { [styles.labelWrap]: labelWrap })}>
          <label htmlFor={id}>
            {label}
            {require && <sup>*</sup>}
          </label>
        </div>
        <Tooltip title={tooltip}>
          <div
            className={cn(
              styles.formItemField,
              { [styles.small]: size === 'small' },
              { [styles.showOnly]: !!showOnly },
            )}
          >
            <Input
              id={id}
              size={size}
              placeholder={placeholder || ''}
              autoFocus={!!autoFocus}
              disabled={!!readOnly || !!showOnly}
              value={val}
              onChange={onChange}
              suffix={suffix}
              onKeyDown={onKeyDown}
              showCount={!!showCount}
            />
          </div>
        </Tooltip>
      </div>
    </Col>
  );
}

XDFText.propTypes = {
  it: PropTypes.shape({
    id: PropTypes.string.isRequired,
    prop: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    placeholder: PropTypes.string,
    autoFocus: PropTypes.bool,
    span: PropTypes.number,
    readOnly: PropTypes.bool,
    showOnly: PropTypes.bool,
    suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    require: PropTypes.bool,
    onEnter: PropTypes.func,
    tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    showCount: PropTypes.bool,
    labelWrap: PropTypes.bool,
  }).isRequired,
  size: PropTypes.string.isRequired,
  model: PropTypes.shape({}).isRequired,
  onPropChanged: PropTypes.func.isRequired,
  onEnterPress: PropTypes.func.isRequired,
};

export default XDFText;
