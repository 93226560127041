import React, { useEffect, useState } from 'react';

import { Button, Drawer, Layout, Menu, Space } from 'antd';
import { BrowserView, MobileView } from 'react-device-detect';
import { Redirect, Route, Switch, useHistory, matchPath, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import {
  CloseOutlined,
  FileProtectOutlined,
  LogoutOutlined,
  MenuOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';

import KybLogoIcon from '../../../assets/svg/kyb-logo.svg';
import { meState } from '../../../state/panel-state';
import W3TestRun from '../W3Test/Run/W3TestRun';
import WebinarEventsActiveList from '../WebinarEvents/Active/WebinarEventsActiveList';
import WebinarEventsAvailableList from '../WebinarEvents/Available/WebinarEventsAvailableList';
import WebinarEventsFinishedList from '../WebinarEvents/Finished/WebinarEventsFinishedList';
import styles from './styles.scss';
import Webinars3UserLogout from './Webinars3UserLogout';
import Webinars3UserProfile from '../Home/Webinars3UserProfile';

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const items = [
  getItem('Мои вебинары и семинары', 'events', <VideoCameraOutlined />, [
    getItem('Активные записи', 'active'),
    getItem('Доступные для записи', 'available'),
    getItem('Завершённые', 'finished'),
  ]),
  // getItem('Мои сертификаты', 'certificates', <FileProtectOutlined />),
  {
    type: 'divider', // Must have
  },
  getItem('Профиль', 'profile', <UserOutlined />),
  getItem('Выход', 'logout', <LogoutOutlined />),
];

const routes = [
  // <Route key="active" path="/" exact component={Webinars3HomePage} />,
  <Redirect key="root" exact from="/" to="/events/active" />,
  <Route key="profile" path="/profile" exact component={Webinars3UserProfile} />,
  <Route key="logout" path="/logout" exact component={Webinars3UserLogout} />,
  <Route key="events_active" path="/events/active" exact component={WebinarEventsActiveList} />,
  <Route key="test_run" path="/test/run/:eventId" exact component={W3TestRun} />,
  <Route
    key="events_finished"
    path="/events/finished"
    exact
    component={WebinarEventsFinishedList}
  />,
  <Route
    key="events_available"
    path="/events/available"
    exact
    component={WebinarEventsAvailableList}
  />,
];

function Webinars3MainForm() {
  const history = useHistory();
  const location = useLocation();

  const me = useRecoilValue(meState);
  const [mobiLeftMenuVisible, setMobiLeftMenuVisible] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(['active']);

  useEffect(() => {
    routes.forEach((r) => {
      if (matchPath(location.pathname, r.props.path)) {
        setSelectedMenu(r.key.split('_'));
      }
    });
  }, [location?.pathname]);

  const onMenuSelect = ({ keyPath }) => {
    const url = keyPath.reverse().join('/');
    history.push(`/${url}`);
    setMobiLeftMenuVisible(false);
  };

  return (
    <>
      <BrowserView>
        <Layout className={styles.web3MainFrame}>
          <Layout.Sider width={256} trigger={null} collapsible collapsed={false} theme="light">
            <div className={styles.logo}>
              <KybLogoIcon />
            </div>
            <Menu
              theme="light"
              defaultOpenKeys={['events']}
              selectedKeys={selectedMenu}
              mode="inline"
              items={items}
              onSelect={onMenuSelect}
            />
          </Layout.Sider>
          <Layout className="site-layout">
            <Layout.Header theme="light">
              <span className={styles.fio}>
                {me?.webinarUser?.lastname} {me?.webinarUser?.firstname}
              </span>
            </Layout.Header>
            <Layout.Content
              className="site-layout-background"
              style={{
                minHeight: 280,
              }}
            >
              <Switch>{routes}</Switch>
            </Layout.Content>
          </Layout>
        </Layout>
      </BrowserView>

      <MobileView>
        <Layout className={styles.web3MainFrame}>
          <Layout className={styles.mobiLayout}>
            <Layout.Header theme="light" className={styles.mobi}>
              <div className={styles.left}>
                <KybLogoIcon />
              </div>
              <div className={styles.right}>
                <Button
                  type="text"
                  size="large"
                  icon={<MenuOutlined />}
                  onClick={() => setMobiLeftMenuVisible(!mobiLeftMenuVisible)}
                />
              </div>
            </Layout.Header>

            <Layout.Content
              style={{
                minHeight: 280,
              }}
            >
              <Switch>{routes}</Switch>
            </Layout.Content>
          </Layout>
        </Layout>

        <Drawer
          title={`${me?.webinarUser?.lastname} ${me?.webinarUser?.firstname}`}
          className={styles.mobiDrawer}
          placement="left"
          onClose={() => setMobiLeftMenuVisible(false)}
          visible={mobiLeftMenuVisible}
          closable={false}
          extra={
            <Space>
              <Button
                type="text"
                size="large"
                icon={<CloseOutlined />}
                onClick={() => setMobiLeftMenuVisible(!mobiLeftMenuVisible)}
              />
            </Space>
          }
          bodyStyle={{ padding: 0 }}
          contentWrapperStyle={{ width: '90%' }}
          headerStyle={{ padding: '12px 12px 12px 24px' }}
        >
          <Menu
            theme="light"
            defaultSelectedKeys={['active']}
            defaultOpenKeys={['events']}
            mode="inline"
            items={items}
            onSelect={onMenuSelect}
          />
        </Drawer>
      </MobileView>
    </>
  );
}

Webinars3MainForm.defaultProps = {};

Webinars3MainForm.propTypes = {};

export default Webinars3MainForm;
