import React, { useEffect, useState } from 'react';

import { Button, Modal, Skeleton, message } from 'antd';
import PropTypes from 'prop-types';

import apiAnyGet from '../../../../api/AnyGet';
import GTooltip from '../../../components/GTooltip/GTooltip';
import XDataForm from '../../../components/xdf-antd/XDataForm';
import { XDF_TYPE } from '../../../components/xdf-antd/XDataForm/index';
import styles from './styles.scss';
import apiUserStateSet from '../../../../api/UserStateSet';
import { warningDialog } from '../../../components/Common/WarningDialog';

function UserAuthorizationDlg({ userId, onClose }) {
  const [model, setModel] = useState(null);

  useEffect(async () => {
    if (userId > 0) {
      const user = await apiAnyGet('User')(userId, [
        '_id',
        'name',
        'lastName',
        'midName',
        'email',
        'phone',
        'phoneOk',
        'phoneLastCodeDt',
        'company',
        'status',
        'country',
        'city',
        'desc',
      ]);

      if (user.phoneOk === 1) user.__phoneState = 'Телефон подтвержден';
      else if (user.phoneLastCodeDt) user.__phoneState = 'Код выслан';

      setModel(user);
    } else {
      setModel(null);
    }
  }, [userId]);

  function onChangeModel(m, isChanged) {
    setModel({ ...m });
  }

  async function blockUser() {
    const { err } = await apiUserStateSet(userId, -1);
    if (!err) message.info('Пользователь заблокирован.');
    onClose();
  }

  async function approveUser() {
    const { err } = await apiUserStateSet(userId, 1);
    if (!err)
      message.info(
        'Пользователь авторизован. Продолжите настройку прав доступа в разделе "Разрешенный доступ"',
      );
    onClose();
  }

  async function onBlockUser() {
    const res = await warningDialog('Смена статуса пользователя', 'Заблокировать пользователя?', [
      { label: 'Отмена' },
      { label: 'Заблокировать' },
    ]);
    if (res === 1) {
      blockUser();
    }
  }

  async function onApproveUser() {
    const res = await warningDialog(
      'Смена статуса пользователя',
      'Авторизовать пользователя в сервисе?',
      [{ label: 'Отмена' }, { label: 'Авторизовать' }],
    );
    if (res === 1) {
      approveUser();
    }
  }

  return (
    <Modal
      className={styles.kybLoginDlg}
      title={<GTooltip tid="authorization_dlg">Управление пользовательским доступом</GTooltip>}
      width={880}
      open={userId > 0}
      closable
      onCancel={onClose}
      footer={[
        <Button key="block" disabled={!model} onClick={onBlockUser}>
          Заблокировать
        </Button>,
        <Button key="approve" type="primary" disabled={!model} onClick={onApproveUser}>
          Авторизовать
        </Button>,
      ]}
    >
      {!model && <Skeleton active />}

      {!!model && (
        <XDataForm
          model={model}
          onChangeModel={onChangeModel}
          form={[
            {
              type: XDF_TYPE.TEXT,
              label: 'ID пользователя',
              prop: '_id',
              span: 8,
              showOnly: true,
            },
            {
              type: XDF_TYPE.TEXT,
              label: 'Телефон',
              prop: 'phone',
              span: 8,
              showOnly: true,
            },
            {
              type: XDF_TYPE.TEXT,
              label: 'Состояние',
              prop: '__phoneState',
              span: 8,
              showOnly: true,
            },
            {
              type: XDF_TYPE.TEXT,
              label: 'Фамилия',
              prop: 'lastName',
              span: 8,
              showOnly: true,
            },
            {
              type: XDF_TYPE.TEXT,
              label: 'Имя',
              prop: 'name',
              span: 8,
              showOnly: true,
            },
            {
              type: XDF_TYPE.TEXT,
              label: 'Отчество',
              prop: 'midName',
              span: 8,
              showOnly: true,
            },
            {
              type: XDF_TYPE.TEXT,
              label: 'E-mail',
              prop: 'email',
              span: 8,
              showOnly: true,
            },
            {
              type: XDF_TYPE.TEXT,
              label: 'Компания',
              prop: 'company',
              span: 8,
              showOnly: true,
            },
            {
              type: XDF_TYPE.TEXT,
              label: 'Должность',
              prop: 'status',
              span: 8,
              showOnly: true,
            },
            {
              type: XDF_TYPE.TEXT,
              label: 'Страна',
              prop: 'country',
              span: 8,
              showOnly: true,
            },
            {
              type: XDF_TYPE.TEXT,
              label: 'Город',
              prop: 'city',
              span: 8,
              showOnly: true,
            },
            {
              type: XDF_TYPE.TEXTAREA,
              label: 'Дополнительная информация',
              prop: 'desc',
              rows: 4,
              span: 16,
              showOnly: true,
            },
          ]}
        />
      )}
    </Modal>
  );
}

UserAuthorizationDlg.defaultProps = {};

UserAuthorizationDlg.propTypes = {
  userId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default UserAuthorizationDlg;
