import React, { useEffect, useState } from 'react';

import { Button, Collapse, Layout, message, PageHeader, Spin, Switch, Typography } from 'antd';
import objectEqual from 'fast-deep-equal/es6';
import { useHistory, useParams } from 'react-router-dom';

import { SettingOutlined } from '@ant-design/icons';

import apiAnyGet from '../../../../api/AnyGet';
import apiUserSet from '../../../../api/UserSet';
import apiUserStateSet from '../../../../api/UserStateSet';
import { KYB_STAFF_ACCESS_ID2NAME } from '../../../../routes';
import { warningDialog } from '../../../components/Common/WarningDialog';
import GTooltip from '../../../components/GTooltip/GTooltip';
import XDataForm, { XDF_TYPE } from '../../../components/xdf-antd/XDataForm';
import KybStaffAccessDialog from './access/KybStaffAccessDialog';
import styles from './styles.scss';

export default function UserAccessEditor() {
  const history = useHistory();
  const { userId } = useParams();
  // eslint-disable-next-line no-underscore-dangle
  const _userId = parseInt(userId, 10);

  const [model, setModel] = useState(null);
  const [oldModel, setOldModel] = useState(null);
  const [isModelChanged, setIsModelChanged] = useState(false);

  const [kybStaffAccDlg, setKybStaffAccDlg] = useState({ visible: false, acc: [] });

  const loadUser = async (reset = true) => {
    if (reset) {
      setModel(null);
      setOldModel(null);
    }

    const user = await apiAnyGet('User')(_userId, [
      '_id',
      'cdt',
      'name',
      'lastName',
      'midName',
      'email',
      'phone',
      'company',
      'status',
      'country',
      'city',
      'desc',
      'kybStaff',
      'kybRoles',
    ]);

    setModel(user);
    setOldModel(user);
    setIsModelChanged(false);
  };

  // load user
  useEffect(() => {
    if (userId > 0) {
      loadUser();
    }
  }, [userId]);

  const onChangeModel = (m) => {
    const _m = { ...model, ...m };
    setModel(_m);
    setIsModelChanged(!objectEqual(_m, oldModel));
  };

  const showKybStaffAccessDialog = (ev) => {
    ev.stopPropagation();
    setKybStaffAccDlg({ ...kybStaffAccDlg, visible: true, acc: model.kybRoles || [] });
  };

  const saveUser = async () => {
    const { err } = await apiUserSet(_userId, model);
    if (!err) {
      message.info('Пользователь сохраненен');
      await loadUser(false);
    }
  };

  const onSaveUser = async () => {
    const res = await warningDialog(
      'Изменение настроек доступа',
      'Сохранить выбранные для пользователя настройки?',
      [{ label: 'Отмена' }, { label: 'Сохранить' }],
    );
    if (res === 1) {
      saveUser();
    }
  };

  const blockUser = async () => {
    const { err } = await apiUserStateSet(_userId, -1);
    if (!err) {
      message.info('Пользователь заблокирован', 9);
      history.goBack();
    }
  };

  const onBack = async () => {
    if (!isModelChanged) {
      history.goBack();
      return;
    }

    const res = await warningDialog(
      'Есть несохраненные изменения',
      'Сохранить перед выходом внесённые изменения?',
      [{ label: 'Не сохранять' }, { label: 'Сохранить' }],
    );
    if (res === 0) {
      history.goBack();
    } else if (res === 1) {
      await saveUser();
      history.goBack();
    }
  };

  return (
    <>
      <PageHeader
        className={styles.pageHeader}
        title={
          <>
            <GTooltip tid="user_access_editor">Настройка доступа</GTooltip>
            {!model && <Spin style={{ marginLeft: 16 }} />}
          </>
        }
        onBack={onBack}
        extra={[
          !!model && (
            <Button key="block" onClick={blockUser}>
              Заблокировать
            </Button>
          ),
          !!model && (
            <Button key="save" type="primary" disabled={!isModelChanged} onClick={onSaveUser}>
              Сохранить
            </Button>
          ),
        ].filter(Boolean)}
      />

      {!!model && (
        <Layout.Content>
          <div className={styles.contextHolder}>
            <div className={styles.context}>
              <Typography.Title level={5}>Общая информация</Typography.Title>
              <XDataForm
                model={model}
                onChangeModel={onChangeModel}
                form={[
                  {
                    type: XDF_TYPE.TEXT,
                    label: 'ID пользователя',
                    prop: '_id',
                    span: 6,
                    showOnly: true,
                  },
                  {
                    type: XDF_TYPE.TEXT,
                    label: 'Телефон',
                    prop: 'phone',
                    span: 6,
                    showOnly: true,
                  },
                  {
                    type: XDF_TYPE.SHOW_DATETIME,
                    label: 'Дата регистрации:',
                    prop: 'cdt',
                    format: 'DD.MM.YYYY HH:mm',
                    span: 6,
                  },
                  {
                    type: XDF_TYPE.TEXT,
                    label: 'E-mail',
                    prop: 'email',
                    span: 6,
                    showOnly: true,
                  },

                  {
                    type: XDF_TYPE.TEXT,
                    label: 'Фамилия',
                    prop: 'lastName',
                    span: 6,
                  },
                  {
                    type: XDF_TYPE.TEXT,
                    label: 'Имя',
                    prop: 'name',
                    span: 6,
                  },
                  {
                    type: XDF_TYPE.TEXT,
                    label: 'Отчество',
                    prop: 'midName',
                    span: 6,
                  },
                  {
                    type: XDF_TYPE.SPACE,
                    span: 6,
                  },

                  {
                    type: XDF_TYPE.TEXT,
                    label: 'Компания',
                    prop: 'company',
                    span: 6,
                  },
                  {
                    type: XDF_TYPE.TEXT,
                    label: 'Должность',
                    prop: 'status',
                    span: 6,
                  },
                  {
                    type: XDF_TYPE.TEXT,
                    label: 'Страна',
                    prop: 'country',
                    span: 6,
                  },
                  {
                    type: XDF_TYPE.TEXT,
                    label: 'Город',
                    prop: 'city',
                    span: 6,
                  },

                  {
                    type: XDF_TYPE.TEXTAREA,
                    label: 'Дополнительная информация',
                    prop: 'desc',
                    rows: 2,
                    span: 12,
                  },
                ]}
              />
            </div>
          </div>

          <div className={styles.contextHolder}>
            <div className={styles.context}>
              <Typography.Title level={5}>Служебный доступ</Typography.Title>
              <div>
                <Switch
                  checkedChildren="Включен"
                  unCheckedChildren="Выключен"
                  onChange={(state) => onChangeModel({ kybStaff: state })}
                  checked={model.kybStaff}
                />
                <span style={{ marginLeft: 16 }}>Доступ к админ панели - Модератора KYB</span>
              </div>
              {model?.kybStaff && (
                <div style={{ marginTop: 16 }}>
                  <Collapse>
                    <Collapse.Panel
                      header="Модератор – КУБ ЕВРАЗИЯ"
                      key="1"
                      extra={
                        <Button
                          type="link"
                          size="small"
                          icon={<SettingOutlined />}
                          onClick={showKybStaffAccessDialog}
                        />
                      }
                    >
                      <div>
                        {(model.kybRoles || [])
                          .sort()
                          .filter((role) => !!KYB_STAFF_ACCESS_ID2NAME[role])
                          .map((role) => (
                            <div key={role} className={styles.roleItem}>
                              {KYB_STAFF_ACCESS_ID2NAME[role]}
                            </div>
                          ))}
                      </div>
                    </Collapse.Panel>
                  </Collapse>
                </div>
              )}
            </div>
          </div>

          <div className={styles.contextHolder}>
            <div className={styles.context}>
              <Typography.Title level={5}>Доступ к личным кабинетам</Typography.Title>
              <div>
                <Button disabled>Добавить кабинет</Button>
              </div>
            </div>
          </div>
        </Layout.Content>
      )}

      <KybStaffAccessDialog
        visible={kybStaffAccDlg.visible}
        onClose={() => setKybStaffAccDlg({ ...kybStaffAccDlg, visible: false })}
        onSave={(acc) => {
          onChangeModel({ kybRoles: acc });
          setKybStaffAccDlg({ ...kybStaffAccDlg, visible: false });
        }}
        acc={kybStaffAccDlg.acc}
      />
    </>
  );
}
