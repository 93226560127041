import React, { useRef, useState } from 'react';

import { Button, Layout, message, PageHeader, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { useRecoilValue } from 'recoil';

import { PlusOutlined, SaveOutlined, UndoOutlined } from '@ant-design/icons';

import apiAnyList from '../../../../api/AnyList';
import { apiPost } from '../../../../api/index';
import { subscribeToChannel, unsubscribeToChannel } from '../../../../api/sio';
import { notifyLastForRoleState } from '../../../../state/notify-state';
import GTooltip from '../../../components/GTooltip/GTooltip';
import XDataTable from '../../../components/xdf-antd/XDataTable';
import { USER_ROLE } from '../../../constants';
import MainCatalogEditDlg from './MainCatalogEditDlg';
import styles from './styles.scss';
import { commonColumns, mainCatalogFormProps } from './table-defs';

export default function MainCatalogList() {
  const notifyLastForRole = useRecoilValue(notifyLastForRoleState(USER_ROLE.CATALOG_MAIN));

  const undoInlineEdit = useRef(null);
  const [inlineEditVal, setInlineEditVal] = useState({});

  const [editDlg, setEditDlg] = useState({ visible: false, model: {} });

  const onRowClick = (item) => {
    setEditDlg({ visible: true, model: item });
  };

  const addRecord = () => {
    setEditDlg({ visible: true, model: {} });
  };

  const onCloseDlg = () => {
    setEditDlg({ ...editDlg, visible: false });
  };

  // const downloadFile = () => {
  //   window.open('/api/1/catalog/main/export', '_blank');
  // };

  const onInlineEditChanged = (changes, undoInlineEditFun) => {
    undoInlineEdit.current = undoInlineEditFun;
    setInlineEditVal(changes);
  };

  const onUndoInlineEdit = () => {
    if (undoInlineEdit.current) {
      undoInlineEdit.current();
    }
  };

  const onSaveMulti = () => {
    const { err, errmsg } = apiPost('/catalog/main/multi/set', {
      list: Object.keys(inlineEditVal).map((idp) => {
        const [id, prop] = idp.split(':');
        return {
          id,
          prop,
          val: inlineEditVal[idp],
        };
      }),
    });
    if (err) {
      if (Array.isArray(errmsg)) {
        for (const em of errmsg) {
          message.error(em.message, 4);
        }
      } else {
        message.error(errmsg || `Net error: ${err}`);
      }
    } else {
      onUndoInlineEdit();
    }
  };

  return (
    <>
      <PageHeader
        className={styles.pageHeader}
        title={<GTooltip tid="catalogs_main">Управление каталогом</GTooltip>}
        extra={[
          <Tooltip key="save" title="Сохранить изменения">
            <Button
              shape="circle"
              icon={<SaveOutlined />}
              disabled={Object.keys(inlineEditVal).length === 0}
              onClick={onSaveMulti}
            />
          </Tooltip>,
          <Tooltip key="undo" title="Отменить изменения">
            <Button
              shape="circle"
              icon={<UndoOutlined />}
              disabled={Object.keys(inlineEditVal).length === 0}
              onClick={onUndoInlineEdit}
            />
          </Tooltip>,
          <Tooltip key="add" title="Добавить запись">
            <Button
              type="primary"
              shape="circle"
              icon={<PlusOutlined />}
              style={{ marginLeft: 48 }}
              onClick={addRecord}
            />
          </Tooltip>,
          // <Tooltip key="download" title="Скачать файл" onClick={downloadFile}>
          //   <Button type="primary" shape="circle" icon={<CloudDownloadOutlined />} />
          // </Tooltip>,
        ]}
      />
      <Layout.Content>
        <div className={styles.contextHolder}>
          <div className={styles.context}>
            <XDataTable
              apiLoadData={apiAnyList('KybCatalog:Main')}
              commonColumns={commonColumns(notifyLastForRole)}
              extentedColumns={null}
              initSort={['_id', 1]}
              initFilter={{}}
              onRowClick={onRowClick}
              sioEvents={['LIST:MAINCATALOG', subscribeToChannel, unsubscribeToChannel]}
              searchProps={[
                'partNumber',
                'firm',
                'model',
                'part',
                'series',
                'type',
                'codes',
                'version',
              ]}
              inlineEditFormProps={mainCatalogFormProps()}
              onInlineEditChanged={onInlineEditChanged}
              excelExport={{
                filename: `CatalogExport_${dayjs().format('YYYY-MM-DD')}`,
                tabname: 'Каталог',
                exportStep: 5000,
                autoWidth: true,
              }}
            />
          </div>
        </div>
      </Layout.Content>

      <MainCatalogEditDlg visible={editDlg.visible} model={editDlg.model} onClose={onCloseDlg} />
    </>
  );
}
