import React from 'react';

import { Col } from 'antd';
import cn from 'classnames';
// eslint-disable-next-line import/no-extraneous-dependencies
import dayjs from 'dayjs';
import gv from 'get-value';
import PropTypes from 'prop-types';

import { DEFAULT_TIMEFORMAT } from './constants';
import DatePicker from './DayJsDatePicker';
import styles from './styles.scss';

function XDFTimeRange({ it, size, model, onPropChanged }) {
  const { id, prop, prop2, label, placeholder, autoFocus, span, readOnly, format, noW100 } = it;

  const onChange = (times) => {
    onPropChanged(
      it,
      times[0].hour() * 60 + times[0].minute(),
      times[1].hour() * 60 + times[1].minute(),
    );
  };

  const val = gv(model, prop, 420);
  const val2 = gv(model, prop2, 900);

  // console.log('XDFTime', model, val, val2);
  return (
    <Col span={span || 24}>
      <div className={styles.formItem}>
        <div className={styles.formItemLabel}>
          <label htmlFor={id}>{label}</label>
        </div>
        <div className={cn(styles.formItemField, { [styles.small]: size === 'small' })}>
          <DatePicker.RangePicker
            picker="time"
            mode={undefined}
            id={id}
            size={size}
            placeholder={placeholder || ''}
            autoFocus={!!autoFocus}
            disabled={!!readOnly}
            style={{ width: noW100 ? 'auto' : '100%' }}
            format={format || DEFAULT_TIMEFORMAT}
            onChange={onChange}
            value={[
              dayjs()
                .second(0)
                .hour(parseInt(val / 60, 10))
                .minute(val % 60),
              dayjs()
                .second(0)
                .hour(parseInt(val2 / 60, 10))
                .minute(val2 % 60),
            ]}
            minuteStep={5}
          />
        </div>
      </div>
    </Col>
  );
}

XDFTimeRange.propTypes = {
  it: PropTypes.shape({
    id: PropTypes.string.isRequired,
    prop: PropTypes.string.isRequired,
    prop2: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    placeholder: PropTypes.string,
    autoFocus: PropTypes.bool,
    span: PropTypes.number,
    readOnly: PropTypes.bool,
    format: PropTypes.string,
    noW100: PropTypes.bool,
  }).isRequired,
  size: PropTypes.string.isRequired,
  model: PropTypes.shape({}).isRequired,
  onPropChanged: PropTypes.func.isRequired,
};

export default XDFTimeRange;
