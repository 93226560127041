import getValue from 'get-value';

/**
 * Работа как с plain props & dots
 * @param {*} obj
 * @param {*} prop
 * @param {*} def
 * @param {*} plain
 * @returns
 */
const xdfGetValue = (obj, prop, def, plain = false) => {
  if (!plain) return getValue(obj, prop, def);
  return obj[prop] || def;
};

export default xdfGetValue;
