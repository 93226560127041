// Общие колонки, есть на всех видах

import { KYB_SITE_ITEM_STATUS } from '../../../constants';

// eslint-disable-next-line import/prefer-default-export
export function commonColumns() {
  return {
    name: null,
    props: [], // список полей для запроса к серверу
    rowStyle: (item) => {
      if (item.status === 0) return { color: 'rgba(255,0,0,0.5)' };
      return {};
    },
    columns: [
      {
        prop: '+status',
        name: 'Статус',
        sort: true,
        nowrap: true,
        prop2value: [KYB_SITE_ITEM_STATUS, 'id', 'name'],
      },
      {
        prop: '+dt',
        name: 'Дата',
        sort: true,
        nowrap: true,
        type: 'date',
        format: 'DD.MM.YYYY',
      },
      {
        prop: '+title',
        name: 'Заголовок',
        sort: true,
        nowrap: true,
      },
    ],
  };
}
