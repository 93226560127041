/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FilterAllIcon from '../../../assets/images/map/fltr_all.png';
import { netPOSTv2 } from '../../../api';

/*
 * Групповой GoogleMap маркер, параметры:
 * 	position: new google.maps.LatLng
 *  icon: png/gif картинка (становится как background к div)
 *  size: размер div {x,y)
 *  offs: смещение для указания места на карте {x,y}
 *  st: содержимое, которое вставляется в div
 *  data: если присутствует, то st - используется как шалон в MGoMApi.tmpl, а data - данные
 *  title: всплывающая посказка
 */
function googleGroupMarker(opt) {
  // params
  this.position_ = opt.position;
  this.offs_ = opt.offs;
  // create marker
  const div = document.createElement('div');
  div.style.cssText =
    `${
      'line-height:normal;position:absolute;display:block;padding:0px;margin:0px;' +
      'background: url('
    }${opt.icon}) no-repeat;` +
    `background-size:${opt.size[0]}px ${opt.size[1]}px;` +
    `z-index: ${1000 + 100 * opt.data.n}`;
  div.style.border = 'none';
  div.style.borderWidth = '0px';
  div.style.cursor = 'pointer';
  // size
  div.style.width = `${opt.size[0]}px`;
  div.style.height = `${opt.size[1]}px`;
  // title
  if (opt.title !== undefined) {
    div.title = opt.title;
  }

  if (opt.data === null) div.innerHTML = opt.st;
  // SPEEDUP: заменить шаблонизатор на простую замену
  else {
    div.innerHTML = `<div class="kyb-igroup kyb-igroup${opt.data.type}">${opt.data.n}</div>`;
  } /* MGoMApi.tmpl(opt.st, opt.data); */

  // save
  this.div_ = div;
}

export default class KybMap extends Component {
  static propTypes = {
    googleKey: PropTypes.string.isRequired,
    categories: PropTypes.array.isRequired,
    onHide: PropTypes.func.isRequired,
    lat: PropTypes.number,
    lng: PropTypes.number,
    zoom: PropTypes.number,
    onSelectObject: PropTypes.func,

    editObject: PropTypes.object,
    onMovedEditObject: PropTypes.func,
  };

  state = {
    cids: [],
    searchAddress: '',
  };

  constructor(props) {
    super(props);
    this.__mapID = `MAP_ID_${new Date().getTime()}`;
    this.__map = null;

    if (!window.google || !window.google.maps) {
      this.loadGoogleMapApi();
    }
  }

  componentDidMount() {
    this.__mount = true;

    setTimeout(() => {
      if (this.__map === null) this.initGoogleMap();
    }, 50);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.editMarket(nextProps);

    if (this.props.lat !== nextProps.lat || this.props.lng !== nextProps.lng) {
      if (this.__map && nextProps.lat !== undefined && nextProps.lng !== undefined) {
        this.__map.setCenter({ lat: nextProps.lat, lng: nextProps.lng });
      }
    }
  }

  componentWillUnmount() {
    this.__mount = false;
    delete this.__map;
    this.__map = null;
  }

  loadGoogleMapApi = () => {
    // console.log('loadGoogleMapApi');
    window.initGoogleMap = this.initGoogleMap;
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${this.props.googleKey}&callback=initGoogleMap`;
    document.head.appendChild(script);
  };

  initGoogleMap = () => {
    if (
      !this.__mount ||
      this.__map !== null ||
      window.google === undefined ||
      window.google.maps === undefined
    )
      return;

    // console.log('initGoogleMap');
    const { categories, lat, lng, zoom } = this.props;
    // create info for icons
    this.__catIcons = {};
    categories.forEach((c) => {
      this.__catIcons[c.id] = {
        i: c.icon,
        w: c.iW,
        h: c.iH,
        x: c.iX,
        y: c.iY,
      };
    });

    const _lat = lat || 55.745827;
    const _lng = lng || 37.624513;
    const _zoom = zoom || 11;

    // init google map
    this.__map = new window.google.maps.Map(document.getElementById(this.__mapID), {
      center: { lat: _lat, lng: _lng },
      zoom: _zoom,
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
    });

    this.__geocoder = new window.google.maps.Geocoder();

    this.__markers = [];
    this.__merkersZoom = 0;

    this.create_googleGroupMarker();

    this.addFakeLayer();

    this.editMarket(this.props);
  };

  userLocationMoved = (latLng) => {
    const { onMovedEditObject } = this.props;
    const obj = { lat: latLng.lat(), lng: latLng.lng() };
    this.__geocoder.geocode({ location: latLng }, (results, status) => {
      // console.log(status, results);
      if (status === 'OK' && results && results.length > 0) {
        obj._addr = results[0].formatted_address;
        results[0].address_components.forEach((x) => {
          if (x.types) {
            if (x.types.includes('locality')) {
              obj.g_city = x.long_name;
            } else if (x.types.includes('administrative_area_level_1')) {
              obj.g_area = x.long_name;
            } else if (x.types.includes('country')) {
              obj.g_country = x.long_name;
            }
          }
        });
      }
      if (onMovedEditObject) {
        onMovedEditObject(obj);
      }
    });
  };

  editMarket = (props) => {
    if (!window.google || !window.google.maps) return;

    const _this = this;
    const { editObject } = props;
    // https://panel.geo4.biz/assets/img/poi4edit.png 50x60 -23 -56

    if (!this.__editMarket) {
      this.__editMarket = new window.google.maps.Marker({
        position: { lat: 0, lng: 0 },
        icon: {
          url: '/assets/static/poi4edit.png',
          size: new window.google.maps.Size(50, 60),
          anchor: new window.google.maps.Point(23, 56),
        },
        zIndex: 1000,
        draggable: true,
        title: 'тащи меня',
      });

      window.google.maps.event.addListener(this.__editMarket, 'dragend', (ev) => {
        _this.userLocationMoved(ev.latLng);
      });
    }

    if (!editObject) {
      // remove
      this.__editMarket.setMap(null);
    } else {
      this.__editMarket.setMap(this.__map);
      this.__editMarket.setPosition({ lat: editObject.lat, lng: editObject.lng });
    }
  };

  addFakeLayer = () => {
    const _this = this;
    this.__fakeLayer = new window.google.maps.ImageMapType({
      getTileUrl(ll, z) {
        _this.tileLoad(ll.x % (1 << z), ll.y, z);
      },
      tileSize: new window.google.maps.Size(256, 256),
      isPng: false,
      opacity: 0,
    });

    // Добавляем слои к карте
    this.__map.overlayMapTypes.insertAt(0, this.__fakeLayer);
  };

  tileLoad = async (x, y, z) => {
    const _this = this;
    const { editObject } = this.props;

    if (this.__merkersZoom !== this.__map.getZoom()) {
      this.__merkersZoom = this.__map.getZoom();
      this.__markers.forEach((m) => m.setMap(null));
      this.__markers = [];
    }

    const { cids } = this.state;
    const { err, data } = await netPOSTv2(`/map/p4t/${z}/${y}/${x}`, { cids });
    if (err === 0) {
      // console.log(data);
      if (data.z === this.__map.getZoom() && data.l) {
        data.l.forEach((m) => {
          if (m.c === 1) {
            if (!editObject || editObject._id !== m.id) {
              const mmm = this.__catIcons[m.cid];

              const marker = new window.google.maps.Marker({
                map: this.__map,
                position: { lat: m.lat, lng: m.lng },
                icon: {
                  url: mmm.i,
                  size: new window.google.maps.Size(mmm.w, mmm.h),
                  anchor: new window.google.maps.Point(mmm.x, mmm.y),
                },
                title: m.a ? 'активно' : 'не активно',
                opacity: m.a ? 1 : 0.4,
              });

              marker.kyb = m;
              this.__markers.push(marker);

              window.google.maps.event.addListener(marker, 'click', function (ev) {
                // $.map('panTo', this.kyb.ll);
                // $.map('zoomIn');
                if (_this.props.onSelectObject) {
                  // console.log(this.kyb);
                  _this.props.onSelectObject(this.kyb);
                }
              });
            }
          } else {
            let type = 1;

            let size = [38, 38];
            if (m.c > 9 && m.c < 100) {
              type = 2;
              size = [48, 48];
            } else if (m.c >= 100) {
              type = 3;
              size = [58, 58];
            }
            // cluster
            const markOpt = {
              position: new window.google.maps.LatLng(m.lat, m.lng),
              icon: `/assets/static/group-icon0${type}.png`,
              size,
              offs: [size[0] / 2, size[1] / 2],
              st: '<div class="mgp_gp1">${n}</div>',
              data: { n: m.c > 999 ? '..' : m.c, type },
            };

            const marker = new googleGroupMarker(markOpt);
            marker.kyb = m;
            marker.setMap(this.__map);
            this.__markers.push(marker);

            window.google.maps.event.addListener(marker, 'click', (ev) => {
              // $.map('panTo', this.kyb.ll);
              // $.map('zoomIn');
            });
          }
        });
      }
    }
  };

  create_googleGroupMarker = () => {
    googleGroupMarker.prototype = new window.google.maps.OverlayView();
    googleGroupMarker.prototype.onAdd = function () {
      const pane = this.getPanes().overlayMouseTarget;
      pane.appendChild(this.div_);
      const me = this;
      this.listeners_ = [
        window.google.maps.event.addDomListener(this.div_, 'click', () => {
          window.google.maps.event.trigger(me, 'click');
        }),
      ];
    };
    googleGroupMarker.prototype.onRemove = function () {
      this.div_.parentNode.removeChild(this.div_);

      // Label is removed from the map, stop updating its position/text.
      for (let i = 0, I = this.listeners_.length; i < I; ++i) {
        window.google.maps.event.removeListener(this.listeners_[i]);
      }
    };
    googleGroupMarker.prototype.draw = function () {
      const projection = this.getProjection();
      const pos = projection.fromLatLngToDivPixel(this.position_);
      pos.x -= this.offs_[0];
      pos.y -= this.offs_[1];

      const div = this.div_;
      div.style.left = `${pos.x}px`;
      div.style.top = `${pos.y}px`;
    };
  };

  addCategory = (cid) => {
    if (cid === 0) {
      this.setState({ cids: [] });
    } else {
      const { cids } = this.state;
      const idx = cids.findIndex((n) => n === cid);
      if (idx >= 0) {
        this.setState({ cids: [...cids.slice(0, idx), ...cids.slice(idx + 1)] });
      } else {
        this.setState({ cids: [...cids, cid] });
      }
    }

    this.__map.overlayMapTypes.removeAt(0);
    this.__merkersZoom = -1;
    this.addFakeLayer();
  };

  render() {
    const { categories, onHide } = this.props;
    const { cids, searchAddress } = this.state;
    return (
      <div className="kyb-map kyb-map kyb-show-searchinput kyb-show-nearlist-objects">
        <div id={this.__mapID} className="kyb-map-body" />

        <div className="kyb-fltr">
          <ul>
            <li className="cid-1" onClick={() => this.addCategory(0)}>
              <img src={FilterAllIcon} alt="All filters" />
            </li>
            {categories.map((x, i) => {
              return (
                <li
                  key={x.id}
                  onClick={() => this.addCategory(x.id)}
                  className={cids.length === 0 || cids.includes(x.id) ? '' : 'noact'}
                >
                  <img title={x.name} src={x.fIcon} alt={x.id} />
                </li>
              );
            })}
          </ul>
        </div>

        <div className="kyb-ctrl-pos-tl2r kyb-ctrl-bar" id="kyb-ts1544864481145-tl2r">
          <ul className="ul-list">
            <li>
              <div className="kyb-ctrl2 kyb-map2-listsearch">
                <div className="kyb-ctrl2-search" id={`${this.__mapID}-ctrl2-search`} />
                <div
                  className="kyb-ctrl2-list"
                  id={`${this.__mapID}-ctrl2-list`}
                  onClick={() => onHide()}
                />
              </div>
            </li>
          </ul>
        </div>

        <div className="kyb-search-ph" id={`${this.__mapID}-mapsearch`}>
          <input
            type="text"
            placeholder="введите адрес"
            value={searchAddress}
            onChange={(ev) => {
              this.setState({ searchAddress: ev.target.value });
            }}
            onKeyDown={(ev) => {
              if (ev.key === 'Enter') {
                this.__geocoder.geocode({ address: searchAddress }, (results, status) => {
                  if (status === 'OK' && results && results.length > 0) {
                    this.__map.panTo(results[0].geometry.location);
                    this.userLocationMoved(results[0].geometry.location);
                  }
                });
              }
            }}
          />
          {/* <div className="close-search" /> */}
        </div>

        <div className="kyb-ctrl-pos-br2u kyb-ctrl-bar" id="kyb-ts1544864481145-br2u">
          <ul className="ul-list">
            <li>
              <div
                className="kyb-ctrl2 kyb-ctrl2-me hint--left"
                id="kyb-ts1544864481145-me"
                data-hint="Определить мое местоположение"
              />
            </li>
            <li>
              <div className="kyb-ctrl2 kyb-ctrl2-submenu" id="kyb-ts1544864481145-submenu" />
            </li>
            <li>
              <div className="kyb-win-submenu" id="kyb-ts1544864481145-win-submenu">
                <ul>
                  <li className="layertype">
                    <div />
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <span className="hint--left" data-hint="На полный экран">
                <div className="kyb-ctrl2 kyb-ctrl2-fs" id="kyb-ts1544864481145-fullscreen" />
              </span>
            </li>
            <li>
              <span className="hint--left" data-hint="Масштаб карты">
                <div className="kyb-ctrl2 kyb-map2-zoom">
                  <div
                    className="kyb-zoom2-in"
                    id="kyb-ts1544864481145-zoomIn"
                    onClick={() => this.__map && this.__map.setZoom(this.__map.getZoom() + 1)}
                  />
                  <div
                    className="kyb-zoom2-out"
                    id="kyb-ts1544864481145-zoomOut"
                    onClick={() => this.__map && this.__map.setZoom(this.__map.getZoom() - 1)}
                  />
                </div>
              </span>
            </li>
            {/*
            <li>
              <div
                className="kyb-ctrl2 kyb-ctrl2-link hint--left"
                id="kyb-ts1544864481145-link"
                data-hint="Ссылка на карту"
              />
            </li>
            <li>
              <div
                className="kyb-ctrl2 kyb-ctrl2-reqs hint--left"
                id="kyb-ts1544864481145-reqs"
                data-hint="Режим редактирования"
              />
            </li>
            <li>
              <span className="hint--left" data-hint="Помощь">
                <div className="kyb-ctrl2 kyb-ctrl2-help" id="kyb-ts1544864481145-help" />
              </span>
            </li>
 */}
          </ul>
        </div>
      </div>
    );
  }
}
