// Общие колонки, есть на всех видах

import dayjs from 'dayjs';
import { WEBINAR3_TYPE, WEBINAR3_VIEW_ID2TAG, WEBINAR3_STATUS_ID2TAG } from '../webinar_constants';

// eslint-disable-next-line import/prefer-default-export
export const eventsWithTestTable = () => ({
  name: null, // 'Кроссы', // название
  props: [], // список полей для запроса к серверу
  columns: [
    {
      prop: 'firtsStage.dtStart',
      name: 'Дата проведения',
      sort: true,
      render: (item) =>
        `${dayjs(item?.firtsStage?.dtStart).format('DD.MM')} .. ${dayjs(
          item?.lastStage?.dtEnd,
        ).format('DD.MM.YYYY')}`,
    },
    {
      prop: '+status',
      name: 'Статус',
      sort: true,
      render: (item, val) => WEBINAR3_STATUS_ID2TAG[val],
    },
    {
      prop: '+view',
      name: 'Вид',
      sort: true,
      render: (item, val) => WEBINAR3_VIEW_ID2TAG[val],
    },
    {
      prop: '+type',
      name: 'Тип',
      sort: true,
      prop2value: [WEBINAR3_TYPE, 'id', 'name'],
    },
    {
      prop: '+name',
      name: 'Название мероприятия',
      sort: true,
      nowrap: true,
    },
  ],
});
