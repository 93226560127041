import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import styles from './styles.scss';

import Logo from '../../../assets/svg/kyb-logo2.svg';
import { BrowserView, MobileView } from 'react-device-detect';
import { Button } from 'antd';
import { CloseOutlined, MenuOutlined } from '@ant-design/icons';

function Webinars3ConnectHeader() {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <>
      <BrowserView>
        <header>
          <nav>
            <span>
              <Logo />
            </span>
            <a href="https://kyb.ru/news" target="_blank" rel="noreferrer">
              о kyb
            </a>
            <a href="https://kyb.ru/" target="_blank" rel="noreferrer">
              продукция
            </a>
            <a href="https://kyb.ru/map" target="_blank" rel="noreferrer">
              где купить
            </a>
            <a href="https://kyb.ru/support/download-catalog" target="_blank" rel="noreferrer">
              поддержка
            </a>
            <a href="https://kyb.ru/partners/project-kyb-service" target="_blank" rel="noreferrer">
              партнерам
            </a>
          </nav>
        </header>
      </BrowserView>

      <MobileView>
        <header className={styles.mobi}>
          <span>
            <Logo />
          </span>
          <span>
            <Button
              size="large"
              type="text"
              icon={showMenu ? <CloseOutlined /> : <MenuOutlined />}
              onClick={() => setShowMenu(!showMenu)}
            />
          </span>
        </header>
        {showMenu && (
          <nav className={styles.mobi}>
            <a href="https://kyb.ru/news" target="_blank" rel="noreferrer">
              о kyb
            </a>
            <a href="https://kyb.ru/" target="_blank" rel="noreferrer">
              продукция
            </a>
            <a href="https://kyb.ru/map" target="_blank" rel="noreferrer">
              где купить
            </a>
            <a href="https://kyb.ru/support/download-catalog" target="_blank" rel="noreferrer">
              поддержка
            </a>
            <a href="https://kyb.ru/partners/project-kyb-service" target="_blank" rel="noreferrer">
              партнерам
            </a>
          </nav>
        )}
      </MobileView>
    </>
  );
}

Webinars3ConnectHeader.defaultProps = {};

Webinars3ConnectHeader.propTypes = {};

export default Webinars3ConnectHeader;
