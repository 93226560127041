/* eslint-disable no-unused-expressions */
import io from 'socket.io-client';
import EventEmitter from 'wolfy87-eventemitter';

let socket = null;
const ee = new EventEmitter();
const channels = {};
let sioSocketId = null;

export const getSioId = () => sioSocketId;

// eslint-disable-next-line import/prefer-default-export
export const initSocketIO = () => {
  if (socket) return;

  socket = io('/', {
    path: '/kyb-ws',
  });

  socket.on('connect', () => {
    sioSocketId = socket.id;
    console.log('SIO - connect', socket.id);

    setTimeout(() => {
      Object.keys(channels).forEach((channelName) => {
        // console.log('subscribeToChannel', channelName);
        socket.emit('E2CH', {
          channelName,
        });
      });
    }, 500);
  });

  socket.on('MSG', (channelName, data) => {
    // if (data._sid === socket.id) return;

    // console.log('SIO - MSG', channelName, data);
    ee.emit(`MessageInChannel:${channelName}`, data);
  });

  socket.on('disconnect', () => {
    sioSocketId = null;
    console.log('SIO - disconnect');
  });

  socket.on('error', (err) => {
    console.error('SIO', err);
  });
};

/**
 * Подписаться на канал
 * @param {*} channelName
 * @param {*} listener
 */
export const subscribeToChannel = (channelName, listener) => {
  ee.addListener(`MessageInChannel:${channelName}`, listener);
  if (!channels[channelName]) channels[channelName] = 1;
  else channels[channelName] += 1;

  if (socket && channels[channelName] === 1) {
    // console.log('subscribeToChannel', channelName);
    socket.emit('E2CH', {
      channelName,
    });
  }
};

/**
 * Отписаться от канала
 * @param {*} channelName
 * @param {*} listener
 */
export const unsubscribeToChannel = (channelName, listener) => {
  ee.removeListener(`MessageInChannel:${channelName}`, listener);
  if (channels[channelName] > 0) channels[channelName] -= 1;

  if (socket && channels[channelName] === 0) {
    // console.log('unsubscribeToChannel', channelName);
    socket.emit('LVCH', {
      channelName,
    });
  }
};
