import React, { useState } from 'react';

import { message, Progress, Upload } from 'antd';
import PropTypes from 'prop-types';

import { PlusOutlined } from '@ant-design/icons';

import styles from './styles.scss';
import xdPrettyBytes from '../utils/xd-pretty-bytes';

function XDataUpload({
  desc,
  uploadType,
  maxSize,
  fileTypes,
  optimizeImage,
  webpImage,
  onSave,
  onStartUpload,
}) {
  const [progress, setProgress] = useState(-1);

  const onChange = ({ file, event }) => {
    if (file.percent > 0 && file.percent <= 100) {
      setProgress(parseInt(file.percent, 10));
    }

    if (file.percent === 100 && file.response) {
      const { err, errmsg, data } = file.response;
      // console.log('DONE', err);
      if (err === 0) {
        setProgress(-1);
        onSave(data);
      } else if (Array.isArray(errmsg)) {
        for (const msg of errmsg) {
          message.error(msg.message);
        }
      } else {
        message.error(errmsg || `Net error ${err}`);
      }
    }
  };

  // Не загружать во вне файл
  const beforeUpload = (file) => {
    setProgress(-1);
    if (file.size > maxSize) {
      message.error(
        `Размер выбранного файла: ${xdPrettyBytes(file.size, {
          locale: 'ru',
        })} больше разрешенного ${xdPrettyBytes(maxSize, { locale: 'ru' })}`,
        10,
      );
      return false;
    }
    // eslint-disable-next-line no-unused-expressions
    onStartUpload && onStartUpload();
    return true;
  };

  return (
    <div className={styles.main}>
      <Upload
        id="upload-gallery-btn"
        name="x-ufile"
        listType="picture-card"
        accept={fileTypes}
        showUploadList={false}
        action="/api/1/upload"
        onChange={onChange}
        beforeUpload={beforeUpload}
        className={styles.uploadBtn}
        headers={{
          'x-ufile-desc': encodeURIComponent(desc),
          'x-ufile-type': encodeURIComponent(uploadType),
          'x-ufile-pic-optimize': optimizeImage ? 1 : 0,
          'x-ufile-pic-webp': webpImage ? 1 : 0,
        }}
        disabled={progress >= 0}
      >
        {progress < 0 && (
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Добавить</div>
          </div>
        )}
        {progress >= 0 && <Progress type="circle" percent={progress} />}
      </Upload>
    </div>
  );
}

XDataUpload.defaultProps = {
  desc: '',
  uploadType: 'file',
  maxSize: Infinity,
  fileTypes: '*/*',
  optimizeImage: true,
  webpImage: true,
  onStartUpload: null,
};

XDataUpload.propTypes = {
  desc: PropTypes.string,
  uploadType: PropTypes.string,
  optimizeImage: PropTypes.bool,
  webpImage: PropTypes.bool,
  maxSize: PropTypes.number,
  fileTypes: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onStartUpload: PropTypes.func,
};

export default XDataUpload;
