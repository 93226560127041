import React, { useEffect, useState } from 'react';

import { Button, Modal } from 'antd';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { isBrowser } from 'react-device-detect';

import { apiPostData } from '../../../api';
import apiMeGet from '../../../api/MeGet';
import XDataForm from '../../components/xdf-antd/XDataForm';
import { USER_XD_FORM } from './user-form';

function Webinars3UserForm({ visible, onSuccess, onClose }) {
  const [showDialog, setShowDialog] = useState(false);
  const [disableEnterPhone, setDisableEnterPhone] = useState(false);

  const [model, setModel] = useState({});
  const [isModelChanged, setIsModelChanged] = useState(false);

  const fetchUser = async () => {
    const { me } = await apiMeGet();
    if (me?._id > 0 && me.phone) {
      console.log('me', me);
      setModel({
        ...model,
        phone: me.phone,
      });
      setDisableEnterPhone(true);
    }
    setShowDialog(true);
  };

  useEffect(() => {
    if (visible) fetchUser();

    if (showDialog && !visible) {
      setShowDialog(false);
    }
  }, [visible]);

  const onChangeModel = (m, ism) => {
    setModel(m);
    setIsModelChanged(ism);
  };

  const onSaveForm = async () => {
    await apiPostData('/webinars3/user/save', {
      ...model,
      timezone: dayjs().format('Z'),
    });
    onSuccess();
  };

  return (
    <Modal
      title="АНКЕТА УЧАСТНИКА МЕРОПРИЯТИЯ KYB"
      style={{ top: isBrowser ? 100 : 10 }}
      closable={false}
      open={showDialog}
      footer={
        <Button type="primary" disabled={!isModelChanged} onClick={onSaveForm}>
          Сохранить
        </Button>
      }
    >
      <XDataForm
        model={model}
        onChangeModel={onChangeModel}
        form={USER_XD_FORM(disableEnterPhone)}
      />
    </Modal>
  );
}

Webinars3UserForm.defaultProps = {};

Webinars3UserForm.propTypes = {
  visible: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Webinars3UserForm;
