/* eslint-disable import/prefer-default-export */
import React from 'react';

import { LinkOutlined } from '@ant-design/icons';
import styles from './styles.scss';

// Общие колонки, есть на всех видах
export function commonColumns() {
  return {
    name: 'Файлы', // название
    props: [], // список полей для запроса к серверу
    columns: [
      {
        prop: '+_id',
        name: 'ID',
        sort: true,
        render: (item, val) => (
          <div className={styles.ellipsisCell} title={val} style={{ width: 70 }}>
            {val}
          </div>
        ),
      },
      {
        prop: '+state',
        name: '',
        sort: true,
        render: (item, val) => {
          if (val === 1) return 'GridFS';
          return '';
        },
      },
      {
        prop: '+desc',
        name: 'Описание',
        sort: true,
        render: (item, val) => (
          <div className={styles.ellipsisCell} title={val} style={{ width: 140 }}>
            {val}
          </div>
        ),
      },
      // {
      //   name: 'Url',
      //   prop: '+uname',
      //   actions: true,
      //   align: 'center',
      //   render: (item, val) => {
      //     return <Typography.Text copyable={{ text: `/ig/${val}` }} />;
      //   },
      // },
      {
        name: <LinkOutlined />,
        prop: '+uname',
        actions: true,
        align: 'center',
        render: (item, val) => {
          if (item.contentType.indexOf('image/') === 0) {
            return (
              <a
                href={`/gi/${val}`}
                target="_blank"
                rel="noreferrer"
                title="Открыть файл в новом окне"
              >
                <LinkOutlined />
              </a>
            );
          }
          return (
            <a
              href={`/gf/${val}`}
              target="_blank"
              rel="noreferrer"
              title="Открыть файл в новом окне"
            >
              <LinkOutlined />
            </a>
          );
        },
      },
      {
        name: 'Создан',
        prop: '+cdt',
        sort: true,
        nowrap: true,
        type: 'date',
        format: 'DD.MM.YYYY HH:mm',
      },
      {
        name: 'Тип файла',
        prop: '+contentType',
        sort: true,
        search: true,
      },
      {
        name: 'Тип',
        prop: '+type',
        sort: true,
        search: true,
      },
      {
        name: 'Размер',
        prop: '+size',
        sort: true,
        align: 'right',
        nowrap: true,
      },
      {
        name: 'Ширина',
        prop: '+width',
        sort: true,
        align: 'right',
      },
      {
        name: 'Высота',
        prop: '+height',
        sort: true,
        align: 'right',
      },
      {
        name: 'Имя файла',
        prop: '+fname',
        sort: true,
        search: true,
        render: (item, val) => (
          <div className={styles.ellipsisCell} title={val} style={{ width: 140 }}>
            {val}
          </div>
        ),
      },
      {
        name: 'Открыт',
        prop: '+loadDt',
        sort: true,
        nowrap: true,
        type: 'date',
        format: 'DD.MM.YYYY HH:mm',
      },
      {
        name: 'Раз',
        prop: '+loadCnt',
        sort: true,
        align: 'right',
      },
    ],
  };
}
