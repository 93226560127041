import React from 'react';

import { Col } from 'antd';
import cn from 'classnames';
import gv from 'get-value';
import PropTypes from 'prop-types';

import XDataGallery from '../XDataGallery/XDataGallery';
import styles from './styles.scss';

function XDFPictureUpload({ it, size, model, onPropChanged }) {
  const { id, prop, label, span, readOnly, picSize, disableDescription, roundImage } = it;

  const val = gv(model, prop, '');

  const onPicListChanged = (plist) => {
    const pic = plist.length > 0 ? plist[0].filename : '';
    if (pic !== val) onPropChanged(it, pic);
  };

  return (
    <Col span={span || 24}>
      <div className={styles.formItem}>
        <div className={styles.formItemLabel}>
          <label htmlFor={id}>{label}</label>
        </div>
        <div className={cn(styles.formItemField)}>
          <XDataGallery
            disabled={readOnly}
            picDescDisable
            picWidth={picSize || 162}
            basePicUrl="/gi/"
            onChange={onPicListChanged}
            items={
              val
                ? [
                    {
                      id: val,
                      filename: val,
                    },
                  ]
                : []
            }
            maxCount={1}
            disableDescription={disableDescription || false}
            roundImage={roundImage || false}
          />
        </div>
      </div>
    </Col>
  );
}

XDFPictureUpload.propTypes = {
  it: PropTypes.shape({
    id: PropTypes.string.isRequired,
    prop: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    span: PropTypes.number,
    readOnly: PropTypes.bool,
    picSize: PropTypes.number,
    disableDescription: PropTypes.bool,
    roundImage: PropTypes.bool,
  }).isRequired,
  model: PropTypes.shape({}).isRequired,
  onPropChanged: PropTypes.func.isRequired,
};

export default XDFPictureUpload;
