import React, { useEffect, useState } from 'react';

import { Button, Modal, message } from 'antd';
import PropTypes from 'prop-types';

import GTooltip from '../../../components/GTooltip/GTooltip';
import XDataForm, { XDF_TYPE } from '../../../components/xdf-antd/XDataForm';
import { warningDialog } from '../../../components/Common/WarningDialog';
import { apiPost } from '../../../../api/index';

function SpecEditDlg({ visible, model, onClose }) {
  const [model0, setModel0] = useState({});
  const [isModelChanged, setIsModelChanged] = useState(false);

  useEffect(() => {
    if (visible) {
      setModel0(model);
      setIsModelChanged(false);
    }
  }, [visible]);

  function onChangeModel(m, v) {
    setModel0({ ...m });
    setIsModelChanged(v);
  }

  async function onSave() {
    const res = await warningDialog('Редактирование записи', 'Сохранить внесённые изменения?', [
      { label: 'Отмена' },
      { label: 'Сохранить' },
    ]);

    if (res === 1) {
      // save record
      const { err, errmsg } = await apiPost('/catalog/spec/set', {
        id: model._id,
        data: model0,
      });
      if (err > 0) {
        if (Array.isArray(errmsg)) {
          for (const msg of errmsg) {
            message.error(msg.message);
          }
        } else {
          message.error(errmsg || `Net error ${err}`);
        }
      } else onClose();
    }
  }

  async function onDelete() {
    const res = await warningDialog(
      'Удаление записи',
      'Удалить запись, без возможности восстановления?',
      [{ label: 'Отмена' }, { label: 'Удалить' }],
    );

    if (res === 1) {
      // delete record
      const { err, errmsg } = await apiPost('/catalog/spec/del', {
        id: model._id,
      });
      if (err > 0) {
        if (Array.isArray(errmsg)) {
          for (const msg of errmsg) {
            message.error(msg.message);
          }
        } else {
          message.error(errmsg || `Net error ${err}`);
        }
      } else onClose();
    }
  }

  return (
    <Modal
      title={<GTooltip tid="catalogs_spec_dlg">Редактирование записи характеристики</GTooltip>}
      open={visible}
      width={700}
      closable={false}
      footer={[
        <Button key="del" style={{ marginRight: 'auto' }} onClick={onDelete}>
          Удалить запись
        </Button>,
        <Button key="close" onClick={onClose}>
          Закрыть
        </Button>,
        <Button key="save" type="primary" onClick={onSave} disabled={!isModelChanged}>
          Сохранить
        </Button>,
      ]}
    >
      <XDataForm
        model={model0}
        onChangeModel={onChangeModel}
        form={[
          {
            type: XDF_TYPE.TEXT,
            label: 'Артикул',
            prop: 'partNumber',
            span: 12,
            require: true,
            autoFocus: true,
          },
          {
            type: XDF_TYPE.TEXT,
            label: 'Деталь',
            prop: 'part',
            require: true,
            span: 12,
          },
          {
            type: XDF_TYPE.TEXT,
            label: 'Серия KYB',
            prop: 'series',
            require: true,
            span: 12,
          },
          {
            type: XDF_TYPE.TEXT,
            label: 'Тип',
            prop: 'type',
            require: true,
            span: 12,
          },
          {
            type: XDF_TYPE.TEXT,
            label: 'Состав артикула',
            prop: 'spec',
            require: true,
            span: 12,
          },
          {
            type: XDF_TYPE.TEXT,
            label: 'Передний / задний',
            prop: 'fr',
            require: true,
            span: 12,
          },
          {
            type: XDF_TYPE.TEXT,
            label: 'Правый / левый',
            prop: 'rl',
            require: true,
            span: 12,
          },
          {
            type: XDF_TYPE.TEXT,
            label: 'Возможная замена',
            prop: 'changes',
            require: true,
            span: 12,
          },
          {
            type: XDF_TYPE.TEXT,
            label: 'Защитный комплект к амортизатору',
            prop: 'amoProtect',
            require: true,
            span: 12,
          },
          {
            type: XDF_TYPE.TEXT,
            label: 'Тип крепления / форма',
            prop: 'fixType',
            require: true,
            span: 12,
          },
        ]}
      />
    </Modal>
  );
}

SpecEditDlg.propTypes = {
  visible: PropTypes.bool.isRequired,
  model: PropTypes.shape({}).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SpecEditDlg;
