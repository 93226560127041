import React, { useState } from 'react';

import { Button, Layout, Modal, PageHeader, Tooltip } from 'antd';

import { PlusOutlined } from '@ant-design/icons';

import apiAnyList from '../../../../api/AnyList';
import { subscribeToChannel, unsubscribeToChannel } from '../../../../api/sio';
import GTooltip from '../../../components/GTooltip/GTooltip';
import XDataTable from '../../../components/xdf-antd/XDataTable';
import IframeEditDlg from './IframeEditDlg';
import styles from './styles.scss';
import { commonColumns } from './table-defs';
import { useScript } from '../../../utils/hooks';

export default function IframeList() {
  const [editDlg, setEditDlg] = useState({ visible: false, model: {} });
  const [showIframeByKey, setShowIframeByKey] = useState(null);

  const [doneApp] = useScript(
    showIframeByKey &&
      `https://kyb.ru/api/2/catalog/iframe/js?divId=kybCatalog&key=${showIframeByKey}`,
    false,
  );

  const onRowClick = (item) => {
    setEditDlg({ visible: true, model: item });
  };

  const addRecord = () => {
    setEditDlg({ visible: true, model: {} });
  };

  const onCloseDlg = () => {
    setEditDlg({ ...editDlg, visible: false });
  };

  const onShowIframe = (item) => {
    setShowIframeByKey(item.key);
  };

  return (
    <>
      <PageHeader
        className={styles.pageHeader}
        title={
          <GTooltip tid="catalogs_iframe">
            Управление настройками для вставки Каталога на сторонних сайтах
          </GTooltip>
        }
        extra={[
          <Tooltip key="add" title="Добавить запись" placement="right">
            <Button type="primary" shape="circle" icon={<PlusOutlined />} onClick={addRecord} />
          </Tooltip>,
        ]}
      />
      <Layout.Content>
        <div className={styles.contextHolder}>
          <div className={styles.context}>
            <XDataTable
              apiLoadData={apiAnyList('Sys:KybCatalogIframe')}
              commonColumns={commonColumns(onShowIframe)}
              extentedColumns={null}
              initSort={['productId', 1]}
              initFilter={{}}
              onRowClick={onRowClick}
              sioEvents={['LIST:CATALOG_SETTINGS', subscribeToChannel, unsubscribeToChannel]}
              searchProps={['key', 'name', 'desc']}
            />
          </div>
        </div>
      </Layout.Content>

      <IframeEditDlg visible={editDlg.visible} model={editDlg.model} onClose={onCloseDlg} />

      {showIframeByKey && (
        <Modal
          title="Тест"
          centered
          open={showIframeByKey}
          width={1100}
          footer={null}
          onCancel={() => setShowIframeByKey(null)}
        >
          <div className={styles.example}>
            {`<script src="https://kyb.ru/api/2/catalog/iframe/js?divId=kybCatalog&key=${showIframeByKey}"></script>`}
          </div>
          <div className={styles.testIframe} id="kybCatalog" />
        </Modal>
      )}
    </>
  );
}
