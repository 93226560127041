function fallbackCopyTextToClipboard(text, resolve) {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  let successOK = false;
  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    // console.log('Fallback: Copying text command was ' + msg);
    successOK = true;
  } catch (err) {
    // console.error('Fallback: Oops, unable to copy', err);
  }
  document.body.removeChild(textArea);
  resolve(successOK);
}

function copyTextToClipboard(text) {
  return new Promise((resolve) => {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text, resolve);
    } else {
      navigator.clipboard.writeText(text).then(
        () => {
          // console.log('Async: Copying to clipboard was successful!');
          resolve(true);
        },
        (err) => {
          // console.error('Async: Could not copy text: ', err);
          resolve(true);
        },
      );
    }
  });
}

export default copyTextToClipboard;
