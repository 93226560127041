import 'react-image-crop/dist/ReactCrop.css';

import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Button, Input, message, Modal, Progress, Upload } from 'antd';
import PropTypes from 'prop-types';
import ReactCrop from 'react-image-crop';

import { CheckOutlined, PlusOutlined } from '@ant-design/icons';

import { getBase64 } from '../XDataForm/XDFUploadBase64';
import styles from './styles.scss';

const defaultCropSettings = { unit: '%', x: 2, y: 2, height: 50, aspect: 1 };

function UploadItemWithCrop({
  uploadFileType,
  picWidth,
  onAddFile,
  disableDescription,
  aspects,
  disabled,
}) {
  const [picDlgVisible, setPicDlgVisible] = useState({ visible: false, width: '90%' });

  const [uploadedImage64, setUploadedImage64] = useState(null);
  const [progress, setProgress] = useState(-1);
  const [picDesc, setPicDesc] = useState('');

  const cropImg = useRef(null);
  const [cropUFile, setCropUFile] = useState('');

  const [crop, setCrop] = useState(defaultCropSettings);
  const [completedCrop, setCompletedCrop] = useState({});
  const [disableEdit, setDisableEdit] = useState(false);

  const beforeUploadResolve = useRef(null);

  const onSetAspect = (aspect) => {
    setCrop({
      ...defaultCropSettings,
      aspect,
      ...(aspect
        ? {}
        : {
            unit: '%',
            x: 2,
            y: 3,
            width: 30,
            height: 70,
          }),
    });
    setDisableEdit(false);
  };

  const onChange = ({ file, event }) => {
    if (file.percent > 0 && file.percent <= 100) {
      setProgress(parseInt(file.percent, 10));
    }

    if (file.percent === 100 && file.response) {
      const { err, errmsg, data } = file.response;
      // console.log('DONE', err);
      if (err === 0) {
        setProgress(-1);
        onAddFile(data);
      } else if (Array.isArray(errmsg)) {
        for (const msg of errmsg) {
          message.error(msg.message);
        }
      } else {
        message.error(errmsg || `Net error ${err}`);
      }
    }
  };

  const onUploadOK = () => {
    beforeUploadResolve.current.resolve();
    setPicDlgVisible({ ...picDlgVisible, visible: false });
    setUploadedImage64(null);
  };

  const onUploadReject = () => {
    beforeUploadResolve.current.reject();
    setPicDlgVisible({ ...picDlgVisible, visible: false });
    setUploadedImage64(null);
  };

  // Не загружать во вне файл
  const beforeUpload = (file) => {
    setPicDesc('');
    setProgress(-1);
    return new Promise((resolve, reject) => {
      beforeUploadResolve.current = {
        resolve,
        reject,
      };
      getBase64(file).then((imageUrl) => {
        const img = new Image();
        img.onload = () => {
          const w = img.width;
          const h = img.height;

          setUploadedImage64(imageUrl);
          setPicDlgVisible({
            ...picDlgVisible,
            visible: true,
            width: w / h > 1 ? '90%' : ((window.innerHeight - 230) * w) / h,
            h,
            w,
          });

          setDisableEdit(false);
          if (Array.isArray(aspects) && aspects.length > 0) {
            onSetAspect(aspects[0].wph);
          } else {
            setCrop({ ...defaultCropSettings });
          }
        };
        img.src = imageUrl;
      });
    });
  };

  // crop
  useEffect(() => {
    // console.log('completedCrop', completedCrop);

    if (cropImg.current) {
      const kx = picDlgVisible.w / cropImg.current.width;
      const ky = picDlgVisible.h / cropImg.current.height;
      let cropFile = '';
      if (!disableEdit) {
        cropFile = `${parseInt(kx * completedCrop.x, 10)},${parseInt(
          ky * completedCrop.y,
          10,
        )},${parseInt(kx * completedCrop.width, 10)},${parseInt(ky * completedCrop.height, 10)}`;
      }
      setCropUFile(cropFile);
    }
  }, [completedCrop, disableEdit]);

  const onLoad = useCallback((img) => {
    cropImg.current = img;
    // console.log('Crop - onLoad');
  }, []);

  const notEdit = () => {
    if (disableEdit) {
      if (Array.isArray(aspects) && aspects.length > 0) {
        onSetAspect(aspects[0].wph);
      } else {
        setCrop({ ...defaultCropSettings });
      }
      setDisableEdit(false);
    } else {
      setCrop({});
      setDisableEdit(true);
    }
  };

  const setAspectBy = (idx) => {
    if (Array.isArray(aspects) && idx < aspects.length) {
      onSetAspect(aspects[idx].wph);
    } else {
      onSetAspect(undefined);
    }
  };

  return (
    <>
      <Upload
        id="upload-gallery-btn"
        name="x-ufile"
        listType="picture-card"
        accept="image/*"
        showUploadList={false}
        action="/api/1/upload"
        onChange={onChange}
        beforeUpload={beforeUpload}
        className={styles.uploadBtn}
        headers={{
          'x-ufile-desc': encodeURIComponent(picDesc || ''),
          'x-ufile-type': encodeURIComponent(uploadFileType),
          'x-ufile-pic-crop': cropUFile,
        }}
        disabled={progress >= 0 || disabled}
      >
        {progress < 0 && (
          <div style={{ opacity: disabled ? 0.4 : 1 }}>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Добавить</div>
          </div>
        )}
        {progress >= 0 && <Progress type="circle" percent={progress} width={picWidth * 0.8} />}
      </Upload>

      <Modal
        title="Отредактировать фото"
        open={picDlgVisible.visible}
        width={picDlgVisible.width}
        style={{ top: 50 }}
        onCancel={onUploadReject}
        destroyOnClose
        footer={[
          ...aspects.map((x) => (
            <Button
              key={x.wph}
              onClick={() => onSetAspect(x.wph)}
              disabled={disableEdit}
              icon={!disableEdit && x.wph === crop.aspect && <CheckOutlined />}
            >
              {x.label}
            </Button>
          )),
          <Button
            key="free"
            onClick={() => onSetAspect(undefined)}
            disabled={disableEdit}
            icon={!disableEdit && crop.aspect === undefined && <CheckOutlined />}
          >
            Любое соотношение
          </Button>,
          <Button
            key="nedit"
            onClick={notEdit}
            style={{ marginRight: 'auto' }}
            icon={disableEdit && <CheckOutlined />}
          >
            Не менять
          </Button>,
          <Button key="upload" type="primary" onClick={onUploadOK}>
            Загрузить
          </Button>,
        ]}
      >
        <div className={styles.picEditPlaceHolder}>
          <ReactCrop
            disabled={disableEdit}
            src={uploadedImage64}
            onImageLoaded={onLoad}
            crop={crop}
            onChange={(c) => setCrop(c)}
            onComplete={(c) => setCompletedCrop(c)}
          />
        </div>
        {!disableDescription && (
          <Input
            placeholder="Описание к фотографии"
            value={picDesc}
            onChange={(ev) => setPicDesc(ev.target.value)}
          />
        )}
      </Modal>
    </>
  );
}

UploadItemWithCrop.defaultProps = {
  uploadFileType: 'picture',
  disableDescription: false,
  disabled: false,
  aspects: [
    {
      label: '1x1',
      wph: 1,
    },
  ],
};

UploadItemWithCrop.propTypes = {
  uploadFileType: PropTypes.string,
  onAddFile: PropTypes.func.isRequired,
  picWidth: PropTypes.number.isRequired,
  disableDescription: PropTypes.bool,
  disabled: PropTypes.bool,
  aspects: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      wph: PropTypes.number,
    }),
  ),
};

export default UploadItemWithCrop;
