import React, { useState } from 'react';

import { Modal } from 'antd';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';

import LoginDlgLogo from '../../../../assets/svg/login-dlg-logo.svg';
import { meState } from '../../../../state/panel-state';
import styles from './styles.scss';
import UserProfile from './UserProfile';

function UserWaitEmailConfirm({ visible }) {
  const me = useRecoilValue(meState);
  const [showProfileDlg, setShowProfileDlg] = useState(false);

  function onShowProfile(ev) {
    ev.preventDefault();
    setShowProfileDlg(true);
  }

  return (
    <>
      <Modal
        className={styles.kybLoginDlg}
        title={null}
        width={610}
        open={visible}
        closable={false}
        footer={null}
      >
        <h1>
          Регистрация в сервисах <LoginDlgLogo />
        </h1>
        <div className={styles.info}>
          Ваш статус: <span>Ожидает авторизации</span>
        </div>
        <div className={styles.info}>
          Мобильный: <span>{me.phone}</span>
        </div>

        <div className={styles.msgRed}>Пожалуйста, подтведите ваш Е-mail</div>

        <div className={styles.msg}>
          Для завершения процедуры регистрации в сервисе, вам необходимо подтвердить ваш E-mail. На
          указанный адрес электронной почты было выслано письмо с подтверждением.
        </div>
        <div className={styles.msg}>
          Если ранее вы указали неверный адрес электронной почты отредактируйте его{' '}
          <a href="#" onClick={onShowProfile}>
            здесь
          </a>
        </div>
      </Modal>
      <UserProfile visible={showProfileDlg} />
    </>
  );
}

UserWaitEmailConfirm.propTypes = {
  visible: PropTypes.bool.isRequired,
};

export default UserWaitEmailConfirm;
