// Общие колонки, есть на всех видах
export function commonColumns() {
  return {
    name: null,
    props: [], // список полей для запроса к серверу
    columns: [
      {
        prop: '+url',
        name: 'URL страницы',
        sort: true,
        nowrap: true,
      },
      {
        prop: '+title',
        name: 'Title',
        sort: true,
        nowrap: true,
      },
      {
        prop: '+desc',
        name: 'Description',
        sort: true,
        nowrap: true,
      },
    ],
  };
}
