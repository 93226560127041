import React, { useState } from 'react';

import { Button, Modal } from 'antd';
import PropTypes from 'prop-types';

import LoginDlgLogo from '../../../../assets/svg/login-dlg-logo.svg';
import XDataForm, { XDF_TYPE } from '../../xdf-antd/XDataForm';
import styles from './styles.scss';

function KybLoginSmsCodeDialog({ visible, onCodeCheck, errCode, codeType }) {
  const [model, setModel] = useState({});

  const onChangeModel = (m) => {
    setModel({ ...m });
  };

  const codeCheck = () => {
    onCodeCheck(model.smsCode);
  };

  return (
    <Modal
      className={styles.kybLoginDlg}
      title={null}
      width={400}
      open={visible}
      closable={false}
      footer={null}
    >
      <h1>
        Вход в сервисы <LoginDlgLogo />
      </h1>
      {codeType === 'call' && (
        <div className={styles.msg}>
          На указанный вами номер будет произведен звонок, поднимите трубку и прослушайте код для входа.
        </div>
      )}
      {codeType === 'sms' && (
        <div className={styles.msg}>
          На указанный вами номер будет выслана СМС с кодом для входа.
        </div>
      )}

      <XDataForm
        model={model}
        onChangeModel={onChangeModel}
        form={[
          {
            type: XDF_TYPE.PHONE_SMS_CODE,
            label: 'Код',
            prop: 'smsCode',
            placeholder: 'введите код',
            span: 24,
            smsCodeLen: 4,
            autoFocus: true,
            require: true,
          },
        ]}
      />
      {errCode && <div className={styles.err1}>Неверный SMS-код</div>}

      <div className={styles.dmsg}>
        Среднее время получения звонка с кодом около 5 минут. Новый код можно запросить через 24
        часа.
      </div>
      <div className={styles.dmsg}>
        Решить возникшие вопросы с авторизацией и завершить её вы можете, связавшись с менеджерами
        KYB.
      </div>
      <div className={styles.footer}>
        <Button type="primary" disabled={!model.smsCode} onClick={codeCheck}>
          Отправить
        </Button>
      </div>
    </Modal>
  );
}

KybLoginSmsCodeDialog.defaultProps = {
  example: null,
  codeType: 'call',
};

KybLoginSmsCodeDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCodeCheck: PropTypes.func.isRequired,
  codeType: PropTypes.string,
};

export default KybLoginSmsCodeDialog;
