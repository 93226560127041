import React from 'react';

import { Button, Modal } from 'antd';
import PropTypes from 'prop-types';

import styles from './styles.scss';

function KybBlockPicsDlg({ visible, pics, onSelect, onClose }) {
  return (
    <Modal
      title="Укажите картинку для вставки"
      open={visible}
      width="80%"
      onCancel={onClose}
      footer={[
        <Button key="close" onClick={onClose}>
          Закрыть
        </Button>,
      ]}
    >
      <div className={styles.picsList}>
        {pics.map((pic) => (
          <button type="button" key={pic.pic} className={styles.item} onClick={() => onSelect(pic)}>
            <img src={`/gi/h/162/${pic.pic}`} alt="pic" />
            {pic.desc && <div className={styles.desc}>{pic.desc}</div>}
          </button>
        ))}
      </div>
    </Modal>
  );
}

KybBlockPicsDlg.defaultProps = {};

KybBlockPicsDlg.propTypes = {
  visible: PropTypes.bool.isRequired,
  pics: PropTypes.arrayOf(
    PropTypes.shape({
      pic: PropTypes.string,
      desc: PropTypes.string,
    }),
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default KybBlockPicsDlg;
