import React, { useEffect, useState } from 'react';

import { Col, Input } from 'antd';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './styles.scss';

function XDFPhoneSmsCode({ it, size, model, onPropChanged }) {
  const { id, prop, label, placeholder, autoFocus, span, require, showOnly, smsCodeLen = 6 } = it;
  const [smsCode0, setSmsCode0] = useState([]);
  const [focusFld, setFocusFld] = useState(0);

  useEffect(() => {
    setSmsCode0([]);
    setFocusFld(0);
  }, []);

  function updateVal(code) {
    const resultCode = code.join('');
    if (resultCode.length === smsCodeLen) onPropChanged(it, resultCode);
    else onPropChanged(it, '');
  }

  function onChange(idx, ev) {
    const dotVal = ev.target.value;
    const codePart = dotVal.charAt(dotVal.length - 1);
    ev.target.value = codePart;

    let code = smsCode0;
    code[idx] = codePart;
    setSmsCode0(code);

    if (codePart && focusFld < smsCodeLen - 1) {
      const focusPos = focusFld + 1;
      const k = `${id}-${focusPos}`;
      const el = document.getElementById(k);
      if (el) el.focus();
      setFocusFld(focusPos);
    }

    updateVal(code);
  }

  function onKeyDown(idx, ev) {
    if (ev.keyCode === 8) {
      if (!smsCode0[idx]) {
        if (focusFld > 0) {
          const focusPos = focusFld - 1;
          const k = `${id}-${focusPos}`;
          const el = document.getElementById(k);
          if (el) el.focus();
          setFocusFld(focusPos);
          ev.preventDefault();
        }
      }
    }
    updateVal(smsCode0);
  }

  return (
    <Col span={span || 24}>
      <div className={styles.formItem}>
        <div className={styles.formItemLabel}>
          <label htmlFor={id}>
            {label}
            {require && <sup>*</sup>}
          </label>
        </div>
        <div
          className={cn(
            styles.formItemField,
            styles.smsCodeField,
            { [styles.small]: size === 'small' },
            { [styles.showOnly]: !!showOnly },
          )}
        >
          {Array.apply(null, Array(smsCodeLen)).map((x, n) => {
            const key = `${id}-${n}`;
            let val = smsCode0[n];
            if (val !== undefined) val = val.charAt(0);
            return (
              <Input
                key={key}
                id={key}
                size={size}
                placeholder={'_'}
                value={val}
                onChange={(ev) => onChange(n, ev)}
                onKeyDown={(ev) => onKeyDown(n, ev)}
                style={{ textAlign: 'center' }}
                autoFocus={n === 0 && autoFocus}
                name={key}
                autoComplete={key}
              />
            );
          })}
        </div>
      </div>
    </Col>
  );
}

XDFPhoneSmsCode.propTypes = {
  it: PropTypes.shape({
    id: PropTypes.string.isRequired,
    prop: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    placeholder: PropTypes.string,
    autoFocus: PropTypes.bool,
    span: PropTypes.number,
    readOnly: PropTypes.bool,
    showOnly: PropTypes.bool,
    smsCodeLen: PropTypes.number,
  }).isRequired,
  size: PropTypes.string.isRequired,
  model: PropTypes.shape({}).isRequired,
  onPropChanged: PropTypes.func.isRequired,
};

export default XDFPhoneSmsCode;
