/* eslint-disable no-underscore-dangle */
import React, { useRef, useState } from 'react';

import { Button, Layout, Modal, PageHeader, Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import {
  CloudUploadOutlined,
  DeleteOutlined,
  DingtalkOutlined,
  FileAddOutlined,
  GlobalOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';

import apiAnyList from '../../../../api/AnyList';
import { subscribeToChannel, unsubscribeToChannel } from '../../../../api/sio';
import { mapCategoriesState, mapGoogleKeyApi } from '../../../../state/map-state';
import { notifyLastForRoleState } from '../../../../state/notify-state';
import GTooltip from '../../../components/GTooltip/GTooltip';
import KybMap from '../../../components/Map/KybMap';
import XDataTable from '../../../components/xdf-antd/XDataTable';
import { USER_ROLE } from '../../../constants';
import styles from './styles.scss';
import { columnsOthers, commonColumns, filterForm } from './table-defs';
import MapObjectsMassChange from './MapObjectsMassChange';
import { useHotkeys } from 'react-hotkeys-hook';
import XDataForm, { XDF_TYPE } from '../../../components/xdf-antd/XDataForm';
import { apiPost } from '../../../../api';
import MapObjectUploadDlg from './MapObjectUploadDlg';
import XDImportExcelFile from '../../../components/xdf-antd/XDataTable/XDImportExcelFile';
import { uploadDataToServer } from '../../Catalogs/Cross/CrossList';

export default function MapObjectsList() {
  const history = useHistory();
  const notifyLastForRole = useRecoilValue(notifyLastForRoleState(USER_ROLE.MAP_OBJECTS));

  const googleKeyApi = useRecoilValue(mapGoogleKeyApi);
  const categoriesList = useRecoilValue(mapCategoriesState);
  const [showMap, setShowMap] = useState(false);
  const [showMassChangeDlg, setShowMassChangeDlg] = useState(false);

  const tableEmitter = useRef(null);
  const [filter, setFilter] = useState({});

  const [model, setModel] = useState({});
  const [dlgDeleteObjects, setDlgDeleteObjects] = useState(false);
  const [uploadDlg, setUploadDlg] = useState(false);

  const [hideActions, setHideActions] = useState(false);
  useHotkeys('alt+o', () => setHideActions(true));

  const onSaveEventEmmitter = (ee) => {
    tableEmitter.current = ee;
    tableEmitter.current?.addListener('FILTER-CHANGED', (f) => {
      setFilter(f);
    });
  };

  const onRowClick = (item) => {
    history.push(`/maps/object/${item._id}`);
  };

  const onMassChanges = () => {
    setShowMassChangeDlg(true);
  };

  const onSaveMassChanges = (m) => {
    setShowMassChangeDlg(false);
    Modal.info({
      title: 'На данный момент изменения заблокированы!',
    });
  };

  const onDeleteObjects = () => {
    setModel({});
    setDlgDeleteObjects(true);
  };

  const onRealDeleteObjects = async () => {
    const { err, errmsg, data } = await apiPost('/map/objects/delete', {
      filter,
      // eslint-disable-next-line no-underscore-dangle
      check: model.__checkNumber,
    });
    if (err) {
      setModel({ ...model, errmsg });
    } else {
      setDlgDeleteObjects(false);
    }
  };

  const onUploadObjects = () => {
    setUploadDlg(true);
  };

  return (
    <>
      <PageHeader
        className={styles.pageHeader}
        title={<GTooltip tid="map_objects">Список объектов</GTooltip>}
        extra={[
          hideActions && (
            <Tooltip key="del-obj" title="Удалить объекты по текущему фильтру">
              <Button shape="circle" icon={<DeleteOutlined />} onClick={onDeleteObjects} />
            </Tooltip>
          ),
          hideActions && (
            <Tooltip key="upload-obj" title="Загрузить объекты их Excel файла">
              <Button
                shape="circle"
                icon={<CloudUploadOutlined />}
                onClick={onUploadObjects}
                style={{ marginRight: 48 }}
              />
            </Tooltip>
          ),

          <Tooltip key="create" title="Создать объект">
            <Button
              shape="circle"
              icon={<FileAddOutlined />}
              onClick={() => history.push('/maps/object/000000000000000000000000')}
            />
          </Tooltip>,
          !showMap && (
            <Tooltip key="map" title="Показать карту">
              <Button shape="circle" icon={<GlobalOutlined />} onClick={() => setShowMap(true)} />
            </Tooltip>
          ),
          showMap && (
            <Tooltip key="list" title="Показать списком">
              <Button
                shape="circle"
                icon={<UnorderedListOutlined />}
                onClick={() => setShowMap(false)}
              />
            </Tooltip>
          ),
        ].filter(Boolean)}
      />
      <Layout.Content>
        {showMap && (
          <div className={styles.contextHolder} style={{ height: '100%', paddingBottom: 12 }}>
            <KybMap
              googleKey={googleKeyApi}
              categories={categoriesList}
              onHide={() => setShowMap(false)}
              onSelectObject={({ id }) => {
                history.push(`/maps/object/${id}`);
              }}
            />
          </div>
        )}

        <div className={styles.contextHolder} style={{ display: showMap ? 'none' : 'block' }}>
          <div className={styles.context}>
            <XDataTable
              apiLoadData={apiAnyList('Sys:MapPoi')}
              commonColumns={commonColumns(notifyLastForRole)}
              extentedColumns={columnsOthers()}
              initSort={['name', 1]}
              initFilter={{ active: [0, 1] }}
              filterForm={filterForm()}
              onRowClick={onRowClick}
              sioEvents={['LIST:MapPoi', subscribeToChannel, unsubscribeToChannel]}
              excelExport={{
                filename: 'Карта_список_объектов',
                tabname: 'Список',
                exportStep: 100,
                autoWidth: true,
              }}
              leftActions={[
                {
                  tooltip: 'Групповые изменения',
                  icon: <DingtalkOutlined />,
                  onClick: onMassChanges,
                },
              ]}
              onSaveEventEmitter={onSaveEventEmmitter}
            />
          </div>
        </div>
      </Layout.Content>

      <MapObjectsMassChange
        visible={showMassChangeDlg}
        onCancel={() => setShowMassChangeDlg(false)}
        onSave={onSaveMassChanges}
      />

      <Modal
        title="Удаление объектов"
        open={dlgDeleteObjects}
        onOk={onRealDeleteObjects}
        okButtonProps={{
          disabled: model.__checkNumber === 0,
        }}
        onCancel={() => setDlgDeleteObjects(false)}
      >
        <XDataForm
          model={model}
          onChangeModel={(m) => setModel({ ...m })}
          form={[
            {
              type: XDF_TYPE.DIVIDER,
              label: 'Проверка возможности удаления объектов',
            },
            {
              type: XDF_TYPE.NUMBER,
              label: 'Введите число',
              prop: '__checkNumber',
              require: true,
            },
          ]}
        />
        {model.errmsg && <div style={{ color: 'red' }}>{model.errmsg}</div>}
      </Modal>

      {/* <MapObjectUploadDlg visible={uploadDlg} /> */}

      <XDImportExcelFile
        visible={uploadDlg}
        onClose={() => setUploadDlg(false)}
        importBtnTitle="Укажите файл для импорта объектов"
        maxStepToLoad={1000}
        uploadApi={uploadDataToServer('Sys:MapPoi', 'LIST:MapPoi', false)}
        initObject={{
          active: 1,
          cid: 5,
          g_country: 'Россия',
        }}
        columns={[
          {
            prop: 'name',
          },
          {
            prop: 'g_city',
          },
          {
            prop: 'addr',
          },
          {
            prop: '',
          },
          {
            prop: 'g_area',
          },
          {
            prop: 'phone',
            splitBy: ',',
          },
          {
            prop: '',
          },
          {
            prop: '',
          },
          {
            prop: 'lat',
            type: 'float',
            minMax: [-90, 90],
          },
          {
            prop: 'lng',
            type: 'float',
            minMax: [-180, 180],
          },
          {
            prop: 'site',
          },
        ]}
      />
    </>
  );
}
