import { PlusOutlined } from '@ant-design/icons';
import { Button, Layout, message, PageHeader, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';

import { useHistory, useLocation, useParams } from 'react-router-dom';
import { apiPost } from '../../../../api';
import apiAnyList from '../../../../api/AnyList';
import { subscribeToChannel, unsubscribeToChannel } from '../../../../api/sio';
import GTooltip from '../../../components/GTooltip/GTooltip';
import XDataTable from '../../../components/xdf-antd/XDataTable';
import KybSeriesEditorDlg from './KybSeriesEditorDlg';

import styles from './styles.scss';
import { commonColumns } from './table-defs';

export default function KybSeries() {
  const [model, setModel] = useState(null);

  const onAdd = () => {
    // history.push(`/site/news/--new--`);
  };

  const onRowClick = (item) => {
    setModel(item);
  };

  const onCloseDlg = () => {
    setModel(null);
  };

  const onSave = async (m) => {
    const { err, errmsg } = await apiPost('/site/series/set', {
      ...m,
    });
    if (err === 0) {
      onCloseDlg();
    } else if (Array.isArray(errmsg)) {
      for (const msg of errmsg) {
        message.error(msg.message);
      }
    } else {
      message.error(errmsg || `Net error ${err}`);
    }
  };

  return (
    <>
      <PageHeader
        className={styles.pageHeader}
        title={<GTooltip tid="kyb_site_series">Серии продуктов</GTooltip>}
        // extra={[
        //   <Tooltip key="add" title="Добавить">
        //     <Button type="primary" shape="circle" icon={<PlusOutlined />} onClick={onAdd} />
        //   </Tooltip>,
        // ]}
      />
      <Layout.Content>
        <div className={styles.contextHolder}>
          <div className={styles.context}>
            <XDataTable
              apiLoadData={apiAnyList('KybDb:Series')}
              commonColumns={commonColumns()}
              extentedColumns={null}
              onRowClick={onRowClick}
              sioEvents={['LIST:SITE_SERIES', subscribeToChannel, unsubscribeToChannel]}
              initSort={['_id', 1]}
              searchProps={['name', 'desc', 'title']}
            />
          </div>
        </div>
      </Layout.Content>

      <KybSeriesEditorDlg model={model} onClose={onCloseDlg} onSave={onSave} />
    </>
  );
}
