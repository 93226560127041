import React, { useEffect, useState } from 'react';

import { Col, Input } from 'antd';
import cn from 'classnames';
import gv from 'get-value';
import PropTypes from 'prop-types';

import styles from './styles.scss';

function validateUrl(value) {
  return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    value,
  );
}

function XDFUrl({ it, size, model, onPropChanged, onEnterPress }) {
  const { id, prop, label, placeholder, autoFocus, span, readOnly, showOnly, require } = it;

  const val = gv(model, prop, '');
  const [url0, setUrl0] = useState(val);
  const [isInvalid, setIsInvalid] = useState(false);

  useEffect(() => {
    setUrl0(gv(model, prop, ''));
  }, []);

  useEffect(() => {
    if (validateUrl(url0)) {
      setIsInvalid(false);
      if (val !== url0) {
        onPropChanged(it, url0);
      }
    } else {
      if (url0) setIsInvalid(true);
      onPropChanged(it, '');
    }
  }, [url0]);

  const onChange = (ev) => {
    setUrl0(ev.target.value);
  };

  const onKeyDown = (ev) => {
    if (ev.keyCode === 13) {
      if (onEnterPress) onEnterPress(ev, it);
    }
  };

  const suffix = (
    <a
      href={val}
      target="_blank"
      rel="noreferrer"
      title="Открыть в новом окне"
      style={{ marginLeft: 4 }}
    >
      {!!val && (
        <svg
          stroke="currentColor"
          fill="none"
          strokeWidth="0"
          viewBox="0 0 24 24"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
          />
        </svg>
      )}
    </a>
  );

  return (
    <Col span={span || 24}>
      <div className={styles.formItem}>
        <div className={styles.formItemLabel}>
          <label htmlFor={id}>
            {label}
            {require && <sup>*</sup>}
          </label>
        </div>
        <div
          className={cn(
            styles.formItemField,
            { [styles.small]: size === 'small' },
            { [styles.showOnly]: !!showOnly },
            { [styles.invalidUrl]: isInvalid },
          )}
        >
          <Input
            id={id}
            size={size}
            placeholder={placeholder || 'https://...'}
            autoFocus={!!autoFocus}
            disabled={!!readOnly || !!showOnly}
            value={url0}
            onChange={onChange}
            suffix={suffix}
            onKeyDown={onKeyDown}
            allowClear
          />
        </div>
      </div>
    </Col>
  );
}

XDFUrl.propTypes = {
  it: PropTypes.shape({
    id: PropTypes.string.isRequired,
    prop: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    placeholder: PropTypes.string,
    autoFocus: PropTypes.bool,
    span: PropTypes.number,
    readOnly: PropTypes.bool,
    showOnly: PropTypes.bool,
    require: PropTypes.bool,
    onEnter: PropTypes.func,
  }).isRequired,
  size: PropTypes.string.isRequired,
  model: PropTypes.shape({}).isRequired,
  onPropChanged: PropTypes.func.isRequired,
  onEnterPress: PropTypes.func.isRequired,
};

export default XDFUrl;
