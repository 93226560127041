import React from 'react';

import { Button, Col, Tooltip, Upload } from 'antd';
import cn from 'classnames';
import gv from 'get-value';
import PropTypes from 'prop-types';

import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import styles from './styles.scss';
import { useXDFScript } from './xdf-hooks';

const PICA_URL = 'https://cdnjs.cloudflare.com/ajax/libs/pica/9.0.1/pica.min.js';

export function getBase64(img) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result));
    reader.readAsDataURL(img);
  });
}

function resizeImage(base64Url, { width, height }) {
  return new Promise((resolve, reject) => {
    // console.log('resizeImage', width, height, base64Url);

    const resizedCanvas = document.createElement('canvas');
    resizedCanvas.height = width;
    resizedCanvas.width = height;

    const img = new Image();
    img.onload = () => {
      // eslint-disable-next-line no-undef
      pica()
        .resize(img, resizedCanvas, {
          // unsharpAmount: 80,
          // unsharpRadius: 0.6,
          // unsharpThreshold: 2,
        })
        .then((result) => {
          const resizedImgBase64 = result.toDataURL('image/jpeg');
          // console.log(resizedImgBase64);
          resolve(resizedImgBase64);
        })
        .catch((err) => {
          alert(err);
        });
    };
    img.src = base64Url;
  });
}

function XDFUploadBase64({ it, size, model, onPropChanged }) {
  const [doneJS] = useXDFScript(PICA_URL);

  const {
    id,
    prop,
    label,
    placeholder,
    span,
    readOnly,
    fileTypes,
    require,
    align,
    height,
    resize,
  } = it;

  const onChange = async (info) => {
    let imageUrl = await getBase64(info.file);

    if (resize) {
      imageUrl = await resizeImage(imageUrl, resize);
    }

    onPropChanged(it, imageUrl);
  };

  // Не загружать во вне файл
  const beforeUpload = async () => false;

  // удалить
  const onRemove = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    onPropChanged(it, null);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>{placeholder || 'Выбрать'}</div>
    </div>
  );

  const val = gv(model, prop, '');
  return (
    <Col span={span || 24}>
      <div className={styles.formItem}>
        <div className={styles.formItemLabel}>
          <label htmlFor={id}>
            {label || <span>&nbsp;</span>}
            {require && <sup>*</sup>}
          </label>
        </div>
        <div
          className={cn(styles.formItemField, styles.uploadPicBase64, {
            [styles.small]: size === 'small',
          })}
          style={{ textAlign: align || 'center', height: height || '104px' }}
        >
          {doneJS && (
            <Upload
              id={id}
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              accept={fileTypes || '.png,.jpg,.jpeg'}
              showUploadList={false}
              action={false}
              onChange={onChange}
              beforeUpload={beforeUpload}
              disabled={readOnly}
            >
              {val ? (
                <div alt="avatar" className="avatar" style={{ backgroundImage: `url('${val}')` }}>
                  &nbsp;
                </div>
              ) : (
                uploadButton
              )}
              {!!val && (
                <Tooltip title="Удалить">
                  <Button
                    size="small"
                    shape="circle"
                    icon={<DeleteOutlined />}
                    className={styles.remove}
                    onClick={onRemove}
                  />
                </Tooltip>
              )}
            </Upload>
          )}
        </div>
      </div>
    </Col>
  );
}

XDFUploadBase64.propTypes = {
  it: PropTypes.shape({
    id: PropTypes.string.isRequired,
    prop: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    placeholder: PropTypes.string,
    fileTypes: PropTypes.string,
    span: PropTypes.number,
    readOnly: PropTypes.bool,
    require: PropTypes.bool,
    align: PropTypes.string,
    height: PropTypes.string,
    resize: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
  }).isRequired,
  size: PropTypes.string.isRequired,
  model: PropTypes.shape({}).isRequired,
  onPropChanged: PropTypes.func.isRequired,
};

export default XDFUploadBase64;
