import { useEffect, useRef, useState } from 'react';

import { subscribeToChannel, unsubscribeToChannel } from '../../api/sio';

export function useScript(src, loadAsync = true) {
  // Keep track of script status ("idle", "loading", "ready", "error")
  const [status, setStatus] = useState(src ? 'loading' : 'idle');
  const [done, setDone] = useState(false);

  useEffect(
    () => {
      // Allow falsy src value if waiting on other data needed for
      // constructing the script URL passed to this hook.
      if (!src) {
        setStatus('idle');
        return;
      }
      setDone(false);

      // Fetch existing script element by src
      // It may have been added by another intance of this hook
      let script = document.querySelector(`script[src="${src}"]`);

      if (!script) {
        // Create script
        script = document.createElement('script');
        script.src = src;
        script.async = loadAsync;
        script.setAttribute('data-status', 'loading');
        // Add script to document body
        document.body.appendChild(script);

        // Store status in attribute on script
        // This can be read by other instances of this hook
        const setAttributeFromEvent = (event) => {
          script.setAttribute('data-status', event.type === 'load' ? 'ready' : 'error');
        };

        script.addEventListener('load', setAttributeFromEvent);
        script.addEventListener('error', setAttributeFromEvent);
      } else {
        // Grab existing script status from attribute and set to state.
        setStatus(script.getAttribute('data-status'));
      }

      // Script event handler to update status in state
      // Note: Even if the script already exists we still need to add
      // event handlers to update the state for *this* hook instance.
      const setStateFromEvent = (event) => {
        setStatus(event.type === 'load' ? 'ready' : 'error');
        setDone(event.type === 'load');
      };

      // Add event listeners
      script.addEventListener('load', setStateFromEvent);
      script.addEventListener('error', setStateFromEvent);

      // Remove event listeners on cleanup
      // eslint-disable-next-line consistent-return
      return () => {
        if (script) {
          script.removeEventListener('load', setStateFromEvent);
          script.removeEventListener('error', setStateFromEvent);
        }
      };
    },
    [src], // Only re-run effect if script src changes
  );

  return [done, status];
}

/**
 * Подписаться на канал
 * @param {*} channelName
 * @param {*} callback
 */
export function useChannelSubscribe(channelName, callback) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Remember the latest callback.
  useEffect(() => {
    function onMessageInChannel(msg) {
      savedCallback.current(msg);
    }

    // eslint-disable-next-line no-unused-expressions
    channelName && subscribeToChannel(channelName, onMessageInChannel);
    return () => {
      // eslint-disable-next-line no-unused-expressions
      channelName && unsubscribeToChannel(channelName, onMessageInChannel);
    };
  }, [channelName]);
}

/**
 * Подписка на несколько каналов с одинм callback (roles)
 * @param {*} channelsName
 * @param {*} callback
 */
export function useChannelsSubscribe(channelsName, callback) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Remember the latest callback.
  useEffect(() => {
    function onMessageInChannel(msg) {
      savedCallback.current(msg);
    }

    for (const channelName of channelsName) {
      // eslint-disable-next-line no-unused-expressions
      channelName && subscribeToChannel(channelName, onMessageInChannel);
    }
    return () => {
      for (const channelName of channelsName) {
        // eslint-disable-next-line no-unused-expressions
        channelName && unsubscribeToChannel(channelName, onMessageInChannel);
      }
    };
  }, [channelsName.join('|')]);
}
