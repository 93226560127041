import { apiPost, ApiError } from '.';

/**
 * Запрос серий продуктов
 */
const apiGTolltipGet = async (tid) => {
  const { err, errmsg, data } = await apiPost('/tooltip/get', { tid });
  if (err !== 0) throw ApiError(err, errmsg);
  return data;
};
export default apiGTolltipGet;
