import React, { useState } from 'react';

import { Layout, PageHeader } from 'antd';

import apiAnyList from '../../../../api/AnyList';
import { subscribeToChannel, unsubscribeToChannel } from '../../../../api/sio';
import GTooltip from '../../../components/GTooltip/GTooltip';
import XDataTable from '../../../components/xdf-antd/XDataTable';
import styles from './styles.scss';
import { commonColumns } from './table-defs';
import UserAuthorizationDlg from './UserAuthorizationDlg';
import { useRecoilValue } from 'recoil';
import { USER_ROLE } from '../../../constants';
import { notifyLastForRoleState } from '../../../../state/notify-state';

export default function UsersNew() {
  const notifyLastForRole = useRecoilValue(notifyLastForRoleState(USER_ROLE.USERS_NEW));
  const [approveUserId, setApproveUserId] = useState(0);

  function onRowClick(item) {
    setApproveUserId(item._id);
  }

  function onCloseApproveDlg() {
    setApproveUserId(0);
  }

  return (
    <>
      <PageHeader
        className={styles.pageHeader}
        title={<GTooltip tid="users_new">Запросы доступа</GTooltip>}
      />
      <Layout.Content>
        <div className={styles.contextHolder}>
          <div className={styles.context}>
            <XDataTable
              apiLoadData={apiAnyList('User')}
              commonColumns={commonColumns(notifyLastForRole)}
              extentedColumns={null}
              initSort={['_id', -1]}
              initFilter={{ state: 0 }}
              onRowClick={onRowClick}
              sioEvents={['LIST:USER', subscribeToChannel, unsubscribeToChannel]}
              searchProps={['name', 'lastName', 'midName', 'phone', 'email', 'company']}
            />
          </div>
        </div>
      </Layout.Content>
      <UserAuthorizationDlg userId={approveUserId} onClose={onCloseApproveDlg} />
    </>
  );
}
