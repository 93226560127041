/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';

import { Button, Layout, message, PageHeader, Tooltip } from 'antd';
import { useRecoilValue } from 'recoil';

import { CloseOutlined } from '@ant-design/icons';

import { webinar1SettingsState } from '../../../state/webinar1-state';
import GTooltip from '../../components/GTooltip/GTooltip';
import XDataForm, { XDF_TYPE } from '../../components/xdf-antd/XDataForm';
import styles from './styles.scss';
import { nanoid } from 'nanoid';
import { apiPost } from '../../../api';

export default function WebinarsV1Settings() {
  const webinarSettings = useRecoilValue(webinar1SettingsState);

  const [sets, setSets] = useState({});
  const [model, setModel] = useState({});
  const [isModelChanged, setIsModelChanged] = useState(false);

  useEffect(() => {
    if (webinarSettings._id === 'WEBINAR2_SETTINGS') {
      setSets({ ...webinarSettings });
    }
  }, [webinarSettings._id]);

  useEffect(() => {
    if (sets._id === 'WEBINAR2_SETTINGS') {
      setModel({
        url: webinarSettings.url,
        region: {
          ...sets.wregions.reduce((a, x) => {
            a[x.id] = x.name;
            return a;
          }, {}),
        },
        date: {
          ...sets.wdates.reduce((a, x) => {
            a[x.id] = x.name;
            return a;
          }, {}),
        },
      });
    }
  }, [sets]);

  const onChangeModel = (m, is) => {
    setIsModelChanged(is);
    setSets({
      ...sets,
      wregions: Object.keys(m.region).map((id) => ({ id, name: m.region[id] })),
      wdates: Object.keys(m.date).map((id) => ({ id, name: m.date[id] })),
    });
  };

  const addProp = (name) => {
    setSets({
      ...sets,
      [name]: [
        ...sets[name],
        {
          id: nanoid(5),
          name: '',
        },
      ],
    });
    setIsModelChanged(true);
  };

  const removeProp = (name, id) => {
    const idx = sets[name].findIndex((x) => x.id === id);
    if (idx >= 0) {
      setSets({
        ...sets,
        [name]: [...sets[name].slice(0, idx), ...sets[name].slice(idx + 1)],
      });
    }
    setIsModelChanged(true);
  };

  const onWebinarSettingsSave = async () => {
    const { err, errmsg } = await apiPost('/webinar2sets/set', { ...sets });
    if (err) {
      if (Array.isArray(errmsg)) {
        for (const em of errmsg) {
          message.error(em.message, 4);
        }
      } else {
        message.error(errmsg || `Net error: ${err}`);
      }
    } else {
      setIsModelChanged(false);
    }
  };

  return (
    <>
      <PageHeader
        className={styles.pageHeader}
        title={<GTooltip tid="webinar1_settings">Настройки анкеты</GTooltip>}
        extra={
          <>
            <Button type="default" onClick={() => addProp('wregions')}>
              Добавить регион{' '}
            </Button>
            <Button type="default" onClick={() => addProp('wdates')}>
              Добавить дату
            </Button>
            <Button type="primary" disabled={!isModelChanged} onClick={onWebinarSettingsSave}>
              Сохранить
            </Button>
          </>
        }
      />
      <Layout.Content>
        <div className={styles.contextHolder}>
          <div className={styles.context}>
            <XDataForm
              model={model}
              onChangeModel={onChangeModel}
              form={[
                {
                  type: XDF_TYPE.TEXT,
                  label: 'Адрес анкеты',
                  prop: 'url',
                  span: 24,
                  showOnly: true,
                },
                {
                  type: XDF_TYPE.DIVIDER,
                  label: 'Ваш регион',
                },
                ...(sets.wregions || []).map((x) => ({
                  type: XDF_TYPE.TEXT,
                  label: null,
                  prop: `region.${x.id}`,
                  suffix: (
                    <Tooltip title="Удалить поле">
                      <Button
                        type="text"
                        suze="small"
                        icon={<CloseOutlined />}
                        onClick={() => removeProp('wregions', x.id)}
                      />
                    </Tooltip>
                  ),
                })),
                {
                  type: XDF_TYPE.DIVIDER,
                  label: 'Даты участия в вебинаре',
                },
                ...(sets.wdates || []).map((x) => ({
                  type: XDF_TYPE.TEXT,
                  label: null,
                  prop: `date.${x.id}`,
                  suffix: (
                    <Tooltip title="Удалить поле">
                      <Button
                        type="text"
                        suze="small"
                        icon={<CloseOutlined />}
                        onClick={() => removeProp('wdates', x.id)}
                      />
                    </Tooltip>
                  ),
                })),
              ]}
            />
          </div>
        </div>
      </Layout.Content>
    </>
  );
}
