/* eslint-disable no-underscore-dangle */
import React, { useEffect, useMemo, useState } from 'react';

import { Badge, Dropdown, Layout, Menu, Popover } from 'antd';
import dayjs from 'dayjs';
import { Link, Switch, useLocation } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import {
  BellOutlined,
  CloseOutlined,
  LogoutOutlined,
  MoreOutlined,
  RetweetOutlined,
  UserOutlined,
} from '@ant-design/icons';

import apiNotifyHideById from '../../../api/NotifyHideById';
import { initSocketIO, subscribeToChannel } from '../../../api/sio';
import KybLogoIcon from '../../../assets/svg/kyb-logo.svg';
import routes from '../../../routes';
import {
  notifyByRoleGroupState,
  notifyCountByRoleState,
  notifyListState,
} from '../../../state/notify-state';
import { meState, rolesState } from '../../../state/panel-state';
import Webinars3Connect from '../../pages-connect/Common/Webinars3Connect';
import { useChannelsSubscribe } from '../../utils/hooks';
import LoginDialog from '../Dialogs/LoginDialog';
import UserBlocked from '../Dialogs/UserProfile/UserBlocked';
import UserEmailConfirmed from '../Dialogs/UserProfile/UserEmailConfirmed';
import UserProfile from '../Dialogs/UserProfile/UserProfile';
import UserWaitEmailConfirm from '../Dialogs/UserProfile/UserWaitEmailConfirm';
import UserWaitRoles from '../Dialogs/UserProfile/UserWaitRoles';
import styles from './styles.scss';
import { useMainMenu } from './useMainMenu';

const AUTH_STATE = {
  NULL: 0,
  LOGIN_PROCESS: 1,
  PROFILE: 2,
  WAIT_CONFIRM_EMAIL: 3,
  EMAIL_CONFIRMED: 4,
  WAIT_ROLES: 5,
  BLOCKED: 7,
};

const WEBINARS3_CONNECT_DOMAINS = [
  'kconnect2021.geo4.biz',
  'kyb-connect.geo4.biz',
  'connect.kyb.ru',
];

/**
 * | Header                                          |
 * ---------------------------------------------------
 * | LMenu |  MainPage
 * |       |
 * |       |
 * |       |
 * @param {*} param0
 */
function MainFrame() {
  const me = useRecoilValue(meState);
  const roles = useRecoilValue(rolesState);

  // const [notifyList, setNotifyList] = useRecoilState(notifyListState);

  const notifyList = useRecoilValue(notifyListState);
  const updateNotifyList = useSetRecoilState(notifyListState);

  const myUpNotifyList = useMemo(
    () => notifyList.filter((item) => item.uid !== me._id),
    [notifyList, me._id],
  );

  const notifyCountByRole = useRecoilValue(notifyCountByRoleState);
  const notifyByRoleGroup = useRecoilValue(notifyByRoleGroupState);

  const [webinars3Connect, setWebinars3Connect] = useState(false);
  const [showMainFrame, setShowMainFrame] = useState(false);
  const [authState, setAuthState] = useState(AUTH_STATE.NULL);

  const location = useLocation();
  const [routesList, selectedItem, openMainItem] = useMainMenu(me, location.pathname);
  const [openItems, setOpenItems] = useState(null);

  const [userProfileDlgVisible, setUserProfileDlgVisible] = useState(false);

  // console.log('notifyList', notifyList);
  // console.log('notifyCountByRole', notifyCountByRole);
  // console.log('notifyByRoleGroup', notifyByRoleGroup);

  useEffect(() => {
    if (me._id) {
      // console.log(me, roles);

      setTimeout(() => {
        initSocketIO();
      }, 1000);

      // user works
      if (me.state === 1 && roles?.length > 0) {
        setShowMainFrame(true);
        return;
      }

      // states
      if (me.state === 0 && !me.email) {
        setAuthState(AUTH_STATE.PROFILE);
        return;
      }

      if (me.state === 0 && me.email && me.emailOk === 0) {
        setAuthState(AUTH_STATE.WAIT_CONFIRM_EMAIL);
        return;
      }

      if (me.state === 0 && me.email && me.emailOk === 1) {
        setAuthState(AUTH_STATE.EMAIL_CONFIRMED);
        return;
      }

      if (me.state === 1) {
        setAuthState(AUTH_STATE.WAIT_ROLES);
        return;
      }

      if (me.state === -1) {
        setAuthState(AUTH_STATE.BLOCKED);
      }
    } else if (me.__error === 103) {
      // blocked
      setAuthState(AUTH_STATE.BLOCKED);
    } else {
      setAuthState(AUTH_STATE.LOGIN_PROCESS);
    }
  }, [me._id, me.email, me.emailOk, roles.join(',')]);

  /**
   * Новое событие для роли
   */
  useChannelsSubscribe(
    roles.map((r) => `ROLE:${r}`),
    (msg) => {
      // console.log('notifyList', notifyList);
      // console.log('ROLEs', msg);
      if (msg.ev === 'noti-add' /* && msg.data.uid !== me._id */) {
        updateNotifyList([msg.data, ...notifyList]);
      } else if (msg.ev === 'noti-hide') {
        const { data } = msg;
        const updatedList = notifyList.filter(
          (it) => !(it.id === data.id && it.role === data.role),
        );
        updateNotifyList(updatedList);
      } else if (msg.ev === 'noti-hide-id') {
        const updatedList = notifyList.filter((it) => it._id !== msg.id);
        updateNotifyList(updatedList);
      }
    },
  );

  /**
   * Обновить страницу, если поменялся доступ
   */
  useEffect(() => {
    if (
      WEBINARS3_CONNECT_DOMAINS.includes(window.location.hostname) ||
      location.pathname === '/webinars/connect/home'
    ) {
      setWebinars3Connect(true);
    } else {
      // subscribe to me
      subscribeToChannel(`ME:${me._id}`, (msg) => {
        if (msg.ev === 'access_updated') {
          document.location.reload();
        }
      });
    }
  }, []);

  const onOpenChange = (keys) => {
    setOpenItems(keys);
  };

  const logout = () => {
    window.location.replace('/api/1/auth/logout');
  };

  const showProfile = () => {
    setUserProfileDlgVisible(true);
  };

  const onCloseUserProfileDlg = () => {
    setUserProfileDlgVisible(false);
  };

  const rightMenu = (
    <Menu>
      <Menu.Item icon={<UserOutlined />} onClick={showProfile}>
        Профиль
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item icon={<LogoutOutlined />} onClick={logout}>
        Выход
      </Menu.Item>
    </Menu>
  );

  const onHideNotify = async (id) => {
    await apiNotifyHideById(id);
  };

  const onHideAllNotify = async (list) => {
    for (const it of list) {
      // eslint-disable-next-line no-await-in-loop
      await apiNotifyHideById(it._id);
    }
  };

  const notifyListContent = () => (
    <div className={styles.notifyList}>
      {myUpNotifyList.map((item) => (
        <div key={item._id} className={styles.notifyItem}>
          <div className={styles.ndt}>{dayjs(item.cdt).format('DD.MM.YYYY HH:mm:ss')}</div>
          <div className={styles.ntxt}>{item.txt}</div>
          <button type="button" onClick={() => onHideNotify(item._id)}>
            <CloseOutlined />
          </button>
        </div>
      ))}
    </div>
  );

  if (webinars3Connect) {
    return <Webinars3Connect />;
  }

  if (!showMainFrame) {
    return (
      <>
        <LoginDialog visible={authState === AUTH_STATE.LOGIN_PROCESS} />
        <UserProfile visible={authState === AUTH_STATE.PROFILE} />
        <UserWaitEmailConfirm visible={authState === AUTH_STATE.WAIT_CONFIRM_EMAIL} />
        <UserEmailConfirmed visible={authState === AUTH_STATE.EMAIL_CONFIRMED} />
        <UserWaitRoles visible={authState === AUTH_STATE.WAIT_ROLES} />
        <UserBlocked visible={authState === AUTH_STATE.BLOCKED} />
      </>
    );
  }

  return (
    <>
      <Layout className={styles.mainLayout}>
        <Layout.Header className={styles.header} style={{ paddingRight: 30 }}>
          <Link to="/" className={styles.logo}>
            <KybLogoIcon />
          </Link>
          <div className={styles.headerHolder}>
            <div className={styles.userType}>
              МОДЕРАТОР{' '}
              <RetweetOutlined
                style={{ marginLeft: 8, fontSize: 16, color: 'rgba(0, 0, 0, 0.25)' }}
              />
            </div>
            <div className={styles.notifications}>
              <Popover
                content={notifyListContent()}
                title={
                  <div className={styles.notificationsWindowTitle}>
                    <span>Оповещения</span>
                    {myUpNotifyList?.length > 0 && (
                      <button type="button" onClick={() => onHideAllNotify(myUpNotifyList)}>
                        сбросить все
                      </button>
                    )}
                  </div>
                }
                placement="bottomRight"
                arrowPointAtCenter
                // trigger="click"
              >
                <Badge size="small" count={myUpNotifyList.length}>
                  <BellOutlined />
                </Badge>
              </Popover>
            </div>
            <div className={styles.rightMenu}>
              <Dropdown overlay={rightMenu} trigger="click" placement="bottomRight">
                <MoreOutlined />
              </Dropdown>
            </div>
          </div>
        </Layout.Header>
        <Layout>
          <Layout.Sider width={256} className={styles.sider}>
            <Menu
              mode="inline"
              selectedKeys={selectedItem}
              openKeys={openItems || openMainItem}
              onOpenChange={onOpenChange}
              style={{ height: '100%' }}
            >
              {routes
                .filter((item) => !!roles.find((x) => x > item.role && x < item.role + 99))
                .map((item) => (
                  <Menu.SubMenu
                    key={item.role}
                    icon={<Badge dot={notifyByRoleGroup[item.role]}>{item.icon}</Badge>}
                    title={item.label}
                  >
                    {item.subs
                      .filter((x) => {
                        if (!x.label) return false;
                        return roles.includes(x.role);
                      })
                      .map((sit) => (
                        <Menu.Item key={`${item.role}-${sit.role}`}>
                          <Link to={sit.path}>
                            {sit.label} <Badge size="small" count={notifyCountByRole[sit.role]} />
                          </Link>
                        </Menu.Item>
                      ))}
                  </Menu.SubMenu>
                ))}
            </Menu>
          </Layout.Sider>
          <Layout className={styles.pageContent}>
            <Switch>{routesList}</Switch>
          </Layout>
        </Layout>
      </Layout>

      <UserProfile visible={userProfileDlgVisible} onClose={onCloseUserProfileDlg} />

      {/* {!me?._id && (
        <>
          {me.__error === 100 && <LoginDialog />}
          {me.__error === 103 && <UserBlocked />}
        </>
      )}
      {me?._id > 0 && (!roles || roles.length === 0) && (
        <>
          {!me?.email && <UserProfile />}
          {me?.state === 0 && me?.email && !me?.emailOk && <UserWaitEmailConfirm />}
          {me?.state === 0 && me?.email && !!me?.emailOk && <UserEmailConfirmed />}
          {me?.state === 1 && <UserWaitRoles />}
        </>
      )} */}
    </>
  );
}

MainFrame.defaultProps = {
  example: null,
};

MainFrame.propTypes = {};

export default MainFrame;
