import '../styles/styles.scss';
import 'antd/dist/antd.variable.min.css';

import React from 'react';

import { BrowserRouter as Router } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import packageJson from '../../package.json';
import MainFrame from './components/MainFrame/MainFrame';
import SiteLoading from './components/SiteLoading/SiteLoading';

// eslint-disable-next-line no-console
console.log(`KYB System v${packageJson.version}`);

function App() {
  return (
    <RecoilRoot>
      <Router>
        <React.Suspense fallback={<SiteLoading />}>
          <MainFrame />
        </React.Suspense>
      </Router>
    </RecoilRoot>
  );
}

export default App;
