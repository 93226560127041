import React, { useEffect, useState } from 'react';

import { Modal } from 'antd';
import PropTypes from 'prop-types';
import XDataForm, { XDF_TYPE } from '../../../components/xdf-antd/XDataForm';

function MapSetsIconsDlg({ visible, model, onSave, onCancel }) {
  const [model0, setModel0] = useState({});
  const [isModelChanged, setIsModelChanged] = useState(false);

  useEffect(() => {
    if (visible) {
      setModel0(model);
    }
  }, [visible]);

  const onSaveModel = () => {
    onSave(model0);
  };

  const onChangeModel = (m, v) => {
    setModel0({ ...m });
    setIsModelChanged(v);
  };

  return (
    <Modal
      title="Редактирование категории"
      open={visible}
      onOk={onSaveModel}
      okButtonProps={{
        disabled: !isModelChanged,
      }}
      onCancel={onCancel}
    >
      <XDataForm
        model={model0}
        onChangeModel={onChangeModel}
        form={[
          {
            type: XDF_TYPE.TEXT,
            label: 'ID',
            prop: 'id',
            span: 12,
            require: true,
          },
          {
            type: XDF_TYPE.TEXT,
            label: 'Название',
            prop: 'name',
            span: 12,
            require: true,
          },
          {
            type: XDF_TYPE.UPLOAD_BASE64,
            label: 'Иконка на карте',
            prop: 'icon',
            require: true,
            span: 24,
          },

          {
            type: XDF_TYPE.DIVIDER,
            label: 'Параметры иконки на карте',
          },
          {
            type: XDF_TYPE.NUMBER,
            label: 'Ширина',
            prop: 'iW',
            require: true,
            span: 6,
          },
          {
            type: XDF_TYPE.NUMBER,
            label: 'Высота',
            prop: 'iH',
            require: true,
            span: 6,
          },
          {
            type: XDF_TYPE.NUMBER,
            label: 'Указатель X',
            prop: 'iX',
            require: true,
            span: 6,
          },
          {
            type: XDF_TYPE.NUMBER,
            label: 'Указатель Y',
            prop: 'iY',
            require: true,
            span: 6,
          },
        ]}
      />
    </Modal>
  );
}

MapSetsIconsDlg.defaultProps = {};

MapSetsIconsDlg.propTypes = {
  visible: PropTypes.bool.isRequired,
  model: PropTypes.shape({}).isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default MapSetsIconsDlg;
