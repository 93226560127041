/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState, useRef } from 'react';
import LZString from 'lz-string';

/**
 * Параметры из URL в состояние таблицы
 * @param {*} urlParams
 *  0 - количество в списке
 *  1 - номер страницы
 *  2 - сортировка (поле)
 *  3 - сортировка (направление)
 *  4 - ID доп колонок
 *  5 - сжатый текущий фильтр
 *
 * @param {*} commonColumns
 * @param {*} extentedColumns
 */
export function useUrlToTableState(
  commonColumns,
  extentedColumns,
  initFilter,
  initSort,
  urlTableState,
  defValues,
) {
  const [pageSize, setPageSize] = useState(defValues.pageSize);
  const [pageNumber, setPageNumber] = useState(1);
  const [sortProp, setSortProp] = useState('');
  const [sortOrder, setSortOrder] = useState(1);
  const [extColumnsSelected, setExtColumnsSelected] = useState(null);
  const [props, setProps] = useState([]);
  const [stateHash, setStateHash] = useState('');
  const [filter, setFilter] = useState(initFilter);
  const [sfilter, setSfilter] = useState('');
  const [filteredByCheckedRows, setFilteredByCheckedRows] = useState(0);
  const [tempId, setTempId] = useState('');

  useEffect(() => {
    const urlState = urlTableState.split(',');
    setStateHash(`STATE-HASH:${urlState.join('$')}`);

    urlState[0] && setPageSize(parseInt(urlState[0], 10));
    urlState[1] && setPageNumber(parseInt(urlState[1], 10));

    if (urlState[2]) {
      setSortProp(urlState[2]);
      setSortOrder(urlState[3] === 'a' ? 1 : -1);
    } else {
      setSortProp(initSort[0]);
      setSortOrder(initSort[1]);
    }

    let exSelected = null;
    if (Array.isArray(extentedColumns) && extentedColumns.length > 0) {
      if (urlState[4]) exSelected = extentedColumns.find((x) => x.id === urlState[4]);
      // eslint-disable-next-line prefer-destructuring
      if (!exSelected) exSelected = extentedColumns[0];
    }
    setExtColumnsSelected(exSelected);

    // filter
    setSfilter(urlState[5] || '');

    let filter0 = initFilter;
    if (urlState[5]) {
      try {
        filter0 = JSON.parse(LZString.decompressFromEncodedURIComponent(urlState[5]));
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
    setFilter(filter0);

    // props
    let _props = [...(commonColumns.props || [])];
    commonColumns.columns.forEach((col) => {
      if (col.prop.charAt(0) === '+') _props.push(col.prop.substring(1));
    });
    if (exSelected && exSelected.id) {
      _props = [..._props, ...(exSelected.props || [])];
      exSelected.columns.forEach((col) => {
        if (col.prop.charAt(0) === '+') _props.push(col.prop.substring(1));
      });
    }

    // temp id
    setTempId(urlState[5 + 1]);

    // checked
    if (urlState[7] === '1') setFilteredByCheckedRows(1);
    else setFilteredByCheckedRows(0);

    setProps(_props);
  }, [urlTableState]);

  return [
    stateHash,
    pageSize,
    pageNumber,
    sortProp,
    sortOrder,
    extColumnsSelected,
    filter,
    sfilter,
    props,
    filteredByCheckedRows,
    tempId,
  ];
}

/**
 * Подписаться на канал
 * @param {*} channelName
 * @param {*} callback
 */
export function useXDTChannelSubscribe(
  channelName,
  subscribeToChannel,
  unsubscribeToChannel,
  callback,
) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Remember the latest callback.
  useEffect(() => {
    function onMessageInChannel(msg) {
      savedCallback.current(msg);
    }

    channelName && subscribeToChannel(channelName, onMessageInChannel);
    return () => {
      channelName && unsubscribeToChannel(channelName, onMessageInChannel);
    };
  }, [channelName]);
}

export const useXDTClickOutside = (ref, callback, useEscKey = false) => {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        callback();
      };
      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      const escKeyUpListener = (ev) => {
        if (ev.keyCode === 27) {
          callback();
        }
      };
      if (useEscKey) {
        document.addEventListener('keyup', escKeyUpListener);
      }
      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
        if (useEscKey) {
          document.removeEventListener('keyup', escKeyUpListener);
        }
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, callback, useEscKey],
  );
};
