/* eslint-disable no-underscore-dangle */
import React, { useRef, useState } from 'react';

import { Layout, PageHeader } from 'antd';
import { useHistory } from 'react-router-dom';

import apiAnyList from '../../../../api/AnyList';
import { subscribeToChannel, unsubscribeToChannel } from '../../../../api/sio';
import GTooltip from '../../../components/GTooltip/GTooltip';
import XDataTable from '../../../components/xdf-antd/XDataTable';
import styles from './styles.scss';
import { commonColumnsComments, filterFormComments } from './table-defs';

export default function MapCommentsList() {
  const history = useHistory();

  const tableEmitter = useRef(null);
  const [filter, setFilter] = useState({});

  const onSaveEventEmmitter = (ee) => {
    tableEmitter.current = ee;
    tableEmitter.current?.addListener('FILTER-CHANGED', (f) => {
      setFilter(f);
    });
  };

  const refreshTable = () => {
    tableEmitter.current?.emit('RELOAD-DATA');
  };

  const filterByObject = (it) => {
    tableEmitter.current?.emit('SET-FILTER', { _oid: [it._oid] });
  };

  const openObjectCard = (objectId) => {
    history.push(`/maps/object/${objectId}`);
  };

  return (
    <>
      <PageHeader
        className={styles.pageHeader}
        title={<GTooltip tid="map_object_comments">Список комментариев</GTooltip>}
        extra={
          [
            // <Tooltip key="upload" title="Загрузить файл на сервер">
            //   <Button shape="circle" icon={<CloudUploadOutlined />} disabled />
            // </Tooltip>,
            // <Tooltip key="add" title="Добавить запись">
            //   <Button type="primary" shape="circle" icon={<PlusOutlined />} onClick={addRecord} />
            // </Tooltip>,
            // <Tooltip key="download" title="Скачать файл" onClick={downloadFile}>
            //   <Button shape="circle" icon={<CloudDownloadOutlined />} />
            // </Tooltip>,
          ]
        }
      />
      <Layout.Content>
        <div className={styles.contextHolder}>
          <div className={styles.context}>
            <XDataTable
              apiLoadData={apiAnyList('Sys:MapPoiComment')}
              commonColumns={commonColumnsComments(
                filter,
                openObjectCard,
                filterByObject,
                refreshTable,
              )}
              extentedColumns={null}
              initSort={['_cdt', -1]}
              initFilter={{ _state: [0] }}
              filterForm={filterFormComments()}
              sioEvents={['LIST:MapPoiComment', subscribeToChannel, unsubscribeToChannel]}
              onSaveEventEmitter={onSaveEventEmmitter}
            />
          </div>
        </div>
      </Layout.Content>
    </>
  );
}
