import React from 'react';

import { Button, Layout, PageHeader, Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import apiAnyList from '../../../../api/AnyList';
import { subscribeToChannel, unsubscribeToChannel } from '../../../../api/sio';
import { notifyLastForRoleState } from '../../../../state/notify-state';
import { webinar1SettingsState } from '../../../../state/webinar1-state';
import GTooltip from '../../../components/GTooltip/GTooltip';
import XDataTable from '../../../components/xdf-antd/XDataTable';
import styles from './styles.scss';
import { commonColumns } from './table-defs';

export default function NewProductsInAssortment() {
  const history = useHistory();

  const webinarSettings = useRecoilValue(webinar1SettingsState);
  const notifyLastForRole = useRecoilValue(notifyLastForRoleState(801));

  const onRowClick = (item) => {
    // goto product page
    history.push(`/news/newproducts/${item._id}`);
  };

  return (
    <>
      <PageHeader
        className={styles.pageHeader}
        title={<GTooltip tid="news_products_in_assortment">Новинки ассортимента</GTooltip>}
        extra={[
          <Tooltip key="create" title="Создать новость">
            <Button type="primary" onClick={() => history.push('/news/newproducts/--new--')}>
              Создать
            </Button>
          </Tooltip>,
        ]}
      />
      <Layout.Content>
        <div className={styles.contextHolder}>
          <div className={styles.context}>
            <XDataTable
              apiLoadData={apiAnyList('KybDb:ProductsInAssortment')}
              commonColumns={commonColumns(notifyLastForRole, webinarSettings)}
              extentedColumns={null}
              onRowClick={onRowClick}
              sioEvents={['LIST:PROD_IN_ASSORTM', subscribeToChannel, unsubscribeToChannel]}
              initFilter={{ status: [0, 1] }}
              initSort={['_dt', -1]}
              searchProps={['surname', 'name']}
            />
          </div>
        </div>
      </Layout.Content>
    </>
  );
}
