export function getConnectDomain() {
  // eslint-disable-next-line no-undef
  return CONNECT_DOMAIN || 'https://kconnect2021.geo4.biz';
}

export function arrayReplaceItemAtIndex(arr, index, newValue) {
  return [...arr.slice(0, index), newValue, ...arr.slice(index + 1)];
}

export function arrayRemoveItemAtIndex(arr, index) {
  return [...arr.slice(0, index), ...arr.slice(index + 1)];
}

export function arrayAddItemToStart(arr, newValue) {
  return [newValue, ...arr];
}

const colors = [
  '#cc0033',
  '#FF9900',
  '#6666FF',
  '#339966',
  '#9933CC',
  '#FF3366',
  '#333399',
  '#CC9900',
  '#FF00CC',
  '#3399CC',
  '#00CC99',
  '#999900',
];

export const getGraphColor = (idx) => colors[idx % colors.length];

/**
 * Округление числа до нужного знака после запятой
 * @param x
 * @param n
 * @returns {*}
 */
export const roundPlus = (x, n = 2) => {
  // x - число, n - количество знаков
  if (isNaN(x) || isNaN(n)) return undefined;
  const m = 10 ** n;
  return Math.round(x * m) / m;
};

export const Logger = {
  log: (...args) => console.log(...args),
  error: (...args) => console.error(...args),
};
