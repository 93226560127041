import { selector } from 'recoil';
import apiMeGet from '../api/MeGet';

export const panelState = selector({
  key: '__panel__',
  get: async ({ get }) => {
    const response = await apiMeGet();

    // const { notifications } = response;
    // console.log('notifications', notifications);
    // const setTodoList = useSetRecoilState(notifyListState);
    // setTodoList(notifications);

    return response;
  },
});

export const meState = selector({
  key: '__me__',
  get: ({ get }) => {
    const { me } = get(panelState);
    return me;
  },
});

export const rolesState = selector({
  key: '__roles__',
  get: ({ get }) => {
    const { roles } = get(panelState);
    return roles;
  },
});

export const configState = selector({
  key: '__config__',
  get: ({ get }) => {
    const { config } = get(panelState);
    return config;
  },
});

export const notificationsInitState = selector({
  key: '__notify_init__',
  get: ({ get }) => {
    // const todoList = useRecoilValue(notifyListState);
    // console.log('todoList', todoList);

    const { notifications } = get(panelState);
    return notifications;
  },
});
