import setValue from 'set-value';

/**
 * Работа как с plain props & dots
 * @param {*} obj
 * @param {*} prop
 * @param {*} def
 * @param {*} plain
 * @returns
 */
const xdfSetValue = (obj, prop, val, plain = false) => {
  if (!plain) setValue(obj, prop, val);
  // eslint-disable-next-line no-param-reassign
  else obj[prop] = val;
};

export default xdfSetValue;
