// Общие колонки, есть на всех видах

import { XDF_TYPE } from '../../../components/xdf-antd/XDataForm';
import { WEBINAR3_TYPE, WEBINAR3_VIEW } from '../webinar_constants';

// eslint-disable-next-line import/prefer-default-export
export const commonColumns = () => ({
  name: null, // 'Кроссы', // название
  props: [], // список полей для запроса к серверу
  rowStyle: (item) => {
    if (item.webinarUser?.emailState === 1)
      return {
        backgroundColor: 'rgba(0, 255, 0, 0.04)',
      };
  },
  columns: [
    {
      prop: '+webinarUser.phone',
      name: 'Телефон',
      sort: true,
    },
    {
      prop: '+webinarUser.lastname',
      name: 'Фамилия',
      sort: true,
    },
    {
      prop: '+webinarUser.firstname',
      name: 'Имя',
      sort: true,
    },
    {
      prop: '+webinarUser.region',
      name: 'Регион',
      nowrap: true,
      sort: true,
    },
    {
      prop: '+webinarUser.city',
      name: 'Город',
      nowrap: true,
      sort: true,
    },
    {
      prop: '+webinarUser.company',
      name: 'Компания',
      nowrap: true,
      sort: true,
    },
    {
      prop: '+webinarUser.status',
      name: 'Должность',
      nowrap: true,
      sort: true,
    },
    {
      prop: '+webinarUser.email',
      name: 'E-mail',
      sort: true,
    },
    {
      prop: '+webinarUser.emailState',
      name: 'Статус E-mail',
      type: 'check',
      align: 'center',
      sort: true,
    },
    {
      prop: '__webiCount',
      name: 'Вебинаров',
      notNull: true,
      sort: true,
      align: 'center',
    },
    {
      prop: '__semiCount',
      name: 'Семинаров',
      notNull: true,
      sort: true,
      align: 'center',
    },
  ],
});

export function filterFormWebinarsUser() {
  return [
    {
      label: 'Подробная инофрмация',
    },
    {
      type: XDF_TYPE.TEXT,
      label: 'Фамилия',
      prop: '~~webinarUser#lastname',
    },
    {
      type: XDF_TYPE.TEXT,
      label: 'Имя',
      prop: '~~webinarUser#firstname',
    },
    {
      type: XDF_TYPE.TEXT,
      label: 'Регион',
      prop: '~~webinarUser#region',
    },
    {
      type: XDF_TYPE.TEXT,
      label: 'Город',
      prop: '~~webinarUser#city',
    },
    {
      label: 'Компания',
    },
    {
      type: XDF_TYPE.TEXT,
      label: 'Компания',
      prop: '~~webinarUser#company',
    },
    {
      type: XDF_TYPE.TEXT,
      label: 'Должность',
      prop: '~~webinarUser#status',
    },
    {
      label: 'Мероприятия',
    },
    {
      type: XDF_TYPE.MULTISELECT,
      label: 'Вид мероприятия',
      prop: '_eventsViews',
      values: WEBINAR3_VIEW,
    },
    {
      type: XDF_TYPE.MULTISELECT,
      label: 'Тип мероприятия',
      prop: '_eventsTypes',
      values: WEBINAR3_TYPE,
    },
  ];
}
