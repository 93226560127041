import { Layout, PageHeader, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';

import { useHistory, useLocation, useParams } from 'react-router-dom';
import GTooltip from '../../../components/GTooltip/GTooltip';
import KSBannerLeft from './KSBannerLeft';
import KSBannerMainPage from './KSBannerMainPage';
import KSBannerTopRight from './KSBannerTopRight';
import KSBannerTopRight2 from './KSBannerTopRight2';

import styles from './styles.scss';

export default function KybSiteBanners() {
  // router
  const history = useHistory();
  // const location = useLocation();
  // const { proudctId } = useParams();

  return (
    <>
      <PageHeader
        className={styles.pageHeader}
        title={<GTooltip tid="kyb_site_banners">Баннеры на сайте</GTooltip>}
        onBack={() => history.goBack()}
      />
      <Layout.Content>
        <div className={styles.contextHolder}>
          <div className={styles.contextPage}>
            <Tabs type="card" tabPosition="right">
              <Tabs.TabPane tab="Главная страница" key="mp" forceRender>
                <KSBannerMainPage />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Справа вверху" key="topright" forceRender>
                <KSBannerTopRight />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Справа вверху ниже" key="topright2" forceRender>
                <KSBannerTopRight2 />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Слева, под меню" key="left" forceRender>
                <KSBannerLeft />
              </Tabs.TabPane>
            </Tabs>
          </div>
        </div>
      </Layout.Content>
    </>
  );
}
