import React from 'react';

import { Modal } from 'antd';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';

import LoginDlgLogo from '../../../../assets/svg/login-dlg-logo.svg';
import { meState } from '../../../../state/panel-state';
import styles from './styles.scss';

function UserBlocked({ visible }) {
  const me = useRecoilValue(meState);

  return (
    <>
      <Modal
        className={styles.kybLoginDlg}
        title={null}
        width={610}
        open={visible}
        closable={false}
        footer={null}
      >
        <h1>
          Регистрация в сервисах <LoginDlgLogo />
        </h1>
        <div className={styles.info}>
          Ваш статус: <span>Заблокирован</span>
        </div>
        <div className={styles.info}>
          Мобильный: <span>{me.phone}</span>
        </div>

        <div className={styles.msgRed}>
          Указанный номер телефона не позволяет авторизоваться в сервисе
        </div>

        <div className={styles.msg}>
          Решить возникшие вопросы с авторизацией и завершить её, вы можете связавшись с менеджерами
          KYB.
        </div>
      </Modal>
    </>
  );
}

UserBlocked.propTypes = {
  visible: PropTypes.bool.isRequired,
};

export default UserBlocked;
