import React, { useState } from 'react';

import { Button, Modal } from 'antd';
import PropTypes from 'prop-types';

import LoginDlgLogo from '../../../../assets/svg/login-dlg-logo.svg';
import XDataForm, { XDF_TYPE } from '../../xdf-antd/XDataForm';
import styles from './styles.scss';

function KybLoginPhoneDialog({ visible, onSendPhone }) {
  const [model, setModel] = useState({});

  const onChangeModel = (m) => {
    setModel({ ...m });
  };

  const sendPhone = () => {
    onSendPhone(model.phone);
  };

  return (
    <Modal
      className={styles.kybLoginDlg}
      title={null}
      width={400}
      open={visible}
      closable={false}
      footer={null}
    >
      <h1>
        Вход в сервисы <LoginDlgLogo />
      </h1>
      <div className={styles.msg}>Для входа или регистрации введите номер мобильного телефона</div>
      <XDataForm
        model={model}
        onChangeModel={onChangeModel}
        form={[
          {
            type: XDF_TYPE.PHONE,
            label: 'Номер вашего телефона',
            prop: 'phone',
            span: 24,
            autoFocus: true,
            require: true,
          },
        ]}
      />
      <div className={styles.dmsg}>
        Нажав на кнопку "Получить код" или иным образом используя данный интернет сервис, вы
        подтверждаете , что прочитали, поняли и согласны с <a href="#">Условиями использования</a> и{' '}
        <a href="#">Политикой конфиденциальности</a>
      </div>
      <div className={styles.footer}>
        <Button type="primary" disabled={!model.phone} onClick={sendPhone}>
          Получить код
        </Button>
      </div>
    </Modal>
  );
}

KybLoginPhoneDialog.defaultProps = {};

KybLoginPhoneDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  onSendPhone: PropTypes.func.isRequired,
};

export default KybLoginPhoneDialog;
