/* eslint-disable no-undef */
/* eslint-disable import/prefer-default-export */
import React, { useRef, useEffect, useState } from 'react';
import { useScript } from '../../../utils/hooks';

const aceJsList = [
  'https://cdnjs.cloudflare.com/ajax/libs/ace/1.4.13/ace.min.js',
  'https://cdnjs.cloudflare.com/ajax/libs/ace/1.4.13/mode-html.min.js',
  'https://cdnjs.cloudflare.com/ajax/libs/ace/1.4.13/theme-monokai.min.js',
  'https://cdnjs.cloudflare.com/ajax/libs/ace/1.4.13/ext-beautify.min.js',
  'https://cdnjs.cloudflare.com/ajax/libs/ace/1.4.13/theme-idle_fingers.min.js',
];

/**
 * Загрузка и инициалиазция Ace редактора для xml
 * @param {*} ref
 */
export const useAceXml = (ref, initCode) => {
  const editor = useRef(null);
  const [done, setDone] = useState(false);
  const [editorValue, setEditorValue] = useState('');

  const [startLoadJs, setStartLoadJs] = useState(false);
  const [jsAllDone, setJsAllDone] = useState(false);
  const [jsDone0] = useScript(startLoadJs && aceJsList[0]);
  const [jsDone1] = useScript(jsDone0 && aceJsList[1], true);
  const [jsDone2] = useScript(jsDone1 && aceJsList[2], true);
  const [jsDone3] = useScript(jsDone2 && aceJsList[3], true);
  const [jsDone4] = useScript(jsDone3 && aceJsList[4], true);

  useEffect(() => {
    if (jsDone4) setJsAllDone(true);
  }, [jsDone4]);

  // load js
  useEffect(() => {
    if (window.ace === undefined) {
      setStartLoadJs(true);
    } else {
      setJsAllDone(true);
    }
  }, []);

  useEffect(() => {
    if (jsAllDone && ref.current) {
      // console.log('All JS Load - done');
      editor.current = ace.edit(ref.current, {
        // enableBasicAutocompletion: true,
        // enableLiveAutocompletion: true,
        // enableSnippets: false,
        showLineNumbers: true,
        tabSize: 2,
        useWorker: false,
        selectionStyle: 'text',
        theme: 'ace/theme/monokai',
        mode: 'ace/mode/html',
      });
      editor.current.setValue(initCode);
      editor.current.on('change', () => {
        setEditorValue(editor.current.getValue());
      });
    }
    return () => {
      // cleanup;
    };
  }, [jsAllDone, ref.current]);

  useEffect(() => {
    if (editor.current) {
      editor.current.setValue(initCode);
    }
  }, [initCode]);

  return [done, editorValue];
};
