import React, { useEffect, useState } from 'react';

import { Button, Modal, message } from 'antd';
import PropTypes from 'prop-types';

import GTooltip from '../../../components/GTooltip/GTooltip';
import XDataForm, { XDF_TYPE } from '../../../components/xdf-antd/XDataForm';
import { warningDialog } from '../../../components/Common/WarningDialog';
import { apiPost } from '../../../../api/index';

function IframeEditDlg({ visible, model, onClose }) {
  const [model0, setModel0] = useState({});
  const [isModelChanged, setIsModelChanged] = useState(false);

  useEffect(() => {
    if (visible) {
      setModel0(model);
      setIsModelChanged(false);
    }
  }, [visible]);

  const onChangeModel = (m, v) => {
    setModel0({ ...m });
    setIsModelChanged(v);
  };

  const onSave = async () => {
    const res = await warningDialog('Редактирование записи', 'Сохранить внесённые изменения?', [
      { label: 'Отмена' },
      { label: 'Сохранить' },
    ]);

    if (res === 1) {
      // save record
      const { err, errmsg } = await apiPost('/catalog/iframe/set', {
        id: model._id,
        data: model0,
      });
      if (err > 0) {
        if (Array.isArray(errmsg)) {
          for (const msg of errmsg) {
            message.error(msg.message);
          }
        } else {
          message.error(errmsg || `Net error ${err}`);
        }
      } else onClose();
    }
  };

  const onDelete = async () => {
    const res = await warningDialog(
      'Удаление записи',
      'Удалить запись, без возможности восстановления?',
      [{ label: 'Отмена' }, { label: 'Удалить' }],
    );

    if (res === 1) {
      // delete record
      const { err, errmsg } = await apiPost('/catalog/iframe/del', {
        id: model._id,
      });
      if (err > 0) {
        if (Array.isArray(errmsg)) {
          for (const msg of errmsg) {
            message.error(msg.message);
          }
        } else {
          message.error(errmsg || `Net error ${err}`);
        }
      } else onClose();
    }
  };

  return (
    <Modal
      title={<GTooltip tid="catalogs_analog_dlg">Редактирование записи аналога</GTooltip>}
      open={visible}
      closable={false}
      footer={[
        <Button key="del" style={{ marginRight: 'auto' }} onClick={onDelete}>
          Удалить запись
        </Button>,
        <Button key="close" onClick={onClose}>
          Закрыть
        </Button>,
        <Button key="save" type="primary" onClick={onSave} disabled={!isModelChanged}>
          Сохранить
        </Button>,
      ]}
    >
      <XDataForm
        model={model0}
        onChangeModel={onChangeModel}
        form={[
          {
            type: XDF_TYPE.SHOW_TEXT,
            label: 'Ключ клиента',
            prop: 'key',
            span: 24,
          },
          {
            type: XDF_TYPE.TAGS,
            label: 'Домены',
            prop: 'domains',
            span: 24,
            values: [],
          },
          {
            type: XDF_TYPE.TEXT,
            label: 'Название',
            prop: 'name',
            span: 24,
            require: true,
            autoFocus: true,
          },
          {
            type: XDF_TYPE.TEXTAREA,
            label: 'Описание',
            prop: 'desc',
            span: 24,
          },
        ]}
      />
    </Modal>
  );
}

IframeEditDlg.propTypes = {
  visible: PropTypes.bool.isRequired,
  model: PropTypes.shape({
    _id: PropTypes.number,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default IframeEditDlg;
