/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/static-property-placement */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */
import { Col, Row, Typography } from 'antd';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Pie } from 'react-chartjs-2';
import { getGraphColor, roundPlus } from '../../../utils/utils';

const chartOptions = {
  legend: {
    display: false,
    position: 'bottom',
  },
  tooltips: {
    enable: true,
  },
  animation: {
    animateRotate: false,
    duration: 50,
  },
};

export default class KybMapAnalyticsByKey extends Component {
  static defaultProps = {
    startItem: 0,
    maxItems: 99999,
  };

  static propTypes = {
    stats: PropTypes.object.isRequired,
    byKey: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    tableTitle: PropTypes.string.isRequired,
    pageTitle: PropTypes.string,
    startItem: PropTypes.number,
    maxItems: PropTypes.number,
    period: PropTypes.string,
  };

  renderPie = (stats, k) => {
    const statData = stats[k];
    if (!statData) return null;

    const labels = [];
    const backgroundColor = [];
    const hoverBackgroundColor = [];
    const data = statData.slice(0, 10).map((x, i) => {
      labels.push(`${x._id} - ${roundPlus((100 * x._cnt) / stats.common._cnt, 1)}%`);
      backgroundColor.push(getGraphColor(i));
      hoverBackgroundColor.push(getGraphColor(i));
      return x._cnt;
    });

    let otherCount = 0;
    statData.slice(10).forEach((x) => {
      otherCount += x._cnt;
    });

    if (otherCount > 0) {
      data.push(otherCount);
      labels.push(`Остальные - ${roundPlus((100 * otherCount) / stats.common._cnt, 1)}%`);
      backgroundColor.push(getGraphColor(10));
      hoverBackgroundColor.push(getGraphColor(10));
    }

    return (
      <Pie
        height={300}
        data={{
          labels,
          datasets: [
            {
              data,
              backgroundColor,
              hoverBackgroundColor,
            },
          ],
        }}
        options={chartOptions}
      />
    );
  };

  render() {
    const { stats, byKey, title, tableTitle, pageTitle, startItem, maxItems, period } = this.props;
    return (
      <div style={{ marginBottom: 16 }}>
        {period && <Typography.Title level={5}>{period}</Typography.Title>}
        {pageTitle && (
          <Row>
            <Col span={24}>
              <Typography.Title level={2}>{pageTitle}</Typography.Title>
            </Col>
          </Row>
        )}
        <Row>
          <Col span={6}>
            <Typography.Title level={3}>{title}</Typography.Title>
          </Col>
          <Col span={8}>
            <table className="stats-table">
              <thead>
                <tr>
                  <td>{tableTitle}</td>
                  <td>Сеансы</td>
                </tr>
              </thead>
              <tbody>
                {stats[byKey].slice(startItem, startItem + maxItems).map((x, i) => (
                  <tr key={`stats-t-${i}`}>
                    <td>{x._id}</td>
                    <td
                      style={{
                        fontWeight: 'bold',
                        color: getGraphColor(i <= 9 && startItem === 0 ? i : 10),
                      }}
                    >
                      {x._cnt}
                    </td>
                  </tr>
                ))}
                {startItem + maxItems >= stats[byKey].length && (
                  <tr style={{ backgroundColor: '#f0f0f0' }}>
                    <td>Всего</td>
                    <td style={{ fontWeight: 'bold' }}>{stats.common._cnt}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </Col>
          <Col span={10}>{startItem === 0 && this.renderPie(stats, byKey)}</Col>
        </Row>
      </div>
    );
  }
}
