/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';

import { Button, Layout, message, PageHeader, Table, Tag } from 'antd';
import dayjs from 'dayjs';
import { Link, useHistory, useParams } from 'react-router-dom';

import {
  DeleteOutlined,
  EyeOutlined,
  FieldTimeOutlined,
  SafetyCertificateOutlined,
} from '@ant-design/icons';

import { apiPost, apiPostData } from '../../../../api';
import { warningDialog } from '../../../components/Common/WarningDialog';
import GTooltip from '../../../components/GTooltip/GTooltip';
import XDataForm, { XDF_TYPE } from '../../../components/xdf-antd/XDataForm';
import WebinarsEventResults0 from '../Events/WebinarsEventResults0';
import {
  WEBINAR3_STATUS_ID2TAG,
  WEBINAR3_TYPES_ID2NAME,
  WEBINAR3_VIEW_ID2TAG,
} from '../webinar_constants';
import styles from './styles.scss';

const tableColumns = (deleteUserFromEvent, onShowUserTestAnswers, onShowUserCertificate) => [
  {
    title: 'Дата проведения',
    dataIndex: 'dates',
    key: 'dates',
    width: 160,
    render: (v, it) => {
      if (it.__event?.__dtStart && it.__event?.__dtEnd) {
        return (
          <span style={{ whiteSpace: 'nowrap' }}>{`${dayjs(it.__event.__dtStart).format(
            'DD.MM',
          )} .. ${dayjs(it.__event.__dtEnd).format('DD.MM.YYYY')}`}</span>
        );
      }
      return '';
    },
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    key: 'status',
    width: 110,
    render: (v, it) => WEBINAR3_STATUS_ID2TAG[it.__event?.status],
  },
  {
    title: 'Вид',
    dataIndex: 'view',
    key: 'view',
    width: 190,
    render: (v, it) => WEBINAR3_VIEW_ID2TAG[it.__event?.view],
  },
  {
    title: 'Тип',
    dataIndex: 'type',
    key: 'type',
    width: 130,
    render: (v, it) =>
      (it.__event?.type || []).map((t) => (
        <Tag key={t} color="error">
          {WEBINAR3_TYPES_ID2NAME[t]}
        </Tag>
      )),
  },
  {
    title: 'Название',
    dataIndex: 'name',
    key: 'name',
    render: (v, it) => <Link to={`/webinars/event/edit/${it.__event._id}`}>{it.__event.name}</Link>,
  },
  {
    title: '',
    dataIndex: 'viewResults',
    key: 'viewResults',
    align: 'center',
    fixed: 'right',
    width: 45,
    render: (v, it) => (
      <Button
        type="text"
        icon={<FieldTimeOutlined />}
        onClick={() => onShowUserTestAnswers(it)}
        disabled={it.test?.done !== 1}
      />
    ),
  },
  {
    title: <SafetyCertificateOutlined />,
    dataIndex: 'cert',
    key: 'cert',
    align: 'center',
    fixed: 'right',
    width: 80,
    render: (v, it) => {
      if (it.test?.done === 1) {
        if (it.test?.success)
          return it.__event?.certificate?.fileId ? (
            <Button type="text" icon={<EyeOutlined />} onClick={() => onShowUserCertificate(it)} />
          ) : (
            'Сдан'
          );
        return 'Не сдал!';
      }
      if (!(it.__event?.test?.id > 0))
        return it.__event?.certificate?.fileId ? (
          <Button type="text" icon={<EyeOutlined />} onClick={() => onShowUserCertificate(it)} />
        ) : null;
      return 'Не сдавал';
    },
  },
  {
    title: '',
    dataIndex: 'remove',
    key: 'remove',
    align: 'center',
    fixed: 'right',
    width: 45,
    render: (v, it) => (
      <Button type="text" icon={<DeleteOutlined />} onClick={() => deleteUserFromEvent(it)} />
    ),
  },
];

export default function WebinarsUserViewer() {
  // router
  // const location = useLocation();

  const history = useHistory();
  const { id } = useParams();

  const [editMode, setEditMode] = useState(false);
  const [model, setModel] = useState({});
  const [isModelChanged, setIsModelChanged] = useState(false);

  const [eventList, setEventList] = useState([]);
  const [userTestAnswersDlg, setUserTestAnswersDlg] = useState({ open: false });

  const fetchData = async (userId) => {
    const data = await apiPostData('/webinars3/user/view', {
      userId,
    });
    setModel(data);
    setEventList(data.__list);
    setIsModelChanged(false);
  };

  useEffect(() => {
    fetchData(parseInt(id, 10));
  }, [id]);

  const onChangeModel = (m, is) => {
    setModel({ ...m });
    setIsModelChanged(is);
  };

  const saveModel = async () => {
    // await apiPostData('/webinars3/test/save', {
    //   id: model._id,
    //   data: model,
    // });
    // setIsModelChanged(false);
  };

  // const onSave = async () => {
  //   const res = await warningDialog('Сохранить изменения', 'Сохранить внесенные изменения?', [
  //     { label: 'Отмена' },
  //     { label: 'Сохранить' },
  //   ]);

  //   if (res === 1) {
  //     saveModel();
  //   }
  // };

  const deleteUserFromEvent = async (it) => {
    const res = await warningDialog(
      'Удалить запись?',
      'Удаленную запись нельзя будет восстановить!',
      [{ label: 'Отмена' }, { label: 'Удалить' }],
    );

    if (res === 1) {
      const { err, errmsg } = await apiPost('/webinars3/user/remove/from/event', {
        userId: id,
        eventId: it.__event._id,
      });
      if (err > 0) {
        if (Array.isArray(errmsg)) {
          for (const msg of errmsg) {
            message.error(msg.message);
          }
        } else {
          message.error(errmsg || `Net error ${err}`);
        }
      } else fetchData(parseInt(id, 10));
    }
  };

  const backToList = async () => {
    if (isModelChanged) {
      const res = await warningDialog(
        'Есть несохраненные изменения',
        'Сохранить перед выходом внесённые изменения?',
        [{ label: 'Отмена' }, { label: 'Сохранить' }],
      );

      if (res === 1) {
        await saveModel();
        history.push('/webinars/tests');
      }
    } else {
      history.push('/webinars/tests');
    }
  };

  const onShowUserTestAnswers = async (it) => {
    setUserTestAnswersDlg({
      open: true,
      test: it.__event.__test,
      resultsMatrix: it.test.resultsMatrix,
    });
  };

  const onShowUserCertificate = (it) => {
    const certUrl = `/api/1/certificate/view/${it.__event._id}/${id}`;
    window.open(certUrl, '_blank');
  };

  return (
    <>
      <PageHeader
        className={styles.pageHeader}
        title={<GTooltip tid="webinars_user_viewer">Карточка участника</GTooltip>}
        extra={[
          <Button key="block" disabled>
            Заблокировать
          </Button>,
          <Button key="save" type="primary" disabled={!isModelChanged}>
            Сохранить
          </Button>,
        ]}
        onBack={backToList}
      />
      {model?._id > 0 && (
        <Layout.Content>
          <div className={styles.contextHolder}>
            <div className={styles.context}>
              <XDataForm
                model={model}
                onChangeModel={onChangeModel}
                form={[
                  {
                    type: XDF_TYPE.TEXT,
                    label: 'Фамилия',
                    prop: 'webinarUser.lastname',
                    showOnly: !editMode,
                    span: 6,
                  },
                  {
                    type: XDF_TYPE.TEXT,
                    label: 'Имя',
                    prop: 'webinarUser.firstname',
                    showOnly: !editMode,
                    span: 6,
                  },
                  {
                    type: XDF_TYPE.TEXT,
                    label: 'Регион',
                    prop: 'webinarUser.region',
                    showOnly: !editMode,
                    span: 6,
                  },
                  {
                    type: XDF_TYPE.TEXT,
                    label: 'Город',
                    prop: 'webinarUser.city',
                    showOnly: !editMode,
                    span: 6,
                  },

                  {
                    type: XDF_TYPE.SHOW_TEXT,
                    label: 'Телефон',
                    prop: 'webinarUser.phone',
                    span: 6,
                  },
                  {
                    type: XDF_TYPE.TEXT,
                    label: 'Компания',
                    prop: 'webinarUser.company',
                    showOnly: !editMode,
                    span: 6,
                  },
                  {
                    type: XDF_TYPE.TEXT,
                    label: 'Должность',
                    prop: 'webinarUser.status',
                    showOnly: !editMode,
                    span: 6,
                  },
                  {
                    type: XDF_TYPE.TEXT,
                    label: 'E-mail',
                    prop: 'webinarUser.email',
                    showOnly: !editMode,
                    span: 6,
                  },

                  {
                    type: XDF_TYPE.SHOW_DATETIME,
                    label: 'Дата создания',
                    prop: 'webinarUser.cdt',
                    span: 8,
                  },
                  {
                    type: XDF_TYPE.SHOW_DATETIME,
                    label: 'Дата подтверждения Е-mail',
                    prop: 'webinarUser.emailOkDt',
                    span: 8,
                  },
                  {
                    type: XDF_TYPE.SHOW_DATETIME,
                    label: 'Дата редактирования',
                    prop: 'webinarUser.udt',
                    span: 8,
                  },
                ]}
              />
            </div>
          </div>
          <div className={styles.contextHolder}>
            <div className={styles.context}>
              <Table
                scroll={{ x: 1500 }}
                size="small"
                bordered
                pagination={false}
                dataSource={eventList}
                columns={tableColumns(
                  deleteUserFromEvent,
                  onShowUserTestAnswers,
                  onShowUserCertificate,
                )}
              />
            </div>
          </div>
        </Layout.Content>
      )}

      <WebinarsEventResults0
        open={userTestAnswersDlg.open}
        onClose={() => setUserTestAnswersDlg({ open: false })}
        test={userTestAnswersDlg.test || {}}
        resultsMatrix={userTestAnswersDlg.resultsMatrix || {}}
      />
    </>
  );
}
