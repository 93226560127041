import React from 'react';

import { notification } from 'antd';
import PropTypes from 'prop-types';

import apiGTolltipGet from '../../../api/GTolltipGet';
import styles from './styles.scss';

function GTooltip({ tid, children }) {
  async function showTooltip() {
    const tt = await apiGTolltipGet(tid);
    notification.info({
      message: 'Подсказка',
      description: tt.txt,
      placement: 'topLeft',
      duration: 16,
    });
  }

  return (
    <span className={styles.main}>
      {children}
      <button type="button" onClick={showTooltip}>
        <svg
          stroke="currentColor"
          fill="currentColor"
          strokeWidth="0"
          viewBox="0 0 24 24"
          height="17px"
          width="17px"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M12 6C9.831 6 8.066 7.765 8.066 9.934h2C10.066 8.867 10.934 8 12 8s1.934.867 1.934 1.934c0 .598-.481 1.032-1.216 1.626-.255.207-.496.404-.691.599C11.029 13.156 11 14.215 11 14.333V15h2l-.001-.633c.001-.016.033-.386.441-.793.15-.15.339-.3.535-.458.779-.631 1.958-1.584 1.958-3.182C15.934 7.765 14.169 6 12 6zM11 16H13V18H11z"></path>
          <path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path>
        </svg>
      </button>
    </span>
  );
}

GTooltip.propTypes = {
  tid: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
};

export default GTooltip;
