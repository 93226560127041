import React, { useEffect, useState } from 'react';

import { Button, Divider, message } from 'antd';
import { nanoid } from 'nanoid';

import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { arrayMove } from '@dnd-kit/sortable';

import { apiPost } from '../../../../api';
import apiAnyGet from '../../../../api/AnyGet';
import XDataForm, { XDF_TYPE } from '../../../components/xdf-antd/XDataForm';
import XDataFormDialog from '../../../components/xdf-antd/XDataForm/XDataFormDialog';
import styles from './styles.scss';
import { KYB_LEFT_BANNER_TYPE } from '../../../constants';

function KSBannerLeft() {
  const [model, setModel] = useState({});
  const [isModelChanged, setIsModelChanged] = useState(false);

  const [dlg, setDlg] = useState({ visible: false, model: {} });

  const fetchBanner = async () => {
    const data = await apiAnyGet('KybDb:Banner')('l', []);
    setModel({
      ...data,
      masks: (data.masks || []).map((x) => ({
        ...x,
        _id: nanoid(),
      })),
    });
    setIsModelChanged(false);
  };

  useEffect(() => {
    fetchBanner();
  }, []);

  const onChangeModel = (m, is) => {
    setModel({ ...m });
    setIsModelChanged(is);
  };

  const onSaveModel = async () => {
    const { err, errmsg } = await apiPost('/site/banner/tr/set', {
      ...model,
    });
    if (err === 0) {
      setIsModelChanged(false);
    } else if (Array.isArray(errmsg)) {
      for (const msg of errmsg) {
        message.error(msg.message);
      }
    } else {
      message.error(errmsg || `Net error ${err}`);
    }
  };

  const onCloseDlg = () => {
    setDlg({ ...dlg, visible: false });
  };

  const onSaveDlg = (m) => {
    const idx = model.masks.findIndex((x) => x._id === m._id);
    if (idx < 0) {
      setModel({
        ...model,
        masks: [...model.masks, m],
      });
    } else {
      setModel({
        ...model,
        masks: [...model.masks.slice(0, idx), m, ...model.masks.slice(idx + 1)],
      });
    }
    setIsModelChanged(true);
    onCloseDlg();
  };

  const onDeleteDlg = (m) => {
    const idx = model.masks.findIndex((x) => x._id === m._id);
    if (idx >= 0) {
      setModel({
        ...model,
        masks: [...model.masks.slice(0, idx), ...model.masks.slice(idx + 1)],
      });
    }
    setIsModelChanged(true);
    onCloseDlg();
  };

  const onMoveUp = (m) => {
    const idx = model.masks.findIndex((x) => x._id === m._id);
    setModel({
      ...model,
      masks: arrayMove(model.masks, idx, idx - 1),
    });
    setIsModelChanged(true);
  };

  const onMoveDown = (m) => {
    const idx = model.masks.findIndex((x) => x._id === m._id);
    setModel({
      ...model,
      masks: arrayMove(model.masks, idx, idx + 1),
    });
    setIsModelChanged(true);
  };

  if (!model._id) return null;
  return (
    <div className={styles.bannerPage}>
      <Button type="primary" disabled={!isModelChanged} onClick={onSaveModel}>
        Сохранить
      </Button>
      <Divider />
      <XDataForm
        model={model}
        onChangeModel={onChangeModel}
        form={[
          {
            type: XDF_TYPE.SWITCH,
            label: 'Включить баннер',
            prop: 'active',
            span: 8,
          },
        ]}
      />
      <Divider />
      <table>
        <thead>
          <tr>
            <th>Маска</th>
            <th>Тип</th>
            <th>Картинка</th>
            <th>
              <Button
                size="small"
                onClick={() =>
                  setDlg({
                    ...dlg,
                    visible: true,
                    model: {
                      _id: nanoid(),
                      enableOpenPage: 1,
                    },
                  })
                }
              >
                Добавить
              </Button>
            </th>
          </tr>
        </thead>
        <tbody>
          {model.masks.map((it, i) => (
            <tr key={it._id}>
              <td onClick={() => setDlg({ ...dlg, visible: true, model: it })}>{it.mask}</td>
              <td onClick={() => setDlg({ ...dlg, visible: true, model: it })}>{it.type}</td>
              <td
                onClick={() => setDlg({ ...dlg, visible: true, model: it })}
                className={styles.pic}
              >
                {!!it.pic && <img src={`/gi/${it.pic}`} alt="pic" />}
              </td>
              <td className={styles.ctrls}>
                <Button
                  size="small"
                  icon={<ArrowUpOutlined />}
                  disabled={i === 0}
                  onClick={() => onMoveUp(it)}
                />
                <Button
                  size="small"
                  icon={<ArrowDownOutlined />}
                  disabled={i === model.masks.length - 1}
                  onClick={() => onMoveDown(it)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <XDataFormDialog
        visible={dlg.visible}
        model={dlg.model}
        onClose={onCloseDlg}
        onSave={onSaveDlg}
        onDelete={onDeleteDlg}
        form={[
          {
            type: XDF_TYPE.TEXT,
            label: 'URL страницы или маска URL для показа баннера',
            prop: 'mask',
            autoFocus: true,
            span: 24,
          },
          {
            type: XDF_TYPE.SELECT,
            label: 'Тип баннера',
            prop: 'type',
            values: KYB_LEFT_BANNER_TYPE,
            span: 24,
          },
          {
            type: XDF_TYPE.TEXT,
            label: 'URL для перехода при клике на баннер',
            prop: 'url',
            span: 24,
          },
          {
            type: XDF_TYPE.TEXTAREA,
            label: 'Текст под баннером',
            prop: 'name',
            rows: 3,
            span: 24,
          },
          {
            type: XDF_TYPE.PICTURE_UPLOAD,
            label: 'Картинка баннера',
            prop: 'pic',
            span: 24,
          },
        ]}
      />
    </div>
  );
}

KSBannerLeft.defaultProps = {};

KSBannerLeft.propTypes = {};

export default KSBannerLeft;
