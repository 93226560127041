// Общие колонки, есть на всех видах
export function commonColumns() {
  return {
    name: 'Шаблоны', // название
    props: [], // список полей для запроса к серверу
    columns: [
      {
        prop: '+_id',
        name: 'ID',
        sort: true,
      },
      {
        prop: '+udt',
        name: 'Обновлено',
        sort: true,
        type: 'date',
        format: 'DD.MM.YYYY / HH:mm'
      },
      {
        prop: '+name',
        name: 'Назание',
        sort: true,
      },
    ],
  };
}

export function extentedColumns() {
  return [
    {
      id: 'example1',
      name: 'Пример просмотра',
      props: [],
      columns: [
        {
          prop: '+email',
          name: 'E-mail',
          sort: true,
        },
        {
          prop: '+city',
          name: 'Город',
        },
      ],
    },
  ];
}
