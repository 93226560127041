/* eslint-disable no-underscore-dangle */
import React from 'react';

import { Button, Tooltip } from 'antd';
import { useRecoilValue } from 'recoil';

import { CheckOutlined, CloseOutlined, FilterOutlined, FormOutlined } from '@ant-design/icons';

import { netPOSTv2 } from '../../../../api';
import { mapCategoriesState } from '../../../../state/map-state';
import { XDF_TYPE } from '../../../components/xdf-antd/XDataForm';
import { KYB_MAP_OBJECT_COMMENT_STATE, OBJECT_STATES } from '../../../constants';

// Общие колонки, есть на всех видах
export function commonColumns() {
  const categoriesList = useRecoilValue(mapCategoriesState);

  return {
    name: 'Общая информация', // название
    props: [], // список полей для запроса к серверу
    columns: [
      {
        prop: '+cid',
        name: 'Категория',
        sort: true,
        prop2value: [categoriesList, 'id', 'name'],
        nowrap: true,
      },
      {
        prop: '+active',
        name: 'Активность',
        sort: true,
        prop2value: [OBJECT_STATES, 'id', 'name'],
      },
      {
        prop: '+name',
        name: 'Название',
        sort: true,
        nowrap: true,
      },
      {
        prop: '+addr',
        name: 'Адрес',
        sort: true,
        nowrap: true,
      },
      {
        prop: '+_eid',
        name: 'KYB ID',
        sort: true,
      },
      {
        prop: '+_accCnt',
        name: 'Просмотров',
        sort: true,
      },
      {
        prop: '+_iid',
        name: 'Иконка',
        sort: true,
      },
      {
        prop: '+gr',
        name: 'Группа',
        sort: true,
      },
    ],
  };
}

export function columnsOthers() {
  return [
    {
      id: 'loc',
      name: 'Местоположение',
      props: [],
      columns: [
        {
          name: 'Город',
          prop: '+g_city',
          sort: true,
          nowrap: true,
        },
        {
          name: 'Регион',
          prop: '+g_area',
          sort: true,
          nowrap: true,
        },
        {
          name: 'Страна',
          prop: '+g_country',
          sort: true,
          nowrap: true,
        },
      ],
    },
    {
      id: 'contacts',
      name: 'Контакты',
      props: [],
      columns: [
        {
          name: 'Телефон',
          prop: '+phone',
          nowrap: true,
        },
        {
          name: 'Email',
          prop: '+email',
          nowrap: true,
        },
      ],
    },
    // {
    //   id: 3,
    //   name: 'Фото',
    // props: [],
    //   columns: [
    //     {
    //       name: 'Фото',
    //       prop: 'phone',
    //       func: (row) => {
    //         const l = row.image || [];
    //         return (
    //           <div className="td-img-list">
    //             {l.map((x, i) => {
    //               return (
    //                 <img
    //                   src={`//api.geo4.biz/1/img/40/${x}?statOff=1`}
    //                   key={`${x}-${i}`}
    //                   alt="Object pic"
    //                 />
    //               );
    //             })}
    //           </div>
    //         );
    //       },
    //     },
    //   ],
    // },
  ];
}

export function filterForm() {
  const categoriesList = useRecoilValue(mapCategoriesState);
  return [
    {
      label: 'Общие',
      color: '#cc0033',
    },
    {
      type: XDF_TYPE.MULTISELECT,
      label: 'Категория',
      prop: 'cid',
      values: categoriesList,
    },
    {
      type: XDF_TYPE.MULTISELECT,
      label: 'Активность',
      prop: 'active',
      values: OBJECT_STATES,
    },
    {
      type: XDF_TYPE.TEXT,
      label: 'Название',
      prop: '~~name',
    },
    {
      type: XDF_TYPE.TEXT,
      label: 'Адрес',
      prop: '~~addr',
    },

    {
      label: 'Местоположение',
      color: '#cc0033',
    },
    {
      type: XDF_TYPE.TEXT,
      label: 'Город',
      prop: '~~g_city',
    },
    {
      type: XDF_TYPE.TEXT,
      label: 'Регион',
      prop: '~~g_area',
    },
    {
      type: XDF_TYPE.TEXT,
      label: 'Страна',
      prop: '~~g_country',
    },

    {
      label: 'Контакты',
      color: '#cc0033',
    },
    {
      type: XDF_TYPE.TEXT,
      label: 'Телефон',
      prop: '~~phone',
    },
    {
      type: XDF_TYPE.TEXT,
      label: 'E-mail',
      prop: '~~email',
    },
  ];
}

export function commonColumnsComments(filter, openObjectCard, filterByObject, refreshTable) {
  return {
    name: null,
    props: ['_oid'], // список полей для запроса к серверу
    columns: [
      {
        prop: '+_state',
        name: 'Действия',
        sort: true,
        action: true,
        nowrap: true,
        render: (it, val) => {
          const onChangeState = async (state) => {
            await netPOSTv2('/map/comment/state/set', { id: it._id, state });
            refreshTable();
          };

          return (
            <>
              <Tooltip title="Утвердить">
                <Button
                  type="primary"
                  icon={<CheckOutlined />}
                  size="small"
                  onClick={() => onChangeState(1)}
                  disabled={val === 1}
                />
              </Tooltip>
              <Tooltip title="Удалить">
                <Button
                  danger
                  icon={<CloseOutlined />}
                  size="small"
                  style={{ marginLeft: 8 }}
                  onClick={() => onChangeState(-1)}
                  disabled={val === -1}
                />
              </Tooltip>

              {!filter._oid && (
                <Tooltip title="Комментарии только данного объекта">
                  <Button
                    icon={<FilterOutlined />}
                    size="small"
                    style={{ marginLeft: 24 }}
                    onClick={() => filterByObject(it)}
                  />
                </Tooltip>
              )}

              {Array.isArray(filter._oid) && filter._oid.length > 0 && (
                <Tooltip title="Открыть карточку объекта">
                  <Button
                    icon={<FormOutlined />}
                    size="small"
                    style={{ marginLeft: 24 }}
                    onClick={() => openObjectCard(filter._oid[0])}
                  />
                </Tooltip>
              )}
            </>
          );
        },
      },
      {
        prop: '+_state',
        name: 'Состояние',
        sort: true,
        nowrap: true,
        prop2value: [KYB_MAP_OBJECT_COMMENT_STATE, 'id', 'name'],
      },
      {
        prop: '+__acc.name',
        name: 'Пользователь',
        sort: true,
        nowrap: true,
      },
      {
        prop: '+_cdt',
        name: 'Дата',
        sort: true,
        format: 'DD.MM.YY HH:mm',
        type: 'date',
        nowrap: true,
      },
      {
        prop: '+rating',
        name: 'Рейтинг',
        sort: true,
        align: 'center',
        render: (it, val) => {
          const r = parseInt(val, 10);
          // eslint-disable-next-line no-restricted-globals
          return isNaN(r) ? 0 : r;
        },
      },
      {
        prop: '+msg',
        name: 'Комментарий',
        sort: true,
        nowrap: true,
      },
    ],
  };
}

export function filterFormComments() {
  return [
    {
      type: XDF_TYPE.MULTISELECT,
      label: 'Активность',
      prop: '_state',
      values: KYB_MAP_OBJECT_COMMENT_STATE,
    },
    {
      type: XDF_TYPE.TAGS,
      label: 'Объекты',
      prop: '_oid',
      values: [],
    },
  ];
}
