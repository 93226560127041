import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import styles from './styles.scss';
import { Modal } from 'antd';

function EmailTemplatePreviewDlg({ templateId, visible, onClose }) {
  const [showIframe, setShowIframe] = useState(false);

  useEffect(() => {
    setShowIframe(visible);
  }, [visible]);

  return (
    <Modal title="Предпросмотр E-mail" open={visible} onCancel={onClose} width={900} footer={null}>
      {showIframe && (
        <iframe
          title="Email-template"
          scrolling="yes"
          src={`/api/1/email/template/preview?templateId=${templateId}&v=${new Date().getTime()}`}
          frameBorder="0"
          height="700px"
          width="100%"
        />
      )}
    </Modal>
  );
}

EmailTemplatePreviewDlg.defaultProps = {};

EmailTemplatePreviewDlg.propTypes = {
  templateId: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EmailTemplatePreviewDlg;
