import { XDF_TYPE } from '../xdf-antd/XDataForm';

const FORM_BLK_TYPE1 = [
  {
    type: XDF_TYPE.TEXT,
    label: 'Заголовок',
    prop: 'title',
    span: 24,
    autoFocus: true,
  },
  {
    type: XDF_TYPE.TEXTAREA,
    label: '1 Колонка',
    prop: 'col1',
    span: 24,
    rows: 16,
    autoSize: true,
  },
];

const FORM_BLK_TYPE2 = [
  {
    type: XDF_TYPE.TEXTAREA,
    label: '1 Колонка',
    prop: 'col1',
    span: 12,
    rows: 20,
    autoSize: true,
    autoFocus: true,
  },
  {
    type: XDF_TYPE.TEXTAREA,
    label: '2 Колонка',
    prop: 'col2',
    span: 12,
    rows: 20,
    autoSize: true,
  },
];

const FORM_BLK_TYPE3 = [
  {
    type: XDF_TYPE.TEXTAREA,
    label: 'Цитирование',
    prop: 'col1',
    span: 24,
    rows: 20,
    autoSize: true,
    autoFocus: true,
  },
];

const FORM_BLK_TYPE4 = [
  {
    type: XDF_TYPE.TEXT,
    label: 'Заголовок',
    prop: 'title',
    span: 24,
    autoFocus: true,
  },
  {
    type: XDF_TYPE.TEXTAREA,
    label: '1 Колонка',
    prop: 'col1',
    span: 12,
    rows: 16,
    autoSize: true,
  },
  {
    type: XDF_TYPE.TEXTAREA,
    label: '2 Колонка',
    prop: 'col2',
    span: 12,
    rows: 16,
    autoSize: true,
  },
];

const FORM_BLK_TYPE5 = [
  {
    type: XDF_TYPE.TEXT,
    label: 'Заголовок',
    prop: 'title',
    span: 24,
    autoFocus: true,
  },
  {
    type: XDF_TYPE.TEXTAREA,
    label: '1 Колонка',
    prop: 'col1',
    span: 24,
    rows: 16,
    autoSize: true,
  },
];

const FORM_BLK_TYPE7 = [
  {
    type: XDF_TYPE.GALLERY_UPLOAD,
    prop: 'pics',
    picsProps: ['pic', 'pic', 'desc'],
    roundImage: true,
    maxCount: 3,
    span: 24,
  },
];

// eslint-disable-next-line import/prefer-default-export
export const getBlockFormByType = (type) => {
  // eslint-disable-next-line default-case
  switch (type) {
    case 1:
      return FORM_BLK_TYPE1;
    case 2:
      return FORM_BLK_TYPE2;
    case 3:
      return FORM_BLK_TYPE3;
    case 4:
      return FORM_BLK_TYPE4;
    case 5:
      return FORM_BLK_TYPE5;
    case 7:
      return FORM_BLK_TYPE7;
  }
  return [];
};
