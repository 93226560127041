/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';

import gv from 'get-value';
import { Col, Row } from 'antd';

function KybMapAnalyticsTop20PI({ stats, statKey }) {
  return (
    <Row>
      <Col span={24}>
        <table className="stats-table">
          <thead>
            <tr>
              <td>п/п</td>
              <td>Категория</td>
              <td>Название</td>
              <td>Адрес</td>
              <td>Сессии</td>
            </tr>
          </thead>
          <tbody>
            {gv(stats, statKey, []).map((x, i) => (
              <tr key={`stats-t-${i}`}>
                <td>{i + 1}</td>
                <td>{gv(stats, `categories.${x.cid}.name`, '')}</td>
                <td>{x.name}</td>
                <td>{x.addr}</td>
                <td>{x._cnt}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Col>
    </Row>
  );
}

KybMapAnalyticsTop20PI.defaultProps = {
  statKey: 'top20PoiInfo',
};

KybMapAnalyticsTop20PI.propTypes = {
  stats: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  statKey: PropTypes.string,
};

export default KybMapAnalyticsTop20PI;
