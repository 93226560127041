import React, { useState } from 'react';

import { Button, Col, message, Upload } from 'antd';
import cn from 'classnames';
import gv from 'get-value';
import PropTypes from 'prop-types';

import styles from './styles.scss';
import xdPrettyBytes from '../utils/xd-pretty-bytes';
import { xdRoundPlus } from '../XDataTable/xdf-utils';
import xdfSetValue from './xdf-set-value';

const BIG_FILE_MAX_SIZE = 2 * 1024 * 1024;

function XDFUploadBigFile({ it, size, model, onPropsChanged }) {
  const [uploading, setUploading] = useState(-1);

  const { id, label, title, prop, span, readOnly, fileProps, maxFileSize } = it;
  const bigfileMaxSize = maxFileSize || BIG_FILE_MAX_SIZE;

  const [propOrigname, propFileSize] = fileProps || [];

  const fileName = gv(model, prop);
  const fileSize = gv(model, propFileSize || '____', 0);

  const onChange = async ({ file }) => {
    if (file.percent > 0 && file.percent <= 100) {
      setUploading(parseInt(file.percent, 10));
    }

    if (file.percent === 100 && file.response) {
      const { err, errmsg, data } = file.response;
      // console.log('DONE', err);
      if (err === 0) {
        setUploading(-1);

        const m = {};
        xdfSetValue(m, prop, data.filename);
        if (propOrigname) xdfSetValue(m, propOrigname, file.name);
        if (propFileSize) xdfSetValue(m, propFileSize, file.size);

        onPropsChanged(m);
      } else if (Array.isArray(errmsg)) {
        for (const msg of errmsg) {
          message.error(msg.message);
        }
      } else {
        message.error(errmsg || `Net error ${err}`);
      }
    }
  };

  // Не загружать во вне файл
  const beforeUpload = (file) => {
    if (file.size > bigfileMaxSize) {
      message.error(`Размер файла больше допустимого: ${xdPrettyBytes(bigfileMaxSize)}`);
      return false;
    }
    return true;
  };

  return (
    <Col span={span || 24}>
      <div className={cn(styles.formItem, styles.uploadBigFile)}>
        {!!label && (
          <div className={styles.formItemLabel}>
            <label htmlFor={id}>{label}</label>
          </div>
        )}
        <div className={cn(styles.formItemField, { [styles.small]: size === 'small' })}>
          <Upload
            id={id}
            name="x-ufile"
            className="big-file-uploader"
            accept="*.*"
            showUploadList={false}
            action="/api/1/upload"
            onChange={onChange}
            beforeUpload={beforeUpload}
            disabled={uploading >= 0 || readOnly}
            headers={{
              'x-ufile-desc': '',
              'x-ufile-type': encodeURIComponent('file'),
              'x-ufile-pic-optimize': 0,
              'x-ufile-pic-webp': 0,
            }}
          >
            {uploading >= 0 && (
              <div className={styles.uploadProgress}>
                <div className={styles.bar} style={{ width: `${uploading}%` }} />
                <span>{xdRoundPlus(uploading, 1)} %</span>
              </div>
            )}
            {uploading < 0 && (
              <Button id={id} size={size} disabled={!!readOnly} style={{ width: '100%' }}>
                {title ||
                  `${fileName || 'Выбрать файл'}${
                    fileSize > 0 ? ` / ${xdPrettyBytes(fileSize)}` : ''
                  }`}
              </Button>
            )}
          </Upload>
        </div>
      </div>
    </Col>
  );
}

XDFUploadBigFile.propTypes = {
  it: PropTypes.shape({
    id: PropTypes.string.isRequired,
    prop: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    readOnly: PropTypes.bool,
    span: PropTypes.number,
    fileProps: PropTypes.arrayOf(PropTypes.string), // ['propOrigFileName', 'propFileSize']
    maxFileSize: PropTypes.number,
  }).isRequired,
  model: PropTypes.shape({}).isRequired,
  size: PropTypes.string.isRequired,
  onPropsChanged: PropTypes.func,
};

export default XDFUploadBigFile;
