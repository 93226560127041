import React, { useState } from 'react';

import { Layout, PageHeader } from 'antd';
import { useRecoilValue } from 'recoil';

import apiAnyList from '../../../../api/AnyList';
import { subscribeToChannel, unsubscribeToChannel } from '../../../../api/sio';
import { notifyLastForRoleState } from '../../../../state/notify-state';
import GTooltip from '../../../components/GTooltip/GTooltip';
import XDataTable from '../../../components/xdf-antd/XDataTable';
import { USER_ROLE } from '../../../constants';
import styles from './styles.scss';
import { commonColumns } from './table-defs';
import UserUnblockDlg from './UserUnblockDlg';

export default function UsersBlocked() {
  const notifyLastForRole = useRecoilValue(notifyLastForRoleState(USER_ROLE.USERS_BLOCKED));
  const [unblockUserId, setUnblockUserId] = useState(0);

  const onRowClick = (item) => {
    setUnblockUserId(item._id);
  };

  const onCloseApproveDlg = () => {
    setUnblockUserId(0);
  };

  return (
    <>
      <PageHeader
        className={styles.pageHeader}
        title={<GTooltip tid="users_blocked">Доступ заблокирован</GTooltip>}
      />
      <Layout.Content>
        <div className={styles.contextHolder}>
          <div className={styles.context}>
            <XDataTable
              apiLoadData={apiAnyList('User')}
              commonColumns={commonColumns(notifyLastForRole)}
              extentedColumns={null}
              initSort={['_id', -1]}
              initFilter={{ state: -1 }}
              onRowClick={onRowClick}
              sioEvents={['LIST:USER', subscribeToChannel, unsubscribeToChannel]}
              searchProps={['name', 'lastName', 'midName', 'phone', 'email', 'company']}
            />
          </div>
        </div>
      </Layout.Content>
      <UserUnblockDlg userId={unblockUserId} onClose={onCloseApproveDlg} />
    </>
  );
}
