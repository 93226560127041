import React, { useEffect, useRef } from 'react';

import { Col } from 'antd';
import cn from 'classnames';
import gv from 'get-value';
import PropTypes from 'prop-types';

import styles from './styles.scss';
import { useJOdit } from './xdf-jodit';

function XDFWysiwyg2({ it, size, model, onPropChanged }) {
  const { id, prop, label, placeholder, autoFocus, span, readOnly, showOnly, rows } = it;

  const val = gv(model, prop, '');

  const editor = useRef(null);
  const [joditDone, content] = useJOdit(editor, val);

  useEffect(() => {
    if (joditDone) {
      onPropChanged(it, content);
    }
  }, [joditDone, content]);

  return (
    <Col span={span || 24}>
      <div className={styles.formItem}>
        <div className={styles.formItemLabel}>
          <label htmlFor={id}>{label}</label>
        </div>
        <div
          className={cn(
            styles.formItemField,
            { [styles.small]: size === 'small' },
            { [styles.showOnly]: !!showOnly },
          )}
        >
          <textarea
            ref={editor}
            id={id}
            placeholder={placeholder || ''}
            disabled={!!readOnly || !!showOnly}
          />
        </div>
      </div>
    </Col>
  );
}

XDFWysiwyg2.propTypes = {
  it: PropTypes.shape({
    id: PropTypes.string.isRequired,
    prop: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    placeholder: PropTypes.string,
    autoFocus: PropTypes.bool,
    span: PropTypes.number,
    readOnly: PropTypes.bool,
    showOnly: PropTypes.bool,
    rows: PropTypes.number,
  }).isRequired,
  size: PropTypes.string.isRequired,
  model: PropTypes.shape({}).isRequired,
  onPropChanged: PropTypes.func.isRequired,
};

export default XDFWysiwyg2;
