import React from 'react';

import {
  ApartmentOutlined,
  GlobalOutlined,
  ReloadOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';

import AnalogList from './app/pages/Catalogs/Analog/AnalogList';
import SpecList from './app/pages/Catalogs/Characters/SpecList';
import CrossList from './app/pages/Catalogs/Cross/CrossList';
import IframeList from './app/pages/Catalogs/IFrame/IframeList';
import MainCatalogList from './app/pages/Catalogs/Main/MainCatalogList';
import CommonEmailEditor from './app/pages/Common/Emails/CommonEmailEditor';
import CommonEmailList from './app/pages/Common/Emails/CommonEmailList';
import CommonFiles from './app/pages/Common/Files/CommonFiles';
import CommonGTooltipList from './app/pages/Common/Tooltips/CommonGTooltipList';
import KybSiteBanners from './app/pages/KybSite/Banners/KybSiteBanners';
import KybSiteBulletins from './app/pages/KybSite/Bulletin/KybSiteBulletins';
import KybSiteCatalogs from './app/pages/KybSite/Catalogs/KybSiteCatalogs';
import KybSiteExperts from './app/pages/KybSite/Experts/KybSiteExperts';
import KybSiteExpertsEditor from './app/pages/KybSite/Experts/KybSiteExpertsEditor';
import KybSiteFAQList from './app/pages/KybSite/FAQ/KybSiteFAQList';
import KybHistory from './app/pages/KybSite/History/KybHistory';
import KybSiteInstallations from './app/pages/KybSite/Installations/KybSiteInstallations';
import KybSiteInstallationsEditor from './app/pages/KybSite/Installations/KybSiteInstallationsEditor';
import KybSiteMeta from './app/pages/KybSite/Meta/KybSiteMeta';
import KybSiteNews from './app/pages/KybSite/News/KybSiteNews';
import KybSiteNewsEditor from './app/pages/KybSite/News/KybSiteNewsEditor';
import KybSiteProduct from './app/pages/KybSite/Product/KybSiteProduct';
import KybSiteProductEditor from './app/pages/KybSite/Product/KybSiteProductEditor';
import KybSeries from './app/pages/KybSite/Series/KybSeries';
import KybSitePages from './app/pages/KybSite/SitePages/KybSitePages';
import KybSiteVideoList from './app/pages/KybSite/Video/KybSiteVideoList';
import MapAnalytics from './app/pages/Map/Analytics/MapAnalytics';
import MapObjectEditor from './app/pages/Map/ObjectEditor/MapObjectEditor';
import MapCommentsList from './app/pages/Map/Objects/MapCommentsList';
import MapNewObjectsList from './app/pages/Map/Objects/MapNewObjectsList';
import MapObjectsList from './app/pages/Map/Objects/MapObjectsList';
import MapSettings from './app/pages/Map/Settings/MapSettings';
import NewProductsInAssortment from './app/pages/News/NewProducts/NewProductsInAssortment';
import NewProductsInAssortmentEditor from './app/pages/News/NewProducts/NewProductsInAssortmentEditor';
import UserAccessEditor from './app/pages/Users/Users/UserAccessEditor';
import Users from './app/pages/Users/Users/Users';
import UsersBlocked from './app/pages/Users/UsersBlocked/UsersBlocked';
import UsersNew from './app/pages/Users/UsersNew/UsersNew';
import WebinarEventEditor from './app/pages/Webinars/Events/WebinarEventEditor';
import WebinarsEventsList from './app/pages/Webinars/Events/WebinarsEventsList';
import WebinarsTestEditor from './app/pages/Webinars/Tests/WebinarsTestEditor';
import WebinarsTestsList from './app/pages/Webinars/Tests/WebinarsTestsList';
import WebinarsUsersList from './app/pages/Webinars/Users/WebinarsUsersList';
import WebinarsUserViewer from './app/pages/Webinars/Users/WebinarsUserViewer';
import WebinarsV1List from './app/pages/WebinarsV1/WebinarsV1List';
import WebinarsV1Settings from './app/pages/WebinarsV1/WebinarsV1Settings';
import CatalogsIcon from './assets/svg/kyb-catalogs.svg';
import MapIcon from './assets/svg/lmenu-map.svg';
import SettingsIcon from './assets/svg/settings.svg';
import UsersIcon from './assets/svg/users.svg';
import WebinarEventResults1 from './app/pages/Webinars/Events/WebinarEventResults1';

const routes = [
  {
    role: 100,
    label: 'Общие настройки',
    icon: <SettingsIcon />,
    subs: [
      {
        role: 101,
        label: 'Подсказки',
        path: '/common/tooltips',
        component: CommonGTooltipList,
      },
      {
        role: 102,
        path: '/common/email/template/:templateId',
        exact: true,
        component: CommonEmailEditor,
      },
      {
        role: 102,
        label: 'Email шаблоны',
        path: '/common/email/templates',
        exact: true,
        component: CommonEmailList,
      },
      {
        role: 103,
        label: 'Файлы',
        path: '/common/files',
        exact: true,
        component: CommonFiles,
      },
    ],
  },
  {
    role: 200,
    label: 'Пользователи',
    icon: <UsersIcon />,
    subs: [
      {
        role: 201,
        label: 'Запросы доступа',
        path: '/users/new',
        exact: true,
        component: UsersNew,
      },
      {
        role: 202,
        label: 'Разрешённый доступ',
        path: '/users/approved',
        exact: true,
        component: Users,
      },
      {
        role: 202,
        path: '/user/edit/:userId',
        exact: true,
        component: UserAccessEditor,
      },
      {
        role: 203,
        label: 'Доступ заблокирован',
        path: '/users/blocked',
        exact: true,
        component: UsersBlocked,
      },
    ],
  },
  {
    role: 800,
    label: 'Новости',
    icon: <ReloadOutlined />,
    subs: [
      {
        role: 801,
        label: 'Новинки ассортимента',
        path: '/news/newproducts',
        exact: true,
        component: NewProductsInAssortment,
      },
      {
        role: 801,
        path: '/news/newproducts/:objectId',
        exact: true,
        component: NewProductsInAssortmentEditor,
      },

      {
        role: 802,
        label: 'Новости сайта',
        path: '/site/news',
        exact: true,
        component: KybSiteNews,
      },
      {
        role: 802,
        path: '/site/news/:newsId',
        exact: true,
        component: KybSiteNewsEditor,
      },
    ],
  },
  {
    role: 300,
    label: 'Каталоги на kyb.ru',
    icon: <CatalogsIcon />,
    subs: [
      {
        role: 301,
        label: 'Управление каталогами',
        path: '/catalogs/list',
        exact: true,
        component: MainCatalogList,
      },
      {
        role: 302,
        label: 'Кроссы',
        path: '/catalogs/cross',
        exact: true,
        component: CrossList,
      },
      {
        role: 303,
        label: 'Аналоги',
        path: '/catalogs/analogs',
        exact: true,
        component: AnalogList,
      },
      {
        role: 304,
        label: 'Характеристики',
        path: '/catalogs/characters',
        exact: true,
        component: SpecList,
      },
      {
        role: 305,
        label: 'iFrame каталог',
        path: '/catalogs/settings',
        exact: true,
        component: IframeList,
      },
    ],
  },
  {
    role: 400,
    label: 'Карта на kyb.ru',
    icon: <MapIcon />,
    subs: [
      {
        role: 401,
        label: 'Управление картой',
        path: '/maps/objects',
        exact: true,
        component: MapObjectsList,
      },
      {
        role: 402,
        label: 'Новые объекты',
        path: '/maps/newobjects',
        exact: true,
        component: MapNewObjectsList,
      },
      {
        role: 452,
        path: '/maps/object/:objectId',
        exact: true,
        component: MapObjectEditor,
      },

      {
        role: 403,
        label: 'Комментарии',
        path: '/maps/comments',
        exact: true,
        component: MapCommentsList,
      },
      {
        role: 404,
        label: 'Настройка карты',
        path: '/maps/settings',
        exact: true,
        component: MapSettings,
      },
      {
        role: 405,
        label: 'Аналитика карты',
        path: '/maps/analytics',
        exact: true,
        component: MapAnalytics,
      },
    ],
  },
  {
    role: 500,
    label: 'Сайт kyb.ru',
    icon: <ApartmentOutlined />,
    subs: [
      {
        role: 501,
        label: 'SEO',
        path: '/site/meta',
        exact: true,
        component: KybSiteMeta,
      },

      {
        role: 503,
        label: 'История KYB',
        path: '/site/history',
        exact: true,
        component: KybHistory,
      },
      {
        role: 504,
        label: 'Серии',
        path: '/site/series',
        exact: true,
        component: KybSeries,
      },

      {
        role: 505,
        label: 'Продукция',
        path: '/site/products',
        exact: true,
        component: KybSiteProduct,
      },
      {
        role: 505,
        path: '/site/product/:productId',
        exact: true,
        component: KybSiteProductEditor,
      },

      {
        role: 506,
        label: 'Каталоги',
        path: '/site/catalogs',
        exact: true,
        component: KybSiteCatalogs,
      },
      {
        role: 507,
        label: 'Бюллетени',
        path: '/site/bulletins',
        exact: true,
        component: KybSiteBulletins,
      },
      {
        role: 508,
        label: 'Вопросы и ответы',
        path: '/site/faq',
        exact: true,
        component: KybSiteFAQList,
      },
      {
        role: 509,
        label: 'Видео инструкции',
        path: '/site/video_manuals',
        exact: true,
        component: KybSiteVideoList,
      },

      {
        role: 510,
        label: 'Рекомендации по установке',
        path: '/site/installations',
        exact: true,
        component: KybSiteInstallations,
      },
      {
        role: 510,
        path: '/site/installations/:zid',
        exact: true,
        component: KybSiteInstallationsEditor,
      },

      {
        role: 511,
        label: 'Экспертный клуб',
        path: '/site/experts',
        exact: true,
        component: KybSiteExperts,
      },
      {
        role: 511,
        path: '/site/experts/:zid',
        exact: true,
        component: KybSiteExpertsEditor,
      },

      {
        role: 512,
        label: 'Страницы',
        path: '/site/pages',
        exact: true,
        component: KybSitePages,
      },

      {
        role: 513,
        label: 'Баннеры',
        path: '/site/banners',
        exact: true,
        component: KybSiteBanners,
      },
    ],
  },
  {
    role: 700,
    label: 'Connect',
    icon: <GlobalOutlined />,
    subs: [
      {
        role: 701,
        label: 'Участники',
        path: '/connect/users',
        exact: true,
        component: WebinarsV1List,
      },
      {
        role: 702,
        label: 'Настройки',
        path: '/connect/settings',
        exact: true,
        component: WebinarsV1Settings,
      },
    ],
  },
  {
    role: 900,
    label: 'Вебинары / семинары',
    icon: <VideoCameraOutlined />,
    subs: [
      {
        role: 901,
        label: 'Участники',
        path: '/webinars/users',
        exact: true,
        component: WebinarsUsersList,
      },
      {
        role: 901,
        path: '/webinars/user/:id',
        exact: true,
        component: WebinarsUserViewer,
      },

      {
        role: 902,
        label: 'Тесты',
        path: '/webinars/tests',
        exact: true,
        component: WebinarsTestsList,
      },
      {
        role: 902,
        path: '/webinars/test/:id',
        exact: true,
        component: WebinarsTestEditor,
      },

      {
        role: 903,
        label: 'Вебинары и семинары',
        path: '/webinars/events',
        exact: true,
        component: WebinarsEventsList,
      },
      {
        role: 903,
        path: '/webinars/event/:view/:id',
        exact: true,
        component: WebinarEventEditor,
      },
      {
        role: 903,
        path: '/webinars/event/:id/r1/:taid',
        exact: true,
        component: WebinarEventResults1,
      },
    ],
  },
];

export const KYB_STAFF_ACCESS_TREE_RULES = routes.map((m) => ({
  key: m.role,
  title: m.label,
  children: m.subs
    .filter((x) => !!x.label)
    .map((x) => ({
      key: x.role,
      title: x.label,
    })),
}));

export const KYB_STAFF_ACCESS_ID2NAME = {};
KYB_STAFF_ACCESS_TREE_RULES.forEach((parent) => {
  if (Array.isArray(parent.children)) {
    parent.children.forEach((role) => {
      KYB_STAFF_ACCESS_ID2NAME[role.key] = (
        <span>
          {parent.title} &rarr; {role.title}
        </span>
      );
    });
  }
});

export default routes;
