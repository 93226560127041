import React from 'react';

import { Col } from 'antd';
import cn from 'classnames';
// eslint-disable-next-line import/no-extraneous-dependencies
import dayjs from 'dayjs';
import gv from 'get-value';
import PropTypes from 'prop-types';

import { DEFAULT_DATEFORMAT } from './constants';
import DatePicker from './DayJsDatePicker';
import styles from './styles.scss';

function XDFDateRange({ it, size, model, onPropChanged }) {
  const { id, prop, prop2, label, placeholder, autoFocus, span, readOnly, noW100, format, ranges } =
    it;

  const onChange = (dates, datesString) => {
    onPropChanged(it, datesString[0], datesString[1]);
  };

  const val = gv(model, prop, dayjs());
  const val2 = gv(model, prop2, dayjs());

  return (
    <Col span={span || 24}>
      <div className={styles.formItem}>
        <div className={styles.formItemLabel}>
          <label htmlFor={id}>{label}</label>
        </div>
        <div
          className={cn('xdf-form-field', styles.formItemField, {
            [styles.small]: size === 'small',
          })}
        >
          <DatePicker.RangePicker
            id={id}
            size={size}
            allowClear={false}
            placeholder={placeholder || ''}
            autoFocus={!!autoFocus}
            disabled={!!readOnly}
            value={[
              dayjs(val, format || DEFAULT_DATEFORMAT),
              dayjs(val2, format || DEFAULT_DATEFORMAT),
            ]}
            onChange={onChange}
            style={{ width: noW100 ? 'auto' : '100%' }}
            format={format || DEFAULT_DATEFORMAT}
            ranges={ranges || null}
          />
        </div>
      </div>
    </Col>
  );
}

XDFDateRange.propTypes = {
  it: PropTypes.shape({
    id: PropTypes.string.isRequired,
    prop: PropTypes.string.isRequired,
    prop2: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    placeholder: PropTypes.string,
    autoFocus: PropTypes.bool,
    span: PropTypes.number,
    readOnly: PropTypes.bool,
    noW100: PropTypes.bool,
    format: PropTypes.string,
    ranges: PropTypes.shape({}),
  }).isRequired,
  size: PropTypes.string.isRequired,
  model: PropTypes.shape({}).isRequired,
  onPropChanged: PropTypes.func.isRequired,
};

export default XDFDateRange;
