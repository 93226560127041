import React, { useEffect, useState } from 'react';

import { Col, Input } from 'antd';
import cn from 'classnames';
import gv from 'get-value';
import PropTypes from 'prop-types';

import styles from './styles.scss';

function getIntValOrNull(val) {
  const ival = parseInt(val, 10);
  if (val !== `${ival}`) return null;
  return ival;
}

function XDFNumberMinMax({ it, size, model, onPropChanged }) {
  const [min0, setMin0] = useState('');
  const [max0, setMax0] = useState('');

  const { id, prop, label, labelSup, autoFocus, span, readOnly, showOnly } = it;

  const [valMin, valMax] = gv(model, prop) || [];

  useEffect(() => {
    setMin0(valMin || '');
    setMax0(valMax || '');
  }, [id, prop]);

  const onChangeMin = (ev) => {
    const val = ev.target.value;
    setMin0(val);

    const ival = getIntValOrNull(val);
    onPropChanged(it, [ival, valMax]);
  };

  const onChangeMax = (ev) => {
    const val = ev.target.value;
    setMax0(val);

    const ival = getIntValOrNull(val);
    onPropChanged(it, [valMin, ival]);
  };

  return (
    <Col span={span || 24}>
      <div className={styles.formItem}>
        <div className={styles.formItemLabel}>
          <label htmlFor={id} title={label}>
            {label} {!!labelSup && <sup>{labelSup}</sup>}
          </label>
        </div>
        <div
          className={cn(
            styles.formItemField,
            styles.itemsFlex,
            { [styles.small]: size === 'small' },
            { [styles.showOnly]: !!showOnly },
          )}
        >
          <Input
            id={id}
            size={size}
            placeholder="мин"
            autoFocus={!!autoFocus}
            disabled={!!readOnly || !!showOnly}
            value={min0}
            onChange={onChangeMin}
            className={styles.flexItem}
            allowClear
          />
          <div className={cn(styles.flexDiv)}>&nbsp;~&nbsp;</div>
          <Input
            id={id}
            size={size}
            placeholder="макс"
            disabled={!!readOnly || !!showOnly}
            value={max0}
            onChange={onChangeMax}
            className={styles.flexItem}
            allowClear
          />
        </div>
      </div>
    </Col>
  );
}

XDFNumberMinMax.propTypes = {
  it: PropTypes.shape({
    id: PropTypes.string.isRequired,
    prop: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    labelSup: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.bool]),
    autoFocus: PropTypes.bool,
    span: PropTypes.number,
    readOnly: PropTypes.bool,
    showOnly: PropTypes.bool,
  }).isRequired,
  size: PropTypes.string.isRequired,
  model: PropTypes.shape({}).isRequired,
  onPropChanged: PropTypes.func.isRequired,
};

export default XDFNumberMinMax;
