import { apiPost, ApiError } from '.';

/**
 * Запрос серий продуктов
 */
const apiMeGet = async () => {
  const { err, errmsg, data } = await apiPost('/me/get', {});
  if (err !== 0) throw ApiError(err, errmsg);
  return data;
};
export default apiMeGet;
