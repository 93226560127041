import React, { useEffect, useState, useRef } from 'react';

const cssCache = {};

/**
 * Load CSS
 * @param {*} src
 */
export function useXDFCss(src) {
  const [done, setDone] = useState(false);

  useEffect(() => {
    if (src) {
      if (cssCache[src]) setDone(true);
      else {
        const link = document.createElement('link');
        link.onload = () => {
          cssCache[src] = true;
          setDone(true);
        };
        link.href = src;
        link.type = 'text/css';
        link.rel = 'stylesheet';
        link.media = 'screen,print';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
    }
  }, [src]);

  return [done];
}

/**
 * Load JS
 * @param {*} src
 * @param {*} loadAsync
 * @returns
 */
export function useXDFScript(src, loadAsync = true) {
  // Keep track of script status ("idle", "loading", "ready", "error")
  const [status, setStatus] = useState(src ? 'loading' : 'idle');
  const [done, setDone] = useState(false);

  useEffect(
    () => {
      // Allow falsy src value if waiting on other data needed for
      // constructing the script URL passed to this hook.
      if (!src) {
        setStatus('idle');
        return;
      }
      // Fetch existing script element by src
      // It may have been added by another intance of this hook
      let script = document.querySelector(`script[src="${src}"]`);
      if (!script) {
        // Create script
        script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.setAttribute('data-status', 'loading');
        // Add script to document body
        document.body.appendChild(script);
        // Store status in attribute on script
        // This can be read by other instances of this hook
        const setAttributeFromEvent = (event) => {
          script.setAttribute('data-status', event.type === 'load' ? 'ready' : 'error');
          setDone(event.type === 'load');
        };
        script.addEventListener('load', setAttributeFromEvent);
        script.addEventListener('error', setAttributeFromEvent);
      } else {
        // Grab existing script status from attribute and set to state.
        setStatus(script.getAttribute('data-status'));
        setDone(script.getAttribute('data-status') === 'ready');
      }
      // Script event handler to update status in state
      // Note: Even if the script already exists we still need to add
      // event handlers to update the state for *this* hook instance.
      const setStateFromEvent = (event) => {
        setStatus(event.type === 'load' ? 'ready' : 'error');
        setDone(event.type === 'load');
      };
      // Add event listeners
      script.addEventListener('load', setStateFromEvent);
      script.addEventListener('error', setStateFromEvent);
      // Remove event listeners on cleanup
      // eslint-disable-next-line consistent-return
      return () => {
        if (script) {
          script.removeEventListener('load', setStateFromEvent);
          script.removeEventListener('error', setStateFromEvent);
        }
      };
    },
    [src], // Only re-run effect if script src changes
  );
  return [done, status];
}
