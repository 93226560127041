// Общие колонки, есть на всех видах

import { XDF_TYPE } from '../../../components/xdf-antd/XDataForm';
import {
  WEBINAR3_TYPE,
  WEBINAR3_VIEW_ID2TAG,
  WEBINAR3_STATUS_ID2TAG,
  WEBINAR3_VIEW,
  WEBINAR3_STATUS,
} from '../webinar_constants';

import styles from './styles.scss';

// eslint-disable-next-line import/prefer-default-export
export const commonColumns = () => ({
  name: null, // 'Кроссы', // название
  props: ['test'], // список полей для запроса к серверу
  columns: [
    {
      prop: '+name',
      name: 'Название мероприятия',
      sort: true,
      nowrap: true,
      className: styles.cellMaxWidth,
    },
    {
      prop: 'firtsStage.dtStart',
      name: 'Старт',
      sort: true,
      type: 'date',
      format: 'DD.MM.YYYY HH:mm',
      nowrap: true,
    },
    {
      prop: '+status',
      name: 'Статус',
      sort: true,
      render: (item, val) => WEBINAR3_STATUS_ID2TAG[val],
    },
    {
      prop: '+view',
      name: 'Вид',
      sort: true,
      render: (item, val) => WEBINAR3_VIEW_ID2TAG[val],
    },
    {
      prop: '+type',
      name: 'Тип',
      sort: true,
      nowrap: true,
      prop2value: [WEBINAR3_TYPE, 'id', 'name'],
    },
    {
      prop: 'test.users',
      name: 'Заявки',
      sort: true,
      align: 'center',
      notNull: true,
    },
    {
      prop: 'test.usersOk',
      name: 'Сдали тест',
      sort: true,
      align: 'center',
      notNull: true,
    },
    {
      prop: 'test.usersErr',
      name: 'Не сдали тест',
      sort: true,
      align: 'center',
      notNull: true,
    },
    {
      prop: 'test.usersPass',
      name: 'Не сдавали',
      sort: true,
      align: 'center',
      notNull: true,
    },
    {
      prop: '+certificate.fileId',
      name: 'Сертификаты',
      sort: true,
      align: 'center',
      render: (item) => {
        if (!item?.certificate?.fileId) return null;
        if (item?.test?.id > 0) return item?.test?.usersOk || null;
        return item?.test?.users || null;
      },
    },
  ],
});

export function filterFormWebinarsEvents() {
  return [
    {
      type: XDF_TYPE.TEXT,
      label: 'Название',
      prop: '~~name',
    },
    {
      type: XDF_TYPE.MULTISELECT,
      label: 'Вид мероприятия',
      prop: 'view',
      values: WEBINAR3_VIEW,
    },
    {
      type: XDF_TYPE.MULTISELECT,
      label: 'Статус мероприятия',
      prop: 'status',
      values: WEBINAR3_STATUS,
    },
    {
      type: XDF_TYPE.MULTISELECT,
      label: 'Тип мероприятия',
      prop: 'type',
      values: WEBINAR3_TYPE,
    },
  ];
}
