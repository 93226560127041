import React from 'react';

import { Col, InputNumber } from 'antd';
import cn from 'classnames';
import gv from 'get-value';
import PropTypes from 'prop-types';

import styles from './styles.scss';

function XDFNumber({ it, size, model, onPropChanged }) {
  const {
    id,
    prop,
    label,
    labelSup,
    placeholder,
    autoFocus,
    span,
    readOnly,
    showOnly,
    step,
    min,
    max,
  } = it;

  const onChange = (val) => {
    onPropChanged(it, val);
  };

  const val = gv(model, prop, 0);
  return (
    <Col span={span || 24}>
      <div className={styles.formItem} data-pname={prop}>
        <div className={styles.formItemLabel}>
          <label htmlFor={id} title={label}>
            {label} {!!labelSup && <sup>{labelSup}</sup>}
          </label>
        </div>
        <div
          className={cn(
            styles.formItemField,
            { [styles.small]: size === 'small' },
            { [styles.showOnly]: !!showOnly },
          )}
        >
          <InputNumber
            id={id}
            size={size}
            placeholder={placeholder || ''}
            autoFocus={!!autoFocus}
            disabled={!!readOnly || !!showOnly}
            value={val}
            step={step}
            min={min}
            max={max}
            onChange={onChange}
            style={{ width: '100%' }}
          />
        </div>
      </div>
    </Col>
  );
}

XDFNumber.propTypes = {
  it: PropTypes.shape({
    id: PropTypes.string.isRequired,
    prop: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    labelSup: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.bool]),
    placeholder: PropTypes.string,
    autoFocus: PropTypes.bool,
    span: PropTypes.number,
    readOnly: PropTypes.bool,
    showOnly: PropTypes.bool,
    step: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
  }).isRequired,
  size: PropTypes.string.isRequired,
  model: PropTypes.shape({}).isRequired,
  onPropChanged: PropTypes.func.isRequired,
};

export default XDFNumber;
