/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Button, Dropdown, Input, Menu, Modal, Pagination, Row, Tooltip } from 'antd';
import cn from 'classnames';
import dayjs from 'dayjs';
import gv from 'get-value';
import LZString from 'lz-string';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import {
  useBeforeUnload,
  useInterval,
  useKey,
  useMeasure,
  useMountedState,
  useScroll,
  useWindowSize,
} from 'react-use';
import EventEmitter from 'wolfy87-eventemitter';

import {
  AppstoreOutlined,
  CalendarOutlined,
  CheckOutlined,
  CheckSquareTwoTone,
  CloseCircleOutlined,
  DownOutlined,
  EditOutlined,
  FileExcelOutlined,
  FilterOutlined,
  ReloadOutlined,
  SwapOutlined,
} from '@ant-design/icons';

import SortIcon from '../../../../assets/svg/sort0.svg';
import SortIconUp from '../../../../assets/svg/sort1.svg';
import SortIconDown from '../../../../assets/svg/sort2.svg';
import XDataForm, { XDF_TYPE } from '../XDataForm';
import XDFDateRange from '../XDataForm/XDFDateRangePicker';
import styles from './styles.scss';
import { xdGetColProp, xdRoundPlus } from './xdf-utils';
import { useUrlToTableState, useXDTChannelSubscribe } from './xdt-hooks';
import XDTExportToExcel from './XDTExportToExcel';

/**
 * Переход на список по URL с инициализаций фильтра и сортировки
 * @param {*} history
 * @param {*} baseTableUrl
 * @param {*} filter
 * @param {*} sortBy
 * @param {*} extColumnId
 * @param {*} pageSize
 * @param {*} urlParamName
 */
export function xdGetTableUrlByParams(
  baseTableUrl,
  filter = {},
  sortBy = ['_id', 1],
  extColumnId = '',
  pageSize = 20,
  urlParamName = '_t_',
) {
  const _lzFilter = LZString.compressToEncodedURIComponent(JSON.stringify(filter));
  const searchParams = `?${urlParamName}=${pageSize},1,${sortBy[0]},${
    sortBy[1] === 1 ? 'a' : 'd'
  },${extColumnId},${_lzFilter},${nanoid(4)},0`;
  return `${baseTableUrl}${searchParams}`;
}

/**
 * Таблица данных
 * @param {*} loadData - загрузка данных (await), параметры:
 *    - page (текущая страница)
 *    - pageSize (размер страницы)
 *    - filter
 *    - props
 *    - sortProp
 *    - sortOrder
 *  - возврат
 *    - list - список объектов
 *    - total - всего объектов
 * @param {*} commonColumns
 * @param {*} extentedColumns
 * @param {*} urlParamName
 * @param {*} defValues
 */
function XDataTable({
  apiLoadData,
  postLoadData,
  commonColumns,
  extentedColumns,
  urlParamName,
  selectRow,
  defValues,
  initFilter,
  datesFilter,
  datesPresets,
  filterForm,
  initSort,
  onRowClick,
  sioEvents,
  searchProps,
  searchLabel,
  leftActions,
  inlineEditFormProps,
  onInlineEditChanged,
  onSaveEventEmitter,
  onCallbacks,
  onSaveTableHandlers,
  excelExport,
  enableItemsSelect,
  debug,
}) {
  const isMounted = useMountedState();
  const ee = useRef(null);

  const windowSize = useWindowSize();
  const [ref, ctrlsHeaderSize] = useMeasure();

  const history = useHistory();
  const location = useLocation();

  const qs = queryString.parse(location.search);
  const urlTableState = qs[urlParamName] || '';

  const [checkedRow, setCheckedRow] = useState({});
  const [prop2ValueCache, setProp2ValueCache] = useState(false);

  // const [filteredByCheckedRows, setFilteredByCheckedRows] = useState(false);
  const [filterBackup, setFilterBackup] = useState({});

  const [filterDialog, setFilterDialog] = useState({ visible: false, model: {} });
  const [loadingList, setLoadingList] = useState(false);
  const [searchStr, setSearchStr] = useState('');
  const [startExport, setStartExport] = useState(false);

  const [
    stateHash,
    pageSize,
    pageNumber,
    sortProp,
    sortOrder,
    extColumnsSelected,
    filter,
    sfilter,
    props,
    filteredByCheckedRows,
    // tempId,
  ] = useUrlToTableState(
    commonColumns,
    extentedColumns,
    initFilter,
    initSort,
    urlTableState,
    defValues,
  );

  const [tableState, setTableState] = useState({
    list: [],
    total: 0,
  });

  const tableRef = useRef(null);
  const tableScroll = useScroll(tableRef);

  const refInlineDlg = useRef(null);
  const [inlineEdit, setInlineEdit] = useState({
    id: -1,
    prop: '',
    model: {},
    isChanged: false,
    visible: false,
  });

  // хранится лог изменения значений для типов inline
  // _id:prop => value
  const [inlineEditLog, setInlineEditLog] = useState({});
  const inlineEditLogLen = useMemo(() => Object.keys(inlineEditLog).length, [inlineEditLog]);

  useBeforeUnload(inlineEditLogLen > 0, 'У вас есть не схраненные данные!');

  function closeInlienEditDlg() {
    setInlineEdit({ ...inlineEdit, visible: false });
  }

  useKey('Escape', () => {
    closeInlienEditDlg();
  });

  function showInlineDlg(id, prop, val, colType) {
    const form = inlineEditFormProps
      .filter((x) => x.prop === prop)
      .map((x) => ({
        ...x,
        id: nanoid(),
        span: 24,
        autoFocus: true,
      }));

    if (form.length === 0 && colType === 'check') {
      form.push({
        id: nanoid(),
        span: 24,
        autoFocus: true,
        type: XDF_TYPE.SWITCH,
        prop,
      });
    }

    setInlineEdit({
      visible: true,
      id,
      prop,
      isChanged: false,
      form,
      model: {
        [prop]: val,
      },
    });
  }

  function onResetInlienEditAll() {
    setInlineEditLog({});
    if (onInlineEditChanged) onInlineEditChanged({});

    closeInlienEditDlg();
  }

  const onSaveInlineEditValue = () => {
    const ieV = {
      ...inlineEditLog,
      [`${inlineEdit.id}:${inlineEdit.prop}`]: inlineEdit.model[inlineEdit.prop],
    };
    setInlineEditLog(ieV);
    if (onInlineEditChanged) onInlineEditChanged(ieV, onResetInlienEditAll);

    closeInlienEditDlg();
  };

  // const onResetInlienEditValue = () => {
  //   const ieV = {
  //     ...inlineEditLog,
  //   };
  //   delete ieV[`${inlineEdit.id}:${inlineEdit.prop}`];
  //   setInlineEditLog(ieV);
  //   if (onInlineEditChanged) onInlineEditChanged(ieV, onResetInlienEditAll);

  //   closeInlienEditDlg();
  // };

  const onChangeInlineEditModel = (m, c) => {
    setInlineEdit({
      ...inlineEdit,
      isChanged: c,
      model: {
        ...m,
      },
    });
  };

  const [highlightId, setHighlightId] = useState(null);
  useInterval(
    () => {
      setHighlightId(null);
    },
    highlightId ? 1000 : null,
  );

  /**
   * Загрузить весь список
   */
  const loadData = async () => {
    setLoadingList(true);
    let result = await apiLoadData(
      pageNumber,
      pageSize,
      filter,
      props,
      sortProp,
      sortOrder,
      searchProps,
    );
    if (postLoadData) {
      const postResult = postLoadData(result);
      if (postResult) result = postResult;
    }

    setTableState({
      list: result.list,
      total: result.total,
    });
    setLoadingList(false);

    // send info from list
    if (result.info && ee.current) {
      ee.current.emit('LIST-INFO', result.info);
    }
  };

  /**
   * Обновить элемент, если он есть в списке
   * @param {*} itemId
   */
  async function reloadItem(itemId) {
    const idPropName = sioEvents ? sioEvents[3] || '_id' : '_id';
    const idx = tableState.list.findIndex((x) => x[idPropName] === itemId);
    if (idx >= 0) {
      const item = tableState.list[idx];

      // reload
      const { list, total } = await apiLoadData(
        1,
        1,
        {
          _id: [item._id],
        },
        props,
        sortProp,
        sortOrder,
        searchProps,
      );

      if (total === 1) {
        setHighlightId(itemId);
        const [data] = list;
        setTableState({
          ...tableState,
          list: [...tableState.list.slice(0, idx), data, ...tableState.list.slice(idx + 1)],
        });
      }
    }
  }

  const recalcDictsCache = async () => {
    if (prop2ValueCache) return loadData();
    setLoadingList(true);

    function createDict4Prop(cache, prop, list, keyName, valName) {
      // eslint-disable-next-line no-param-reassign
      cache[prop] = list.reduce((a, x) => {
        // eslint-disable-next-line no-param-reassign
        a[x[keyName]] = x[valName];
        return a;
      }, {});
    }

    // load cache
    const cache = {};

    async function updateCache(columns) {
      for (const col of columns) {
        if (Array.isArray(col.prop2value) && col.prop2value.length === 3) {
          const prop = xdGetColProp(col.prop);
          const [src, keyName, valName] = col.prop2value;
          if (Array.isArray(src)) {
            createDict4Prop(cache, prop, src, keyName, valName);
          } else {
            // eslint-disable-next-line no-await-in-loop
            const list = await src();
            createDict4Prop(cache, prop, list, keyName, valName);
          }
        }
      }
    }
    await updateCache(commonColumns.columns);

    if (extentedColumns) {
      for (const ec of extentedColumns) {
        // eslint-disable-next-line no-await-in-loop
        await updateCache(ec.columns);
      }
    }

    setProp2ValueCache(cache);
    return loadData();
  };

  useEffect(() => {
    if (stateHash) {
      // loadData();
      recalcDictsCache();
    }
  }, [stateHash]);

  useEffect(() => {
    if (searchStr !== filter.__search) {
      setSearchStr(filter.__search);
    }
  }, [filter.__search]);

  function changeTableState(changeTo = {}, replace = false) {
    if (!isMounted) return;
    // console.log('changeTableState', changeTo, replace);

    const _lzFilter = LZString.compressToEncodedURIComponent(
      JSON.stringify(changeTo.filter || filter),
    );

    const searchParams = `${changeTo.pageSize || pageSize},${changeTo.pageNumber || pageNumber},${
      changeTo.sortProp || sortProp
    },${(changeTo.sortOrder || sortOrder) === 1 ? 'a' : 'd'},${
      changeTo.extColId || (extColumnsSelected && extColumnsSelected.id) || ''
    },${_lzFilter},${nanoid(4)},${
      changeTo.filteredByCheckedRows !== undefined
        ? changeTo.filteredByCheckedRows
        : filteredByCheckedRows
    }`;

    // console.log(searchParams);

    const sq = queryString.stringify({
      ...queryString.parse(location.search),
      [urlParamName]: searchParams,
    });
    history[replace ? 'replace' : 'push'](`?${sq}`);
  }

  // event
  useEffect(() => {
    if (ee.current) {
      ee.current.emit('FILTER-CHANGED', filter);
    }
  }, [ee.current, sfilter]);

  // alternative -> onCallbacks
  useEffect(() => {
    if (onCallbacks?.onFilterChanged) {
      onCallbacks.onFilterChanged(filter);
    }
  }, [sfilter]);

  // + handlers
  useEffect(() => {
    if (onSaveTableHandlers) {
      // reload data
      onSaveTableHandlers('RELOAD-DATA', () => {
        changeTableState({}, true);
      });

      // set filter
      onSaveTableHandlers('SET-FILTER', (f) => {
        changeTableState({
          filter: {
            ...f,
          },
          pageNumber: 1,
        });
      });
    }
  }, [onSaveTableHandlers]);

  useXDTChannelSubscribe(sioEvents[0], sioEvents[1], sioEvents[2], (msg) => {
    const idPropName = sioEvents[3] || '_id';
    // console.log('useChannelSubscribe', msg);
    if (msg.ev === 'update') {
      // прилетает полностью готовый объект, прилетевшие поля меняют поля объекта в списке
      const idx = tableState.list.findIndex((x) => x[idPropName] === msg.id);
      if (idx >= 0) {
        setHighlightId(msg.id);
        setTableState({
          ...tableState,
          list: [
            ...tableState.list.slice(0, idx),
            {
              ...tableState.list[idx],
              ...msg.data,
            },
            ...tableState.list.slice(idx + 1),
          ],
        });
      }
    } else if (msg.ev === 'reload') {
      // прилетает только id, если он есть в списке
      // то делаем обновление всего списка
      reloadItem(msg.id);
    } else if (msg.ev === 'remove') {
      const idx = tableState.list.findIndex((x) => x[idPropName] === msg.id);
      if (idx >= 0) {
        setTableState({
          ...tableState,
          list: [...tableState.list.slice(0, idx), ...tableState.list.slice(idx + 1)],
        });
      }
    } else if (msg.ev === 'add') {
      setHighlightId(msg.id);
      setTableState({
        ...tableState,
        list: [{ ...msg.data }, ...tableState.list],
      });
    } else if (msg.ev === 'refresh') {
      // console.log('REFRESH');
      setHighlightId(msg.id);
      // refresh
      loadData();
    }
  });

  const onChangePageNumber = (pn, psize) => {
    changeTableState({
      pageNumber: pageSize !== psize ? 1 : pn,
      pageSize: psize,
    });
  };

  const selectExtColumns = ({ key }) => {
    changeTableState({
      extColId: key,
    });
  };

  function onChangeSort(prop) {
    changeTableState({
      sortProp: prop,
      sortOrder: sortProp === prop ? sortOrder * -1 : 1,
      pageNumber: 1,
    });
  }

  const onSearch = (val) => {
    changeTableState({
      filter: {
        ...filter,
        __search: val || undefined,
      },
      pageNumber: 1,
    });
  };

  const onShowFilter = () => {
    setFilterDialog({
      visible: true,
      model: filter,
    });
  };

  const onFilterChanged = (f) => {
    setFilterDialog({
      ...filterDialog,
      model: {
        ...filterDialog.model,
        ...f,
      },
    });
  };

  const onSetFilter = () => {
    setFilterDialog({
      ...filterDialog,
      visible: false,
    });
    changeTableState({
      filter: {
        ...filterDialog.model,
      },
      pageNumber: 1,
      filteredByCheckedRows: 0,
    });
  };

  const onResetFilter = () => {
    // setFilteredByCheckedRows(false);

    const flt = {};
    if (Array.isArray(datesFilter) && datesFilter.length > 2) {
      if (initFilter[datesFilter[0]]) flt[datesFilter[0]] = initFilter[datesFilter[0]];
      if (initFilter[datesFilter[1]]) flt[datesFilter[1]] = initFilter[datesFilter[1]];
    }

    changeTableState({
      filter: flt,
      pageNumber: 1,
      filteredByCheckedRows: 0,
    });
  };

  const onSetPresetDates = ({ key }) => {
    const dates = key.split('|');
    if (dates.length >= 2) {
      changeTableState({
        filter: {
          ...filter,
          [datesFilter[0]]: dates[0],
          [datesFilter[1]]: dates[1],
        },
        pageNumber: 1,
      });
    }
  };

  const onExportToExcel = () => {
    setStartExport(true);
  };

  const onCloseExport = () => {
    setStartExport(false);
  };

  // external connection EventEmmiter
  useEffect(() => {
    if (onSaveEventEmitter) {
      ee.current = new EventEmitter();
      // add listeners
      ee.current.addListener('SET-FILTER', (f) => {
        changeTableState({
          filter: {
            ...f,
          },
          pageNumber: 1,
        });
      });

      ee.current.addListener('RELOAD-DATA', () => {
        // console.log('RELOAD-DATA');
        changeTableState({}, true);
        // loadData();
      });
      // save
      onSaveEventEmitter(ee.current);
    }
    return () => {
      ee.current?.removeAllListeners();
    };
  }, []);

  const onFilterBySelectedRows = () => {
    let flt = {
      ...filterBackup,
    };
    if (!filteredByCheckedRows) {
      setFilterBackup({ ...filter });
      flt = {
        _id: Object.keys(checkedRow)
          .filter((x) => checkedRow[x])
          .map((x) => (enableItemsSelect?.type !== 'number' ? x : parseInt(x, 10))),
      };
    }
    // setFilteredByCheckedRows(!filteredByCheckedRows);
    changeTableState({
      filter: flt,
      pageNumber: 1,
      filteredByCheckedRows: filteredByCheckedRows ? 0 : 1,
    });
  };

  // Элементы управления
  function listControls() {
    return (
      <div className={styles.listCtrls} ref={ref}>
        {Object.keys(checkedRow).filter((x) => checkedRow[x]).length > 0 && (
          <div className={cn(styles.elCtrl, styles.showCheckCount)}>
            <Button
              type={filteredByCheckedRows === 1 ? 'primary' : 'default'}
              icon={<CheckSquareTwoTone twoToneColor="#52c41a" />}
              size="small"
              onClick={onFilterBySelectedRows}
            >
              &nbsp;{Object.keys(checkedRow).filter((x) => checkedRow[x]).length}
            </Button>
          </div>
        )}

        {extColumnsSelected && extentedColumns.length > 1 && (
          <div className={styles.elCtrl}>
            <Dropdown
              overlay={
                <Menu onClick={selectExtColumns} selectedKeys={[extColumnsSelected?.id]}>
                  {extentedColumns.map((col) => (
                    <Menu.Item key={col.id}>{col.name}</Menu.Item>
                  ))}
                </Menu>
              }
            >
              <Button size="small">
                <AppstoreOutlined />
                <DownOutlined />
              </Button>
            </Dropdown>
          </div>
        )}

        {ctrlsHeaderSize.width > 1000 &&
          searchProps &&
          Object.keys(checkedRow).filter((x) => checkedRow[x]).length === 0 && (
            <div className={styles.elCtrl} key="search">
              <Input.Search
                disabled={loadingList}
                loading={filter.__search && loadingList}
                size="small"
                allowClear
                placeholder={searchLabel}
                onSearch={onSearch}
                style={{ maxWidth: 200 }}
                value={searchStr}
                onChange={(ev) => setSearchStr(ev.target.value)}
              />
            </div>
          )}

        {!!excelExport && (
          <div className={cn(styles.elCtrl)} key="excel-export">
            <Tooltip title="Экспорт в Excel">
              <Button size="small" icon={<FileExcelOutlined />} onClick={onExportToExcel} />
            </Tooltip>
          </div>
        )}

        {!!leftActions &&
          leftActions.map((el, i) => (
            <div className={cn(styles.elCtrl)} key={`act-${i}`}>
              {el.icon && (
                <Tooltip title={el.tooltip}>
                  <Button
                    type={el.primary ? 'primary' : 'default'}
                    size="small"
                    icon={el.icon}
                    onClick={el.onClick}
                    disabled={el.disabled}
                  />
                </Tooltip>
              )}
              {el.label && (
                <Button
                  type={el.primary ? 'primary' : 'default'}
                  size="small"
                  onClick={el.onClick}
                  disabled={el.disabled}
                >
                  {el.label}
                </Button>
              )}
            </div>
          ))}

        <div className={cn(styles.elCtrl, styles.right)} />

        {!filteredByCheckedRows && !!datesFilter && (
          <>
            {!!datesPresets && (
              <div className={cn(styles.elCtrl, styles.datesFilter)} key="dates-presets">
                <Dropdown
                  overlay={
                    <Menu
                      onClick={onSetPresetDates}
                      items={datesPresets.map((ps, i) => ({
                        key: `${ps.dt1}|${ps.dt2}|${i}`,
                        label: ps.label,
                      }))}
                    />
                  }
                >
                  <Button size="small" icon={<CalendarOutlined />} />
                </Dropdown>
              </div>
            )}

            <div className={cn(styles.elCtrl, styles.datesFilter)} key="dates-filter">
              <Row gutter={[0, 0]}>
                <XDFDateRange
                  it={{
                    id: 'table-dates-filter',
                    prop: datesFilter[0],
                    prop2: datesFilter[1],
                    format: datesFilter[2],
                  }}
                  size="small"
                  model={filter}
                  onPropChanged={(it, value, value2) => {
                    changeTableState({
                      filter: {
                        ...filter,
                        [it.prop]: value,
                        [it.prop2]: value2,
                      },
                      pageNumber: 1,
                    });
                  }}
                />
              </Row>
            </div>
          </>
        )}

        {!!filterForm && (
          <div className={cn(styles.elCtrl, styles.filter)} key="filter">
            <Tooltip title="Фильтрация данных">
              <Button
                type={Object.keys(filter).length > 0 ? 'primary' : null}
                size="small"
                icon={<FilterOutlined />}
                onClick={onShowFilter}
              />
            </Tooltip>
            <Tooltip title="Сбросить фильтр">
              <Button
                size="small"
                icon={<CloseCircleOutlined />}
                onClick={onResetFilter}
                style={{ marginLeft: 8 }}
              />
            </Tooltip>
            <Tooltip title="Обновить список">
              <Button
                size="small"
                icon={<ReloadOutlined />}
                onClick={loadData}
                style={{ marginLeft: 8 }}
              />
            </Tooltip>
          </div>
        )}

        <div className={cn(styles.elCtrl, styles.last)}>
          <Pagination
            size="small"
            total={tableState.total}
            showSizeChanger
            showQuickJumper
            pageSize={pageSize}
            current={pageNumber}
            onChange={onChangePageNumber}
            showTotal={(total) => `${total}`}
            pageSizeOptions={[10, 20, 50, 100, 500]}
            locale={{
              page: '',
              jump_to: <SwapOutlined title="Перейти к нужной странице" />,
            }}
          />
        </div>
      </div>
    );
  }

  function renderHdr1() {
    if (commonColumns.name === null) return null;

    // calc extColumns groups
    const groupsStartIdx = extColumnsSelected
      ? extColumnsSelected.columns.findIndex((c) => Array.isArray(c.group))
      : -1;
    return (
      <tr key="hdr1">
        <th key="common-hdr" colSpan={commonColumns.columns.length + (enableItemsSelect ? 1 : 0)}>
          {commonColumns.name}
        </th>
        {extColumnsSelected && groupsStartIdx < 0 && (
          <th key="ext-hdr" colSpan={extColumnsSelected.columns.length}>
            {extColumnsSelected.name}
          </th>
        )}
        {groupsStartIdx >= 0 &&
          extColumnsSelected.columns
            .filter((c) => c.group)
            .map((c, i) => (
              <th key={`ext-hdr-g${i}`} colSpan={c.group[1]}>
                {c.group[0]}
              </th>
            ))}
      </tr>
    );
  }

  function renderSortBtn(col) {
    if (!col.sort) return null;

    const colProp = xdGetColProp(col.prop);
    let icon = <SortIcon />;
    if (colProp === sortProp) {
      if (sortOrder === 1) {
        icon = <SortIconUp />;
      } else {
        icon = <SortIconDown />;
      }
    }
    return (
      <button
        type="button"
        onClick={() => onChangeSort(colProp)}
        className={cn({ [styles.hl]: colProp === sortProp })}
      >
        {icon}
      </button>
    );
  }

  const onCheckAllRows = ({ target }) => {
    const sel = {};
    tableState.list.forEach((item) => {
      sel[item[enableItemsSelect.prop || '_id']] = target.checked;
    });
    setCheckedRow({
      ...checkedRow,
      ...sel,
    });
  };

  const isAllChecked = () => {
    for (const item of tableState.list) {
      if (checkedRow[item[enableItemsSelect.prop || '_id']]) return true;
    }
    return false;
  };

  function renderHdr2() {
    return (
      <tr key="hdr2" className={styles.secondHdr}>
        {enableItemsSelect && (
          <th key="checkbox" className={styles.checkCell}>
            <input type="checkbox" onChange={onCheckAllRows} checked={isAllChecked()} />
          </th>
        )}
        {commonColumns.columns.map((col, idx) => (
          <th key={`${col.prop}-p${idx}`} className={cn({ [styles.sortHdr]: !!col.sort })}>
            <span>{col.name}</span>
            {renderSortBtn(col)}
          </th>
        ))}
        {extColumnsSelected &&
          extColumnsSelected.columns.map((col, idx) => (
            <th key={`${col.prop}-s${idx}`} className={cn({ [styles.sortHdr]: !!col.sort })}>
              <span>{col.name}</span>
              {renderSortBtn(col)}
            </th>
          ))}
      </tr>
    );
  }

  function getRowValue(col, prop, item, forExcel = false) {
    let val = gv(item, prop);

    // check inline
    const inlienEditKey = `${item._id}:${prop}`;
    if (inlineEditLog[inlienEditKey] !== undefined) {
      val = inlineEditLog[inlienEditKey];
    }

    // if prop2value
    if (!col.render && col.prop2value && prop2ValueCache[prop]) {
      if (Array.isArray(val)) {
        val = val.map((x) => {
          const v = prop2ValueCache[prop][x];
          return v !== undefined ? v : '';
        });
      } else {
        val = prop2ValueCache[prop][val];
        if (val === undefined) val = '';
      }
    }

    // types
    if (col.type === 'check') {
      if (forExcel) val = val ? '+' : '';
      else val = val ? <CheckOutlined /> : '';
    }
    if (col.type === 'date' && val) {
      val = dayjs(val).format(col.format || 'DD.MM.YYYY HH:mm:ss');
    }

    // round
    if (typeof val === 'number' && col.round !== undefined) {
      val = xdRoundPlus(val, col.round);
    }

    // if render
    if (!forExcel && col.render) {
      val = col.render(item, val, prop2ValueCache[prop]);
    } else if (forExcel && col.excelRender) {
      if (col.excelRender === 'render') val = col.render(item, val);
      else val = col.excelRender(item, val);
    }

    if (col.notNull && val === 0) {
      val = '';
    }

    if (Array.isArray(val)) {
      val = val.map((x, i) => (
        <span key={i} className={styles.multi}>
          {x}
        </span>
      ));
    } else if (col.type === 'bigInt') {
      if (!forExcel && typeof val === 'number') {
        val = val.toLocaleString();
      }
    }

    if (!forExcel) return val;

    // forExcel
    if (typeof val === 'string') return val;
    if (typeof val === 'number') return val;
    if (val === undefined) return '';
    if (val === null) return '';

    return String(val);
  }

  function renderRow(colPart, item, rowIdx) {
    if (!colPart) return null;
    const row = [];

    let idx = 0;
    for (const col of colPart.columns) {
      let style = {};
      const prop = xdGetColProp(col.prop);
      const val = getRowValue(col, prop, item);

      if (commonColumns.rowStyle) {
        const rs = commonColumns.rowStyle(item);
        if (rs) {
          style = {
            ...style,
            ...rs,
          };
        }
      }

      // styles
      if (col.style) {
        style = {
          ...style,
          ...col.style,
        };
      }

      if (col.width > 0) {
        style.width = col.width;
      } else if (col.width) {
        style.width = col.width;
      }
      if (col.align) {
        style.textAlign = col.align;
      }

      if (col.zeroColor) {
        const vvv = parseInt(val, 10);
        if (vvv > 0) style.color = 'green';
        else if (vvv < 0) style.color = 'red';
      }

      // classname
      let cellClassName;
      if (col.className) {
        if (typeof col.className === 'function') {
          cellClassName = col.className(item, val);
        } else {
          cellClassName = col.className;
        }
      }

      // eslint-disable-next-line no-inner-declarations
      function onClickTD(ev) {
        ev.preventDefault();
        if (col.onClick) {
          col.onClick(item, rowIdx, filter);
        } else if (onRowClick) {
          onRowClick(item, rowIdx, filter);
        }
      }

      const idPropName = sioEvents ? sioEvents[3] || '_id' : '_id';
      if (!col.inline) {
        row.push(
          <td
            key={`${prop}-${colPart.id}-${idx++}`}
            style={style}
            onClick={col.actions ? undefined : onClickTD}
            className={cn({ [styles.nowrap]: col.nowrap }, cellClassName, {
              [styles.hl]: highlightId === item[idPropName],
            })}
          >
            <div>{val}</div>
          </td>,
        );
      } else {
        const changedValue = inlineEditLog[`${item._id}:${prop}`];

        row.push(
          <td
            key={`${prop}-${colPart.id}-${idx++}`}
            style={style}
            className={cn({ [styles.nowrap]: col.nowrap }, styles.inlineEditCell, cellClassName, {
              [styles.hl]: highlightId === item[idPropName],
            })}
          >
            <div
              role="button"
              tabIndex={0}
              className={cn(
                styles.inlineValue,
                {
                  [styles.editing]:
                    inlineEdit.visible && item._id === inlineEdit.id && prop === inlineEdit.prop,
                },
                {
                  [styles.changedValue]: changedValue !== undefined,
                },
              )}
              onClick={(ev) => {
                ev.preventDefault();
                // showInlineDlg(item._id, prop, changedValue !== undefined ? changedValue : val);
                showInlineDlg(item._id, prop, val, col.type);
              }}
            >
              {/* {changedValue !== undefined ? changedValue : val} */}
              {val}
            </div>
            {inlineEdit.visible && item._id === inlineEdit.id && prop === inlineEdit.prop && (
              <div
                className={styles.inlineDlg}
                ref={refInlineDlg}
                style={{ marginLeft: -tableScroll.x }}
              >
                <XDataForm
                  plain
                  model={inlineEdit.model}
                  form={inlineEdit.form}
                  onChangeModel={onChangeInlineEditModel}
                />
                <div className={styles.ctrls}>
                  {/* <Button
                    size="small"
                    icon={<UndoOutlined />}
                    disabled={changedValue === undefined}
                    onClick={onResetInlienEditValue}
                    style={{ marginRight: 8 }}
                  /> */}
                  <Button
                    type="primary"
                    size="small"
                    icon={<EditOutlined />}
                    onClick={onSaveInlineEditValue}
                    disabled={!inlineEdit.isChanged}
                  >
                    Сохранить
                  </Button>
                </div>
                <button type="button" className={styles.close} onClick={closeInlienEditDlg}>
                  ✕
                </button>
              </div>
            )}
          </td>,
        );
      }
    }

    return row;
  }

  const onCheckRow = ({ target }, item) => {
    setCheckedRow({
      ...checkedRow,
      [item[enableItemsSelect.prop || '_id']]: target.checked,
    });
  };

  function renderTableBody() {
    return tableState.list.map((item, rowIdx) => {
      let selectThisRow = false;
      if (Array.isArray(selectRow) && selectRow.length === 2) {
        if (item[selectRow[0]] === selectRow[1]) selectThisRow = true;
      }

      return (
        <tr key={`${item._id}-${rowIdx}`} className={cn({ [styles.selectRow]: selectThisRow })}>
          {enableItemsSelect && (
            <td key="checkbox" className={styles.checkCell}>
              <input
                type="checkbox"
                onChange={(e) => onCheckRow(e, item)}
                checked={!!checkedRow[item[enableItemsSelect.prop || '_id']]}
              />
            </td>
          )}
          {renderRow(commonColumns, item, rowIdx)}
          {extColumnsSelected && renderRow(extColumnsSelected, item, rowIdx)}
        </tr>
      );
    });
  }

  function renderTable() {
    return (
      <table>
        <thead>
          {renderHdr1()}
          {renderHdr2()}
        </thead>
        <tbody>{renderTableBody()}</tbody>
      </table>
    );
  }

  return (
    <>
      <div className={styles.listHolder}>
        {listControls()}
        <div className={styles.list} ref={tableRef}>
          {renderTable()}
          {loadingList && <div className={styles['progress-line']} />}
        </div>
      </div>

      {!!filterForm && (
        <Modal
          title="Фильтрация объектов"
          open={filterDialog.visible}
          onOk={onSetFilter}
          onCancel={() => setFilterDialog({ ...filterDialog, visible: false })}
          width={700}
          bodyStyle={{
            overflowY: 'auto',
            maxHeight: windowSize.height - 220,
          }}
          style={{ top: 40 }}
          okText="Применить"
        >
          <XDataForm
            table
            size="small"
            model={filterDialog.model}
            onChangeModel={onFilterChanged}
            form={filterForm}
            debug={!!debug?.filter}
          />
        </Modal>
      )}

      <XDTExportToExcel
        startExport={startExport}
        onCloseExport={onCloseExport}
        excelExport={{
          ...excelExport,
          getRowValue,
          apiLoadData,
          filter,
          props,
          searchProps,
          sortProp,
          sortOrder,
          commonColumns,
          extColumnsSelected,
        }}
      />
    </>
  );
}

XDataTable.defaultProps = {
  postLoadData: null,
  sioEvents: [],
  urlParamName: '_t_',
  selectRow: null,
  defValues: {
    pageSize: 20,
  },
  initFilter: {},
  datesFilter: null,
  datesPresets: null,
  filterForm: null,
  initSort: ['', 1],
  onRowClick: null,
  searchProps: null,
  searchLabel: 'Искать',
  leftActions: null,
  inlineEditFormProps: [],
  onInlineEditChanged: null,
  extentedColumns: null,
  onSaveEventEmitter: null,
  onCallbacks: null,
  onSaveTableHandlers: null,
  excelExport: null,
  enableItemsSelect: null,
  debug: null,
};

XDataTable.propTypes = {
  // API load data
  apiLoadData: PropTypes.func.isRequired, // функция для загрузки данных
  postLoadData: PropTypes.func, // функция для обработки (если возвращает - то изменение) данных
  sioEvents: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func])), // [channelName, subscribeToChannel, unsubscribeToChannel, {название поля = msg.id}]
  // columns
  commonColumns: PropTypes.shape({
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    props: PropTypes.arrayOf(PropTypes.string),
    rowStyle: PropTypes.func,
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        prop: PropTypes.string.isRequired,
        name: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      }),
    ).isRequired,
  }).isRequired,
  extentedColumns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      columns: PropTypes.arrayOf(
        PropTypes.shape({
          prop: PropTypes.string.isRequired,
          name: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        }),
      ).isRequired,
    }),
  ),
  // url parameter state
  urlParamName: PropTypes.string,
  // object
  selectRow: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])), // ['id-prop-name', 'value']
  // default
  defValues: PropTypes.shape({
    pageSize: PropTypes.number,
  }),
  // sort
  initSort: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  // filter
  initFilter: PropTypes.shape({}),
  filterForm: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.number,
    }),
  ),
  // search
  searchProps: PropTypes.arrayOf(PropTypes.string), // если перед именем поля стоит # - то искать как число
  searchLabel: PropTypes.string,
  // date periods filter
  datesFilter: PropTypes.arrayOf(PropTypes.string), // ['fromPropName', 'toPropName', 'DATE_FORMAT']
  datesPresets: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      dt1: PropTypes.string,
      dt2: PropTypes.string,
    }),
  ),
  // events
  onRowClick: PropTypes.func,
  // actions - buttons
  leftActions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.element,
      onClick: PropTypes.func,
      tooltip: PropTypes.string,
    }),
  ),
  // inline
  inlineEditFormProps: PropTypes.arrayOf(PropTypes.shape({})),
  onInlineEditChanged: PropTypes.func,
  // external ctrl
  onSaveEventEmitter: PropTypes.func,
  // on callbacks / controls
  onCallbacks: PropTypes.shape({
    onFilterChanged: PropTypes.func,
  }),
  onSaveTableHandlers: PropTypes.func,
  // Excel export table
  excelExport: PropTypes.shape({
    filename: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    tabname: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    exportStep: PropTypes.number,
    autoWidth: PropTypes.bool,
  }),
  // select items (checkboxes)
  enableItemsSelect: PropTypes.shape({
    prop: PropTypes.string,
    type: PropTypes.string, // 'string' (default) | 'number'
  }),
  // DEBUG
  debug: PropTypes.shape({
    filter: PropTypes.bool,
  }),
};

export default XDataTable;
