import { message } from 'antd';
import axios from 'axios';

/**
 * Генерация ошибки (сообщения)
 * @param errCode
 * @returns {Error}
 * @constructor
 */
export const ApiError = (err, errmsg) =>
  new Error(`Network API error: ${err}${errmsg ? ` -> ${errmsg}` : ''}`);

/**
 * Создать правильный конфиг для axios
 * @returns {{baseURL: string, headers: {}}}
 */
export const getAxiosConfig = () => {
  const axiosConfig = {
    baseURL: '/api/1',
    headers: {},
  };

  // если есть токен, добавить его в header
  // if (getApiToken()) {
  //   axiosConfig.headers['access-token'] = getApiToken();
  // }

  return axiosConfig;
};

/**
 * Создать правильный конфиг для axios
 * @returns {{baseURL: string, headers: {}}}
 */
export const getAxiosConfig2 = () => {
  const axiosConfig = {
    baseURL: '/api/2',
    headers: {},
  };

  // если есть токен, добавить его в header
  // if (getApiToken()) {
  //   axiosConfig.headers['access-token'] = getApiToken();
  // }

  return axiosConfig;
};

/**
 * Сделать POST запрос к серверу
 * @param url
 * @param data
 * @returns {AxiosPromise<any>}
 * @constructor
 */
export const apiPostRaw = (url, data) => axios.post(url, data, getAxiosConfig());

/**
 * Запрос сервера с разбором статуса ответа (200)
 * @param {*} url
 * @param {*} data
 */
export const apiPost = (url, data = {}) =>
  new Promise((r) => {
    apiPostRaw(url, { ...data /* , _tt_: getTerminalToken() */ }).then((result) => {
      if (result.status === 200) {
        r(result.data);
      } else {
        console.error('HTTP Error', result);
      }
    });
  });

export const apiPostData = async (url, params = {}) => {
  const { err, errmsg, data } = await apiPost(url, params);
  if (err !== 0) {
    if (Array.isArray(errmsg)) {
      for (const msg of errmsg) {
        message.error(msg.message);
      }
    } else {
      message.error(errmsg || `Net error ${err}`);
    }
    throw ApiError(err, errmsg);
  }
  return data;
};

/**
 * Сделать GET запрос к серверу (необходимо указать полный URL с доменом)
 * @param url
 * @param params
 * @returns {Promise<any>}
 * @constructor
 */
export const apiPureGet = (url, params = {}) => axios.get(url, { params });

/**
 * Выполнить POST запрос к серверу API v2
 * @param {string} route
 * @param {object} data
 */
export const netPOSTv2 = async (route, data) =>
  new Promise((resolve) => {
    axios
      .post(route, data, getAxiosConfig2())
      .then((response) => {
        const rsd = response.data;
        if (rsd.err === 0) {
          resolve({ err: 0, data: rsd.data });
        } else {
          resolve({ err: rsd.err, errmsg: rsd.errmsg });
        }
      })
      .catch((error) => {
        /* eslint-disable no-console */
        console.error(error);
        resolve({ err: -1 });
      });
  });
