import React, { useEffect, useRef, useState } from 'react';

import { Col, Input } from 'antd';
import cn from 'classnames';
import gv from 'get-value';
import IMask from 'imask';
import { phone } from 'phone';
import PropTypes from 'prop-types';

import { PhoneOutlined } from '@ant-design/icons';

// import PhoneInput from 'react-phone-input-2';
// import ru from 'react-phone-input-2/lang/ru.json';
import styles from './styles.scss';

function XDFPhone({ it, size, model, onPropChanged }) {
  const inputRef = useRef(null);
  const { id, prop, label, autoFocus, span, readOnly, showOnly, require, placeholder, mask } = it;
  const [phone0, setPhone0] = useState('');

  useEffect(() => {
    const val = gv(model, prop, '');
    setPhone0(val);
  }, []);

  useEffect(() => {
    if (inputRef.current?.input) {
      IMask(inputRef.current.input, {
        mask: mask || '+{7} (000) 000-00-00',
      });
    }
  }, [inputRef.current]);

  // const onChange = (val) => {
  //   setPhone0(val);
  //   if (val) {
  //     // eslint-disable-next-line no-underscore-dangle
  //     const _phone = (val.charAt(0) === '+' ? '' : '+') + val;
  //     const phRes = phone(_phone);
  //     if (phRes.isValid) {
  //       onPropChanged(it, phRes.phoneNumber);
  //     }
  //   }
  // };

  const onChange = (ev) => {
    const val = ev.target.value;
    setPhone0(val);
    if (val) {
      // eslint-disable-next-line no-underscore-dangle
      const _phone = (val.charAt(0) === '+' ? '' : '+') + val;
      const phRes = phone(_phone);
      if (phRes.isValid) {
        onPropChanged(it, phRes.phoneNumber);
      } else {
        onPropChanged(it, '');
      }
    }
  };

  return (
    <Col span={span || 24}>
      <div className={styles.formItem}>
        <div className={styles.formItemLabel}>
          <label htmlFor={id}>
            {label}
            {require && <sup>*</sup>}
          </label>
        </div>
        <div
          className={cn(
            styles.formItemField,
            { [styles.small]: size === 'small' },
            { [styles.showOnly]: !!showOnly },
          )}
        >
          {/* <PhoneInput
            localization={ru}
            country="ru"
            value={phone0}
            onChange={onChange}
            styles={{ width: '100%' }}
            autoFormat
            disabled={!!readOnly || !!showOnly}
            inputProps={{
              name: 'phone',
              required: true,
              autoFocus: !!autoFocus,
            }}
            copyNumbersOnly
          /> */}

          <Input
            ref={inputRef}
            id={id}
            size={size}
            placeholder={placeholder || ''}
            autoFocus={!!autoFocus}
            disabled={!!readOnly || !!showOnly}
            value={phone0}
            onChange={onChange}
            prefix={<PhoneOutlined />}
          />
        </div>
      </div>
    </Col>
  );
}

XDFPhone.propTypes = {
  it: PropTypes.shape({
    id: PropTypes.string.isRequired,
    prop: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    placeholder: PropTypes.string,
    autoFocus: PropTypes.bool,
    span: PropTypes.number,
    readOnly: PropTypes.bool,
    showOnly: PropTypes.bool,
    suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    mask: PropTypes.string,
  }).isRequired,
  size: PropTypes.string.isRequired,
  model: PropTypes.shape({}).isRequired,
  onPropChanged: PropTypes.func.isRequired,
};

export default XDFPhone;
