import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Button, Col, Divider, Input, Select } from 'antd';
import cn from 'classnames';
import gv from 'get-value';
import PropTypes from 'prop-types';

import { PlusOutlined } from '@ant-design/icons';

import styles from './styles.scss';

function XDFSelect({ it, size, model, onPropChanged, multiple }) {
  const {
    id,
    prop,
    label,
    placeholder,
    autoFocus,
    span,
    readOnly,
    showOnly,
    values,
    filterValues,
    loadingDataMsg,
    canAddValue,
  } = it;

  const mountedRef = useRef(false);
  const isMounted = useCallback(() => mountedRef.current, []);

  const [loading, setLoading] = useState(false);
  const [values0, setValues0] = useState([]);
  const [newValue, setNewValue] = useState('');

  // mount / unmount
  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  });

  // load values, if promise
  useEffect(() => {
    if (values && Array.isArray(values)) {
      setValues0(values);
    } else {
      // eslint-disable-next-line react/prop-types
      values().then((list) => {
        // console.log(list);
        if (isMounted()) {
          setValues0(list);
          setLoading(false);
        }
      });
      setLoading(true);
    }
  }, [values]);

  const onChange = (value) => {
    onPropChanged(it, value);
  };

  const onChangeNewValue = (ev) => {
    setNewValue(ev.target.value);
  };

  const onAddNewValue = () => {
    setValues0([
      ...values0,
      {
        id: newValue,
        name: newValue,
      },
    ]);
    setNewValue('');
  };

  let showOnlyVal = '';
  let val = gv(model, prop, multiple ? [] : undefined);
  if (loading) {
    val = multiple ? [] : undefined;
  }

  if (multiple && !Array.isArray(val)) {
    console.error(`Error with value for prop: ${prop} -> value = ${val} - MUST BE ARRAY!`);
  }

  if (showOnly) {
    const item = values0.find((x) => x.id === val);
    if (item?.name) showOnlyVal = item.name;
  }

  // check val
  if (!Array.isArray(val)) {
    const item = values0.find((x) => x.id === val);
    if (!item) {
      val = '';
    }
  }

  let dropdownRender = false;
  if (canAddValue) {
    dropdownRender = (menu) => (
      <>
        {menu}
        <Divider style={{ margin: '4px 0' }} />
        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
          <Input
            size="small"
            style={{ flex: 'auto' }}
            value={newValue}
            onChange={onChangeNewValue}
          />
          <Button
            size="small"
            type="dashed"
            onClick={onAddNewValue}
            disabled={!newValue}
            style={{ marginLeft: 4 }}
          >
            <PlusOutlined />
          </Button>
        </div>
      </>
    );
  }

  return (
    <Col span={span || 24}>
      <div className={styles.formItem}>
        {!!label && (
          <div className={styles.formItemLabel}>
            <label htmlFor={id}>{label}</label>
          </div>
        )}
        <div
          className={cn(
            styles.formItemField,
            { [styles.large]: size === 'large' },
            { [styles.small]: size === 'small' },
            { [styles.selectNotShow]: loading },
          )}
        >
          {showOnly && (
            <div className="ant-select ant-select-single" style={{ width: '100%' }}>
              <div className="ant-select-selector">
                <span className="ant-select-selection-item">{showOnlyVal}</span>
              </div>
            </div>
          )}

          {!showOnly && (
            <Select
              id={id}
              size={size}
              placeholder={loading ? loadingDataMsg || 'loading data...' : placeholder}
              autoFocus={!!autoFocus}
              disabled={!!readOnly || loading}
              value={val}
              onChange={onChange}
              style={{ width: '100%' }}
              showSearch
              loading={loading}
              mode={multiple ? 'multiple' : ''}
              optionFilterProp="children"
              dropdownRender={dropdownRender}
            >
              {(values0 || [])
                .filter((v) => {
                  if (!filterValues) return true;
                  return filterValues.includes(v.id);
                })
                .map((v) => (
                  <Select.Option key={v.id} value={v.id}>
                    {v.name}
                  </Select.Option>
                ))}
            </Select>
          )}
        </div>
      </div>
    </Col>
  );
}

XDFSelect.propTypes = {
  it: PropTypes.shape({
    id: PropTypes.string.isRequired,
    prop: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    placeholder: PropTypes.string,
    autoFocus: PropTypes.bool,
    span: PropTypes.number,
    readOnly: PropTypes.bool,
    showOnly: PropTypes.bool,
    values: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
    filterValues: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    loadingDataMsg: PropTypes.string,
    canAddValue: PropTypes.bool,
  }).isRequired,
  size: PropTypes.string.isRequired,
  model: PropTypes.shape({}).isRequired,
  onPropChanged: PropTypes.func.isRequired,
  multiple: PropTypes.bool.isRequired,
};

export default XDFSelect;
