/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';

import { AutoComplete, Col, Modal, Tooltip } from 'antd';
import cn from 'classnames';
import gv from 'get-value';
import PropTypes from 'prop-types';
import { useDebounce, useMountedState } from 'react-use';

import { ExclamationCircleOutlined } from '@ant-design/icons';

import styles from './styles.scss';

function XDFGeocoder({ it, size, model, onPropChanged }) {
  const isMounted = useMountedState();

  const {
    id,
    prop,
    label,
    placeholder,
    autoFocus,
    span,
    readOnly,
    revGeocoder,
    geocoder,
    pointNumber,
    pointNumberColor,
    onClickPointNumber,
    removeBtn,
    switchBtn,
    onClickSwitch,
    onlyRemove,
    alertBeforeRemove,
    tooltip,
  } = it;
  const [val0, setVal0] = useState('');
  const [options, setOptions] = useState([]);

  const onSelect = (val, option) => {
    setVal0(option.label);
    onPropChanged(it, option.value.split(',').map(parseFloat));
  };

  const removePoint = () => {
    if (alertBeforeRemove) {
      Modal.confirm({
        icon: <ExclamationCircleOutlined />,
        title: alertBeforeRemove,
        onOk() {
          onPropChanged(it, null);
        },
        onCancel() {},
      });
    } else {
      onPropChanged(it, null);
    }
  };

  const [, cancel] = useDebounce(
    async () => {
      if (val0 && val0.length > 2 && !options.find((x) => x.label === val0)) {
        const list = await geocoder(val0);
        if (isMounted()) setOptions(list);
      }
    },
    300,
    [val0],
  );

  const onSearchAddress = (addr) => {
    setVal0(addr);
  };

  const reverse = async (ll) => {
    const sll = ll.join(',');
    if (options.length === 0 || !options.find((x) => x.value === sll)) {
      const addr = await revGeocoder(ll);
      if (isMounted()) setVal0(addr);
    }
  };

  const val = gv(model, prop);
  useEffect(() => {
    if (val && Array.isArray(val) && val.length === 2) reverse(val);
  }, [val]);

  const onFocus = (ev) => {
    ev.target.select();
  };

  const clickOnPointNumber = () => {
    onClickPointNumber(val);
  };

  return (
    <Col span={span || 24}>
      <div className={styles.formItem}>
        {!!label && (
          <div className={styles.formItemLabel}>
            <label htmlFor={id}>{label}</label>
          </div>
        )}
        <Tooltip title={tooltip}>
          <div
            className={cn(
              styles.formItemField,
              styles.autocomplete,
              {
                [styles.small]: size === 'small',
              },
              { [styles.withPointNumber]: !!pointNumber },
              { [styles.withRemoveBtn]: !!removeBtn },
            )}
          >
            <AutoComplete
              id={id}
              size={size}
              placeholder={placeholder || ''}
              autoFocus={!!autoFocus}
              disabled={!!readOnly || !!onlyRemove}
              value={val0}
              options={options}
              onSelect={onSelect}
              onSearch={onSearchAddress}
              onFocus={onFocus}
              style={{ width: '100%' }}
            />
            {!!pointNumber && (
              <div
                className={styles.pointNumber}
                style={{ backgroundColor: pointNumberColor }}
                onClick={clickOnPointNumber}
              >
                {pointNumber}
              </div>
            )}
            {!!removeBtn && !readOnly && (
              <button
                className={styles.removePoint}
                type="button"
                title="Удалить точку"
                onClick={removePoint}
              >
                &times;
              </button>
            )}
            {switchBtn && (
              <button
                type="button"
                className={styles.switchPoint}
                onClick={(ev) => onClickSwitch(ev, prop)}
                title="Поменять местами"
              >
                <svg
                  stroke="#000000"
                  fill="none"
                  strokeWidth="0"
                  viewBox="0 0 24 24"
                  height="14px"
                  width="14px"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M7 16V4m0 0L3 8m4-4l4 4m6 0v12m0 0l4-4m-4 4l-4-4"
                  />
                </svg>
              </button>
            )}
          </div>
        </Tooltip>
      </div>
    </Col>
  );
}

XDFGeocoder.propTypes = {
  it: PropTypes.shape({
    id: PropTypes.string.isRequired,
    prop: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    placeholder: PropTypes.string,
    autoFocus: PropTypes.bool,
    span: PropTypes.number,
    readOnly: PropTypes.bool,
    onlyRemove: PropTypes.bool,
    alertBeforeRemove: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    revGeocoder: PropTypes.func.isRequired,
    geocoder: PropTypes.func.isRequired,
    pointNumber: PropTypes.string,
    pointNumberColor: PropTypes.string,
    onClickPointNumber: PropTypes.func,
    removeBtn: PropTypes.bool,
    switchBtn: PropTypes.bool,
    onClickSwitch: PropTypes.func,
    tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  }).isRequired,
  size: PropTypes.string.isRequired,
  model: PropTypes.shape({}).isRequired,
  onPropChanged: PropTypes.func.isRequired,
};

export default XDFGeocoder;
